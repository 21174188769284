import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../Styles/Billing/TableDesign";

const AttendanceTable = ({ data }) => {
  

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Member</StyledTableCell>
            <StyledTableCell>InTime</StyledTableCell>
            <StyledTableCell>OutTime</StyledTableCell>
            <StyledTableCell>Working Time</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell>{item.get("date", "")}</StyledTableCell>
                <StyledTableCell>{item.get("member", "")}</StyledTableCell>
                <StyledTableCell>{item.get("inTime", "")}</StyledTableCell>
                <StyledTableCell>{item.get("outTime", "")}</StyledTableCell>
                <StyledTableCell>{item.get("workingTime", "")}</StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AttendanceTable;
