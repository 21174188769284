import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  button: {
    background: "#f3f5f7",
    padding: "5px 16px",
    border: "1px solid #CFD7DF",
    textTransform: "capitalize",
    letterSpacing: "1px",
    boxShadow: "none",
    color: "black",
    "&:hover": {
      backgroundColor: "#DCE5EF",
      boxShadow: "none",
    },
    "&:active": {
      backgroundColor: "#f3f5f7",
      boxShadow: "none",
    },
  },


});
