import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { Autocomplete, Box, TextField } from "@mui/material";
import { selectUsers } from "../../../Redux/TestCase/Selector";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../../Redux/TestCase/Action";
import { fromJS } from "immutable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const ApprovalPopup = ({
  approval,
  setApproval,
  handleApproval,
  Auth_data,
}) => {
  const initialFormData = fromJS({
    Name: "",
    Description: "",
  });
  const Users = useSelector(selectUsers).toJS();
  const dispatch = useDispatch();
  const [data, setData] = useState(initialFormData);
  console.log("approvla data", data);
  const handleClose = () => {
    // setApproval(false);
    console.log("setApproval");
    setApproval(false);
  };
  const handleChange = (onChangeValue, key) => {
    if (key === "Name") {
      setData((prev) => {
        return prev.set(key, fromJS(onChangeValue));
      });
    } else {
      setData((prev) => {
        return prev.set(key, onChangeValue.target.value);
      });
    }
  };
  useEffect(() => {
    dispatch(getUserData());
  }, []); // eslint-disable-line
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={approval}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "400px", // Set your width here
            height: "250px",
            borderRadius: "10px",
          },
        },
      }}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Get Approval
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box className="d-flex flex-column h-100 justify-content-center align-items-center gap-3">
          <Autocomplete
            id="To"
            size="small"
            options={Users.filter(
              (item) => item.email !== Auth_data?.email
            ).map((item) => {
              return { ...item, label: item.username };
            })}
            fullWidth
            renderInput={(params) => <TextField {...params} label="To" />}
            onChange={(e, option) => handleChange(option, "Name")}
          />

          <TextField
            fullWidth
            id="outlined-basic"
            label="Description"
            variant="outlined"
            onChange={(e) => handleChange(e, "Description")}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleApproval(data)}
          disabled={
            data.get("Name", "") === "" || data.get("Description", "") === ""
              ? true
              : false
          }
        >
          Submit
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ApprovalPopup;
