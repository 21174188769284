import { Autocomplete, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SubDrawer from "@mui/material/Drawer";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./ReportSideBar.css";
import {
  selectSupportClientList,
  selectSupportList,
  selectIssueData,
} from "../../../../../Redux/ClientSupportManagement/Selector";
import { useDispatch, useSelector } from "react-redux";
import { List, fromJS, Map } from "immutable";
import {
  getSupportClient,
  setData,
} from "../../../../../Redux/ClientSupportManagement/Action";
import { ToastContainer, toast } from "react-toastify";

const drawerWidth = 310;
const StatusFilter = ["Open", "Close", "Inprogress", "Waiting on Customer"];
const priorityFilter = ["Low", "Medium", "High", "Urgent"];
const IssueType = ["Issue", "Data Update", "Feature"];
const ClientType = ["LoginPage", "student", "staff"];
const defaultData = Map({
  page: 1,
  limit: 10,
  clientName: "",
  clientType: "",
  type: "",
  priority: "",
  status: "",
  startDate: null,
  endDate: null,
});

const ReportSideBar = ({ open }) => {
  const [date, setDate] = useState(fromJS({ startDate: null, endDate: null }));

  const ClientList = useSelector(selectSupportList);
  const IssueDataFilter = useSelector(selectIssueData);
  const dispatch = useDispatch();
  const supportClientList = useSelector(selectSupportClientList);
  const [initialClientList, setInitialClientList] = useState(List());
  useEffect(() => {
    if (!supportClientList.size) dispatch(getSupportClient());
  }, [supportClientList]); //eslint-disable-line
  useEffect(() => {
    setInitialClientList(ClientList.get("AllClientId", List()));
  }, [ClientList, IssueDataFilter]); //eslint-disable-line

  const clientName = supportClientList
    .filter((client) => initialClientList.includes(client.get("_id", "")))
    .map((client) => ({
      name: client.get("clientName", ""),
      value: client.get("_id", ""),
    }))
    .toJS();

  const getSelectedClientName = () => {
    if (!IssueDataFilter.get("clientName", "")) return null;
    const selectedOption = clientName.find(
      (item) => item.value === IssueDataFilter.get("clientName", "")
    );
    if (selectedOption) return selectedOption;
    return null;
  };

  const handleValidateDate = () => {
    if (date.get("startDate", 0) > date.get("endDate", 0)) {
      if (Boolean(date.get("endDate", 0))) {
        toast.error(
          "To Date must be later than the From Date to ensure the validity of the range.",
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        return true;
      }
      return false;
    } else {
      return true;
    }
  };

  const handleSetDate = (key, value) => {
    setDate((pre) => pre.set(key, value));
  };

  useEffect(() => {
    if (handleValidateDate()) {
      dispatch(
        setData(
          fromJS({
            filterClientIssueData: IssueDataFilter.set(
              "startDate",
              date.get("startDate", 0)
            ).set("endDate", date.get("endDate", 0)),
          })
        )
      );
    }
  }, [date]); //eslint-disable-line

  const handlefilterState = (key, value) => {
    let check = key === "clear";
    if (check) setDate(fromJS({ startDate: null, endDate: null }));
    dispatch(
      setData(
        fromJS({
          filterClientIssueData: check
            ? defaultData
            : IssueDataFilter.set(key, value).set("page", 1),
        })
      )
    );
  };

  return (
    <SubDrawer
      sx={{
        width: drawerWidth,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          height: "80vh",
          top: "120px",
          zIndex: "0",
          padding: "20px 8px 30px 16px",
          background: "#F5F7F9",
          borderLeft: "1px solid #cfd7df",
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <span>FILTERS</span>
        </div>
        <Button
          startIcon={<DeleteIcon />}
          variant="outlined"
          color="error"
          onClick={() => handlefilterState("clear", defaultData)}>
          Clear All
        </Button>
      </div>
      <div className="pt-4">
        <div className="mb-4">
          <Autocomplete
            disablePortal
            id="ClientName"
            size="small"
            value={getSelectedClientName()}
            options={clientName}
            getOptionLabel={(option) => option.name}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Client Name" />
            )}
            onChange={(e, option) => {
              handlefilterState("clientName", option ? option.value : "");
            }}
          />
        </div>
        <div className="mb-4">
          <Autocomplete
            disablePortal
            id="ClientType"
            size="small"
            value={IssueDataFilter.get("clientType", "")}
            options={ClientType}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Client Type" />
            )}
            onChange={(e, option) => {
              handlefilterState("clientType", option ? option : "");
            }}
          />
        </div>

        <div className="mb-4">
          <Autocomplete
            disablePortal
            id="IssueType"
            size="small"
            value={IssueDataFilter.get("type", "")}
            options={IssueType}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Issue Type" />
            )}
            onChange={(e, option) => {
              handlefilterState("type", option ? option : "");
            }}
          />
        </div>

        <div className="mb-4">
          <Autocomplete
            disablePortal
            id="priority"
            size="small"
            value={IssueDataFilter.get("priority", "")}
            options={priorityFilter}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Priority" />}
            onChange={(e, option) => {
              handlefilterState("priority", option ? option : "");
            }}
          />
        </div>

        <div className="mb-4">
          <Autocomplete
            disablePortal
            id="Status"
            size="small"
            value={IssueDataFilter.get("status", "")}
            options={StatusFilter}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Status" />}
            onChange={(e, option) => {
              handlefilterState("status", option ? option : "");
            }}
          />
        </div>

        <div className="mb-2">
          <label>Date Range</label>
        </div>
        <div className="mb-3 w-100 DatePicker">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From Date"
              value={date.get("startDate", "")}
              onChange={(date) => {
                handleSetDate("startDate", date);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </div>
        <div className="mt-3 DatePicker">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To Date"
              value={date.get("endDate", "")}
              onChange={(date) => {
                handleSetDate("endDate", date);
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" fullWidth />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>
      <ToastContainer style={{ width: "600px" }} />
    </SubDrawer>
  );
};

export default ReportSideBar;
