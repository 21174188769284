import { Autocomplete, Box, Button, TextField } from "@mui/material";
import { BootstrapDialog, BootstrapDialogTitle } from "../../TestCase/Styles";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, { useEffect, useState } from "react";

import { getUserData } from "../../../Redux/TestCase/Action";
import { useDispatch } from "react-redux";

const SharePopup = ({
  open,
  shareType,
  setShareType,
  options,
  selected,
  handleSubmit,
}) => {
  console.log(
    options,
    selected,
    options.filter((item) => selected.includes(item._id))
  );
  const initialValue = [];
  const [value, setValue] = useState(
    options.filter((item) => selected.includes(item._id))
  );
  console.log(value);
  const dispatch = useDispatch();
  const handleClose = () => {
    setValue(initialValue);
    setShareType("");
  };

  const handleChange = (e, option) => {
    setValue(option.map((d) => d._id));
  };

  useEffect(() => {
    dispatch(getUserData());
  }, []); // eslint-disable-line
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
              height: "250px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Share With {shareType}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box className="h-100 d-flex justify-content-center align-items-center">
            <Autocomplete
              multiple
              fullWidth
              id="tags-outlined"
              options={options}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              defaultValue={value}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${shareType} Name`}
                  placeholder="Name.."
                />
              )}
              onChange={(e, option) => handleChange(e, option)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleSubmit(shareType, value)}
            autoFocus
            disabled={value.length ? false : true}
          >
            submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SharePopup;
