import React from "react";
import { Button } from "@material-ui/core";
import { googleLogout } from "@react-oauth/google";
import logo from "../../Assets/logo.svg";
import warningIcon from "../../Assets/warning-icon.svg";
import "./PendingApproval.css";

const PendingApproval = () => {
  const logOut = () => {
    localStorage.clear();
    googleLogout();
    window.location.href = "/auth/login";
  };

  return (
    <div className="digi-login-page">
      <div className="digi-info">
        <div className="header">Welcome to Digival Solutions</div>
        <div className="sub-header">
          {" "}
          We build products for educational institutions to stay relevant for
          the present and future digital world.
        </div>
        <div className="sub-header">
          {" "}
          Digival IT Solutions Private Limited is founded to transform
          educational institutions to stay relevant for the present and future
          digital world by providing them relevant, personalized, appropriate,
          and cost justified products, solutions, and training. “Our products,
          solutions, & training enable the educational institutions to achieve
          the learning outcomes by enhancing the quality of programs{" "}
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <div className="registration-page">
          <div className="login-container w-auto m-2">
            <img src={logo} alt="logo" />
            <div className="digi-approval-container mt-5">
              <div class="digi-flex-column-center">
                <div class="digi-flex-row-center">
                  <img
                    src={warningIcon}
                    alt="warning"
                    className="digi-warning-icon"
                  />
                  <span className="digi-approve-admin">
                    Your account needs to be approved by Admin
                  </span>
                </div>
                <span className="digi-approve-email">
                  You will receive an email once your account has been approved.
                </span>
              </div>
              <div className="mt-4 ms-5">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 16 }}
                  onClick={logOut}
                >
                  Log Out
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingApproval;
