import React from 'react';
import './Loader.css';
import PropTypes from 'prop-types';

const Loader = (props) => {
  const showLoading = props.isLoading;
  const bg = props.bg;
  let loaderClass = `loader ${bg !== undefined ? bg : ''} `;

  if (!showLoading) {
    loaderClass += 'loader-fade ';
  }
  if (props.transparent === true) {
    loaderClass += 'gray-bg-transparent';
  } else {
    loaderClass += 'loaders';
  }

  return (
    <div id="loader" className={loaderClass}>
      <div className="spinner-frame">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
  transparent: PropTypes.bool,
  bg: PropTypes.string,
};

export default Loader;
