import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  EditBillIDAction,
  getAllType,
} from "../../../Redux/BillingManagement/Action";
import BillingForms from "../Pages/Forms";
import { Divider } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function EditViewPopup({ open, setOpen, type, value }) {
  const dispatch = useDispatch();

  useEffect(() => {
    value === undefined && dispatch(getAllType(dispatch));
  }, []); // eslint-disable-line

  const handleClose = () => {
    setOpen(pre => pre.set('open', false));
    dispatch(EditBillIDAction(false));
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={
          type !== "Edit"
            ? {
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "90%",
                  height: "600px",
                  display: "flex",
                  justifyContent: "center",
                },
              },
            }
            : {
              "& .MuiDialog-container": {
                "& .MuiPaper-root": { width: "100%", maxWidth: "650px" },
              },
            }
        }
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {type === "Edit" ? "Update" : "INVOICE"}
        </BootstrapDialogTitle>
        <Divider />
        <>
          <BillingForms setOpen={setOpen} type={type} value={value} />
        </>
      </BootstrapDialog>
    </>
  );
}
