import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, Card, IconButton, Tooltip } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";

import { useDispatch, useSelector } from "react-redux";
import {
  deleteAssetServiceApi,
  EditAssetsAction,
  PopupAction,
} from "../../../../Redux/AssetsManagement/Action";
import UiPopUpForm from "../../02.Ui/PopUpForm";
import PopupImage from "./PopupImage";
import { styled } from "@mui/material/styles";
import { useSweetAlert } from "../../../../Utils/Sweetalert";
import Pagination from "../../../../Utils/Pagination";

const MainAssetsTable = () => {
  const sweetAlert = useSweetAlert();
  // props------------------------------------------>
  const AssetsApi = useSelector((state) => state.AssetsManagement).get(
    "getAssetsApi",
    ""
  );
  const [paginationSplice, setPaginationSplice] = useState({
    start: 0,
    end: 5,
  });


  const EditAsset = useSelector((state) => state.EditAssets);

  // const assets = useSelector((state) => state.AssetsManagement).get(
  //   "AssetsformData",
  //   {}
  // );

  const [PopupImageOpen, setPopupImage] = React.useState("");

  const dispatch = useDispatch();

  const handleEdit = (index) => {
    dispatch(EditAssetsAction(index));
    dispatch(PopupAction(true));
  };

  const handelDelete = (id) => {
    // dispatch(deleteAssetServiceApi(id));
    sweetAlert({ id, ApiCall: deleteAssetServiceApi });
  };
  const handleViewOpen = (attachment) => {
    setPopupImage(attachment);
  };
  const handleViewClose = () => {
    setPopupImage("");
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.white,
      fontSize: "20px",
      fontWight: "600",
      backgroundColor: "#1976D2",
      textTransform: "capitalize",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px 16px",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <div>
      <Card>
        <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>Asset Type</StyledTableCell>
                <StyledTableCell>Purchase on</StyledTableCell>
                <StyledTableCell>price</StyledTableCell>
                <StyledTableCell>Model</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Attachment</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {AssetsApi.slice(
                paginationSplice.start,
                paginationSplice.end
              ).map((items, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>
                      {items.get("assetType", "")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {new Date(
                        items.get("purchaseOn", "")
                      ).toLocaleDateString()}
                      {/* {items.get("purchaseOn", "")} */}
                    </StyledTableCell>

                    <StyledTableCell>{items.get("price")}</StyledTableCell>
                    <StyledTableCell>{items.get("model")}</StyledTableCell>
                    <StyledTableCell>
                      {items.get("description", "")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className="d-flex">
                        <Tooltip title="view Image" arrow placement="top">
                          <IconButton>
                            <Avatar
                              alt="attachment"
                              src={items.get("attachment", "")}
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handleViewOpen(items.get("attachment", ""))
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className="d-flex">
                        <Tooltip title="Edit" arrow placement="top">
                          <IconButton
                            onClick={() => handleEdit(index)}
                            sx={{ marginRight: "18px" }}
                          >
                            <BorderColorIcon color="success" />
                          </IconButton>
                        </Tooltip>

                        <Tooltip title="Delete" arrow placement="top">
                          <IconButton
                            onClick={() => handelDelete(items.get("_id", ""))}
                          >
                            <DeleteForeverIcon color="error" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Pagination handleChange={setPaginationSplice} count={AssetsApi.size} />

      {EditAsset !== false && <UiPopUpForm />}
      {PopupImageOpen !== false && (
        <PopupImage
          handleViewClose={handleViewClose}
          PopupImageOpen={PopupImageOpen}
        />
      )}
    </div>
  );
};

export default MainAssetsTable;
