import { combineReducers } from "redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import TestCaseReducer from "./Redux/TestCase/Reducer";
import AssetsManagement from "./Redux/AssetsManagement/Reducer";
import DbDump from "./Redux/DbDump/Reducer";
import BillingState from "./Redux/BillingManagement/Reducer";
import Documentation from "./Redux/DocumentManagement/Reducer";
import ClientSupport from "./Redux/ClientSupportManagement/Reducer";
import AttendanceManagement from "./Redux/AttendanceManagement/Reducer";

const rootReducers = combineReducers({
  TestCaseReducer,
  AssetsManagement,
  BillingState,
  DbDump,
  Documentation,
  ClientSupport,
  AttendanceManagement
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25,
  })
  : null || compose;
const middleware = [thunk];

const store = createStore(rootReducers, undefined, composeEnhancers(applyMiddleware(...middleware)));
export default store;
