import { AppBar, Box, Button, Toolbar } from "@mui/material";
import React from "react";

import { ButtonData } from "../../ButtonDesign/ButtonData";
import { ButtonDesign } from "../../ButtonDesign/ButtonDesign";
import Expand from "../../../../../Assets/Expand.svg";
import "./ReplyHeader.css";
const ReplyHeader = ({
  open,
  handleForwardOpen,
  handleSideBarOpen,
  handleReplyAction,
  handleStatusToggle,
  handleDeleteTicket,
  handleChangeButtonName,
}) => {
  return (
    <AppBar className="ReplyHeader">
      <Toolbar className="d-flex justify-content-between">
        <Box className="d-flex gap-3">
          {!open.isReplay && (
            <ButtonDesign
              name={ButtonData.Reply_Name}
              icon={ButtonData.Reply_icon}
              tooltip={ButtonData.Reply_tooltip}
              handleAction={handleReplyAction}
            />
          )}
          {/* <ButtonDesign
            name={ButtonData.Note_Name}
            icon={ButtonData.Note_icon}
            tooltip={ButtonData.Note_tooltip}
            // handleAction={handleForwardOpen}
          /> */}
          <ButtonDesign
            name={ButtonData.Close_Name}
            icon={ButtonData.Close_icon}
            tooltip={ButtonData.Close_tooltip}
            handleAction={handleStatusToggle}
            handleChangeButtonName={handleChangeButtonName}
          />
          <ButtonDesign
            name={ButtonData.Forward_Name}
            icon={ButtonData.Forward_icon}
            tooltip={ButtonData.Forward_tooltip}
            handleAction={handleForwardOpen}
          />

          <ButtonDesign
            name={ButtonData.Delete_Name}
            icon={ButtonData.Delete_icon}
            tooltip={ButtonData.Delete_tooltip}
            handleAction={handleDeleteTicket}
          />
        </Box>
        <Box className="d-flex gap-3">
          <Button
            color="inherit"
            aria-label="open drawer"
            onClick={handleSideBarOpen}
            edge="start"
            disabled={open.forward && true}
            className="Expand_icon">
            <img alt="" src={Expand} />
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default ReplyHeader;
