import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import cardImage1 from "../../../Assets/Assest manage.jpg";
import cardImage2 from "../../../Assets/invoice.jpg";
import cardImage3 from "../../../Assets/testing.jpg";
import cardImage4 from "../../../Assets/dbdump.jpg";
import cardImage6 from "../../../Assets/documentaion.png";
import "./Swipper.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

const productData = [
  {
    icon: cardImage1,
    title: "Asset Management",
    bgColor: "rgba(36, 153, 239, 0.2)",
    color: "rgb(36, 153, 239)",
    NavigatePath: "/assetManagement",
    content:
      "Our Asset Management tool allows you to manage your assets with ease. You can upload inventory and map them to employees. You can also store important details like the version, type of asset, issued date, and time",
  },
  {
    icon: cardImage2,
    title: "Billing",
    bgColor: "rgba(36, 153, 239, 0.2)",
    color: "rgb(36, 153, 239)",
    NavigatePath: "/cloudBilling",
    content:
      "Our Billing tool helps you analyze your cloud costs on a daily basis. You can also analyze the server hits through our application Asset Management tool allows you to manage your assets with ease. You can upload inventory and map them to employees. You can also store important details like the version, type of asset, issued date, and time",
  },
  {
    icon: cardImage4,
    title: "DB Dump",
    bgColor: "rgba(36, 153, 239, 0.2)",
    color: "rgb(36, 153, 239)",
    NavigatePath: "/dumpDB",
    content:
      "Our Db Dump feature helps you take backups of the database and create new environments through automation by providing the environment details",
  },
  {
    icon: cardImage3,
    title: "Testing Tools",
    bgColor: "rgba(36, 153, 239, 0.2)",
    color: "rgb(36, 153, 239)",
    NavigatePath: "/testCase",
    content:
      "Our Testing Tools help you streamline your testing processes. You can upload test cases and link them with Jira. You can execute test cases and easily record your screen for future reference. You can also take reports through our testing tool.Our Db Dump feature helps you take backups of the database and create new environments through automation by providing the environment details",
  },
  {
    icon: cardImage6,
    title: "Documentation",
    NavigatePath: "/MyDocuments",
    bgColor: "rgba(36, 153, 239, 0.2)",
    color: "rgb(36, 153, 239)",
    content:
      "Our Documentation help you streamline your testing processes. You can upload test cases and link them with Jira. You can execute test cases and easily record your screen for future reference. ",
  },
];

export const SwiperSlider = () => {
  const navigate = useNavigate();
  
  const handleNaviGate = (redirect) => {
    navigate(redirect);
  };
  
  return (
    <Swiper
    
      slidesPerView={3}
      spaceBetween={30}
      autoplay={{
        delay: 3500,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {productData.map((data) => {
        return (
          <SwiperSlide className="product">
   
            <div className="product_card">
              <div className="product_card_tags">
                <div className="product_card_Img">
                  <img src={data.icon} alt="" className="product_card_Img" />
                </div>
                <div className="product_card_title">
                  <h2 className="product-title"> {data.title}</h2>
                </div>
                <div className="product_card_content">
                  <p>{data.content}</p>
                </div>
                <Button
                  variant="contained"
                  onClick={() => handleNaviGate(data.NavigatePath)}
                  startIcon={<SwapHorizIcon />}
                  className="mb-2"
                >
                  Go to
                </Button>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
