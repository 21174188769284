import { Dump, Restore } from "./main";
import React, { useEffect, useState } from "react";
import * as action from "../../Redux/DbDump/Action";
import {
  selectIsLoading,
  selectMessage,
  selectDbList,
} from "../../Redux/DbDump/Selector";
import { connect } from "react-redux";
import "./index.css";
import Snackbar from "../../LoaderSnackbar/Snackbar";
import Loader from "../../LoaderSnackbar/Loader";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";

function Index({
  setData,
  postRestore,
  getDatabaseName,
  postDump,
  loadingStatus,
  messageStatus,
  folderName,
}) {
  const [isOpen, setOpen] = useState(true);
  const [uri, setUri] = useState("");
  const [selectedFolder, setSelectedFolder] = useState("");

  console.log(isOpen, "isOpen");
  //handle dump button
  const handleDump = () => {
    setOpen(true);
    setUri("");
    setSelectedFolder("");
    console.log("dumb");
  };

  //handle restore button
  const handleRestore = () => {
    setOpen(false);
    setUri("");
    showDatabaseList();
  };

  //update dump URI data
  const updateUri = (e) => {
    console.log(e.target.value)
    const value = e.target.value;
    setUri(value);
  };

  //dump function

  const mongoDbDump = () => {
    postDump({ dumpDbUri: uri });
  };

  //update Restore URI data
  const updateRestoreUri = (e) => {
    const value = e.target.value;
    setUri(value);
  };

  //restore function
  const mongoDbRestore = () => {
    const payload = {
      uri,
      selectedFolder,
    };
    postRestore(payload);
  };

  //existing dumped database list
  const showDatabaseList = () => {
    getDatabaseName();
    console.log("showDatabase");
  };

  useEffect(() => {
    !isOpen && showDatabaseList();
  }, []); //eslint-disable-line

  const checking = (e) => {
    setSelectedFolder(e.target.value);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    // <div className="DBbody">
    //   <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
    //   <Loader isLoading={loadingStatus} />
    //   {/* <div className="db_aside">
    //     <Content handleDump={handleDump} handleRestore={handleRestore} />
    //   </div> */}
    //   <div className="db_main">
    //     {/* <Content handleDump={handleDump} handleRestore={handleRestore} /> */}

    //     {isOpen && (
    //       <Dump
    //         updateUri={updateUri}
    //         dumpDbUri={uri}
    //         mongoDbDump={mongoDbDump}
    //         folderName={folderName}
    //         handleDump={handleDump}
    //         handleRestore={handleRestore}
    //       />
    //     )}
    //     {!isOpen && (
    //       <Restore
    //         updateRestoreUri={updateRestoreUri}
    //         uri={uri}
    //         mongoDbRestore={mongoDbRestore}
    //         selectedFolder={selectedFolder}
    //         checking={checking}
    //         folderName={folderName}
    //       />
    //     )}
    //   </div>
    // </div>

    <div className="DBbody">
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <Card sx={{ width: "100%", height: "100%" }} className="container-fluid">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ height: "70px" }}
          >
            <Tab
              label="Dump DB"
              {...a11yProps(0)}
              onClick={handleDump}
              sx={{ height: "70px", width: "200px" }}
            />
            <Tab
              label="Restore DB"
              {...a11yProps(1)}
              onClick={handleRestore}
              sx={{ height: "70px", width: "200px" }}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {isOpen && (
            <Dump
              setUri={setUri}
              dumpDbUri={uri}
              mongoDbDump={mongoDbDump}
              folderName={folderName}
              updateUri={updateUri}

            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {!isOpen && (
            <Restore
              updateRestoreUri={updateRestoreUri}
              uri={uri}
              mongoDbRestore={mongoDbRestore}
              selectedFolder={selectedFolder}
              checking={checking}
              folderName={folderName}
            />
          )}
        </TabPanel>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingStatus: selectIsLoading(state),
    messageStatus: selectMessage(state),
    folderName: selectDbList(state).toJS(),
  };
};
export default connect(mapStateToProps, action)(Index);
