import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";

import { selectUser, selectAuthType } from "../../Redux/TestCase/Selector";
import { connect } from "react-redux";
import * as action from "../../Redux/TestCase/Action";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import "./Header.css";

import Popup from "../TestCase/Modal/userPopup";
import { Box, Popover } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function Header({ userRole, userData }) {
  const [user, setUser] = useState(null);
  // const [anchorElUser, setAnchorElUser] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    const user = userRole;
    if (user) {
      setUser(user);
    }
  }, []); //eslint-disable-line
  const logOut = () => {
    localStorage.clear();
    window.location.href = "/auth/login";
  };

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(false);
  // };
  const handleUserPopup = () => {
    setUserOpen(true);
    // setAnchorElUser(false)
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{ zIndex: "999" }}>
      <div className="d-flex align-items-center">
        <Nav className="me-auto">
          {user && user.role === "Admin" && (
            <Nav.Link className="text-white" href="/admin">
              Admin Portal
            </Nav.Link>
          )}
        </Nav>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          {user && (
            <div class="digi-username-container">
              <div>
                <img
                  src={userData.picture}
                  className="digi-user-img"
                  referrerPolicy="no-referrer"
                  alt="user_image"
                  sx={{ height: "40px", width: "40px" }}
                  onClick={handleUserPopup}
                />
              </div>
              <span className="digi-user-name me-2">{userData.name}</span>

              <span onClick={logOut} class="digi-logout-icon">
                <PowerSettingsNewIcon />
              </span>
            </div>
          )}
        </Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={{ mr: 2, display: { md: "none" } }}
          onClick={handleClick}>
          <MenuIcon />
        </IconButton>
        <Popover
          id={id}
          open={!matches && open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}>
          <Box>
            {user && (
              <div class="digi-username-container">
                <div>
                  <img
                    src={userData.picture}
                    className="digi-user-img"
                    referrerPolicy="no-referrer"
                    alt="user_image"
                    sx={{ height: "40px", width: "40px" }}
                    onClick={handleUserPopup}
                  />
                </div>
                <span className="digi-user-name me-2">{userData.name}</span>

                <span onClick={logOut} class="digi-logout-icon">
                  <PowerSettingsNewIcon />
                </span>
              </div>
            )}
          </Box>
        </Popover>
      </div>
      {/* <Navbar
        sticky="top"
        bg="#1976d2"
        expand="lg"
        className="d-flex justify-content-between  w-100"
      >
        <Navbar.Brand href="\" className="p-0"></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav " className="glow-unset mx-2">
          <Nav className="me-auto">
            {user && user.role === "Admin" && (
              <Nav.Link className="text-white" href="/admin">
                Admin Portal
              </Nav.Link>
            )}
          </Nav>
          show logout button if User
          <div>
            {user && (
              <div class="digi-username-container">
                <div>
                  <img
                    src={userData.picture}
                    className="digi-user-img"
                    referrerPolicy="no-referrer"
                    alt="user_image"
                    sx={{ height: "40px", width: "40px" }}
                    onClick={handleUserPopup}
                  />
                </div>
                <span className="digi-user-name me-2">{userData.name}</span>

                <span onClick={logOut} class="digi-logout-icon">
                  <PowerSettingsNewIcon />
                </span>
              </div>
            )}
          </div>
        </Navbar.Collapse>
      </Navbar> */}
      <>
        {true && (
          <Popup
            userOpen={userOpen}
            setUserOpen={setUserOpen}
            userData={userData}
            user={user}
          />
        )}
      </>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userRole: selectUser(state)?.toJS(),
  userData: selectAuthType(state)?.toJS(),
});

export default connect(mapStateToProps, action)(Header);
