import React from "react";
import { BootstrapDialog, BootstrapDialogTitle } from "../../../TestCase/Styles";
import { Button, DialogActions, DialogContent, TextField } from "@mui/material";
import {
  createSupportClient,
  getSupportClient,
  putSupportClient,
} from "../../../../Redux/ClientSupportManagement/Action";

import { useDispatch } from "react-redux";

const CreateAdd = ({ isAddPopUp, setAddPopup, AddValues, setAddValues }) => {
  const dispatch = useDispatch();
  const handleChange = (event) => {
    setAddValues({ ...AddValues, [event.target.name]: event.target.value });
  };
  const handleClose = () => {
    setAddPopup(false);
    setAddValues({
      ...AddValues,
      setAddValues,
      clientName: "",
      type: "submit",
    });
  };

  const callBack = () => {
    setAddPopup(false);
    setAddValues({
      ...AddValues,
      clientName: "",
      type: "submit",
    });
    dispatch(getSupportClient());
  };

  const Update_callBack = () => {
    setAddPopup(false);
    setAddValues({ ...AddValues, clientName: "", type: "submit" });
    dispatch(getSupportClient());
  };

  const handleSubmit = (values, type) => {
    const payload = {
      clientName: values,
    };
    const update_payload = {
      id: AddValues.id,
      clientName: values,
    };
    if (type === "update") {
      console.log("id", AddValues.id);
      dispatch(putSupportClient(update_payload, Update_callBack));
    } else {
      dispatch(createSupportClient(payload, callBack));
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isAddPopUp}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "350px", // Set your width here
          },
        },
      }}
    >
      <BootstrapDialogTitle id="customized-dialog-title">
        Add Clients
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <div>
          <TextField
            label="Client Name"
            fullWidth
            size="small"
            type="text"
            name="clientName"
            value={AddValues.clientName}
            onChange={handleChange}
            autoComplete="none"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          cancel
        </Button>
        <Button
          autoFocus
          onClick={() => handleSubmit(AddValues.clientName, AddValues.type)}
        >
          {AddValues.type === "submit" ? "Submit" : "Update"}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default CreateAdd;
