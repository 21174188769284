import { createAction } from "../../Utils/FunctionUtils";
import axios from "axios";

const backEndApi = process.env.REACT_APP_BackEndUrl;

export const SET_DATA_SUCCESS = "SET_DATA_SUCCESS";
// setData({message:'some thing'})
const setDataSuccess = createAction(SET_DATA_SUCCESS, "data");
export const setData = (data) => {
  return (dispatch) => {
    dispatch(setDataSuccess(data));
  };
};

export const GET_ALL_USER_WITH_DATE_REQUEST = "GET_ALL_USER_WITH_DATE_REQUEST";
export const GET_ALL_USER_WITH_DATE_SUCCESS = "GET_ALL_USER_WITH_DATE_SUCCESS";
export const GET_ALL_USER_WITH_DATE_FAILURE = "GET_ALL_USER_WITH_DATE_FAILURE";

const getAllUserWithDateRequest = createAction(GET_ALL_USER_WITH_DATE_REQUEST);
const getAllUserWithDateSuccess = createAction(
  GET_ALL_USER_WITH_DATE_SUCCESS,
  "data"
);
const getAllUserWithDateFailure = createAction(
  GET_ALL_USER_WITH_DATE_FAILURE,
  "error"
);

export const getAllUserWithDate = (start,end) => {
  return (dispatch) => {
    dispatch(getAllUserWithDateRequest());
    axios
      .get(
        `${backEndApi}attendance/getAllUserWithDate?start=${start}&end=${end}` //?filter=${dateWishFilter}
      )
      .then((res) => {
        dispatch(getAllUserWithDateSuccess(res.data.message));
      })
      .catch((error) => dispatch(getAllUserWithDateFailure(error)));
  };
};

export const GET_SPECIFIC_DATE_DETAIL_REQUEST =
  "GET_SPECIFIC_DATE_DETAIL_REQUEST";
export const GET_SPECIFIC_DATE_DETAIL_SUCCESS =
  "GET_SPECIFIC_DATE_DETAIL_SUCCESS";
export const GET_SPECIFIC_DATE_DETAIL_FAILURE =
  "GET_SPECIFIC_DATE_DETAIL_FAILURE";

const getSpecificDateDetailRequest = createAction(
  GET_SPECIFIC_DATE_DETAIL_REQUEST
);
const getSpecificDateDetailSuccess = createAction(
  GET_SPECIFIC_DATE_DETAIL_SUCCESS,
  "data"
);
const getSpecificDateDetailFailure = createAction(
  GET_SPECIFIC_DATE_DETAIL_FAILURE,
  "error"
);

export const getSpecificDateDetail = (date, callback) => {
  return (dispatch) => {
    dispatch(getSpecificDateDetailRequest());
    axios
      .get(`${backEndApi}attendance/getSpecificDateDetail?date=${date}`)
      .then((res) => {
        dispatch(getSpecificDateDetailSuccess(res.data.message));
        callback && callback();
      })
      .catch((error) => dispatch(getSpecificDateDetailFailure(error)));
  };
};
