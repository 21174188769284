import { List, Map } from "immutable";
const Documentation = (state) => state.ClientSupport;

const selectIsLoading = (state) => Documentation(state).get("isLoading", false);
const selectMessage = (state) => Documentation(state).get("message", "");
const selectSupportList = (state) =>
  Documentation(state).get("supportList", Map());
const selectSupportClientList = (state) =>
  Documentation(state).get("supportClientList", "");
const selectAllAgentData = (state) =>
  Documentation(state).get("allAgentData", []);
const selectIssueData = (state) =>
  Documentation(state).get("filterClientIssueData", Map());
const selectCategoryList = (state) =>
  Documentation(state).get("allCategoryList", List());
const selectCategoryAndCountList = (state) =>
  Documentation(state).get("CategoryAndCountList", List());
const selectCategoryBasedTickets = (state) =>
  Documentation(state).get("categoryBasedTickets", List());

export {
  selectIsLoading,
  selectMessage,
  selectSupportList,
  selectSupportClientList,
  selectAllAgentData,
  selectIssueData,
  selectCategoryList,
  selectCategoryAndCountList,
  selectCategoryBasedTickets,
};
