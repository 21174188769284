import React, { useState } from "react";
import {
  BootstrapDialog,
  BootstrapDialogTitle,
} from "../../../TestCase/Styles";
import LoupeIcon from "@mui/icons-material/Loupe";
import AddClients from "./CreateAdd";
import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSupportClientList,
  selectAllAgentData,
} from "../../../../Redux/ClientSupportManagement/Selector";
import { useSweetAlert } from "../../../../Utils/Sweetalert";
import {
  deleteSupportClient,
  getSupportClient,
  updateAssignedPerson,
  getAllAgentData,
} from "../../../../Redux/ClientSupportManagement/Action";

const ClientAssigned = ({ isPopup, setPopup }) => {
  const [isAddPopUp, setAddPopup] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [AddValues, setAddValues] = useState({
    clientName: "",
    type: "submit",
  });
  const [values, setValues] = useState({
    clientName: "",
    Assigned: [],
  });

  const supportClientList = useSelector(selectSupportClientList).toJS();
  const allAgentData = useSelector(selectAllAgentData).toJS();

  const sweetAlert = useSweetAlert();
  const dispatch = useDispatch();

  const options = allAgentData.map((data) => ({
    label: data.username,
    id: data.userId,
  }));

  const assignedUserList = options
    .filter((item) => values.Assigned.includes(item.id))
    .map((item) => item);

  const handleClose = () => {
    setPopup(false);
  };
  const handleClick = () => {
    setAddPopup(true);
  };
  const callBack = () => {
    setIsShown(false);
    setPopup(false);
    dispatch(getSupportClient());
  };
  const handleSubmit = (e, values) => {
    e.preventDefault();
    dispatch(updateAssignedPerson(values, callBack));
  };
  const handleUpdate = (e, UpdateValue, UpdateId) => {
    e.stopPropagation();
    setAddPopup(true);
    setAddValues((prev) => {
      return { ...prev, clientName: UpdateValue, type: "update", id: UpdateId };
    });
  };
  const Delete_callBack = () => {
    dispatch(getSupportClient());
  };

  const handleDelete = (e, DeleteId) => {
    e.stopPropagation();
    // dispatch(deleteSupportClient(DeleteId, Delete_callBack));
    sweetAlert({
      id: DeleteId,
      ApiCall: deleteSupportClient,
      callBack: Delete_callBack,
    });
  };
  const handleChangeShow = (index) => {
    const showData = {
      [index]: !isShown[index],
    };
    setIsShown(showData);
  };
  const handleChange = (e, option, key) => {
    if (key === "Assigned") {
      const getAssignedUserId = option.map((item) => item.id);
      setValues((prev) => {
        return { ...prev, Assigned: getAssignedUserId };
      });
      setIsShown(false);
    } else {
      const id = supportClientList.find(
        (data, index) => data.clientName === option
      );

      setValues((prev) => {
        return {
          ...prev,
          clientName: option,
          id: id ? id._id : "",
          Assigned: id ? id.Assigned : [],
        };
      });
      setIsShown(false);
    }
  };

  useEffect(() => {
    if (supportClientList.length === 0) dispatch(getSupportClient());
    if (allAgentData.length === 0) dispatch(getAllAgentData());
  }, []); // eslint-disable-line

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isPopup}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px", // Set your width here
          },
        },
      }}>
      <BootstrapDialogTitle id="customized-dialog-title">
        create
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <div className="mb-4 d-flex gap-2">
          <Autocomplete
            id="ClientName"
            value={values.clientName}
            options={supportClientList.map((option) => option.clientName)}
            fullWidth
            sx={{ width: "90%" }}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField {...params} label="Client Name" />
            )}
            renderOption={(props, option, state) => (
              <li {...props}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  onMouseEnter={(e) => {
                    handleChangeShow(state.index);
                  }}
                  onMouseLeave={() => setIsShown(false)}>
                  <span>{option}</span>
                  <div className="d-flex gap-3">
                    {isShown[state.index] && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          handleUpdate(
                            e,
                            option,
                            supportClientList[state.index]._id
                          );
                        }}>
                        <EditIcon />
                      </IconButton>
                    )}
                    {isShown[state.index] && (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          handleDelete(
                            e,

                            supportClientList[state.index]._id
                          );
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              </li>
            )}
            onChange={(e, option) => handleChange(e, option, "clientName")}
          />

          <div>
            <Tooltip title="Add Client" placement="top" arrow>
              <IconButton onClick={handleClick}>
                <LoupeIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div>
          <Autocomplete
            multiple
            filterSelectedOptions
            id="Assigned"
            options={options}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={assignedUserList}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assigned"
                placeholder="Assigned...."
              />
            )}
            onChange={(e, option) => handleChange(e, option, "Assigned")}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          cancel
        </Button>
        <Button
          autoFocus
          type="submit"
          disabled={values.clientName === "" && values.Assigned.length}
          onClick={(e) => handleSubmit(e, values)}>
          submit
        </Button>
      </DialogActions>
      {isAddPopUp && (
        <AddClients
          isAddPopUp={isAddPopUp}
          setAddPopup={setAddPopup}
          AddValues={AddValues}
          setAddValues={setAddValues}
        />
      )}
    </BootstrapDialog>
  );
};

export default ClientAssigned;
