import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { Video } from "../../../Utils/FunctionUtils";

export const ShowMultiMedia = ({ close, show, url, title = "" }) => {
  return (
    <Modal className="my-modal" show={show} onHide={close} onCLose={close} dialogClassName="modal-900" centered>
      <Modal.Header closeButton className="bg-gray">
        <Modal.Title className="text-center text-decoration-none">{title === "" ? "Video player" : title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-2 f-16 d_all_center flex-column">
        <Video url={url} fullWidth={true} />
        <button className="btn-primary my-2 p-2">
          <a href={url} className="text-white text-decoration-none">
            Download
          </a>
        </button>
      </Modal.Body>
    </Modal>
  );
};

ShowMultiMedia.prototype = {
  show: PropTypes.bool,
  close: PropTypes.func,
  url: PropTypes.func,
  title: PropTypes.func,
};
