import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { MenuItem, Select } from "@mui/material";

export default function SelectCategory({
  open,
  setOpen,
  handleChange,
  category,
  getAllCategory,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const categories = getAllCategory.filter((item) => !item.get("parentId", ""));
  const subCategories = getAllCategory.filter(
    (item) => item.get("parentId", "") === category.get("categoryId", "")
  );
  const sections = getAllCategory.filter(
    (item) => item.get("parentId", "") === category.get("subCategoryId", "")
  );

  const renderValue = (selected) => {
    if (!selected) {
      return "Select";
    }

    let name = "Select";
    getAllCategory.forEach((item) => {
      if (item.get("_id", "") === selected) {
        name = item.get("category", "");
      }
    });

    return name;
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle id="alert-dialog-title">Select Category</DialogTitle>
      <DialogContent>
        <div className="d-flex flex-column align-items-center justify-content-center gap-3">
          <div className="d-flex justify-content-center align-items-center gap-3 mt-2 mb-2">
            <div>Category</div>
            <Select
              value={category.get("categoryId", "")}
              onChange={(event) => handleChange(event, "category")}
              renderValue={(selected) => renderValue(selected)}
              sx={{ minWidth: "200px", maxHeight: "35px", marginLeft: "25px" }}
            >
              <MenuItem value="">Select</MenuItem>
              {categories.map((item) => (
                <MenuItem value={item.get("_id", "")}>
                  {item.get("category", "")}
                </MenuItem>
              ))}
            </Select>
          </div>
          {category.get("categoryId", "") && subCategories.size !== 0 && (
            <div className="d-flex justify-content-center align-items-center gap-3 mt-2 mb-2">
              <div>SubCategory</div>
              <Select
                value={category.get("subCategoryId", "")}
                onChange={(event) => handleChange(event, "subCategory")}
                renderValue={(selected) => renderValue(selected)}
                sx={{ minWidth: "200px", maxHeight: "35px" }}
              >
                {subCategories.map((item) => (
                  <MenuItem value={item.get("_id", "")}>
                    {item.get("category", "")}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          {category.get("subCategoryId", "") && sections.size !== 0 && (
            <div className="d-flex justify-content-center align-items-center gap-3 mt-2 mb-2">
              <div>Section</div>
              <Select
                value={category.get("sectionId", "")}
                onChange={(event) => handleChange(event, "section")}
                renderValue={(selected) => renderValue(selected)}
                sx={{
                  minWidth: "200px",
                  maxHeight: "35px",
                  marginLeft: "35px",
                }}
              >
                {sections.map((item) => (
                  <MenuItem value={item.get("_id", "")}>
                    {item.get("category", "")}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Cancel
        </Button>
        <Button onClick={handleClose} variant="contained">
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}
