import React, { useState } from "react";
import { MoreVert, Add } from "@material-ui/icons";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
} from "@material-ui/core";
import "./AdminTable.css";
import { Chip, Stack } from "@mui/material";
import Pagination from "../../Utils/Pagination";
import { useSelector, useDispatch } from "react-redux";
import { selectUsers, selectRoles } from "../../Redux/TestCase/Selector";
import { fromJS } from "immutable";
import { Button } from "@material-ui/core";
import { putRoleChange } from "../../Redux/TestCase/Action";
import { getUserData } from "../../Redux/TestCase/Action";
import emailjs from "@emailjs/browser";
import UpdateDetails from "./UpdateDetails";
import { deleteUserRole } from "../../Redux/TestCase/Action";
import BioMetric from "./bioMetric";
import { CiFilter } from "react-icons/ci";

/*-------------------------------UtilsStart---------------------------------*/
const initialData = {
  selectedOption: "All",
  nameFilter: "",
  paginationSplice: { start: 0, end: 5 },
  anchorEl: null,
  openVert: false,
  open: null,
  selectedRow: null,
  actionType: "",
  openActionDialog: false,
  canChangeRole: false,
  selectedRole: "",
  role: "",
  updateDetailsOpen: false,
  id: "",
  rowUserName: "",
  rowPhoneNumber: "",
  bioOpen: false,
  imgUrl: "",
};

const Value = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Developer",
    label: "Developer",
  },
  {
    value: "Tester",
    label: "Tester",
  },
  {
    value: "Accountant",
    label: "Accountant",
  },
  {
    value: "Admin",
    label: "Admin",
  },
  {
    value: "Clientsupport",
    label: "Clientsupport",
  },
  {
    value: "New",
    label: "New",
  },
];
const anchorOrigin = {
  vertical: "top",
  horizontal: "left",
};

const transformOrigin = {
  vertical: "top",
  horizontal: "left",
};
/*-------------------------------UtilsEnd-----------------------------------*/

/*-------------------------------CustomHooksStart----------------------------*/
const useUserData = () => {
  const [userData, setUserData] = useState(fromJS(initialData));
  const dispatch = useDispatch();

  const handleWriteAccess = (data) => {
    setUserData((pre) =>
      pre
        .set("selectedRow", data)
        .set("actionType", "write")
        .set("openActionDialog", true)
    );
  };

  const handleMoreVertClose = () => {
    setUserData((pre) => pre.set("selectedRow", null).set("openVert", ""));
  };

  const handleActionDialogClose = () => {
    setUserData((pre) =>
      pre
        .set("actionType", "")
        .set("canChangeRole", false)
        .set("openActionDialog", false)
    );
    handleMoreVertClose();
  };

  const updateUserData = (data) => {
    const payload = {
      userId: data.userId,
      username: data.username,
      email: data.email,
      newRole: data.role,
      isActive: data.isActive,
      writeAccess: data.writeAccess,
    };
    dispatch(putRoleChange(payload));
  };

  const handleWriteAccessFormSubmit = (event) => {
    event.preventDefault();
    const data = {
      userId: userData.getIn(["selectedRow", "_id"], ""),
      username: userData.getIn(["selectedRow", "username"], ""),
      email: userData.getIn(["selectedRow", "email"], ""),
      role: userData.getIn(["selectedRow", "role"], ""),
      isActive: userData.getIn(["selectedRow", "isActive"], false),
      writeAccess: !userData.getIn(["selectedRow", "writeAccess"], false),
    };

    updateUserData(data);
    setUserData((pre) =>
      pre.set("actionType", "").set("openActionDialog", false)
    );
    dispatch(getUserData());
  };

  const handleMoreVertClick = (event, row, data, index) => {
    setUserData((pre) =>
      pre
        .set("openVert", data)
        .set("selectedRow", row)
        .set("selectedRole", row)
        .set("anchorEl", event.currentTarget)
        .set("open", index)
    );
  };

  const handleActivate = (role, type) => {
    setUserData((pre) =>
      pre
        .set("actionType", type)
        .set("role", role)
        .set("openActionDialog", true)
    );
  };

  const handleCanChange = () => {
    setUserData((pre) =>
      pre.set("canChangeRole", !pre.get("canChangeRole", false))
    );
  };

  const sendActivateEmail = () => {
    const service_id = "service_lghknom";
    const public_key = "qucxk-L5j3ZMTMnM1";
    const template_id = "template_etgcik5";
    const message = userData.getIn(["selectedRow", "isActive"], false)
      ? "Your Account has been deactivated"
      : "Your Account has been activated";

    const templateParams = {
      to_name: userData.getIn(["selectedRow", "username"], ""),
      user_email: userData.getIn(["selectedRow", "email"], false),
      from_name: "Admin",
      from_email: "",
      message: message,
    };
    emailjs.send(service_id, template_id, templateParams, public_key).then(
      (result) => {
        console.log(templateParams);
      },
      (error) => {
        console.log(error.text);
      }
    );
  };

  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const data = {
      userId: userData.getIn(["selectedRow", "_id"], ""),
      username: userData.getIn(["selectedRow", "username"], ""),
      email: userData.getIn(["selectedRow", "email"], ""),
      role: userData.get("role", ""),
      isActive: userData.getIn(["selectedRow", "isActive"], false),
      writeAccess: userData.getIn(["selectedRow", "writeAccess"], ""),
    };

    updateUserData(data);
    setUserData((pre) =>
      pre.set("actionType", "").set("openActionDialog", false)
    );
    handleMoreVertClose();
  };

  const handleActivateFormSubmit = (event) => {
    event.preventDefault();
    const payload = {
      userId: userData.getIn(["selectedRow", "_id"], ""),
      isActive: !userData.getIn(["selectedRow", "isActive"], false),
      newRole: userData.get("canChangeRole", false)
        ? userData.get("role", "")
        : userData.getIn(["selectedRow", "role"], ""),
    };
    dispatch(putRoleChange(payload, () => sendActivateEmail()));
    setUserData((pre) =>
      pre
        .set("canChangeRole", false)
        .set("actionType", "")
        .set("openActionDialog", false)
    );
    handleMoreVertClose();
  };

  const handleEdit = (id, type) => {
    setUserData((pre) =>
      pre.set("actionType", type).set("openActionDialog", true)
    );
  };

  const handleUpdateDetails = (id, userName, phoneNumber) => {
    setUserData((pre) =>
      pre
        .set("updateDetailsOpen", true)
        .set("id", id)
        .set("rowUserName", userName)
        .set("rowPhoneNumber", phoneNumber)
    );
  };

  const handleDeleteUser = (id, type) => {
    setUserData((pre) =>
      pre.set("actionType", type).set("openActionDialog", true)
    );
  };

  const handleDeleteUserSubmit = (event) => {
    event.preventDefault();
    const payload = {
      userId: userData.getIn(["selectedRow", "_id"], ""),
    };
    dispatch(deleteUserRole(payload));
    setUserData((pre) =>
      pre.set("actionType", "").set("openActionDialog", false)
    );
    handleMoreVertClose();
  };

  const handleBioMetric = (getImgUrl, rowId, userName) => {
    setUserData((pre) =>
      pre
        .set("imgUrl", getImgUrl)
        .set("id", rowId)
        .set("rowUserName", userName)
        .set("bioOpen", true)
    );
  };

  const handleDropdownChange = (event) => {
    setUserData((pre) => pre.set("selectedOption", event.target.value));
  };

  const handleUsernameFilter = (event) => {
    setUserData((pre) => pre.set("nameFilter", event.target.value));
  };

  return [
    userData,
    setUserData,
    handleWriteAccess,
    handleActionDialogClose,
    handleWriteAccessFormSubmit,
    handleBioMetric,
    handleDeleteUserSubmit,
    handleDeleteUser,
    handleUpdateDetails,
    handleEdit,
    handleActivateFormSubmit,
    handleEditFormSubmit,
    handleCanChange,
    handleActivate,
    handleMoreVertClick,
    handleMoreVertClose,
    handleUsernameFilter,
    handleDropdownChange,
  ];
};

/*-------------------------------CustomHooksEnd------------------------------*/

const UserTable2 = () => {
  //   const [nameFilter, setNameFilter] = useState("");
  const [paginationSplice, setPaginationSplice] = useState({
    start: 0,
    end: 5,
  });
  const [
    userData,
    setUserData,
    handleWriteAccess,
    handleActionDialogClose,
    handleWriteAccessFormSubmit,
    handleBioMetric,
    handleDeleteUserSubmit,
    handleDeleteUser,
    handleUpdateDetails,
    handleEdit,
    handleActivateFormSubmit,
    handleEditFormSubmit,
    handleCanChange,
    handleActivate,
    handleMoreVertClick,
    handleMoreVertClose,
    handleUsernameFilter,
    handleDropdownChange,
  ] = useUserData();

  const users = useSelector(selectUsers);
  const roles = useSelector(selectRoles);

  return (
    <div>
      <div className="container m-3">
        <h2>Admin Portal</h2>
        <div className="d-flex gap-4 my-3">
          <div>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "20ch" },
              }}
              noValidate
              autoComplete="off">
              <TextField
                id="outlined-select-currency"
                select
                value={userData.get("selectedOption", "")}
                onChange={handleDropdownChange}
                label="Role Filter"
                defaultValue="All"
                size="small">
                {Value.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </div>
          <div>
            <TextField
              label="UserName & Email Filter"
              id="outlined-start-adornment"
              size="small"
              value={userData.get("nameFilter", "")}
              onChange={handleUsernameFilter}
              sx={{ width: "25ch" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CiFilter size={20} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>

      <h2 className="role-list-title mb-3">User Data</h2>
      <table className="portal_table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Write Access</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users
            .toJS()
            .filter(
              (item) =>
                (userData.get("selectedOption", "") === "All" ||
                  item.role === userData.get("selectedOption", "")) &&
                (item.username
                  .toLowerCase()
                  .includes(userData.get("nameFilter", "").toLowerCase()) ||
                  item.email
                    .toLowerCase()
                    .includes(userData.get("nameFilter", "").toLowerCase()))
            )
            .slice(paginationSplice.start, paginationSplice.end)
            .map((data, index) => {
              return (
                <tr key={index}>
                  <td>{data.username}</td>
                  <td>{data.email}</td>
                  <td>{data.role}</td>
                  <td
                    className={
                      data.isActive ? "digi-green bold" : "digi-red bold"
                    }>
                    {data.isActive ? "ACTIVE" : "INACTIVE"}
                  </td>
                  <td>
                    {
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={`Read ${data.writeAccess ? "" : "only"}`}
                          color="primary"
                        />
                        {data.writeAccess ? (
                          <Chip
                            label="Write"
                            color="error"
                            onDelete={(event) => handleWriteAccess(data)}
                          />
                        ) : (
                          <Chip
                            icon={<Add />}
                            label="Add"
                            variant="outlined"
                            color="success"
                            clickable
                            onClick={(event) => handleWriteAccess(data)}
                          />
                        )}
                      </Stack>
                    }
                  </td>
                  <td>
                    <MoreVert
                      className="icon"
                      onClick={(event) =>
                        handleMoreVertClick(event, data, "user", index)
                      }
                    />
                    <Menu
                      anchorOrigin={anchorOrigin}
                      transformOrigin={transformOrigin}
                      open={Boolean(
                        userData.get("openVert", "") &&
                          userData.get("open") === index
                      )}
                      onClose={handleMoreVertClose}
                      anchorEl={userData.get("anchorEl", null)}>
                      <MenuItem
                        disabled={
                          userData.get("selectedRow", null) &&
                          userData.getIn(["selectedRow", "isActive"], false)
                        }
                        onClick={() => {
                          handleActivate(
                            userData.getIn(["selectedRow", "_id"], ""),
                            "activate"
                          );
                        }}>
                        <span className="bold">Activate</span>
                      </MenuItem>

                      <MenuItem
                        disabled={
                          userData.get("selectedRow", null) &&
                          !userData.getIn(["selectedRow", "isActive"], false)
                        }
                        onClick={() =>
                          handleActivate(
                            userData.getIn(["selectedRow", "_id"], ""),
                            "activate"
                          )
                        }>
                        <span className="bold">Deactivate</span>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleEdit(
                            userData.getIn(["selectedRow", "_id"], ""),
                            "edit"
                          )
                        }>
                        <span className="bold">Edit Role</span>
                      </MenuItem>
                      <MenuItem>
                        <span
                          className="bold"
                          onClick={() =>
                            handleUpdateDetails(
                              userData.getIn(["selectedRow", "_id"], ""),
                              data.username,
                              data.phoneNumber
                            )
                          }>
                          Update Details
                        </span>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleDeleteUser(
                            userData.getIn(["selectedRow", "_id"], ""),
                            "delete"
                          )
                        }>
                        <span className="bold">Delete User</span>
                      </MenuItem>
                      <MenuItem>
                        <span
                          className="bold"
                          onClick={() =>
                            handleBioMetric(
                              userData.getIn(["selectedRow", "attachment"], ""),
                              userData.getIn(["selectedRow", "_id"], ""),
                              userData.getIn(["selectedRow", "username"], "")
                            )
                          }>
                          Bio Metric
                        </span>
                      </MenuItem>
                    </Menu>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <Pagination
        handleChange={setPaginationSplice}
        count={
          users
            .toJS()
            .filter(
              (item) =>
                userData.get("selectedOption", "") === "All" ||
                item.role === userData.get("selectedOption", "")
            ).length
        }
      />
      <Dialog
        open={userData.get("openActionDialog", false)}
        onClose={handleActionDialogClose}>
        {userData.get("actionType", "") === "write" && (
          <div>
            <DialogTitle>Write Access</DialogTitle>
            <DialogContent>
              <div>
                {userData.getIn(["selectedRow", "writeAccess"], false) ? (
                  <p>Are you sure you want to remove write access?</p>
                ) : (
                  <p>Are you sure you want to give write access?</p>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleActionDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleWriteAccessFormSubmit} color="primary">
                {userData.getIn(["selectedRow", "writeAccess"], false)
                  ? "Remove"
                  : "Give"}
              </Button>
            </DialogActions>
          </div>
        )}
        {userData.get("actionType", "") === "activate" && (
          <div>
            <DialogTitle>
              {userData.getIn(["selectedRow", "isActive"], false)
                ? "Deactivate"
                : "Activate"}{" "}
              User
            </DialogTitle>
            <DialogContent>
              <div>
                {userData.getIn(["selectedRow", "isActive"], false) && (
                  <p>Are you sure you want to deactivate this user?</p>
                )}
                {!userData.getIn(["selectedRow", "isActive"], false) && (
                  <div>
                    <p>Are you sure you want to activate this user?</p>
                    {!userData.get("canChangeRole", "") && (
                      <p>
                        <span className="bold">
                          {userData.getIn(["selectedRow", "username"], "")}
                        </span>{" "}
                        will get{" "}
                        <span className="bold">
                          {userData.getIn(["selectedRow", "role"], "")}
                        </span>{" "}
                        role{" "}
                        <span
                          onClick={handleCanChange}
                          className="change-button bold">
                          Change
                        </span>
                      </p>
                    )}
                    {userData.get("canChangeRole", false) && (
                      <div className="form-group">
                        <label className="mb-2 bold">
                          Choose Role:
                          <select
                            value={userData.get("role", "")}
                            onChange={(event) =>
                              setUserData((pre) =>
                                pre.set("role", event.target.value)
                              )
                            }
                            style={{ padding: "5px" }}
                            required>
                            <option value="">Select Role</option>
                            {roles.toJS().length > 0 &&
                              roles.toJS().map((role, index) => (
                                <option key={index} value={role.role}>
                                  {role.role}
                                </option>
                              ))}
                          </select>
                        </label>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleActionDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleActivateFormSubmit} color="primary">
                {userData.getIn(["selectedRow", "isActive"], false)
                  ? "Deactivate"
                  : "Activate"}
              </Button>
            </DialogActions>
          </div>
        )}
        {userData.get("actionType", false) === "edit" && (
          <div>
            <DialogTitle>Edit Role</DialogTitle>
            <DialogContent>
              <form id="edit-form" onSubmit={handleEditFormSubmit}>
                {userData.get("actionType", false) === "edit" && (
                  <div className="form-group">
                    <label>
                      Role:
                      <select
                        value={userData.get("role", "")}
                        onChange={(event) =>
                          setUserData((pre) =>
                            pre.set("role", event.target.value)
                          )
                        }
                        style={{ padding: "5px" }}
                        required>
                        <option value="">Select Role</option>
                        {roles.toJS().length > 0 &&
                          roles.toJS().map((role) => (
                            <option key={role} value={role.role}>
                              {role.role}
                            </option>
                          ))}
                      </select>
                    </label>
                  </div>
                )}
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleActionDialogClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" form="edit-form" color="primary">
                Update
              </Button>
            </DialogActions>
          </div>
        )}
        {userData.get("actionType", "") === "delete" && (
          <div>
            <DialogTitle>Delete User</DialogTitle>
            <DialogContent>
              <div>
                <p>Are you sure you want to delete this user?</p>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleActionDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteUserSubmit} color="primary">
                Delete
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
      {userData.get("updateDetailsOpen", false) && (
        <UpdateDetails
          id={userData.get("id", "")}
          userData={userData}
          setUserData={setUserData}
          updateDetailsOpen={userData.get("updateDetailsOpen", false)}
        />
      )}
      {userData.get("bioOpen", false) && (
        <BioMetric
          userData={userData}
          setUserData={setUserData}
          id={userData.get("id", "")}
        />
      )}
    </div>
  );
};

export default UserTable2;
