import { Button, Tooltip } from "@mui/material";

import React from "react";
import { useStyles } from "./ButtonStyle";

export const ButtonDesign = ({
  name,
  icon,
  tooltip,
  handleAction,
  handleChangeButtonName,
}) => {
  const classes = useStyles();

  return (
    <>
      {handleChangeButtonName ? (
        <div>
          <Tooltip title={tooltip} placement="bottom" arrow>
            <Button
              variant="contained"
              className={classes.button}
              startIcon={icon}
              onClick={handleAction}>
              {handleChangeButtonName() ? "Close" : "Reopen"}
            </Button>
          </Tooltip>
        </div>
      ) : (
        <div>
          <Tooltip title={tooltip} placement="bottom" arrow>
            <Button
              variant="contained"
              className={classes.button}
              startIcon={icon}
              onClick={handleAction}>
              {name}
            </Button>
          </Tooltip>
        </div>
      )}
    </>
  );
};
export const ExpandButton = ({ name, icon, tooltip, handleAction }) => {
  const classes = useStyles();
  return (
    <Tooltip title={tooltip} placement="bottom" arrow>
      <Button
        variant="contained"
        className={classes.button}
        startIcon={icon}
        onClick={handleAction}>
        {name}
      </Button>
    </Tooltip>
  );
};
