import React, { useRef } from "react";
import Webcam from "react-webcam";
import "./attendance.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
const backEndApi = process.env.REACT_APP_BackEndUrl;

const AttendanceRegister = ({
  setCamera,
  camera,
  username,
  imageCount,
  setImageCount,
  setImg1,
  setImg2,
  setImg3,
  setHideCameraButton,
  capturedImages,
  setCapturedImages,
  mongoId,
  setReminderPicNotification,
}) => {
  const webcamRef = useRef(null);

  const capture = async () => {
    console.log("working");
    const imageSrc = webcamRef.current.getScreenshot();

    let updateUserName = username?.replaceAll(" ", "_");

    let newUserName = `${updateUserName}____${mongoId}_${imageCount + 1}.jpg`;

    setCamera(false);

    setCapturedImages([
      ...capturedImages,
      { imgLink: imageSrc, userName: newUserName },
    ]);

    // get picture url from server
    axios
      .post(`${backEndApi}oauth/postUploadFile`, {
        imageSrc,
        imageCount,
      })
      .then((res) => {
        let count = res.data.message.imgCount;
        let url = res.data.message.imgURL;
        setImageCount(count);
        if (count === 1) {
          setImg1(url);
          setReminderPicNotification(true);
        } else if (count === 2) {
          setImg2(url);
        } else {
          setImg3(url);
          setHideCameraButton(false);
        }
      })
      .catch((error) => {
        //     // Handle error
        console.error(error);
      });
  };

  const removeCamera = () => {
    setCamera(false);
  };

  return (
    <div>
      <Dialog open={camera} className="dialog-box">
        <DialogTitle className="heading">Webcam Capture</DialogTitle>
        <Webcam className="handleCameraImage" audio={false} ref={webcamRef} />
        <Button
          onClick={capture}
          endIcon={<CameraAltIcon />}
          variant="outlined"
          className="take-picture-user-image">
          Take Picture
        </Button>
        <Button
          onClick={removeCamera}
          endIcon={<CloseIcon />}
          className="close-user-picture-register"
          variant="outlined">
          Cancel
        </Button>
      </Dialog>
    </div>
  );
};

export default AttendanceRegister;
