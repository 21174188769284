import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

function Dump({ setUri, dumpDbUri, mongoDbDump, dumpError }) {
  const updateUri = (e) => {
    const value = e.target.value;
    setUri(value);
  };

  return (
    <div className="w-100  d-flex flex-column justify-content-center align-items-center gap-3">
      <div className="w-50 card d-flex justify-content-center  align-items-center p-2">
        <div className="container d-flex flex-column gap-4">
          <h1 class="text-capitalize fs-2">Dump mongodb</h1>

          {/* <input
        type="text"
        className="dump-text"
        placeholder=" Enter Dump DB Uri"
        value={dumpDbUri}
        onChange={updateUri}
      /> */}

          <TextField
            type="text"
            label="Dump URL"
            variant="outlined"
            value={dumpDbUri}
            onChange={updateUri}
            sx={{ width: "100%" }}
            autoComplete="none"
            autoFocus={true}
          />
          <div>
            <Button
              variant="contained"
              onClick={mongoDbDump}
              disabled={dumpDbUri === "" ? true : false}
              // sx={{ background: "red" }}
            >
              Dump Database
            </Button>
          </div>
          {/* <button className="submit-button" onClick={mongoDbDump}>
        Dump Database
      </button> */}
        </div>
      </div>
    </div>
  );
}

function Restore({
  updateRestoreUri,
  uri,
  mongoDbRestore,
  selectedFolder,
  folderName,
  checking,
}) {
  /*className="dump-heading"*/

  return (
    <div className="w-100  d-flex flex-column justify-content-center align-items-center gap-3">
      <div className="w-50 card d-flex justify-content-center align-items-center p-2">
        <div className="container d-flex flex-column gap-4">
          <h1 class="text-capitalize fs-2">Restore mongodb</h1>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <label class="text-capitalize fs-5 fst-italic">
                Select Database :
              </label>
            </div>
            <FormControl sx={{ width: "75%" }}>
              <InputLabel id="demo-controlled-open-select-label">
                Select DB
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                label="Dump URL"
                value={selectedFolder}
                onChange={checking}
              >
                {folderName?.length &&
                  folderName.map((role, index) => (
                    <MenuItem key={index} value={role}>
                      {role}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          <TextField
            label="Enter Restore DB Uri"
            variant="outlined"
            sx={{ width: "100%" }}
            value={uri}
            onChange={updateRestoreUri}
            autoFocus={true}
          />
          {console.log(selectedFolder, selectedFolder === "" ? "aaaa" : "bbbb")}
          <div>
            <Button
              variant="contained"
              onClick={mongoDbRestore}
              disabled={selectedFolder === "" || uri === "" }
            >
              Restore Database
            </Button>
          </div>
        </div>
      </div>
      {/* <h1>Restore mongodb</h1> */}
    </div>
  );
}

export { Dump, Restore };
