import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AssetsMapForm from "../AssetsMap/AssetsMapForm";
import { getAssetEmpListApi, getAssetMappingApi, getAssetServiceApi,setData } from "../../../Redux/AssetsManagement/Action";
import Snackbar from "../../../LoaderSnackbar/Snackbar";
import Loader from "../../../LoaderSnackbar/Loader";

const AssetsMap = () => {
  const loadingStatus = useSelector((state) => state.AssetsManagement).get("isLoading", "");
  const messageStatus = useSelector((state) => state.AssetsManagement).get("message", "");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssetMappingApi());
    dispatch(getAssetEmpListApi());
    dispatch(getAssetServiceApi());
  }, []); // eslint-disable-line

  return (
    <div className='container-fluid mt-4'>
      <AssetsMapForm />
      <Snackbar show={true}  message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
    </div>
  );
};

export default AssetsMap;
