import { useFormik } from "formik";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useState, React, useEffect } from "react";
import { InsertRowModel } from "./Modal/InsertRowModel";
import { AgTable } from "./AgTable";
import { createColumnDefs } from "./constants";
import { getRow } from "../../Utils/FunctionUtils";
import * as action from "../../Redux/TestCase/Action";
import { connect } from "react-redux";
import Snackbar from "../../LoaderSnackbar/Snackbar";
import Loader from "../../LoaderSnackbar/Loader";
import {
  selectIsLoading,
  selectExecuted,
  selectTestCaseData,
  selectMessage,
} from "../../Redux/TestCase/Selector";

function Create({
  getUploadedCase,
  getUploadedCloneCase,
  testCaseList,
  postTestCaseData,
  postTestCaseInsertRow,
  postCloneCaseInsertRow,
  setData,
  loadingStatus,
  messageStatus,
}) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const cloneKey = searchParams?.get("cloneKey");
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [caseCreated, setCaseCreated] = useState("");
  const [file, setFile] = useState();

  const formik = useFormik({
    initialValues: {
      featuresName: "",
      module: "",
      version: "",
    },
    onSubmit: (values) => {
      let messageData = "";
      if (caseCreated === "" && values.module === "") {
        messageData = "Please enter module Name";
      } else if (caseCreated === "" && values.module.length <= 4) {
        messageData = "Please enter module Name more than 4 letters";
      } else if (caseCreated === "" && values.featuresName === "") {
        messageData = "Please enter features Name";
      } else if (caseCreated === "" && values.featuresName.length <= 4) {
        messageData = "Please enter features Name more than 4 letters";
      } else if (caseCreated === "" && values.version === "") {
        messageData = "Please enter Version";
      } else if (caseCreated !== "" && file === undefined) {
        messageData = "Please select file before submit.";
      }
      if (messageData !== "") {
        setData({ message: messageData });
        return;
      }
      const newFormData = new FormData();
      const uploadData = {
        version: formik.values.version,
        featuresName: formik.values.featuresName,
        module: formik.values.module,
      };
      if (caseCreated !== "") {
        newFormData.append("upload", file);
        newFormData.append("id", caseCreated);
      }
      const payload = caseCreated === "" ? uploadData : newFormData;
      const callBack = (data) => {
        const message = "Details Added Successfully...";
        setData({ message: message });
        setCaseCreated(data);
        navigate(`/testCase/createAndEdit/${data}`);
      };
      postTestCaseData(caseCreated, payload, getCallBack, callBack);

      // alert(
    },
  });

  useEffect(() => {
    const paramsId = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    if (cloneKey !== null) {
      getUploadedCloneCase(paramsId, cloneKey);
      setCaseCreated(paramsId);
    } else if (paramsId !== "createAndEdit" && paramsId !== "") {
      getUploadedCase(paramsId);
      setCaseCreated(paramsId);
    }
  }, []); // eslint-disable-line

  const getCallBack = (id) => {
    const message = "Uploaded successfully...";
    setData({ message: message });
    setCaseCreated(id);
    if (cloneKey !== null) {
      getUploadedCloneCase(id, cloneKey);
      setCaseCreated(id);
    } else if (id !== "createAndEdit") {
      getUploadedCase(id);
      setCaseCreated(id);
    }
  };

  const handleCallBack = (data) => {
    if (cloneKey === null)
      postTestCaseInsertRow(data, () => getCallBack(caseCreated));
    else {
      data.cloneKey = cloneKey;
      if (testCaseList.data?.length > 0)
        data.executionReason = testCaseList.data[0].executionReason;
      data.paul = "paul";
      postCloneCaseInsertRow(data, () => getCallBack(caseCreated));
    }
  };

  const getNextTestId = () => {
    let lastId = testCaseList.data.reduce((returnValue, obj = {}) => {
      let count = obj.TestCaseID;
      if (returnValue < count && count.includes("_")) returnValue = count;
      return returnValue;
    }, "");
    const testIdText = lastId.substring(0, lastId.lastIndexOf("_") + 1);
    const testId = parseInt(lastId.substring(lastId.lastIndexOf("_") + 1)) + 1;

    const returnValue = testIdText + String(testId).padStart(3, "0");
    return returnValue;
  };

  const handleView = (id) => {
    navigate(
      `/testCase/viewTestCase?id=${id}${
        cloneKey === null ? "" : `&cloneKey=${cloneKey}`
      }`
    );
  };

  return (
    <>
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <div className="container mt-5">
        <div className="d-sm-flex align-items-center justify-content-center mb-4">
          <h1 className="h3 mb-0 text-dark-800">
            {caseCreated === "" ? "Create Feature" : "Add TestCase"}{" "}
          </h1>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row mb-2 d_all_center">
            <div className="col-lg-3">
              <label className="featurelable">
                <b>Module Name</b> <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="col-lg-6">
              {caseCreated === "" ? (
                <input
                  className={`form-control text_box `}
                  type={"text"}
                  value={formik.values.module}
                  onChange={formik.handleChange}
                  placeholder="Enter module name"
                  name="module"
                />
              ) : (
                <label>
                  {formik.values.module !== ""
                    ? formik.values.module
                    : testCaseList.featureData[0].module}
                </label>
              )}
            </div>
          </div>
          <div className="row mb-2 d_all_center">
            <div className="col-lg-3">
              <label className="featurelable">
                <b>Features Name</b> <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="col-lg-6">
              {caseCreated === "" ? (
                <input
                  className={`form-control text_box `}
                  type={"text"}
                  value={formik.values.featuresName}
                  onChange={formik.handleChange}
                  placeholder="Enter features name"
                  name="featuresName"
                />
              ) : (
                <label>
                  {testCaseList.featureData[0].featuresName === ""
                    ? formik.values.featuresName
                    : testCaseList.featureData[0].featuresName}
                </label>
              )}
            </div>
          </div>
          <div className="row mb-2 d_all_center">
            <div className="col-lg-3">
              <label className="featurelable">
                {" "}
                <b>Version</b> <span style={{ color: "red" }}>*</span>
              </label>
            </div>
            <div className="col-lg-6">
              {caseCreated === "" ? (
                <input
                  className={`form-control text_box `}
                  type={"text"}
                  value={formik.values.version}
                  onChange={formik.handleChange}
                  name="version"
                  placeholder="Enter Version"
                />
              ) : (
                <label>
                  {testCaseList.featureData[0].version === ""
                    ? formik.values.version
                    : testCaseList.featureData[0].version}
                </label>
              )}
            </div>
          </div>

          {caseCreated !== "" && testCaseList.data.length === 0 && (
            <div className="row mb-2 d_all_center">
              <div className="col-lg-3">
                <label htmlFor="file" className="featurelable">
                  <b>File</b> <span style={{ color: "red" }}>*</span>
                </label>{" "}
                <br />
              </div>
              <div className="col-lg-6">
                <input
                  className="field"
                  type="file"
                  id="file"
                  accept="pdf,excel"
                  onChange={(event) => {
                    setFile(event.target.files[0]);
                  }}
                />
              </div>
            </div>
          )}
          {testCaseList.data.length === 0 && (
            <div className="d-flex align-items-center justify-content-center mb-2">
              <button
                className="btn-primary p-1 mt-2"
                type="submit"
                value="Create"
              >
                {caseCreated === "" ? "Create" : "Import Test Case"}
              </button>
            </div>
          )}
        </form>
      </div>
      {modelShow && (
        <InsertRowModel
          close={() => setModelShow(false)}
          show={modelShow}
          handleCallBack={handleCallBack}
          feature={
            formik.values.featuresName !== ""
              ? formik.values.featuresName
              : testCaseList.featureData[0].featuresName
          }
          version={
            formik.values.version !== ""
              ? formik.values.version
              : testCaseList.featureData[0].version
          }
          module={
            formik.values.module !== ""
              ? formik.values.module
              : testCaseList.featureData[0].module
          }
          id={caseCreated}
          setData={setData}
          getNextTestId={getNextTestId}
        />
      )}
      {testCaseList.data.length > 0 && (
        <>
          <div>
            <button
              className="btn-primary p-1 m-2 "
              onClick={() => setModelShow(true)}
            >
              ADD a TestCase
            </button>
          </div>
          <AgTable
            columnDefs={createColumnDefs(handleView)}
            rowData={getRow(testCaseList.data)}
          />
        </>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loadingStatus: selectIsLoading(state),
    messageStatus: selectMessage(state),
    executedStatus: selectExecuted(state),
    testCaseList: selectTestCaseData(state).toJS(),
  };
};

export default connect(mapStateToProps, action)(Create);
