import "./App.css";
import MainPage from "./Modules/TestCase/Mainpage";
import Homepage from "./Modules/TestCase/Homepage";
import NoAccess from "./Modules/TestCase/NoAccess";
import Document from "./Modules/DocumentManagement/index";
import CreateDocument from "./Modules/DocumentManagement/CreateDocument";
import { Routes, Route, Navigate } from "react-router-dom";
import Create from "./Modules/TestCase/Create";
import Execute from "./Modules/TestCase/Execute";
import ViewTestCase from "./Modules/TestCase/ViewTestCase";
import Login from "./Modules/TestCase/login";
import AdminTable from "./Modules/TestCase/AdminTable";
import PendingApproval from "./Modules/TestCase/PendingApproval";
import UiMainAssets from "./Modules/AssetsManage/02.Ui/MainAssets";
import AssetsMap from "./Modules/AssetsManage/AssetsMap/AssetsMap";
import BillTable from "./Modules/BillingManagement/Pages/BillTable";
import CreateForm from "./Modules/BillingManagement/Pages/CreateForm";
import SnapchatTable from "./Modules/BillingManagement/Pages/SnapchatTable";
import Attendance from "./Modules/Attendance/Pages/index";
import DateWishView from "./Modules/Attendance/Pages/dateWishView";
import Index from "./Modules/DbDump/index";
import { useState, useEffect } from "react";
import SideNavbar from "./Modules/TestCase/SideNavbar";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, selectAccessToken } from "./Redux/TestCase/Selector";
import { useNavigate } from "react-router-dom";
import {
  getOauth2UserInfo,
  reGenerateAccessToken,
} from "./Redux/TestCase/Action";
import { List } from "immutable";
import Box from "@mui/material/Box";
import { Main } from "./Modules/TestCase/Styles";
import viewIssue from "./Modules/ClientSupport/viewIssue";
import TicketReports from "./Modules/ClientSupports/TicketReports";
import TicketReply from "./Modules/ClientSupports/TicketReply";
import { client_secret, client_id } from "./config";
import ClientDashboard from "./Modules/ClientSupports/Dashboard";

const Routing = () => {
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [isAPICalled, setIsAPICalled] = useState(false);
  const [open, setOpen] = useState(false);
  const accessTokenFromRedux = useSelector(selectAccessToken);
  const accessToken = JSON.parse(localStorage.getItem("access_token"));
  const finalAccessToken = accessTokenFromRedux
    ? accessTokenFromRedux
    : accessToken;
  const userType = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationArray = window.location.href.split("/");
  const refreshToken = JSON.parse(localStorage.getItem("refresh_token"));
  const innerTime = localStorage.getItem("inner_time");
  const systemTime = new Date().getTime();
  const checkValidity = systemTime >= innerTime;
  const checkGoogleAuthInfoApi = checkValidity
    ? accessTokenFromRedux
    : finalAccessToken &&
      finalAccessToken !== "" &&
      !window.location.href.split("/").includes("login");

  const loginCallBack = (data) => {
    const currentURL = localStorage.getItem("urlPathName");
    if (data.role !== "") {
      if (currentURL) {
        navigate(currentURL);
      } else if (data.role === "Admin") {
        navigate("/admin/");
      } else if (!data.isActive && data.attachment?.length === 0) {
        navigate(`/auth/login?id=${data._id}`);
      } else if (data.isActive) {
        navigate("/");
      } else {
        navigate("/auth/pendingApproval/");
      }
    } else {
      navigate("/auth/login/");
    }
  };

  useEffect(() => {
    const status =
      locationArray.includes("login") ||
      locationArray.some((item) => item?.includes("login")) ||
      locationArray.includes("pendingApproval");
    !status &&
      localStorage.setItem(
        "urlPathName",
        window.location.pathname + window.location.search
      );
    setSidebarStatus(!status);
  }, [window.location.href]); //eslint-disable-line

  useEffect(() => {
    const payload = {
      params: {
        client_id: client_id,
        client_secret: client_secret,
        refresh_token: refreshToken,
        grant_type: "refresh_token",
      },
    };
    if (checkValidity && refreshToken) dispatch(reGenerateAccessToken(payload));
  }, []); //eslint-disable-line

  useEffect(() => {
    if (checkGoogleAuthInfoApi) {
      dispatch(
        getOauth2UserInfo(finalAccessToken, (data) => loginCallBack(data))
      );
    }
    setIsAPICalled(true);
  }, [accessTokenFromRedux]); //eslint-disable-line

  // const userData = localStorage.getItem("authRes");

  const authCheck = (userTypeText, Component) => {
    if (finalAccessToken) {
      if (userType?.size > 0) {
        var userRole = userType?.get("role", "");
        if (userTypeText === "user") {
          return <Component />;
        } else
          return userType.get("pageAccess", List()).includes(userTypeText) ||
            userRole === "Admin" ? (
            <Component />
          ) : (
            <NoAccess />
          );
      } else {
        return;
      }
    } else {
      return <Navigate to="/auth/login/" />;
    }
  };

  if (!isAPICalled) return <></>;
  return (
    // className="mt-2">

    <Box sx={{ display: "flex" }}>
      {sidebarStatus && <SideNavbar open={open} setOpen={setOpen} />}
      <Main
        style={{
          ...(!sidebarStatus && {
            marginLeft: "0px",
            marginTop: "0px",
            height: "100vh",
          }),
        }}
        open={open}
        onClick={() => {
          setOpen(false);
        }}
      >
        <Routes>
          <Route path="/auth/login/" element={<Login />} />

          <Route path="/Attendance/" element={authCheck("Admin", Attendance)} />
          <Route
            path="/Attendance/ViewByDate/"
            element={authCheck("Admin", DateWishView)}
          />

          <Route path="/" element={authCheck("user", Homepage)} />

          <Route path="/admin" element={authCheck("Admin", AdminTable)} />

          <Route
            path="/testCase"
            element={authCheck("TestCase", MainPage)}
            exact
          />
          <Route
            path="/testCase/execute/:id"
            element={authCheck("TestCase", Execute)}
          />
          <Route
            path="/testCase/viewTestCase"
            element={authCheck("TestCase", ViewTestCase)}
          />
          <Route
            path="/testCase/createAndEdit"
            element={authCheck("TestCase", Create)}
          />
          <Route
            path="/testCase/createAndEdit/:id"
            element={authCheck("TestCase", Create)}
          />

          {/* <Route path="/auth/login/" element={<Login />} /> */}
          <Route path="/auth/pendingApproval" element={<PendingApproval />} />

          <Route
            path="/assetManagement"
            element={authCheck("Assets", UiMainAssets)}
          ></Route>
          <Route
            path="/assetsMapList"
            element={authCheck("Assets", AssetsMap)}
          ></Route>

          <Route
            path="/clientSupport/viewIssue"
            element={authCheck("Support", viewIssue)}
          ></Route>
          {/* <Route
              path="/clientSupport/viewTicket"
              element={authCheck("Support", ClientTicket)}
            ></Route> */}
          <Route
            path="/clientSupport/viewTicket"
            element={authCheck("Support", TicketReply)}
          ></Route>
          <Route path="/dumpDB" element={authCheck("DB dump", Index)}></Route>

          {/* <Route
            path="/clientSupport"
            element={authCheck("Support", ClientSupport)}
          ></Route> */}
          <Route
            path="/clientSupport"
            element={authCheck("Support", TicketReports)}
          ></Route>
          <Route
            path="/clientSupport/dashboard"
            element={authCheck("Support", ClientDashboard)}
          ></Route>

          <Route
            path="/Documentation"
            element={authCheck("Documentation", Document)}
          ></Route>
          <Route
            path="/MyDocuments"
            element={authCheck("Documentation", Document)}
          ></Route>
          <Route
            path="/CreateDocument"
            element={authCheck("Documentation", CreateDocument)}
          ></Route>
          <Route
            path="/EditDocument"
            element={authCheck("Documentation", CreateDocument)}
          ></Route>
          <Route
            path="/viewDocument"
            element={authCheck("Documentation", CreateDocument)}
          ></Route>
          <Route
            path="/documentHistory"
            element={authCheck("Documentation", Document)}
          ></Route>

          <Route
            path="/cloudBilling"
            element={authCheck("Billing", BillTable)}
          ></Route>
          <Route
            path="/cloudBilling/Snapchat/:id"
            element={authCheck("Billing", SnapchatTable)}
          ></Route>
          <Route
            path="/cloudBilling/billing"
            element={authCheck("Billing", CreateForm)}
          ></Route>
        </Routes>
      </Main>
    </Box>
  );
};

export default Routing;
