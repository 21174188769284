import React from "react";
import Snackbar from "@mui/material/Snackbar";
import { connect } from "react-redux";
import IconButton from "@mui/material/IconButton";
import * as action from "../Redux/TestCase/Action";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";

function SnackBar(props) {
  const { show, message, reduxSetData = () => {} } = props;
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    open: show,
    vertical: "bottom",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
    dispatch(reduxSetData({ message: "" }));
  };
  return (
    <div>
      {message !== "" && message !== undefined && (
        <Snackbar
          className="snackbar-error-toaster"
          anchorOrigin={{ vertical, horizontal }}
          open={true}
          autoHideDuration={5000}
          onClose={handleClose}
          message={message}
          key={Math.random()}
          sx={{ "& .MuiPaper-root": { display: "flex", flexWrap: "nowrap" } }}
          action={
            <React.Fragment>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
    </div>
  );
}

SnackBar.propTypes = {
  messageStatus: PropTypes.string,
  message: PropTypes.string,
  show: PropTypes.bool,
};

export default connect(null, action)(SnackBar);
