import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { List, Map } from "immutable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCategoryAndCountList,
  selectCategoryBasedTickets,
} from "../../../Redux/ClientSupportManagement/Selector";
import {
  getAllCategoryAndCountList,
  getAllFilteredTicketList,
} from "../../../Redux/ClientSupportManagement/Action";
import DashBoardTicketReports from "./models/DashBoardTicketReports";

// headers
const Header = ({ totalTickets, clientCount, categoriesCount }) => {
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <div className="d-flex">
          <div className="mx-3">Total Tickets : {totalTickets}</div>
          <div className="mx-3">Total Clients : {clientCount}</div>
          <div className="mx-3">Total Categories : {categoriesCount}</div>
        </div>
      </CardContent>
    </Card>
  );
};

// clients
const ClientType = ({ data, setData, clientList }) => {
  const handleChange = (value, name) => {
    data = data
      .set("clientId", value)
      .set("clientName", name)
      .remove("categoryId")
      .remove("subCategoryId")
      .remove("sectionId");

    const clientData = clientList.filter((item) => {
      if (item.get("_id", "") === value) {
        return item.get("tickets", List());
      }
    });
    const tickets = clientData.getIn([0, "tickets"]);
    const ticketIds = tickets.map((item) => item.get("_id", ""));
    data = data.set("ticketIds", ticketIds);

    setData(data);
  };

  return (
    <Card
      className="mt-4 mx-3"
      sx={{ minWidth: "25%", maxWidth: "25%", borderRadius: "10px" }}
    >
      <CardContent>
        <div className="text-center text-primary fs-5 mb-2 fw-bold">
          Client List
        </div>
        <div className="d-flex flex-column align-items-center">
          {clientList.map((item) => (
            <div
              key={item.get("clientName", "")}
              className={`d-flex justify-content-between col-12 col-md-8 gap-5 mb-1 cursor-pointer ${
                item.get("_id") === data.get("clientId", "")
                  ? "text-white bg-primary"
                  : ""
              }`}
              onClick={() =>
                handleChange(item.get("_id", ""), item.get("clientName", ""))
              }
            >
              <div className="col-6">{item.get("clientName", "")}</div>
              <div className="col-6">{item.get("ticketCount", 0)}</div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

// category
const Category = ({ data, setData, clientList, categories }) => {
  const clientData = clientList.find(
    (item) => item.get("_id", "") === data.get("clientId", "")
  );
  const tickets = clientData.get("tickets", List());
  const mappedCategories = tickets.filter((item) => item.get("categoryId", ""));

  const handleChange = (value, name) => {
    data = data
      .set("categoryId", value)
      .remove("subCategoryId")
      .remove("sectionId");

    if (name !== "unCategory") {
      const ticketIds = tickets
        .filter((item) => item.get("categoryId", "") === value)
        .map((item) => item.get("_id", ""));
      data = data.set("ticketIds", ticketIds);
    } else {
      const ticketIds = tickets
        .filter((item) => !item.get("categoryId", ""))
        .map((item) => item.get("_id", ""));
      data = data.set("ticketIds", ticketIds);
    }

    setData(data);
  };

  const findCategoryCount = (id) => {
    const filteredCategory = tickets.filter(
      (item) => item.get("categoryId", "") === id
    );

    return filteredCategory.size;
  };

  const getUnCategoryCount = () => {
    return tickets.size - mappedCategories.size;
  };

  return (
    <Card
      className="mt-4 mx-3"
      sx={{ minWidth: "25%", maxWidth: "25%", borderRadius: "10px" }}
    >
      <CardContent>
        <div className="text-center text-primary fs-5 mb-2 fw-bold ">
          Category List
        </div>
        <div className="d-flex flex-column align-items-center">
          {categories.map((item) => (
            <div
              key={item.get("category", "")}
              className={`d-flex justify-content-between col-12 col-md-8 gap-5 mb-1 cursor-pointer ${
                item.get("_id") === data.get("categoryId", "")
                  ? "text-white bg-primary"
                  : ""
              }`}
              onClick={() =>
                handleChange(item.get("_id", ""), item.get("category", ""))
              }
            >
              <div className="col-6">{item.get("category", "")}</div>
              <div className="col-6">
                {item.get("category", "") === "unCategory"
                  ? getUnCategoryCount()
                  : findCategoryCount(item.get("_id", ""))}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

// sub category
const SubCategory = ({ data, setData, categories, clientList }) => {
  const clientData = clientList.find(
    (item) => item.get("_id", "") === data.get("clientId", "")
  );
  const tickets = clientData.get("tickets", List());

  const handleChange = (value) => {
    data = data.set("subCategoryId", value);

    const SubCategoryTickets = tickets.filter(
      (item) => item.get("subCategoryId", "") === value
    );
    const ticketIds = SubCategoryTickets.map((item) => item.get("_id", ""));
    data = data.set("ticketIds", ticketIds);

    setData(data);
  };

  const individualCategory = categories.find(
    (item) => item.get("_id", "") === data.get("categoryId", "")
  );

  const findSubCategoryCount = (id) => {
    const filteredSubCategory = tickets.filter(
      (item) => item.get("subCategoryId", "") === id
    );

    return filteredSubCategory.size;
  };

  return (
    <Card
      className="mt-4 mx-3"
      sx={{ minWidth: "25%", maxWidth: "25%", borderRadius: "10px" }}
    >
      <CardContent>
        <div className="text-center text-primary fs-5 mb-2 fw-bold ">
          SubCategory List
        </div>
        <div className="d-flex flex-column align-items-center">
          {individualCategory.get("subCategories", List()).map((item) => (
            <div
              key={item.get("category", "")}
              className={`d-flex justify-content-between col-12 col-md-8 gap-5 mb-1 cursor-pointer ${
                item.get("_id") === data.get("subCategoryId", "")
                  ? "text-white bg-primary"
                  : ""
              }`}
              onClick={() => handleChange(item.get("_id", ""))}
            >
              <div className="col-6">{item.get("category", "")}</div>
              <div className="col-6">
                {findSubCategoryCount(item.get("_id", ""))}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

// section
const Section = ({ data, setData, categories, clientList }) => {
  const clientData = clientList.find(
    (item) => item.get("_id", "") === data.get("clientId", "")
  );
  const tickets = clientData.get("tickets", List());

  const handleChange = (value) => {
    data = data.set("sectionId", value);

    const sectionTickets = tickets.filter(
      (item) => item.get("sectionId", "") === value
    );
    const ticketIds = sectionTickets.map((item) => item.get("_id", ""));
    data = data.set("ticketIds", ticketIds);

    setData(data);
  };

  const individualCategory = categories.find(
    (item) => item.get("_id", "") === data.get("categoryId", "")
  );
  const individualSubCategory = individualCategory
    .get("subCategories", List())
    .find((item) => item.get("_id", "") === data.get("subCategoryId", ""));

  const findSectionCount = (id) => {
    const filteredSection = tickets.filter(
      (item) => item.get("sectionId", "") === id
    );

    return filteredSection.size;
  };

  return (
    <Card
      className="mt-4 mx-3"
      sx={{ minWidth: "25%", maxWidth: "25%", borderRadius: "10px" }}
    >
      <CardContent>
        <div className="text-center text-primary fs-5 mb-2 fw-bold ">
          Section List
        </div>
        <div className="d-flex flex-column align-items-center">
          {individualSubCategory.get("sections", List()).map((item) => (
            <div
              key={item.get("category", "")}
              className={`d-flex justify-content-between col-12 col-md-8 gap-5 mb-1 cursor-pointer ${
                item.get("_id") === data.get("sectionId", "")
                  ? "text-white bg-primary"
                  : ""
              }`}
              onClick={() => handleChange(item.get("_id", ""))}
            >
              <div className="col-6">{item.get("category", "")}</div>
              <div className="col-6">
                {findSectionCount(item.get("_id", ""))}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default function ClientDashboard() {
  const [data, setData] = useState(
    Map({
      clientId: "",
      clientName: "",
      categoryId: "",
      subCategoryId: "",
      sectionId: "",
      isNeedTicketComponent: false,
      ticketIds: [],
    })
  );
  const dispatch = useDispatch();
  const getAllCategoryList = useSelector(selectCategoryAndCountList);
  const getAllCategoryBasedTickets = useSelector(selectCategoryBasedTickets);

  const totalTickets = getAllCategoryList.get("totalTickets", List()).size;
  const categories = getAllCategoryList.get("categories", List());
  const clientList = getAllCategoryList.get("clientList", List());

  useEffect(() => {
    dispatch(getAllCategoryAndCountList());
  }, []);

  useEffect(() => {
    if (data.get("ticketIds", List).size !== 0)
      dispatch(getAllFilteredTicketList(data.get("ticketIds", List)));
  }, [data]);

  const checkIsSubCategory = () => {
    const index = categories.findIndex(
      (item) => item.get("_id", "") === data.get("categoryId", "")
    );
    const subCategorySize = categories.getIn(
      [index, "subCategories"],
      List()
    ).size;

    return subCategorySize !== 0;
  };

  const checkIsSection = () => {
    const index = categories.findIndex(
      (item) => item.get("_id", "") === data.get("categoryId", "")
    );
    const subCategory = categories.getIn([index, "subCategories"], List());
    const subIndex = subCategory.findIndex(
      (item) => item.get("_id", "") === data.get("subCategoryId", "")
    );
    const sectionSize = subCategory.getIn([subIndex, "sections"], List()).size;

    return sectionSize !== 0;
  };

  return (
    <div className="d-flex flex-column gap-2">
      <Header
        totalTickets={totalTickets}
        clientCount={clientList.size}
        categoriesCount={categories.size}
      />
      <div className="d-flex flex-wrap mb-3">
        <ClientType data={data} setData={setData} clientList={clientList} />
        {data.get("clientId", "") && (
          <Category
            data={data}
            setData={setData}
            clientList={clientList}
            categories={categories}
          />
        )}
        {data.get("categoryId", "") && checkIsSubCategory() && (
          <SubCategory
            data={data}
            setData={setData}
            categories={categories}
            clientList={clientList}
          />
        )}
        {data.get("subCategoryId", "") && checkIsSection() && (
          <Section
            data={data}
            setData={setData}
            categories={categories}
            clientList={clientList}
          />
        )}
      </div>
      {getAllCategoryBasedTickets.size !== 0 &&
        data.get("ticketIds", List).size !== 0 && (
          <DashBoardTicketReports
            tickets={getAllCategoryBasedTickets}
            clientName={data.get("clientName", "")}
          />
        )}
    </div>
  );
}
