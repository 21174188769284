import Swal from "sweetalert2";
import { useDispatch } from "react-redux";

export const useSweetAlert = () => {
  const dispatch = useDispatch();
  const sweetAlert = ({ id, ApiCall,callBack,type }) => {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "",
        cancelButton: "",
      },
      // buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "you want to delete this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, sure!",
        confirmButtonColor: "red",
        cancelButtonText: "No, cancel!",
        // reverseButtons: true
      })
      .then((result) => {
        if (result.value) {
          // dispatch(ApiCall(id,type,callBack))
          // console.log("callBack",callBack);
           type === undefined ? dispatch(ApiCall(id,callBack)):dispatch(ApiCall(id,type,callBack));
          swalWithBootstrapButtons.fire(
            "Deleted!",
            "Your data has been deleted.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelled",
            "Your data is safe",
            "error"
          );
        }
      });

  };
  return sweetAlert;
};
