import React, { useState } from "react";
import ReportHeader from "./Components/Header/ReportHeader";
import ReportSideBar from "./Components/Sidebar/ReportSideBar";
import { Box, CssBaseline } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { setData } from "../../../Redux/ClientSupportManagement/Action";
import { useSelector } from "react-redux";
import {
  selectIsLoading,
  selectMessage,
} from "../../../Redux/ClientSupportManagement/Selector";
import Snackbar from "../../../LoaderSnackbar/Snackbar";
import Loader from "../../../LoaderSnackbar/Loader";
import "./index.css";
import IssueData from "./Components/IssueData";

const TicketReports = ({ isFromDashboard = false }) => {
  const messageStatus = useSelector(selectMessage);
  const loadingStatus = useSelector(selectIsLoading);

  const [open, setOpen] = useState(false);
  const [select, setSelect] = React.useState(
    localStorage.getItem("SelectView")
      ? localStorage.getItem("SelectView")
      : "CardView"
  );

  const handleSubDrawerOpen = () => {
    setOpen(!open);
  };

  const handleSelect = (event) => {
    localStorage.setItem("SelectView", event.target.value);
    setSelect(event.target.value);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <CssBaseline />
      <ReportHeader
        select={select}
        handleSelect={handleSelect}
        handleSubDrawerOpen={handleSubDrawerOpen}
        open={open}
      />
      <Box sx={{ display: open && "flex" }}>
        <IssueData select={select} isFromDashboard={isFromDashboard} />
        <ReportSideBar open={open} />
      </Box>
    </Box>
  );
};

export default TicketReports;
