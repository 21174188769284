import Button from "@mui/material/Button";
import "./Mainpage.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AgTable } from "./AgTable";
import TabPanel, { columnDefs, executedColumnDefs } from "./constants";
import * as action from "../../Redux/TestCase/Action";
import { ExecutionReason } from "./Modal/ExecutionReason";
import { connect } from "react-redux";
import Snackbar from "../../LoaderSnackbar/Snackbar";
import Loader from "../../LoaderSnackbar/Loader";
import { Tabs, Tab, Box } from "@mui/material";
import { selectIsLoading, selectFeatureList, selectUser, selectMessage } from "../../Redux/TestCase/Selector";

function MainPage({ loadingStatus, getAllFeatureList, deleteFeatureData, featureList, setData, user, messageStatus }) {
  const [modelShow, setModelShow] = useState(false);
  const [executedId, setExecutedId] = useState(false);
  const [tab, setTab] = React.useState(0);

  const navigate = useNavigate();
  const handleClose = (save = false) => {
    setModelShow(false);
  };
  const handleCreate = () => {
    navigate("/testCase/createAndEdit");
  };

  useEffect(() => {
    getAllFeatureList();
  }, []); // eslint-disable-line
  const handleEdit = (id, cloneKey = "") => {
    cloneKey === "" ? navigate(`/testCase/createAndEdit/${id}`) : navigate(`/testCase/createAndEdit/${id}?cloneKey=${cloneKey}`);
  };
  const handleExecute = (id, cloneKey = "") => {
    if (cloneKey === "") {
      setModelShow((previousData) => !previousData);
    } else navigate(`/testCase/execute/${id}?cloneKey=${cloneKey}`);
    setExecutedId(id);
  };
  const handleDelete = (id, cloneKey = null) => {
    deleteFeatureData(id, cloneKey, () => getAllFeatureList());
  };

  const handleNavigate = () => {
    setData({ executed: true });
    navigate(`/testCase/execute/${executedId}`);
  };

  const handleNavi = () => {
    navigate("/admin");
  };

  return (
    <>
      <Snackbar show={true} message={messageStatus} reduxSetData={setData}/>
      <Loader isLoading={loadingStatus} />
      {modelShow && <ExecutionReason close={handleClose} show={modelShow} id={executedId} callBack={() => handleNavigate()} setData={setData} />}
      {/* id={executedId} */}
      <div className="CreateTestCase container-fluid mt-2 pe-4">
        {user && user.role === "Admin" && (
          <Button variant="contained" className="createbtn me-2" onClick={handleNavi}>
            Admin Portal
          </Button>
        )}
        <Button variant="contained" className="createbtn" onClick={handleCreate}>
          Create Feature <AddCircleIcon className="ms-2" />
        </Button>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tab} onChange={(e, number) => setTab(number)} aria-label="basic tabs example">
          <Tab label="Original Feature Tests" />
          <Tab label="Already Executed Tests" />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        {featureList?.originalData?.length > 0 ? <AgTable columnDefs={columnDefs(handleEdit, handleExecute, handleDelete)} rowData={featureList?.originalData} /> : <span className="w-100 d_all_center">Nothing to show</span>}
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {featureList?.executedData?.length > 0 ? <AgTable columnDefs={executedColumnDefs(handleEdit, handleExecute, handleDelete)} rowData={featureList?.executedData} /> : <span className="w-100 d_all_center">Nothing to show</span>}
      </TabPanel>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingStatus: selectIsLoading(state),
    messageStatus: selectMessage(state),
    featureList: selectFeatureList(state).toJS(),
    user: selectUser(state),
  };
};

export default connect(mapStateToProps, action)(MainPage);
