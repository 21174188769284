import React from "react";
import italic from "../../../../../../Assets/italic.svg";
import timeLogs from "../../../../../../Assets/timeLogs.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStyles } from "./ReplyStyle";
import "./ReplySideBar.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
} from "@mui/material";

const ReplySideBar = ({ expanded, handleChange, ticketData }) => {
  const classes = useStyles();

  return (
    <div className="ReplySideBar">
      <div>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          // className="ReplySideBar_Accordion"
          className={classes.sidebar_custom_accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.custom_accordion_summary}>
            <span className="d-flex">
              <img
                style={{ width: "25px", height: "25px" }}
                src={italic}
                alt="dsd"
              />
              CONTACT DETAILS
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <div className="d-flex gap-2">
              <Avatar alt="Remy Sharp" sx={{ width: "32px", height: "32px" }}>
                {ticketData?.name.split("")[0].toUpperCase()}
              </Avatar>
              <span className={classes.sender_name}>{ticketData?.name}</span>
            </div>
            <div className={`mt-3 `}>
              <span className={classes.info_details_Label}>Email</span>
              <div className={`${classes.info_details_content}`}>
                <span>{ticketData?.email}</span>
              </div>
              <span className={classes.info_details_Label}>Mobile Number</span>
              <div className={`${classes.info_details_content}`}>
                <span>
                  {ticketData?.mobileNumber ? ticketData?.mobileNumber : "---"}
                </span>
              </div>
            </div>
            <div className={classes.contacts__view_more_info}>
              {/* <a className={classes.view_more_info} href="#">
                View more info
              </a> */}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="mt-2">
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className={classes.sidebar_custom_accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.custom_accordion_summary}>
            <span className="d-flex gap-2">
              <img
                style={{ width: "25px", height: "25px" }}
                src={timeLogs}
                alt="dsd"
              />
              TIME LOGS
            </span>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.time_entries_content}>
              <div className={classes.time_tracked}>
                <p>Start tracking the time you spend on this ticket</p>
                <button className={`mt-8 ${classes.Log_btn}`}>Log time</button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default ReplySideBar;
