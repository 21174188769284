import React from "react";
import { connect } from "react-redux";
import Snackbar from "../../LoaderSnackbar/Snackbar";
import Loader from "../../LoaderSnackbar/Loader";
import { selectIsLoading, selectMessage } from "../../Redux/TestCase/Selector";
import * as action from "../../Redux/TestCase/Action";
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
function NoAccess({ loadingStatus, setData, messageStatus }) {
  return (
    <>
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <div
        style={{
          height: "calc(100vh - 65px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // marginTop: "10px",
        }}
      >
        <div ><ReportGmailerrorredIcon sx={{fontSize:"80px"}}  color="error"/></div>
        <h1>You don't have a Access</h1>
        <span style={{fontSize:"20px"}}>Contact Admin to visit this page.</span>
      </div>
      {/* id={executedId} */}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loadingStatus: selectIsLoading(state),
    messageStatus: selectMessage(state),
  };
};
export default connect(mapStateToProps, action)(NoAccess);