import React from "react";
import italic from "../../../../../../Assets/italic.svg";
import timeLogs from "../../../../../../Assets/timeLogs.svg";
import "./ReplySideIcon.css";
import { Tooltip } from "@mui/material";

const ReplySideIcon = ({ expanded, handleChange }) => {
  return (
    <div className="p-4 d-flex gap-4 flex-column align-items-center ReplySideIcon">
      <Tooltip
        title="contact details"
        arrow
        placement="right"
        // onChange={handleChange("panel1")}
      >
        <img src={italic} alt="dsd" />
      </Tooltip>
      <Tooltip
        title="timelogs"
        arrow
        placement="right"
        onChange={handleChange("panel2")}
      >
        <img src={timeLogs} alt="TimeLogs" />
      </Tooltip>
    </div>
  );
};

export default ReplySideIcon;
