import { createAction } from "../../Utils/FunctionUtils";
import axios from "axios";

const backEndApi = process.env.REACT_APP_BackEndUrl;

export const SET_DATA_SUCCESS = "SET_DATA_SUCCESS";
// setData({message:'some thing'})
const setDataSuccess = createAction(SET_DATA_SUCCESS, "data");
export const setData = (data) => {
  return (dispatch) => {
    dispatch(setDataSuccess(data));
  };
};

export const POST_CREATE_DOCUMENTS_TYPE_REQUEST =
  "POST_CREATE_DOCUMENTS_TYPE_REQUEST";
export const POST_CREATE_DOCUMENTS_TYPE_SUCCESS =
  "POST_CREATE_DOCUMENTS_TYPE_SUCCESS";
export const POST_CREATE_DOCUMENTS_TYPE_FAILURE =
  "POST_CREATE_DOCUMENTS_TYPE_FAILURE";

const postCreateDocumentsTypeRequest = createAction(
  POST_CREATE_DOCUMENTS_TYPE_REQUEST
);
const postCreateDocumentsTypeSuccess = createAction(
  POST_CREATE_DOCUMENTS_TYPE_SUCCESS,
  "data"
);
const postCreateDocumentsTypeFailure = createAction(
  POST_CREATE_DOCUMENTS_TYPE_FAILURE,
  "error"
);

export const postCreateDocumentsType = (payload, callBack) => {
  return (dispatch) => {
    dispatch(postCreateDocumentsTypeRequest());
    axios
      .post(`${backEndApi}Documentation/createDocumentsType`, payload)
      .then((res) => {
        dispatch(postCreateDocumentsTypeSuccess(res.data.message));
        dispatch(getDocumentsType(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(postCreateDocumentsTypeFailure(error)));
  };
};

export const POST_CREATE_DOCUMENT_REQUEST = "POST_CREATE_DOCUMENT_REQUEST";
export const POST_CREATE_DOCUMENT_SUCCESS = "POST_CREATE_DOCUMENT_SUCCESS";
export const POST_CREATE_DOCUMENT_FAILURE = "POST_CREATE_DOCUMENT_FAILURE";

const postCreateDocumentRequest = createAction(POST_CREATE_DOCUMENT_REQUEST);
const postCreateDocumentSuccess = createAction(
  POST_CREATE_DOCUMENT_SUCCESS,
  "data"
);
const postCreateDocumentFailure = createAction(
  POST_CREATE_DOCUMENT_FAILURE,
  "error"
);

export const postCreateDocument = (payload, callBack) => {
  return (dispatch) => {
    dispatch(postCreateDocumentRequest());
    axios
      .post(`${backEndApi}Documentation/createDocument`, payload)
      .then((res) => {
        dispatch(postCreateDocumentSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(postCreateDocumentFailure(error)));
  };
};

export const GET_DOCUMENTS_TYPE_REQUEST = "GET_DOCUMENTS_TYPE_REQUEST";
export const GET_DOCUMENTS_TYPE_SUCCESS = "GET_DOCUMENTS_TYPE_SUCCESS";
export const GET_DOCUMENTS_TYPE_FAILURE = "GET_DOCUMENTS_TYPE_FAILURE";

const getDocumentsTypeRequest = createAction(GET_DOCUMENTS_TYPE_REQUEST);
const getDocumentsTypeSuccess = createAction(
  GET_DOCUMENTS_TYPE_SUCCESS,
  "data"
);
const getDocumentsTypeFailure = createAction(
  GET_DOCUMENTS_TYPE_FAILURE,
  "error"
);

export const getDocumentsType = (payload, callBack) => {
  return (dispatch) => {
    dispatch(getDocumentsTypeRequest());
    axios
      .get(`${backEndApi}Documentation/getDocumentsType`, payload)
      .then((res) => {
        dispatch(getDocumentsTypeSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(getDocumentsTypeFailure(error)));
  };
};

export const GET_MY_DOCUMENT_LIST_REQUEST = "GET_MY_DOCUMENT_LIST_REQUEST";
export const GET_MY_DOCUMENT_LIST_SUCCESS = "GET_MY_DOCUMENT_LIST_SUCCESS";
export const GET_MY_DOCUMENT_LIST_FAILURE = "GET_MY_DOCUMENT_LIST_FAILURE";

const getMyDocumentListRequest = createAction(GET_MY_DOCUMENT_LIST_REQUEST);
const getMyDocumentListSuccess = createAction(
  GET_MY_DOCUMENT_LIST_SUCCESS,
  "data"
);
const getMyDocumentListFailure = createAction(
  GET_MY_DOCUMENT_LIST_FAILURE,
  "error"
);

export const getMyDocumentList = (author, callBack) => {
  return (dispatch) => {
    dispatch(getMyDocumentListRequest());
    axios
      .get(`${backEndApi}Documentation/getMyDocumentList?author=${author}`)
      .then((res) => {
        dispatch(getMyDocumentListSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(getMyDocumentListFailure(error)));
  };
};

export const GET_ALL_DOCUMENT_LIST_REQUEST = "GET_ALL_DOCUMENT_LIST_REQUEST";
export const GET_ALL_DOCUMENT_LIST_SUCCESS = "GET_ALL_DOCUMENT_LIST_SUCCESS";
export const GET_ALL_DOCUMENT_LIST_FAILURE = "GET_ALL_DOCUMENT_LIST_FAILURE";

const getAllDocumentListRequest = createAction(GET_ALL_DOCUMENT_LIST_REQUEST);
const getAllDocumentListSuccess = createAction(
  GET_ALL_DOCUMENT_LIST_SUCCESS,
  "data"
);
const getAllDocumentListFailure = createAction(
  GET_ALL_DOCUMENT_LIST_FAILURE,
  "error"
);

export const getAllDocumentList = (payload, callBack) => {
  console.log(payload, "payload");
  return (dispatch) => {
    dispatch(getAllDocumentListRequest());
    axios
      .get(
        `${backEndApi}Documentation/getAllDocumentList?departments=${payload?.department}&id=${payload?.id}`
      )
      .then((res) => {
        dispatch(getAllDocumentListSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(getAllDocumentListFailure(error)));
  };
};
export const GET_DOCUMENT_BY_ID_REQUEST = "GET_DOCUMENT_BY_ID_REQUEST";
export const GET_DOCUMENT_BY_ID_SUCCESS = "GET_DOCUMENT_BY_ID_SUCCESS";
export const GET_DOCUMENT_BY_ID_FAILURE = "GET_DOCUMENT_BY_ID_FAILURE";

const getDocumentByIdRequest = createAction(GET_DOCUMENT_BY_ID_REQUEST);
const getDocumentByIdSuccess = createAction(GET_DOCUMENT_BY_ID_SUCCESS, "data");
const getDocumentByIdFailure = createAction(
  GET_DOCUMENT_BY_ID_FAILURE,
  "error"
);

export const getDocumentById = (id, callBack) => {
  return (dispatch) => {
    dispatch(getDocumentByIdRequest());
    axios
      .get(`${backEndApi}Documentation/getDocumentById?id=${id}`)
      .then((res) => {
        dispatch(getDocumentByIdSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(getDocumentByIdFailure(error)));
  };
};

export const GET_DOCUMENT_HISTORY_BY_ID_REQUEST =
  "GET_DOCUMENT_HISTORY_BY_ID_REQUEST";
export const GET_DOCUMENT_HISTORY_BY_ID_SUCCESS =
  "GET_DOCUMENT_HISTORY_BY_ID_SUCCESS";
export const GET_DOCUMENT_HISTORY_BY_ID_FAILURE =
  "GET_DOCUMENT_HISTORY_BY_ID_FAILURE";

const getDocumentHistoryByIdRequest = createAction(
  GET_DOCUMENT_HISTORY_BY_ID_REQUEST
);
const getDocumentHistoryByIdSuccess = createAction(
  GET_DOCUMENT_HISTORY_BY_ID_SUCCESS,
  "data"
);
const getDocumentHistoryByIdFailure = createAction(
  GET_DOCUMENT_HISTORY_BY_ID_FAILURE,
  "error"
);

export const getDocumentHistoryById = (id, callBack) => {
  return (dispatch) => {
    dispatch(getDocumentHistoryByIdRequest());
    axios
      .get(`${backEndApi}Documentation/getDocumentHistoryById?id=${id}`)
      .then((res) => {
        dispatch(getDocumentHistoryByIdSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(getDocumentHistoryByIdFailure(error)));
  };
};
export const GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_REQUEST =
  "GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_REQUEST";
export const GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_SUCCESS =
  "GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_SUCCESS";
export const GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_FAILURE =
  "GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_FAILURE";

const getDocumentHistoryByDocumentIdRequest = createAction(
  GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_REQUEST
);
const getDocumentHistoryByDocumentIdSuccess = createAction(
  GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_SUCCESS,
  "data"
);
const getDocumentHistoryByDocumentIdFailure = createAction(
  GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_FAILURE,
  "error"
);

export const getDocumentHistoryByDocumentId = (id, callBack) => {
  return (dispatch) => {
    dispatch(getDocumentHistoryByDocumentIdRequest());
    axios
      .get(`${backEndApi}Documentation/getDocumentHistoryByDocumentId?id=${id}`)
      .then((res) => {
        dispatch(getDocumentHistoryByDocumentIdSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(getDocumentHistoryByDocumentIdFailure(error)));
  };
};

export const DELETE_DOCUMENTS_REQUEST = "DELETE_DOCUMENTS_REQUEST";
export const DELETE_DOCUMENTS_SUCCESS = "DELETE_DOCUMENTS_SUCCESS";
export const DELETE_DOCUMENTS_FAILURE = "DELETE_DOCUMENTS_FAILURE";

const deleteDocumentsRequest = createAction(DELETE_DOCUMENTS_REQUEST);
const deleteDocumentsSuccess = createAction(DELETE_DOCUMENTS_SUCCESS, "data");
const deleteDocumentsFailure = createAction(DELETE_DOCUMENTS_FAILURE, "error");

export const deleteDocuments = (id, callBack) => {
  return (dispatch) => {
    dispatch(deleteDocumentsRequest());
    axios
      .delete(`${backEndApi}Documentation/deleteDocuments?id=${id}`)
      .then((res) => {
        dispatch(deleteDocumentsSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(deleteDocumentsFailure(error)));
  };
};

export const PUT_DOCUMENT_UPDATE_REQUEST = "PUT_DOCUMENT_UPDATE_REQUEST";
export const PUT_DOCUMENT_UPDATE_SUCCESS = "PUT_DOCUMENT_UPDATE_SUCCESS";
export const PUT_DOCUMENT_UPDATE_FAILURE = "PUT_DOCUMENT_UPDATE_FAILURE";

const putDocumentUpdateRequest = createAction(PUT_DOCUMENT_UPDATE_REQUEST);
const putDocumentUpdateSuccess = createAction(
  PUT_DOCUMENT_UPDATE_SUCCESS,
  "data"
);
const putDocumentUpdateFailure = createAction(
  PUT_DOCUMENT_UPDATE_FAILURE,
  "error"
);

export const putDocumentUpdate = (payload, callBack) => {
  return (dispatch) => {
    dispatch(putDocumentUpdateRequest());
    axios
      .put(`${backEndApi}Documentation/updateDocument`, payload)
      .then((res) => {
        dispatch(putDocumentUpdateSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(putDocumentUpdateFailure(error)));
  };
};

export const UPDATE_DOC_TYPE_REQUEST = "UPDATE_DOC_TYPE_REQUEST";
export const UPDATE_DOC_TYPE_SUCCESS = "UPDATE_DOC_TYPE_SUCCESS";
export const UPDATE_DOC_TYPE_FAILURE = "UPDATE_DOC_TYPE_FAILURE";

const updateDocTypeRequest = createAction(UPDATE_DOC_TYPE_REQUEST);
const updateDocTypeSuccess = createAction(UPDATE_DOC_TYPE_SUCCESS, "data");
const updateDocTypeFailure = createAction(UPDATE_DOC_TYPE_FAILURE, "error");
export const updateDocType = (payload, callBack) => {
  console.log("payload", payload);
  return (dispatch) => {
    dispatch(updateDocTypeRequest());
    axios
      .put(`${backEndApi}documentation/updateDepartment`, payload)
      .then((res) => {
        dispatch(updateDocTypeSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(updateDocTypeFailure(error)));
  };
};

export const DELETE_DOC_TYPE_REQUEST = "DELETE_DOC_TYPE_REQUEST";
export const DELETE_DOC_TYPE_SUCCESS = "DELETE_DOC_TYPE_SUCCESS";
export const DELETE_DOC_TYPE_FAILURE = "DELETE_DOC_TYPE_FAILURE";

const deleteDocTypeRequest = createAction(DELETE_DOC_TYPE_REQUEST);
const deleteDocTypeSuccess = createAction(DELETE_DOC_TYPE_SUCCESS, "data");
const deleteDocTypeFailure = createAction(DELETE_DOC_TYPE_FAILURE, "error");

export const deleteDocType = (id, type, callBack) => {
  return (dispatch) => {
    dispatch(deleteDocTypeRequest());
    axios
      .delete(
        `${backEndApi}documentation/deleteDepartment?id=${id}&type=${type}`
      )
      .then((res) => {
        dispatch(deleteDocTypeSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(deleteDocTypeFailure(error)));
  };
};

export const POST_NOTIFICATION_REQUEST = "POST_NOTIFICATION_REQUEST";
export const POST_NOTIFICATION_SUCCESS = "POST_NOTIFICATION_SUCCESS";
export const POST_NOTIFICATION_FAILURE = "POST_NOTIFICATION_FAILURE";

const postNotificationRequest = createAction(POST_NOTIFICATION_REQUEST);
const postNotificationSuccess = createAction(POST_NOTIFICATION_SUCCESS, "data");
const postNotificationFailure = createAction(
  POST_NOTIFICATION_FAILURE,
  "error"
);
export const postNotification = (payload, callBack) => {
  return (dispatch) => {
    dispatch(postNotificationRequest());
    axios
      .post(`${backEndApi}documentation/notification`, payload)
      .then((res) => {
        dispatch(postNotificationSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(postNotificationFailure(error)));
  };
};

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";
const getNotificationRequest = createAction(GET_NOTIFICATION_REQUEST);
const getNotificationSuccess = createAction(GET_NOTIFICATION_SUCCESS, "data");
const getNotificationFailure = createAction(GET_NOTIFICATION_FAILURE, "error");

export const getNotification = (id) => {
  return (dispatch) => {
    dispatch(getNotificationRequest());
    axios
      .get(`${backEndApi}documentation/individualNotification?mentor=${id}`)
      .then((res) => {
        dispatch(getNotificationSuccess(res.data.message));
      })
      .catch((error) => dispatch(getNotificationFailure(error)));
  };
};

export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILURE = "UPDATE_NOTIFICATION_FAILURE";

const updateNotificationRequest = createAction(UPDATE_NOTIFICATION_REQUEST);
const updateNotificationSuccess = createAction(
  UPDATE_NOTIFICATION_SUCCESS,
  "data"
);
const updateNotificationFailure = createAction(
  UPDATE_NOTIFICATION_FAILURE,
  "error"
);
export const updateNotification = (payload, callBack) => {
  return (dispatch) => {
    dispatch(updateNotificationRequest());
    axios
      .put(`${backEndApi}documentation/updateNotificationStatus`, payload)
      .then((res) => {
        dispatch(updateNotificationSuccess(res.data.message));
        dispatch(getNotification(payload.get("mentor", "")));
        callBack && callBack();
      })
      .catch((error) => dispatch(updateNotificationFailure(error)));
  };
};

export const DELETE_NOTIFICATION_REQUEST = "DELETE_NOTIFICATION_REQUEST";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILURE = "DELETE_NOTIFICATION_FAILURE";

const deleteNotificationRequest = createAction(DELETE_NOTIFICATION_REQUEST);
const deleteNotificationSuccess = createAction(
  DELETE_NOTIFICATION_SUCCESS,
  "data"
);
const deleteNotificationFailure = createAction(
  DELETE_NOTIFICATION_FAILURE,
  "error"
);

export const deleteNotification = (payload, callBack) => {
  console.log(payload);
  return (dispatch) => {
    dispatch(deleteNotificationRequest());
    axios
      .delete(`${backEndApi}documentation/deleteNotification`, {
        data: payload,
      })
      .then((res) => {
        dispatch(deleteNotificationSuccess(res.data.message));
        dispatch(getNotification(payload.mentor));
        callBack && callBack();
      })
      .catch((error) => dispatch(deleteNotificationFailure(error)));
  };
};
