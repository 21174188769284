import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { validateTableRow } from "../../../Utils/Validation";

export const InsertRowModel = ({
  close,
  show,
  feature,
  version,
  module,
  id,
  handleCallBack,
  setData,
  getNextTestId,
}) => {
  const [rowData, setRowData] = useState({
    TestCaseID: getNextTestId(),
    version: version,
    module: module,
    featuresName: feature,
    id: id,
    Priority: "Low",
  });
  const handleChange = (value, key) => {
    setRowData({ ...rowData, [key]: value });
  };
  const handleAddRow = () => {
    const validation = validateTableRow(rowData);
    if (validation !== "") {
      setData({ message: validation });
      return;
    }
    handleCallBack(rowData);
    close();
  };

  return (
    <Modal className="my-modal" show={show} onHide={close} centered>
      <div closeButton className="bg-gray">
        {/* <Modal.Title>Insert Row</Modal.Title> */}
        <h3 className="text-center">Add Test case</h3>
      </div>
      <div className="auth-wrapper">
        <div className="auth-inner">
          <div className="container">
            <div className="p-0 f-16">
              <table className="m-3">
                <tr className="popup_tr">
                  <th scope="col" className="popup_th">
                    TestCase ID
                  </th>
                  <th scope="col" className="popup_th">
                    <label className="bold_normal">{getNextTestId()}</label>
                  </th>
                </tr>
                <tr className="popup_tr">
                  <th scope="col" className="popup_th">
                    Features Name
                  </th>
                  <label>{feature}</label>
                </tr>
                <tr className="popup_tr">
                  <th scope="col" className="popup_th">
                    Version
                  </th>
                  <label>{version}</label>
                </tr>
                <tr className="popup_tr">
                  <th scope="col" className="popup_th">
                    module
                  </th>
                  <label>{module}</label>
                </tr>
                <tr className="popup_tr">
                  <th scope="col" className="popup_th">
                    TestCase Description
                  </th>
                  <th scope="col" className="popup_th">
                    <textarea
                      className="popup_tr"
                      onChange={(e) =>
                        handleChange(e.target.value, "TestCaseDescription")
                      }
                    ></textarea>
                  </th>
                </tr>
                <tr className="popup_tr">
                  <th scope="col" className="popup_th">
                    Steps
                  </th>
                  <th scope="col" className="popup_th">
                    <input
                      type="text"
                      onChange={(e) => handleChange(e.target.value, "Steps")}
                    ></input>
                  </th>
                </tr>
                <tr className="popup_tr">
                  <th scope="col" className="popup_th">
                    Expected Result
                  </th>
                  <th scope="col" className="popup_th">
                    <input
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.value, "ExpectedResult")
                      }
                    ></input>
                  </th>
                </tr>
                <tr className="popup_tr">
                  <th scope="col" className="popup_th">
                    Actual Result
                  </th>
                  <th scope="col" className="popup_th">
                    <input
                      type="text"
                      onChange={(e) =>
                        handleChange(e.target.value, "ActualResult")
                      }
                    ></input>
                  </th>
                </tr>

                <tr className="popup_tr">
                  <th scope="col" className="popup_th">
                    Priority
                  </th>
                  <th scope="col" className="popup_th">
                    <select
                      className="popup_select"
                      onChange={(e) => handleChange(e.target.value, "Priority")}
                    >
                      <option value="Lowest">Lowest</option>
                      <option value="Low">Low</option>
                      <option value="Medium">Medium</option>
                      <option value="High">High</option>
                      <option value="Highest">Highest</option>
                    </select>
                  </th>
                </tr>
              </table>
              <div className="w-100 d-flex align-items-center justify-content-center">
                <button
                  className="btn-primary m-2 p-1"
                  onClick={() => handleAddRow()}
                >
                  save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

InsertRowModel.prototype = {
  show: PropTypes.bool,
  close: PropTypes.func,
  getData: PropTypes.func,
  feature: PropTypes.string,
  version: PropTypes.string,
  caseCreated: PropTypes.string,
  id: PropTypes.string,
};
