import React, { useEffect } from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../Styles/Billing/TableDesign";

import {
  Card,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Table } from "react-bootstrap";
import { List } from "immutable";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import impulse from "../../../../../Assets/impulse.png";
import { useDispatch, useSelector } from "react-redux";
import {
  putClientPriority,
  updateClientStatus,
  getAllAgentData,
} from "../../../../../Redux/ClientSupportManagement/Action";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImagePop from "../ImagePop/imagePop";
import {
  selectSupportClientList,
  selectAllAgentData,
} from "../../../../../Redux/ClientSupportManagement/Selector";
import { getSupportClient } from "../../../../../Redux/ClientSupportManagement/Action";
import PersonIcon from "@mui/icons-material/Person";
import { getAssigneeName } from "../ReportsCardList/ReportsCardList";
import { duplicateNumberForNoAssignee } from "../../../../../Utils/DuplicateNumber";

/*--------------------------UtileStart-----------------------------*/

/*--------------------------UtileEnd-------------------------------*/

const ReportsTable = ({
  filteredTableData,
  rowsPerPage,
  pageNo,
  handleChangeAssigneeName,
  newAgentData,
  callBack,
}) => {
  const navigate = useNavigate();
  const supportClientList = useSelector(selectSupportClientList).toJS();
  const getAllAgent = useSelector(selectAllAgentData);

  const handleView = (id, key) => {
    if (key === "ReplyPage") {
      navigate(`/clientSupport/viewTicket?id=${id}`);
    } else {
      navigate(`/clientSupport/viewIssue?id=${id}`);
    }
  };

  const priorityOptions = [
    { value: "Low", dotColor: "#A0D76A" },
    { value: "Medium", dotColor: "#4DA1FF" },
    { value: "High", dotColor: "#FFD012" },
    { value: "Urgent", dotColor: "#FF5959" },
  ];
  const statusOptions = [
    { value: "Open", icon: impulse },
    { value: "Close", icon: impulse },
    { value: "Inprogress", icon: impulse },
    { value: "Waiting on Customer", icon: impulse },
  ];
  const dispatch = useDispatch();

  const handlePriorityChange = (event, id) => {
    const payload = {
      supportId: id,
      newPriority: event.target.value,
    };
    dispatch(putClientPriority(payload, callBack));
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleStatusChange = (event, id) => {
    const payload = {
      supportId: id,
      currentStatus: event.target.value,
    };
    dispatch(updateClientStatus(payload, callBack));
  };

  useEffect(() => {
    if (supportClientList.length === 0) dispatch(getSupportClient());
    if (getAllAgent.size === 0) dispatch(getAllAgentData());
  }, []); //eslint-disable-line

  return (
    <div className="container-fluid">
      <Card>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: "72vh", maxWidth: "100vw" }}
          className="m-0 client_support_table"
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead style={{ position: "sticky", top: 0, zIndex: 10 }}>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>Client Name</StyledTableCell>
                <StyledTableCell>Issue Type</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Type</StyledTableCell>
                {/* <StyledTableCell>Email</StyledTableCell> */}
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Assginee</StyledTableCell>
                <StyledTableCell>Priority</StyledTableCell>
                <StyledTableCell>Attachment</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {filteredTableData.get("result", List()).map((item, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    onClick={() => handleView(item.get("_id", ""), "ReplyPage")}
                  >
                    <StyledTableCell>
                      {rowsPerPage * (pageNo - 1) + index + 1}
                    </StyledTableCell>
                    <StyledTableCell
                      className="navigate_cell"
                      onClick={() =>
                        handleView(item.get("_id", ""), "ReplyPage")
                      }
                    >
                      {
                        supportClientList.find(
                          (data) => data._id === item.get("clientId", "")
                        )?.clientName
                      }
                    </StyledTableCell>
                    <StyledTableCell>{item.get("type", "")}</StyledTableCell>
                    <StyledTableCell>{item.get("name", "")}</StyledTableCell>
                    <StyledTableCell>
                      {item.get("clientType", "")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {new Date(item.get("createdAt", "")).toLocaleDateString()}
                    </StyledTableCell>
                    <StyledTableCell onClick={handleStopPropagation}>
                      <div className="FormControl">
                        <Tooltip
                          title={item.get("status", "")}
                          placement="left"
                          arrow
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <FormControl sx={{ m: 0, minWidth: 0 }}>
                            <Select
                              value={item.get("status", "")}
                              onChange={(event) =>
                                handleStatusChange(event, item.get("_id", ""))
                              }
                              IconComponent={KeyboardArrowDownIcon}
                              sx={{
                                width: "110px",

                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiOutlinedInput-input": {
                                  fontSize: "14px",
                                  padding: "0px",
                                },
                                "& .MuiSvgIcon-root.MuiSelect-icon": {},
                              }}
                            >
                              {statusOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                  component="div"
                                  style={{ display: "flex", gap: "10px" }}
                                >
                                  <img
                                    style={{
                                      width: 20,
                                      height: 20,
                                    }}
                                    src={option.icon}
                                    alt=""
                                  />
                                  {option.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className="FormControl">
                        <Tooltip
                          title={getAssigneeName(
                            "userId",
                            item.get("assignee", "No Assignee"),
                            newAgentData
                          )}
                          placement="left"
                          arrow
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <FormControl sx={{ m: 0, minWidth: 0 }}>
                            <Select
                              value={item.get(
                                "assignee",
                                duplicateNumberForNoAssignee
                              )}
                              onChange={(event) =>
                                handleChangeAssigneeName({
                                  AssigneeId: event.target.value,
                                  id: item.get("_id", ""),
                                })
                              }
                              IconComponent={KeyboardArrowDownIcon}
                              sx={{
                                width: "110px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "0px",
                                },
                              }}
                            >
                              {newAgentData.map((option) => (
                                <MenuItem
                                  key={option.userId}
                                  value={option.userId}
                                  component="div"
                                  style={{ display: "flex", gap: "10px" }}
                                >
                                  <PersonIcon
                                    style={{ fontSize: "20px" }}
                                    className="text-primary"
                                  />
                                  {option.username}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell onClick={handleStopPropagation}>
                      <div className="FormControl">
                        <Tooltip
                          title={item.get("priority", "")}
                          placement="left"
                          arrow
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <FormControl sx={{ m: 0, minWidth: 0 }}>
                            <Select
                              value={item.get("priority", "")}
                              onChange={(event) =>
                                handlePriorityChange(event, item.get("_id", ""))
                              }
                              IconComponent={KeyboardArrowDownIcon}
                              sx={{
                                width: "110px",
                                "& .MuiOutlinedInput-notchedOutline": {
                                  border: "none",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "0px",
                                  display: "flex",
                                  justifyContent: "start",
                                },
                              }}
                            >
                              {priorityOptions.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                  component="div"
                                  style={{ display: "flex", gap: "10px" }}
                                >
                                  <div
                                    style={{
                                      width: 9,
                                      height: 9,
                                      margin: "6px",
                                      backgroundColor: option.dotColor,
                                    }}
                                  />

                                  {option.value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Tooltip>
                      </div>
                    </StyledTableCell>

                    <StyledTableCell onClick={handleStopPropagation}>
                      <div className=" d-flex flex-row justify-content-start">
                        {item.get("attachment", List()).size} Attachment
                        {item.get("attachment", List()).size !== 0 && (
                          // <div
                          //   className="text-primary ms-2 user-select-none cursor-pointer bold"
                          //   onClick={(e) =>
                          //     handleViewFile(item.get("attachment", ""))
                          //   }>
                          //   View
                          // </div>
                          <ImagePop
                            Image={
                              item.get("screenShot", "")
                                ? item
                                    .get("attachment", List())
                                    .push(item.get("screenShot", ""))
                                : item.get("attachment", List())
                            }
                          />
                        )}
                      </div>
                    </StyledTableCell>

                    <StyledTableCell onClick={handleStopPropagation}>
                      <div className="d-flex gap-2">
                        <Tooltip title="View" arrow placement="top">
                          <IconButton
                            onClick={() => handleView(item.get("_id", ""), "")}
                          >
                            <VisibilityIcon sx={{ color: "#1976D2" }} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* {open.viewPopup && <ViewFilePopup open={open} setOpen={setOpen} />} */}
      </Card>
    </div>
  );
};

export default ReportsTable;
