import React, { useEffect, useState } from "react";
import "./AdminTable.css";
import {
  selectUsers,
  selectRoles,
  selectIsLoading,
  selectMessage,
  selectDepartment,
} from "../../Redux/TestCase/Selector";
import * as action from "../../Redux/TestCase/Action";
import { connect } from "react-redux";
import Snackbar from "../../LoaderSnackbar/Snackbar";
import Loader from "../../LoaderSnackbar/Loader";
import UserRolesTable from "./UserRolesTable";
import UserDepartmentTable from "./UserDepartmentTable";
import UserDataTable from "./UserDataTable";

const options = {
  options: { threshold: 0.1 },
};

const useOnScreen = ({ options = {} }) => {
  const [ref, setRef] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting);
    }, options);
    if (ref) observer.observe(ref);
    return () => {
      if (ref) observer.unobserve(ref);
    };
  }, [options, ref]);

  return [setRef, visible];
};

const AdminTable = ({
  getUserData,
  getUserRoleData,
  loadingStatus,
  messageStatus,
  setData,
}) => {
  const [role, setRole] = useState("");
  const [departmentTableRef, departmentTableVisible] = useOnScreen(options);

  useEffect(() => {
    getUserData();
    getUserRoleData();
  }, []); // eslint-disable-line

  return (
    <div className="m-2 form-group pb-3">
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <UserDataTable />
      <UserRolesTable setRole={setRole} />
      {UserDataTable && departmentTableVisible ? (
        <UserDepartmentTable role={role} setRole={setRole} />
      ) : (
        <div ref={departmentTableRef}> Loading DepartmentTable...</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingStatus: selectIsLoading(state),
    messageStatus: selectMessage(state),
    roles: selectRoles(state).toJS(),
    users: selectUsers(state).toJS(),
    allDepartment: selectDepartment(state).toJS(),
  };
};

export default connect(mapStateToProps, action)(AdminTable);
