import React, { useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";

import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import ClientAssigned from "../../Models/ClientAssigned";
import CreateCategory from "../../Models/CreateCategory";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const drawerWidth = 310;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ReportHeader = ({ select, handleSelect, handleSubDrawerOpen, open }) => {
  const [isPopup, setPopup] = useState(false);
  const [isCategoryPopup, setCategoryPopUp] = useState(false);
  const handleClick = (type) => {
    type === "category" ? setCategoryPopUp(true) : setPopup(true);
  };

  return (
    <>
      <AppBar
        sx={{
          position: "sticky",
          top: "64px",
          boxShadow: "1",
          zIndex: "1",
          background: "#F5F7F9",
          color: "#3F5566",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="d-flex align-items-center">
            <span>Layout : </span>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={select}
                onChange={handleSelect}
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "8px 5px",
                    fontSize: "15px",
                  },
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    height: "-webkit-fill-available",
                  },
                }}
              >
                <MenuItem value={"CardView"}>Card View</MenuItem>
                <MenuItem value={"TableView"}>Table View</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="d-flex justify-content-center align-items-center  gap-4">
            <Button
              startIcon={<AddIcon />}
              className="client_create_btn"
              onClick={() => handleClick("category")}
            >
              Create Category
            </Button>
            <Button
              startIcon={<AddIcon />}
              className="client_create_btn"
              onClick={() => handleClick()}
            >
              Create Client
            </Button>

            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleSubDrawerOpen}
            >
              {open ? (
                <ClearIcon sx={{ color: "#3F5566" }} />
              ) : (
                <MenuIcon sx={{ color: "#3F5566" }} />
              )}
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {isPopup && <ClientAssigned isPopup={isPopup} setPopup={setPopup} />}
      {isCategoryPopup && (
        <CreateCategory isPopup={isCategoryPopup} setPopup={setCategoryPopUp} />
      )}
    </>
  );
};

export default ReportHeader;
