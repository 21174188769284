import AgTableButton from "../../Utils/AgTableButton";
// import AgTableHyperLink from "../Utils/AgTableHyperLink";
import AgTableSerialNumber from "../../Utils/AgTableSerialNumber";
// import AgTableSelect from "../Utils/AgTableSelect";
import AgTableReason from "../../Utils/AgTableReason";
// import AgTableDate from "../Utils/AgTableDate";
import { Box } from "@mui/material";

export const createColumnDefs = (handleView = () => {}) => {
  return [
    {
      field: "sno",
      cellRenderer: AgTableSerialNumber,
    },
    {
      field: "TestCaseID",
      headerName: "TestCase ID",
    },
    {
      field: "TestCaseDescription",
      headerName: "TestCase Description",
    },
    {
      field: "execute",
      cellRenderer: AgTableButton,
      cellRendererParams: {
        name: "View",
        clicked: function (index) {
          handleView(index._id);
        },
      },
    },
    // {
    //   field: "Steps",
    //   headerName: "Steps",
    // },
    // {
    //   field: "ActualResult",
    //   headerName: "Actual Result",
    // },
    // {
    //   field: "ExpectedResult",
    //   headerName: "Expected Result",
    // },
  ];
};

export const ExecuteColumnDefs = (handleExecute, handleSelect) => {
  return [
    {
      field: "sno",
      cellRenderer: AgTableSerialNumber,
    },
    {
      field: "TestCaseID",
      headerName: "TestCase ID",
    },
    {
      field: "TestCaseDescription",
      headerName: "TestCase Description",
    },
    {
      field: "Execute",
      cellRenderer: AgTableButton,
      cellRendererParams: {
        name: "View",
        clicked: function (index) {
          handleExecute(index._id);
        },
      },
    },
    // {
    //   field: "status",
    //   cellRenderer: AgTableSelect,
    //   cellRendererParams: {
    //     options: ["Not-verified", "Open", "Re-open", "Hold", "Passed", "Failed"],
    //     clicked: function (value, id, previousData) {
    //       handleSelect(value, id, previousData);
    //     },
    //   },
    // },
    // {
    //   field: "videolink",
    //   cellRenderer: AgTableHyperLink,
    //   cellRendererParams: {
    //     name: "Video",
    //     clicked: function (index) {
    //       handleExecute(index._id);
    //     },
    //   },
    // },
    // {
    //   field: "TestCaseDescription",
    //   headerName: "TestCase Description",
    // },
    // {
    //   field: "Steps",
    //   headerName: "Steps",
    // },
    // {
    //   field: "ActualResult",
    //   headerName: "Actual Result",
    // },
    // {
    //   field: "ExpectedResult",
    //   headerName: "Expected Result",
    // },
  ];
};

export const columnDefs = (handleEdit, handleExecute, handleDelete) => {
  return [
    {
      field: "sno",
      minWidth: 50,
      cellRenderer: AgTableSerialNumber,
    },
    {
      field: "module",
      headerName: "Module",
    },
    {
      field: "featuresName",
      headerName: "Features Name",
    },
    {
      field: "version",
      minWidth: 60,
      headerName: "Version",
    },
    {
      field: "edit",
      cellRenderer: AgTableButton,
      cellRendererParams: {
        name: "Edit",
        clicked: function (index) {
          handleEdit(index._id);
        },
      },
    },
    {
      field: "execute",
      minWidth: 250,
      cellRenderer: AgTableButton,
      cellRendererParams: {
        name: "Create test execution",
        clicked: function (index) {
          handleExecute(index._id);
        },
      },
    },
    {
      field: "delete",
      cellRenderer: AgTableButton,
      cellRendererParams: {
        name: "Delete",
        clicked: function (index) {
          handleDelete(index._id);
        },
      },
    },
  ];
};
export const executedColumnDefs = (handleEdit, handleExecute, handleDelete) => {
  return [
    {
      field: "sno",
      minWidth: 60,
      cellRenderer: AgTableSerialNumber,
    },
    {
      field: "module",
      headerName: "Module",
    },
    {
      field: "featuresName",
      headerName: "Features Name",
    },
    {
      field: "version",
      minWidth: 100,
      headerName: "Version",
    },
    {
      field: "cloneKey",
      minWidth: 100,
      headerName: "Clone Key",
    },
    {
      field: "reason",
      cellRenderer: AgTableReason,
      cellRendererParams: {
        name: "Reason",
        clicked: function (index) {
          handleEdit(index.id, index.cloneKey);
        },
      },
    },
    {
      field: "edit",
      cellRenderer: AgTableButton,
      cellRendererParams: {
        name: "Edit",
        clicked: function (index) {
          handleEdit(index.id, index.cloneKey);
        },
      },
    },
    {
      field: "execute",
      cellRenderer: AgTableButton,
      cellRendererParams: {
        name: "Execute",
        clicked: function (index) {
          handleExecute(index.id, index.cloneKey);
        },
      },
    },
    {
      field: "delete",
      cellRenderer: AgTableButton,
      cellRendererParams: {
        name: "Delete",
        clicked: function (index) {
          handleDelete(index.id, index.cloneKey);
        },
      },
    },
  ];
};

export default function TabPanel(props) {
  const { children, value, index } = props;
  return <div role="tabpanel">{value === index && <Box sx={{ p: 3 }}>{children}</Box>}</div>;
}
