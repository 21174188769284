import React from "react";
import { connect, useSelector } from "react-redux";
import Snackbar from "../../LoaderSnackbar/Snackbar";
import Loader from "../../LoaderSnackbar/Loader";
import { selectIsLoading, selectMessage } from "../../Redux/TestCase/Selector";
import * as action from "../../Redux/TestCase/Action";
import { Box, Card } from "@mui/material";
import Divider from "@mui/material/Divider";
import { selectAuthType } from "../../Redux/TestCase/Selector";
import "./Homepage.css";
import { SwiperSlider } from "./Slider/Swipper";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";

function Homepage({ loadingStatus, setData, messageStatus }) {
  const Auth_data = useSelector(selectAuthType).toJS();
  const recentTabs =
    JSON.parse(localStorage.getItem("recent")) === null
      ? []
      : JSON.parse(localStorage.getItem("recent"));
  const navigate = useNavigate();
  const handleNaviGate = (redirect) => {
    navigate(redirect);
  };

  return (
    <>
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <Box className="container HomePage">
        <div>
          <h4 className="HomePageTitle ">Hi {Auth_data.name} &#128075;</h4>
        </div>
        <div>
          <Card className="p-2 Welcome_card_heading">
            <h4>Welcome to our internal software!</h4>
            <Divider />
            <p className="Welcome_card_paragraph">
              Our platform provides various tools to help manage your assets,
              testing processes, billing, and documentation. Here's a brief
              overview of the main features:
            </p>
          </Card>
        </div>
        <div className="swiper_card">
          <SwiperSlider />
        </div>
        {recentTabs.length !== 0 && (
          <div>
            <h4 className="HomePageTitle ">Recently Used</h4>
          </div>
        )}
        <div className="d-flex justify-content-between gap-2 flex-warp">
          {recentTabs.map((item, index) => {
            if (index > 2) return false;
            return (
              <div className="recentlyCards">
                <div>
                  {index + 1}. {item.title}{" "}
                </div>
                <Button
                  variant="contained"
                  onClick={() => handleNaviGate(item.redirect)}
                  startIcon={<SwapHorizIcon />}
                  className="mt-2"
                >
                  Go to
                </Button>
              </div>
            );
          })}
        </div>
      </Box>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loadingStatus: selectIsLoading(state),
    messageStatus: selectMessage(state),
  };
};
export default connect(mapStateToProps, action)(Homepage);
