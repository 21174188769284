import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
const backEnd_Api = process.env.REACT_APP_BackEndUrl;
const VAPID_KEY = process.env.VAPID_KEY;
const firebaseConfig = {
  apiKey: "AIzaSyB6gOdd-Aa9sBMm6UXgEX-5mFle5jJRbVQ",
  authDomain: "fir-message-a101e.firebaseapp.com",
  projectId: "fir-message-a101e",
  storageBucket: "fir-message-a101e.appspot.com",
  messagingSenderId: "823335941420",
  appId: "1:823335941420:web:7b58f6d25c2a1056d66889",
  measurementId: "G-EED4EBE8LD",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const SendRequest = (email, username, userId) => {
  let tokens = [];
  Notification.requestPermission().then(async (permission) => {
    if (permission === "granted") {
      return getToken(messaging, {
        vapidKey: VAPID_KEY,
      })
        .then(async (token) => {
          if (token) {
            await axios
              .post(`${backEnd_Api}FcmNotification/createNotificationToken`, {
                email,
                username,
                userId,
                token,
              })
              .then((res) => {
                console.log(res.data);
              })
              .catch((err) => {
                console.log(err.message);
              });
          } else {
            console.log("Failed to generate the registration token.");
          }
        })
        .catch((err) => {
          console.log(
            "An error occurred when requesting to receive the token.",
            err
          );
        });
    } else {
      await axios
        .post(`${backEnd_Api}FcmNotification/createNotificationToken`, {
          email,
          username,
          userId,
          tokens,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  });
};
