import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import PopupForm from "../03.Pages/MainAssets/PopupForm";
import { useDispatch, useSelector } from "react-redux";

import {
  EditAssetsAction,
  ErrorAction,
  PopupAction,
  TotalAssetsAction,
  TotalResetAction,
} from "../../../Redux/AssetsManagement/Action";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function PopupFormUi({ handleSubmit }) {
  //props-------------------------------------------------->

  const popUpOpen = useSelector((state) => state.AssetsManagement).getIn(
    ["popUpStatus", "PopupOpen"],
    false
  );

  const EditAsset = useSelector((state) => state.AssetsManagement).getIn(
    ["popUpStatus", "EditAsset"],
    false
  );

  // const assets = useSelector((state) => state.AssetsManagement).get(
  //   "AssetsformData",
  //   {}
  // );

  const AssetsApi = useSelector((state) => state.AssetsManagement).get(
    "getAssetsApi",
    ""
  );

  const Update = AssetsApi.get(EditAsset);

  const dispatch = useDispatch();

  useEffect(() => {
    if (Update && Object.keys(Update).length !== 0) {
      dispatch(
        TotalAssetsAction(
          Update?.set(
            "attachment",
            Update?.get("attachment", "").split("/").pop()
          )?.set(
            "previousAttach",
            Update?.get("attachment", "").split("/").pop()
          )
        )
      );
    }
  }, [Update]); //eslint-disable-line

  const onClickClose = () => {
    dispatch(PopupAction(false));
    dispatch(ErrorAction(false));
    dispatch(EditAssetsAction(false));
    dispatch(TotalResetAction());
  };

  return (
    <div>
      <BootstrapDialog
        onClose={onClickClose}
        aria-labelledby="customized-dialog-title"
        open={popUpOpen}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onClickClose}
         
        >
          Create Assets
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography>
            <PopupForm handleSubmit={handleSubmit} />
          </Typography>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
