import { List } from "immutable";
const AttendanceManagement = (state) => state.AttendanceManagement;

const selectIsLoading = (state) => AttendanceManagement(state).get("isLoading", false);
const selectMessage = (state) => AttendanceManagement(state).get("message", "");
const selectDateWiseDate = (state) => AttendanceManagement(state).get("dateWiseDate", List());
const selectAllUser = (state) => AttendanceManagement(state).get("allUser", List());
const selectSpecificDateDetail = (state) => AttendanceManagement(state).get("SpecificDateDetail", List());

export { selectIsLoading, selectMessage, selectDateWiseDate, selectAllUser, selectSpecificDateDetail };
