import { createAction } from "../../Utils/FunctionUtils";
import axios from "axios";

const backEndApi = process.env.REACT_APP_BackEndUrl;

export const SET_DATA_SUCCESS = "SET_DATA_SUCCESS";
// setData({message:'some thing'})
const setDataSuccess = createAction(SET_DATA_SUCCESS, "data");
export const setData = (data) => {
  return (dispatch) => {
    dispatch(setDataSuccess(data));
  };
};

export const POST_RESTORE_REQUEST = "POST_RESTORE_REQUEST";
export const POST_RESTORE_SUCCESS = "POST_RESTORE_SUCCESS";
export const POST_RESTORE_FAILURE = "POST_RESTORE_FAILURE";

const postRestoreRequest = createAction(POST_RESTORE_REQUEST);
const postRestoreSuccess = createAction(POST_RESTORE_SUCCESS, "data");
const postRestoreFailure = createAction(POST_RESTORE_FAILURE, "error");

export const postRestore = (payload, callBack) => {
  return (dispatch) => {
    dispatch(postRestoreRequest());
    axios
      .post(`${backEndApi}mongodb-tool/restore`, payload)
      .then((res) => {
        dispatch(postRestoreSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(postRestoreFailure(error)));
  };
};

export const POST_DUMP_REQUEST = "POST_DUMP_REQUEST";
export const POST_DUMP_SUCCESS = "POST_DUMP_SUCCESS";
export const POST_DUMP_FAILURE = "POST_DUMP_FAILURE";

const postDumpRequest = createAction(POST_DUMP_REQUEST);
const postDumpSuccess = createAction(POST_DUMP_SUCCESS, "data");
const postDumpFailure = createAction(POST_DUMP_FAILURE, "error");

export const postDump = (payload, callBack) => {
  return (dispatch) => {
    dispatch(postDumpRequest());
    axios
      .post(`${backEndApi}mongodb-tool/dump`, payload)
      .then((res) => {
        dispatch(postDumpSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(postDumpFailure(error)));
  };
};

export const GET_DATABASE_NAME_REQUEST = "GET_DATABASE_NAME_REQUEST";
export const GET_DATABASE_NAME_SUCCESS = "GET_DATABASE_NAME_SUCCESS";
export const GET_DATABASE_NAME_FAILURE = "GET_DATABASE_NAME_FAILURE";

const getDatabaseNameRequest = createAction(GET_DATABASE_NAME_REQUEST);
const getDatabaseNameSuccess = createAction(GET_DATABASE_NAME_SUCCESS, "data");
const getDatabaseNameFailure = createAction(GET_DATABASE_NAME_FAILURE, "error");

export const getDatabaseName = (callBack) => {
  return (dispatch) => {
    dispatch(getDatabaseNameRequest());
    axios
      .get(`${backEndApi}mongodb-tool/getDatabaseName`)
      .then((res) => {
        dispatch(getDatabaseNameSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(getDatabaseNameFailure(error)));
  };
};
