import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  postAddServiceType,
  getAllType,
  updateServiceType,
} from "../../../Redux/BillingManagement/Action";
import { useEffect } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ServicePopup({
  options,
  servicePopup,
  setServicePopup,
  updateService,
  setUpdateService,
}) {
  const dispatch = useDispatch();

  const initialData = {
    perHour: "",
    perMonth: "",
    serviceType: "",
    ramNo: "",
    cpuNo: "",
  };

  const [data, setData] = useState(initialData);
  const [errorShow, setErrorShow] = useState(false);
  const disableSubmit = () => {
    return options.some(
      (item) =>
        item.get("serviceType", "").toLowerCase() ===
        data.serviceType.toLowerCase()
    );
  };



  const callBack = () => {
    dispatch(getAllType());
    setServicePopup(false);
  };

  const handleSubmit = () => {
    if (
      data.serviceType === "" ||
      data.perHour === "" ||
      data.perMonth === ""
    ) {
      setErrorShow(true);
    } else {
      if (updateService === "") dispatch(postAddServiceType(data, callBack));
      else {
        dispatch(updateServiceType(data, callBack));
      }
    }
  };

  const handleClose = () => {
    setServicePopup(false);
    setUpdateService("");
  };
  useEffect(() => {
    if (updateService !== "") {
      setData(updateService.toJS());
    }
  }, [updateService]);

  const handleChange = (value, key) => {
    if (key === "perHour") {
      var hourToMonth = value * 720;
      setData((prev) => {
        return { ...prev, perMonth: hourToMonth?.toFixed(2) };
      });
    }
    setData((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const handleKeyDownPrevent = (event) => {
    if (["e", "-", "+"].includes(event.key)) event.preventDefault();
  };

  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={servicePopup}
        className="width75"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Service Type
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Box
              sx={{
                width: "400px",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
              }}
            >
              <TextField
                error={errorShow && data.serviceType === "" ? true : false}
                id="outlined-basic"
                label="Service Type *"
                variant="outlined"
                autoComplete="none"
                className="p-2"
                value={data.serviceType}
                onChange={(e) => handleChange(e.target.value, "serviceType")}
                helperText={
                  errorShow && data.serviceType === ""
                    ? "serviceType is required!"
                    : ""
                }
              />

              <TextField
                error={errorShow && data.perHour === "" ? true : false}
                id="outlined-basic"
                label="Per hour *"
                variant="outlined"
                autoComplete="none"
                className="p-2"
                value={data.perHour}
                type="number"
                onKeyPress={(event) => handleKeyDownPrevent(event)}
                onChange={(e) => handleChange(e.target.value, "perHour")}
                helperText={
                  errorShow && data.perHour === ""
                    ? "serviceType is required!"
                    : ""
                }
              />

              <TextField
                error={errorShow && data.perMonth === "" ? true : false}
                id="outlined-basic"
                label="Per Month *"
                variant="outlined"
                autoComplete="none"
                className="p-2"
                type="number"
                onKeyPress={(event) => handleKeyDownPrevent(event)}
                value={data.perMonth}
                onChange={(e) => handleChange(e.target.value, "perMonth")}
                helperText={
                  errorShow && data.perMonth === ""
                    ? "serviceType is required!"
                    : ""
                }
              />
              <TextField
                id="outlined-basic"
                label="No CPU - (optional)"
                variant="outlined"
                autoComplete="none"
                className="p-2"
                value={data.cpuNo}
                type="number"
                onKeyPress={(event) => handleKeyDownPrevent(event)}
                onChange={(e) => handleChange(e.target.value, "cpuNo")}
              />

              <TextField
                id="outlined-basic"
                label="No RAM - (optional)"
                variant="outlined"
                autoComplete="none"
                className="p-2"
                value={data.ramNo}
                type="number"
                onKeyPress={(event) => handleKeyDownPrevent(event)}
                onChange={(e) => handleChange(e.target.value, "ramNo")}
              />
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <Button disabled={disableSubmit()} onClick={handleSubmit}>
            {updateService === "" ? "submit" : "Update"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}
