import { List } from "immutable";
export const validateReason = (reason) => {
  let msg = "";
  if (reason.executed === "") {
    msg = "Executed By is required.";
  } else if (reason.reason === "") {
    msg = "Execution Reason is required.";
  } else if (reason.reason.length < 5 || reason.reason.length > 30) {
    msg = "5 to 30 character required in Reason.";
  }
  return msg;
};
export const validateTicketData = (reason) => {
  let msg = "";
  if (reason.Title === "") {
    msg = "Title is required.";
  } else if (reason.Description === "") {
    msg = "Description is required.";
  } else if (reason.AssigneeId === "") {
    msg = "Assignee Selection is required.";
  }
  return msg;
};

export const validateTableRow = (RowData) => {
  let msg = "";
  if (RowData.TestCaseID === "" || RowData.TestCaseID === undefined) {
    msg = "Test CaseID is required.";
  } else if (
    RowData.TestCaseDescription === "" ||
    RowData.TestCaseDescription === undefined
  ) {
    msg = "Test case Description is required.";
  } else if (RowData.Steps === "" || RowData.Steps === undefined) {
    msg = "Steps is required.";
  } else if (
    RowData.ActualResult === "" ||
    RowData.ActualResult === undefined
  ) {
    msg = "ActualResult is required.";
  } else if (
    RowData.ExpectedResult === "" ||
    RowData.ExpectedResult === undefined
  ) {
    msg = "Expected Result is required.";
  } else if (RowData.version === "" || RowData.version === undefined) {
    msg = "version is required.";
  } else if (
    RowData.featuresName === "" ||
    RowData.featuresName === undefined
  ) {
    msg = "featuresName is required.";
  } else if (RowData.module === "" || RowData.module === undefined) {
    msg = "module is required.";
  }
  return msg;
};
export const validateDocuments = (Data) => {
  let msg = "";
  if (
    Data.getIn(["DepartmentName", "label"], "") === "" ||
    Data.getIn(["DepartmentName", "label"], "") === undefined
  ) {
    msg = "DepartmentName is required.";
  } else if (
    Data.getIn(["ProductName", "label"], "") === "" ||
    Data.getIn(["ProductName", "label"], "") === undefined
  ) {
    msg = "ProductName is required.";
  } else if (Data.get("DocumentTags", "").size === 0) {
    msg = "Tags is required.";
  } else if (
    Data.get("DocumentName", "") === "" ||
    Data.get("DocumentName", "") === undefined
  ) {
    msg = "DocumentName is required.";
  } else if (
    Data.get("Content", "") === "" ||
    Data.get("Content", "") === undefined
  ) {
    msg = "Content is required.";
  }
  return msg;
};

export const validateBilling = (Data) => {
  let msg = "";
  if (Data.get("clientName", "") === "") {
    msg = "client Name is required.";
  } else if (Data.get("cloudType", "") === "") {
    msg = "cloud Type is required.";
  } else {
    Data.get("service", List()).every((item, index) => {
      if (item.get("serviceType", "") === "") {
        msg = `service Type is required in ${index + 1} index`;
        return false;
      }
      if (item.get("reason", "") === "") {
        msg = `reason is required in ${index + 1} index`;
        return false;
      }
      return true;
    });
  }
  return msg;
};
export const validateHelpPopover = (Data) => {
  let msg = "";
  if (Data.name === "") {
    msg = "Name is required.";
  } else if (Data.email === "") {
    msg = "Email is required.";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Data.email)) {
    msg = "Please enter a valid email";
  } else if (Data.clientName === "") {
    msg = "Client Name is required.";
  } else if (Data.description === "") {
    msg = "Description is required.";
  } else if (Data.attachment.length > 0) {
    Data.attachment.forEach((item) => {
      if (!item.type.startsWith("image/"))
        msg = "Unsupported format in Attachment";
      else if (item.size > 5000000) msg = "Attachment is to large";
    });
  }
  return msg;
};
