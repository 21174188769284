import React from "react";
import SubDrawer from "@mui/material/Drawer";
import TurnRightIcon from "@mui/icons-material/TurnRight";
import { Avatar, IconButton, TextField } from "@mui/material";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import * as ClassicEditor from "ckeditor5-custom-build/build/ckeditor";
// import { Button } from "@material-ui/core";
import { Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import "./ReplySideBar.css";
// import { Editor } from "draft-js";

import { Stack } from "@mui/material";
import { List } from "immutable";
import { useEffect } from "react";

const drawerWidth = 500;
// const toolbarOptions = {
//   options: ["inline", "list", "textAlign", "image"],
//   inline: {
//     options: ["bold", "italic", "underline"],
//   },
//   list: {
//     options: ["unordered", "ordered"],
//   },
//   textAlign: {
//     options: ["left", "center", "right", "justify"],
//   },
//   image: {
//     uploadEnabled: true,
//     previewImage: true,
//     inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
//     dropdownClassName: "custom-image-dropdown",
//     className: "custom-image-option",
//   },
// };

// const editorConfig = {
//   toolbar: {
//     items: [
//       "bold",
//       "italic",
//       "underline",
//       "|",
//       "fontSize",
//       "fontColor",
//       "textColor", // Added "textColor" option
//       "|",
//       "numberedList",
//       "bulletedList",
//       "|",
//       "paragraph",
//       "link",
//       "imageInsert",
//       "table",
//       "code",
//       "|",
//       "undo",
//       "redo",
//       "removeFormat",
//       "defaultFontStyle",
//     ],
//   },
// };

const mailData = [
  {
    mail: "kaleel@digivalsolutions.com",
  },
  {
    mail: "prakash@digivalsolutions.com",
  },
  {
    mail: "ajeshrandam@gmail.com",
  },
  {
    mail: "karan@digivalsolutions.com",
  },
  {
    mail: "kalilurrahuman@digivalsolutions.com",
  },
];

const ReplySideBar = ({
  open,
  handleForwardClose,
  setSendForWardMail,
  handleSendForwardMail,
  sendForwardMail,
  ticketData,
}) => {
  const url = window.location.href;

  const handleGetMail = (e) => {
    setSendForWardMail((pre) => pre.set("mail", e.target.value));
  };
  const handleGetCcMails = (event, value) => {
    setSendForWardMail((pre) => pre.set("Cc", value));
  };

  const handleGetBccMails = (event, value) => {
    setSendForWardMail((pre) => pre.set("Bcc", value));
  };

  const handleGetMessage = (value) => {
    setSendForWardMail((pre) => pre.set("message", value.getData()));
  };

  useEffect(() => {
    setSendForWardMail((pre) =>
      pre.set("subject", ticketData?.ticketNo).set("url", url)
    );
  }, [sendForwardMail]);//eslint-disable-line

  return (
    <SubDrawer
      sx={{
        // width: drawerWidth,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          top: "120px",
          zIndex: "101",
          padding: "8px 15px 15px 15px",
          background: "#F5F7F9",
          display: "flex",
          minHeight: "calc(100vh - 130px)",
          // justifyContent: "center",
          alignItems: "center",
          borderLeft: "1px solid #cfd7df",
          backgroundColor: "white",
        },
      }}
      variant="persistent"
      anchor="right"
      open={open.forward}>
      <div className="w-100 h-100">
        <div className="Forward_head">
          <div className="Forward_head_title">
            <div className="circle">
              <TurnRightIcon />
            </div>
            <h4 className="mb-0">Forward</h4>
          </div>
          <div className="Forward_head_icon">
            <IconButton onClick={handleForwardClose}>
              <i class="fa fa-times" aria-hidden="true"></i>
            </IconButton>
          </div>
        </div>
        <div className="Forward_container">
          <div className="from-email-field">
            <Avatar
              variant="rounded"
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{
                width: "32px",
                height: "32px",
                backgroundColor: "#ffdab9",
                color: "#808080",
              }}
            />

            <div className="from_email_field_title">
              digivalsolutions support@digivalsolutions....
            </div>
          </div>
          <div className="email-action-field d-flex gap-3 align-items-center">
            <div>
              <span>To</span>
            </div>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              autoComplete="none"
              fullWidth
              onChange={handleGetMail}
              value={sendForwardMail.get("mail", "")}
            />
          </div>
          <div className="email-action-field d-flex gap-3 align-items-center">
            <div>
              <span>Cc </span>
            </div>
            <Stack spacing={3} sx={{ width: 500 }}>
              <Autocomplete
                multiple
                id="tags-outlined"
                value={sendForwardMail.get("Cc", List())}
                size="small"
                options={mailData}
                getOptionLabel={(option) => option.mail}
                onChange={handleGetCcMails}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </div>
          <div className="email-action-field d-flex gap-2 align-items-center">
            <div>
              <span>Bcc</span>
            </div>
            <Stack spacing={3} sx={{ width: 500 }}>
              <Autocomplete
                multiple
                id="tags-outlined"
                size="small"
                options={mailData}
                getOptionLabel={(option) => option.mail}
                value={sendForwardMail.get("Bcc", List())}
                onChange={handleGetBccMails}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} />}
              />
            </Stack>
          </div>
          <div>
            {/* <Editor
              editorState={editorState}
              onEditorStateChange={handleEditorChange}
              toolbarClassName="email-editor-toolbar"
              wrapperClassName="email-editor-content"
              editorClassName="email-editor"
              toolbar={toolbarOptions}
            /> */}
            <GrammarlyEditorPlugin>
              <CKEditor
                editor={ClassicEditor}
                // config={editorConfig}
                data={sendForwardMail.get("message", "")}
                onChange={(event, editor) => {
                  handleGetMessage(editor);
                }}
                config={{
                  toolbar: [
                    "bold",
                    "italic",
                    "underline",
                    "numberedList",
                    "bulletedList",
                    "link",
                    "imageUpload",
                    "imageInsert",
                    "insertTable",
                    "code",
                    "mediaEmbed",
                    "undo",
                    "redo",
                  ],
                }}
              />
            </GrammarlyEditorPlugin>
            <div className="forward-reply text-end">
              <Button className="text-primary" onClick={handleSendForwardMail}>
                Forward
              </Button>
            </div>
          </div>
        </div>
      </div>
    </SubDrawer>
  );
};

export default ReplySideBar;
