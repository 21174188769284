import PropTypes from "prop-types";

export const getRow = (data, executedId = "") => {
  const rowData = data?.map((item, index) => {
    return {
      ...item,
      ...(executedId === item._id && { className: "red" }),
    };
  });
  return rowData;
};

export const Video = ({ url, fullWidth }) => (
  <video
    width={fullWidth ? "100%" : "150px"}
    height={fullWidth ? "100%" : "110px"}
    key={url}
    controls>
    <source src={url} type="video/mp4" />
    {/* not working to check */}
    <source src={url} type="video/x-flv" />
    <source src={url} type="application/x-mpegURL" />
    <source src={url} type="video/MP2T" />
    <source src={url} type="video/x-msvideo" />
    <source src={url} type="video/x-ms-wmv" />
    {/* not loading */}
    <source src={url} type="video/3gp2" />
  </video>
);

Video.propTypes = {
  url: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export const createAction = (type, ...args) => {
  return function (...argsvalues) {
    const action = { type };
    args.forEach(function (key, index) {
      action[args[index]] = argsvalues[index];
    });
    return action;
  };
};

export function setLocalStorageValue(...rest) {
  const [a, b, c] = [...rest];
  const constructedObject = {
    access_token: a,
    inner_time: b,
    refresh_token: c,
  };
  const keysAndValue = Object.entries(constructedObject);
  for (let create of keysAndValue) {
    if (create[1]) localStorage.setItem(create[0], JSON.stringify(create[1]));
  }
}
