import {
  GridList,
  GridListTile,
  makeStyles,
  Modal,
  Backdrop,
  Fade,
} from "@material-ui/core";
import React, { useState } from "react";
import "./ImagePop.css";

/*--------------------------UtileStart-----------------------------*/
const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  modal: {
    margin: "0px auto",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red",
    },
  },
  img: {
    outline: "none",
  },
}));
/*--------------------------UtileEnd-------------------------------*/

const ImagePop = ({ Image, openScreenShot, setOpenScreenShot }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [imageModal, setImageModal] = useState(false);
  const [image, setImage] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModal = () => {
    setImageModal(false);
  };

  const handleImage = (value) => {
    setImage(value);
    setImageModal(true);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  return (
    <div className="App">
      {Image.size ? (
        <>
          <span
            onClick={handleOpenModal}
            className="mx-2 fw-bold text-primary"
            style={{ cursor: "pointer" }}>
            view
          </span>
          <Modal
            className={classes.modal}
            style={{ width: Image.size > 1 ? "50%" : "80%" }}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
            {Image.size > 1 ? (
              <GridList className={classes.gridList} cols={2.5}>
                {Image.map((tile) => (
                  <GridListTile key={tile.img} className="container">
                    <img
                      src={tile}
                      alt={tile}
                      onClick={(e) => handleImage(tile)}
                      style={{ minWidth: "50%" }}
                    />
                  </GridListTile>
                ))}
              </GridList>
            ) : (
              <Fade in={open} timeout={500} className={classes.img}>
                <img
                  src={Image.get(0, "")}
                  alt="asd"
                  style={{ maxHeight: "90%", maxWidth: "90%" }}
                />
              </Fade>
            )}
          </Modal>
          {imageModal ? (
            <Modal
              className={classes.modal}
              open={imageModal}
              onClose={handleCloseModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}>
              <Fade in={open} timeout={500} className={classes.img}>
                <img
                  src={image}
                  alt="asd"
                  style={{ maxHeight: "90%", maxWidth: "90%" }}
                />
              </Fade>
            </Modal>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <Modal
            className={classes.modal}
            open={openScreenShot}
            onClose={() => setOpenScreenShot(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}>
            <Fade in={openScreenShot} timeout={500} className={classes.img}>
              <img
                src={Image}
                alt="asd"
                style={{ maxHeight: "90%", maxWidth: "90%" }}
              />
            </Fade>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ImagePop;
