import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  postAddCloudType,
  updateCloudType,
  getAllType,
} from "../../../Redux/BillingManagement/Action";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CloudPopup({
  options,
  cloudPopup,
  setCloudPopup,
  Billing,
  setBilling,
  updateCloud,
  setUpdateCloud,
}) {
  const [name, setName] = useState("");
  console.log("name", name);
  const [errorShow, setErrorShow] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => {
    setCloudPopup(false);
    setUpdateCloud("");
    setName("");
  };

  const disableSubmit = () => {
    // return options.some((item) => item.get("name", "").toLowerCase() === name.toLowerCase()) || name === "";
    return name === "";
  };

  const callBack = () => {
    dispatch(getAllType());
    setCloudPopup(false);
    setBilling(Billing.set("cloudType", name));
    setName("");
    setUpdateCloud("");
  };

  const handleSubmit = () => {
    const payload = {
      name,
      ...(updateCloud !== "" && { id: updateCloud.get("_id", "") }),
    };
    if (name === "") {
      setErrorShow(true);
    } else {
      updateCloud === ""
        ? dispatch(postAddCloudType(payload, callBack))
        : dispatch(updateCloudType(payload, callBack));
    }
  };

  useEffect(() => {
    if (updateCloud !== "") {
      setName(updateCloud.get("name", ""));
    }
  }, [updateCloud]);
  return (
    <Box component={"form"} onSubmit={handleSubmit}>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={cloudPopup}
        className="width75"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Cloud Type
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom className="d-flex flex-column">
            <TextField
              id="outlined-basic"
              label="Cloud Type"
              variant="outlined"
              autoComplete="none"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={errorShow && name === "" ? true : false}
              helperText={
                errorShow && name === "" ? "CloudType is required!" : ""
              }
            />
          </Typography>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center">
          <Button
            disabled={disableSubmit()}
            type="submit"
            onClick={handleSubmit}
          >
            {updateCloud === "" ? "Submit" : "Update"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
}
