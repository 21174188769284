import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import DocumentForm from "./DocumentForm";

const useStyles = makeStyles({
  Container: {
    width: "100%",
    height: "calc(100vh - 65px)",

  },
  BackArrow: {
    marginLeft: "5px",
    fontSize: "17px",
    fontWeight: 500,
  },
});

const CreateDocument = () => {
  const classes = useStyles();

  return (
    <div className="">
      <Box className={classes.Container}>
        <DocumentForm />
      </Box>
    </div>
  );
};

export default CreateDocument;
