import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { fromJS } from "immutable";
import {
  EditMapIDAction,
  EditMapIndexAction,
  postAssetMappingApi,
  putAssetMappingApi,
} from "../../../Redux/AssetsManagement/Action";
import dayjs from "dayjs";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const useStyles = makeStyles({
  form: {
    width: "550px",
    display: "flex",
    gap: "25px",
    flexDirection: "column",
    justifyContent: "center",
  },
  formGroup: {
    width: "100%",
  },
});
export default function MapPopup({ open, setOpen }) {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
    dispatch(EditMapIDAction(false));
    dispatch(EditMapIndexAction(""));
  };
  const EmpDataForm = fromJS({
    employeeName: "",
    assetType: "",
    receivedDate: new Date().toLocaleDateString(),
    returnDate: null,
  });
  const [empData, setEmpData] = useState(EmpDataForm);

  const dispatch = useDispatch();

  const empList = useSelector((state) => state.AssetsManagement).get(
    "getEmpList",
    ""
  );

  const AssetType = useSelector((state) => state.AssetsManagement).get(
    "getAssetsApi",
    ""
  );

  const EditPopupIndex = useSelector((state) => state.AssetsManagement).getIn([
    "EditMapPopup",
    "EditMapIndex",
  ]);

  const MapList = useSelector((state) => state.AssetsManagement).get(
    "getAssetsMapList",
    ""
  );

  // const EditPopupIndex = useSelector((state) => state.AssetsManagement).getIn(["EditMapPopup", "EditMapIndex"]);
  // const MapList = useSelector((state) => state.AssetsManagement).get("getAssetsMapList", "");

  const data = AssetType.filter((data) =>
    MapList.every(
      (item, index) =>
        item.get("assetType", "") !==
          data.get("assetType", "") + " " + data.get("model", "") ||
        index === EditPopupIndex
    )
  );

  const onchangeEmp = (onChangeValue, key) => {
    const keyValue =
      key === "receivedDate" || key === "returnDate"
        ? new Date(onChangeValue).toLocaleDateString()
        : onChangeValue.target.value;
    setEmpData(empData.set(key, keyValue));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (EditPopupIndex !== "") {
      dispatch(putAssetMappingApi(empData, setOpen, setEmpData, EmpDataForm));
      dispatch(EditMapIDAction(false));
      dispatch(EditMapIndexAction(""));
    } else {
      dispatch(postAssetMappingApi(empData, setOpen, setEmpData, EmpDataForm));
    }
  };

  useEffect(() => {
    if (EditPopupIndex !== "") {
      setEmpData(MapList.get(EditPopupIndex));
    }
  }, [EditPopupIndex]); // eslint-disable-line

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Employees
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component={"form"}
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <Box className={classes.formGroup}>
              {/* <TextField
                select
                label="Employee List"
                variant="outlined"
                sx={{ width: "100%" }}
                autoComplete="none"
                onChange={(e) => onchangeEmp(e, "employeeName")}
                value={empData.get("employeeName", "")}
              >
                {empList.map((data, index) => {
                  return (
                    <MenuItem value={data.get("name", "")} key={index}>
                      {data.get("name", "")}
                    </MenuItem>
                  );
                })}
              </TextField> */}

              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">
                  Employee List
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  label="Employee List"
                  variant="outlined"
                  autoComplete="none"
                  onChange={(e) => onchangeEmp(e, "employeeName")}
                  value={empData.get("employeeName", "")}
                  sx={{ width: "550px" }}
                  MenuProps={{
                    style: {
                      maxHeight: "350px",
                    },
                  }}
                >
                  {empList.map((data, index) => {
                    return (
                      <MenuItem value={data.get("name", "")} key={index}>
                        {data.get("name", "")}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box className={classes.formGroup}>
              {/* <TextField
                select
                label="Assets_type"
                variant="outlined"
                sx={{ width: "100%" }}
                autoComplete="none"
                value={empData.get("assetType", "")}
                onChange={(e) => onchangeEmp(e, "assetType")}
              >
                {data.map((items, index) => {
                  return (
                    <MenuItem
                      value={
                        items.get("assetType", "") +
                        " " +
                        items.get("model", "")
                      }
                      key={index}
                    >
                      {items.get("assetType", "")} {items.get("model", "")}
                    </MenuItem>
                  );
                })}
              </TextField> */}
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">
                  Assets_type
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  label="Assets_type"
                  variant="outlined"
                  autoComplete="none"
                  value={empData.get("assetType", "")}
                  onChange={(e) => onchangeEmp(e, "assetType")}
                  sx={{ width: "550px" }}
                  MenuProps={{
                    style: {
                      maxHeight: "350px",
                    },
                  }}
                >
                  {data.map((items, index) => {
                    return (
                      <MenuItem
                        value={
                          items.get("assetType", "") +
                          " " +
                          items.get("model", "")
                        }
                        key={index}
                      >
                        {items.get("assetType", "")} {items.get("model", "")}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Received Date"
                  sx={{ width: "100%" }}
                  value={dayjs(empData.get("receivedDate", ""))}
                  onChange={(e) => onchangeEmp(e, "receivedDate")}
                  renderInput={(params) => (
                    <TextField {...params} autoComplete="none" />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <DialogActions>
              <Button autoFocus type="submit">
                Submit
              </Button>
            </DialogActions>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
