import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fromJS, List } from "immutable";
import {
  selectLoadingStauts,
  selectMessageStatus,
  selectBillingApi,
  selectGetٍSeparateBillingData,
  selectPagination,
} from "../../../Redux/BillingManagement/Selector";
import {
  deleteBilling,
  EditBillIDAction,
  getBilling,
  snapPostBilling,
  setData,
  getAllType,
  getIndividualServiceData,
} from "../../../Redux/BillingManagement/Action";
import { useNavigate } from "react-router";
import { useSweetAlert } from "../../../Utils/Sweetalert";
import Snackbar from "../../../LoaderSnackbar/Snackbar";
import Loader from "../../../LoaderSnackbar/Loader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import HistoryIcon from "@mui/icons-material/History";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Card,
  IconButton,
  Tooltip,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../Styles/Billing/TableDesign";
import EditViewPopup from "../ModelPopup/EditAndView";
import ReminderForm from "../ModelPopup/reminderPopUp";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import PaginationControlled from "../../../Shared/Pagination";

/*-------------------------------------UtilStart-------------------------------------*/
const tableTopStyle = { width: "100%", height: "50px" };
const iconSize = { marginLeft: "18px" };
const BoxStyle = {
  height: "100%",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
};
/*-------------------------------------UtilEnd---------------------------------------*/

/*-------------------------------------CustomHooksStart------------------------------*/
const useReduxData = () => {
  const loadingStatus = useSelector(selectLoadingStauts);
  const messageStatus = useSelector(selectMessageStatus);
  const getBillingApi = useSelector(selectBillingApi);
  const billingData = useSelector(selectGetٍSeparateBillingData);
  const pageNation = useSelector(selectPagination);
  return [loadingStatus, messageStatus, getBillingApi, billingData, pageNation];
}; //this hooks use for get this compound redux data

const useCommonDispatchFunctions = () => {
  const dispatch = useDispatch();
  const ServiceData = (data) => dispatch(getIndividualServiceData(data));
  const BillIdAction = (data) => dispatch(EditBillIDAction(data));
  const billing = (data) => dispatch(getBilling(data));
  const snapPost = (data) => dispatch(snapPostBilling(data));
  const allType = () => dispatch(getAllType());
  const setReduxData = (data) => dispatch(setData(data));
  return [ServiceData, BillIdAction, billing, snapPost, allType, setReduxData];
}; // this hooks use for all redux functions set into single function

const useBillingTableInsideUtils = ({ filteredData }) => {
  const totalAmount = filteredData
    .reduce((sum, current) => current.get("totalCost", 0) + sum, 0)
    .toFixed(2);

  function cutServiceType(serviceArr, number) {
    return serviceArr
      .slice(0, number)
      .map((item) => item.get("serviceType", ""))
      .join(",");
  }

  function allServiceList(serviceArr) {
    if (serviceArr.size > 2) {
      return (
        <>
          {cutServiceType(serviceArr, 2)}
          <Tooltip
            arrow
            placement="top"
            title={cutServiceType(serviceArr, serviceArr.size + 1)}
            className="text-primary">
            View All
          </Tooltip>
        </>
      );
    }
    return cutServiceType(serviceArr, 2);
  }
  return [totalAmount, allServiceList];
};

const usePageNation = ({ setReduxData, pageNation, getBillingApi }) => {
  const handlePageNation = (value, key = "page") => {
    setReduxData(fromJS({ pagination: pageNation.set(key, value) }));
  };
  const pageNo = pageNation.get("page", 0);
  const totalPageCount = Math.ceil(
    getBillingApi.get("total", 0) / pageNation.get("limit", 0)
  );
  return [pageNo, totalPageCount, handlePageNation];
};

const useActionFunctions = ({
  snapPost,
  billing,
  ServiceData,
  BillIdAction,
  pageNation,
}) => {
  const [popup, setPopup] = useState(
    fromJS({
      viewPopupType: "",
      open: false,
      rowId: "",
      showPopup: false,
    })
  );
  const navigate = useNavigate();
  const sweetAlert = useSweetAlert();

  function makeTableActions({ event, index, id, key }) {
    let takeActionToDeleteHistorySnap = event && id;
    let takeActionToEditPopup = event && index && id;
    let takeActionToViewPopup = index && id && event === null;
    const page = pageNation.get("page", 0);
    const limit = pageNation.get("limit", 0);
    const filterName = pageNation.get("filterName", "");

    const IndividualCallBack = () => setPopup((pre) => pre.set("open", true));
    const callBack = () => {
      billing({
        page: page,
        limit: limit,
        filterName: filterName,
      });
    };

    if (takeActionToDeleteHistorySnap) {
      if (key === "Delete") {
        event.stopPropagation();
        sweetAlert({ id, ApiCall: deleteBilling, callBack });
      }
      if (key === "History") {
        event.stopPropagation();
        navigate(`/cloudBilling/Snapchat/${id}`);
      }
      if (key === "Snap") {
        event.stopPropagation();
        snapPost(id);
      }
    }
    if (takeActionToEditPopup) {
      event.stopPropagation();
      setPopup((pre) => pre.set("viewPopupType", "Edit").set("open", true));
      ServiceData(id, IndividualCallBack);
      BillIdAction(index);
    }
    if (takeActionToViewPopup) {
      setPopup((pre) => pre.set("viewPopupType", "View").set("open", true));
      ServiceData(id, IndividualCallBack);
      BillIdAction(index);
    }
    if (key === "Navigate") {
      navigate("/cloudBilling/billing");
    }
    if (key === "Reminder") {
      setPopup((pre) => pre.set("rowId", id).set("showPopup", true));
    }
  }

  return [popup, setPopup, makeTableActions];
}; //this hooks use for billing table actions handle like view,snap,delete,reminder,etc..,

const useDebounce = ({ getBillingApi, setReduxData, pageNation }) => {
  const filteredData = getBillingApi.get("result", List());
  const waitToSearch = debounce((event) => {
    setReduxData(
      fromJS({
        pagination: pageNation
          .set("filterName", event.target.value)
          .set("page", 1),
      })
    );
  });
  function debounce(cb, delay = 500) {
    let timeout;
    return (...args) => {
      clearInterval(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  }
  return [waitToSearch, filteredData];
}; // this hooks use for filter the billing table filter with clientName

/*-------------------------------------CustomHooksEnd---------------------------------*/

/*-------------------------------------CompoundStart----------------------------------*/
const BillingTable = () => {
  const [ServiceData, BillIdAction, billing, snapPost, allType, setReduxData] =
    useCommonDispatchFunctions();
  const [loadingStatus, messageStatus, getBillingApi, billingData, pageNation] =
    useReduxData();
  const [waitToSearch, filteredData] = useDebounce({
    setReduxData,
    pageNation,
    getBillingApi,
  });
  const [popup, setPopup, makeTableActions] = useActionFunctions({
    snapPost,
    billing,
    ServiceData,
    BillIdAction,
    pageNation,
  });
  const [totalAmount, allServiceList] = useBillingTableInsideUtils({
    filteredData,
  });

  const open = popup.get("open", false);
  const showReminder = popup.get("showPopup", false);
  const rowId = popup.get("rowId", 0);
  const page = pageNation.get("page", 0);
  const limit = pageNation.get("limit", 0);
  const filterName = pageNation.get("filterName", "");

  useEffect(() => {
    billing({
      page: page,
      limit: limit,
      filterName: filterName,
    });
    allType();
  }, [pageNation]); //eslint-disable-line

  return (
    <>
      <div className="container-fluid">
        <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
        <Loader isLoading={loadingStatus} />
        <Box sx={tableTopStyle} className="mt-4">
          <Box sx={BoxStyle}>
            <Box className="my-2">
              <label className="bold">
                Per Month Cost :
                <span className="text-primary">{totalAmount}</span>
              </label>
            </Box>

            <TextField
              name="search"
              id="outlined-basic"
              size="small"
              className="mx-2"
              label="Client Name Search"
              variant="outlined"
              autoComplete="none"
              onChange={waitToSearch}
            />
            <Button
              variant="contained"
              onClick={() => makeTableActions({ key: "Navigate" })}>
              + Create
            </Button>
          </Box>
        </Box>
        <BillingShowTable
          filteredData={filteredData}
          allServiceList={allServiceList}
          makeTableActions={makeTableActions}
          setReduxData={setReduxData}
          pageNation={pageNation}
          getBillingApi={getBillingApi}
        />
      </div>
      {open && (
        <EditViewPopup
          open={open}
          setOpen={setPopup}
          type={popup.get("viewPopupType", "")}
          value={billingData}
        />
      )}
      {showReminder && (
        <ReminderForm
          showPopup={showReminder}
          setShowPopup={setPopup}
          rowId={rowId}
        />
      )}
    </>
  );
};

export default BillingTable;
/*-------------------------------------CompoundEnd------------------------------------*/

/*------------------------------Billing Table CompoundStart---------------------------*/
const BillingShowTable = ({
  filteredData,
  allServiceList,
  makeTableActions,
  setReduxData,
  pageNation,
  getBillingApi,
}) => {
  const [pageNo, totalPageCount, handlePageNation] = usePageNation({
    setReduxData,
    pageNation,
    getBillingApi,
  });

  const indexNo = pageNation.get("limit", 0) * (pageNation.get("page", 0) - 1);
  const limit = pageNation.get("limit", 0);

  return (
    <>
      <Card>
        <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>Client Name</StyledTableCell>
                <StyledTableCell>Cloud Type</StyledTableCell>
                <StyledTableCell>Service Type</StyledTableCell>
                <StyledTableCell>Total Cost</StyledTableCell>
                <StyledTableCell>snapshot</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((item, index) => {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{indexNo + index + 1}</StyledTableCell>
                    <StyledTableCell>
                      {item.get("clientName", "")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {item.get("cloudType", "")}
                    </StyledTableCell>
                    <StyledTableCell>
                      <div className=" d-flex flex-row justify-content-center">
                        {allServiceList(item.get("service", List()))}
                      </div>
                    </StyledTableCell>
                    <StyledTableCell>
                      {item.get("totalCost", 0).toFixed(2)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <ReusableIcon
                        makeTableActions={makeTableActions}
                        title="snapshot"
                        OptionsIcon={AddAPhotoIcon}
                        construed={{
                          id: item.get("_id", ""),
                          key: "Snap",
                        }}
                        iconColor="primary"
                      />
                      <ReusableIcon
                        makeTableActions={makeTableActions}
                        title="History"
                        OptionsIcon={HistoryIcon}
                        construed={{
                          index: index,
                          id: item.get("_id", ""),
                          key: "History",
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell>
                      <ReusableIcon
                        makeTableActions={makeTableActions}
                        title="View"
                        OptionsIcon={VisibilityIcon}
                        construed={{
                          index: index + 1,
                          id: item.get("_id", ""),
                          event: null,
                        }}
                        iconColor="primary"
                        check={true}
                      />
                      <ReusableIcon
                        makeTableActions={makeTableActions}
                        title="Edit"
                        OptionsIcon={EditIcon}
                        construed={{
                          index: index + 1,
                          id: item.get("_id", ""),
                        }}
                        iconColor="success"
                        check={false}
                      />
                      <ReusableIcon
                        makeTableActions={makeTableActions}
                        title="Reminder"
                        OptionsIcon={CircleNotificationsIcon}
                        construed={{
                          key: "Reminder",
                          id: item.get("_id", ""),
                        }}
                        iconColor="danger"
                      />
                      <ReusableIcon
                        makeTableActions={makeTableActions}
                        title="Delete"
                        OptionsIcon={DeleteForeverIcon}
                        construed={{
                          key: "Delete",
                          id: item.get("_id", ""),
                        }}
                        iconColor="danger"
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <div className="d-flex my-3 justify-content-end align-items-center">
        <FormControl>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            size="small"
            value={limit}
            onChange={(event) => handlePageNation(event.target.value, "limit")}>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
          </Select>
        </FormControl>
        <PaginationControlled
          currentPage={pageNo}
          totalPageCount={totalPageCount}
          float={"none"}
          switchPagination={handlePageNation}
        />
      </div>
    </>
  );
};
/*------------------------------Billing Table CompoundEnd-----------------------------*/

/*------------------------------Reusable CompoundStart--------------------------------*/
const ReusableIcon = ({
  makeTableActions,
  title,
  OptionsIcon,
  construed,
  iconColor,
  check = false,
}) => {
  return (
    <Tooltip title={title} arrow placement="top">
      <IconButton
        onClick={(event) =>
          makeTableActions(check ? construed : { event: event, ...construed })
        }
        sx={iconSize}>
        <OptionsIcon className={`text-${iconColor}`} />
      </IconButton>
    </Tooltip>
  );
};
/*---------------------------------Reusable CompoundEnd-------------------------------*/
