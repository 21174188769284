import React, { useState } from "react";
import AssetsMapList from "./AssetsMapList";
import { Button } from "@mui/material";
import MapPopup from "./MapPopup";
import AddIcon from "@mui/icons-material/Add";
export default function AssetsMapForm() {
  const [open, setOpen] = useState(false);
  const handleCreate = () => {
    setOpen(true);
  };

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button variant="contained" onClick={handleCreate} className="mb-2">
          <AddIcon sx={{ fontSize: "18px", fontWeight: "700" }} /> Create
        </Button>
      </div>
      <AssetsMapList setOpen={setOpen} />
      {open && <MapPopup open={open} setOpen={setOpen} />}
    </>
  );
}
