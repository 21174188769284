import { Box, CssBaseline, Snackbar } from "@mui/material";
import React from "react";
import Loader from "../../../LoaderSnackbar/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTicket,
  // getIndividualIssue,
  setData,
} from "../../../Redux/ClientSupportManagement/Action";
import {
  selectIsLoading,
  selectMessage,
} from "../../../Redux/ClientSupportManagement/Selector";
import ReplyHeader from "./Components/Header/ReplyHeader";
import ReplyMainPage from "./Components/Mainpage/ReplyMainPage";
import ReplySideBar from "./Components/Sidebar/ReplySideBar";
import {
  retrieveAllIssue,
  postForwardEmail,
  updateClientStatus,
} from "../../../Redux/ClientSupportManagement/Action";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { fromJS, List } from "immutable";
import {
  selectSupportList,
  selectIssueData,
} from "../../../Redux/ClientSupportManagement/Selector";

const TicketReply = () => {
  const initial = fromJS({
    mail: "",
    message: "",
    Cc: [],
    Bcc: [],
    subject: "",
    url: "",
  });
  const messageStatus = useSelector(selectMessage);
  const loadingStatus = useSelector(selectIsLoading);
  const IssueDataFiltered = useSelector(selectIssueData);
  const [ticketData, setTicketData] = useState();
  const [open, setOpen] = useState({
    sidebar: true,
    forward: false,
    isReplay: false,
    scrollView: false,
  });
  const [sendForwardMail, setSendForWardMail] = useState(initial);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const problemList = useSelector(selectSupportList);
  const getRole = JSON.parse(localStorage.getItem("authRes"));
  const userId = getRole.role === "Admin" ? getRole.role : getRole._id;

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const filterData = problemList.get("result", List()).find((item) => {
    return item.get("_id", "") === id;
  });

  const handleChangeButtonName = () => {
    return filterData?.get("status", "") === "Open" ||
      filterData?.get("status", "") === "Inprogress"
      ? true
      : false;
  };

  const handleForwardOpen = () => {
    setOpen((prev) => {
      return { ...prev, forward: true, sidebar: false };
    });
  };

  const handleForwardClose = () => {
    setOpen((prev) => {
      return { ...prev, forward: false };
    });
  };

  const handleSideBarOpen = () => {
    setOpen((prev) => {
      return { ...prev, sidebar: !open.sidebar, forward: false };
    });
  };
  const handleReplyAction = () => {
    setOpen((prev) => {
      return { ...prev, isReplay: true, scrollView: true };
    });
  };

  const callBack = () => {
    dispatch(
      retrieveAllIssue({ roleId: userId, filterData: IssueDataFiltered })
    );
  };

  const handleStatusToggle = async () => {
    const payload = {
      supportId: id,
      currentStatus: handleChangeButtonName() ? "Close" : "Open",
    };
    await dispatch(updateClientStatus(payload, callBack));
    navigate("/clientSupport");
  };

  const handleForwardMailValidate = (data) => {
    if (data.get("mail", "") === "") {
      alert("Email is required.");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.get("mail", ""))) {
      alert("Please enter a valid email");
    } else if (data.get("message", "") === "") {
      alert("Message is required.");
    } else {
      return true;
    }
    return false;
  };

  const handleSendForwardMail = async () => {
    const payload = {
      mail: sendForwardMail.get("mail", ""),
      subject: sendForwardMail.get("subject", ""),
      message: sendForwardMail.get("message", ""),
      url: sendForwardMail.get("url", ""),
      Cc: sendForwardMail.get("Cc", List()),
      Bcc: sendForwardMail.get("Bcc", List()),
    };

    if (handleForwardMailValidate(sendForwardMail)) {
      await dispatch(postForwardEmail(payload, callBack));
      setSendForWardMail(initial);
      handleForwardClose();
    }
  };

  const handleDeleteTicket = async () => {
    const payload = { id: id };
    await dispatch(deleteTicket(payload, callBack));
    navigate("/clientSupport");
  };

  useEffect(() => {
    dispatch(
      retrieveAllIssue({ roleId: userId, filterData: IssueDataFiltered })
    );
  }, []); //eslint-disable-line

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <CssBaseline />
      <ReplyHeader
        open={open}
        handleForwardOpen={handleForwardOpen}
        handleSideBarOpen={handleSideBarOpen}
        handleReplyAction={handleReplyAction}
        handleStatusToggle={handleStatusToggle}
        handleDeleteTicket={handleDeleteTicket}
        handleChangeButtonName={handleChangeButtonName}
      />
      <Box sx={{ display: open.forward && "flex" }}>
        <ReplyMainPage
          open={open}
          setOpen={setOpen}
          handleForwardOpen={handleForwardOpen}
          handleReplyAction={handleReplyAction}
          ticketData={ticketData}
          setTicketData={setTicketData}
        />
        <ReplySideBar
          open={open}
          handleForwardClose={handleForwardClose}
          setSendForWardMail={setSendForWardMail}
          sendForwardMail={sendForwardMail}
          handleSendForwardMail={handleSendForwardMail}
          ticketData={ticketData}
        />
      </Box>
    </Box>
  );
};

export default TicketReply;
