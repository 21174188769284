import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./Modules/TestCase/InsertRowModel.css";
import "./Modules/TestCase/Create.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <GoogleOAuthProvider clientId="954459013363-8fh3a0bcrhjnlft5l8qkif0kv6vfcn93.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
