import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";

const CreatePopup = ({ show, handleClose, handleClick, role, validate, title }) => {
  const [data, setData] = useState("");
  const convertToTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle>{`Add ${title}`}</DialogTitle>
      <DialogContent>
        <form id="add-form" onSubmit={(e, data) => handleClick(e, data)}>
          <label>
            {title}:
            <input type="text" value={data} onChange={(event) => setData(convertToTitleCase(event.target.value))} style={{ padding: "5px" }} />
          </label>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={(e) => handleClick(e, data)} color="primary">
          {/* // disabled={validate.some((item) => item.role === data) || data === "Admin"}>  */}
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePopup;
