import React from "react";

export default class AgTableButton extends React.Component {
  constructor(props) {
    super(props);
    this.btnClickedHandler = this.btnClickedHandler.bind(this);
  }
  btnClickedHandler() {
    this.props.clicked(this.props.data);
  }
  render() {
    return (
      <div className="d_all_center">
        <button className={`${this.props.data.className} table_button d_all_center`} onClick={this.btnClickedHandler}>
          {this.props.name}
        </button>
      </div>
    );
  }
}
