import * as actions from "./Action";
import { fromJS } from "immutable";

const initialState = fromJS({
  isLoading: false,
  message: "",
  allOption: {},
  separateDocument: {},
  documentList: [],
  notification: [],
});

export default function ReduxStore(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DATA_SUCCESS: {
      return state.merge(action.data);
    }
    case actions.POST_CREATE_DOCUMENTS_TYPE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_CREATE_DOCUMENTS_TYPE_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.POST_CREATE_DOCUMENTS_TYPE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_CREATE_DOCUMENT_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_CREATE_DOCUMENT_SUCCESS: {
      return state.set("isLoading", false).set("message", `Document Created successfully...`);
    }
    case actions.POST_CREATE_DOCUMENT_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_DOCUMENTS_TYPE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_DOCUMENTS_TYPE_SUCCESS: {
      return state.set("isLoading", false).set("allOption", fromJS(action.data));
    }
    case actions.GET_DOCUMENTS_TYPE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_ALL_DOCUMENT_LIST_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_ALL_DOCUMENT_LIST_SUCCESS: {
      return state.set("isLoading", false).set("documentList", fromJS(action.data));
    }
    case actions.GET_ALL_DOCUMENT_LIST_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_MY_DOCUMENT_LIST_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_MY_DOCUMENT_LIST_SUCCESS: {
      return state.set("isLoading", false).set("documentList", fromJS(action.data));
    }
    case actions.GET_MY_DOCUMENT_LIST_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.DELETE_DOCUMENTS_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.DELETE_DOCUMENTS_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.DELETE_DOCUMENTS_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.UPDATE_DOC_TYPE_REQUEST: {
      return state.set("loading", true);
    }
    case actions.UPDATE_DOC_TYPE_SUCCESS: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.UPDATE_DOC_TYPE_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.DELETE_DOC_TYPE_REQUEST: {
      return state.set("loading", true);
    }
    case actions.DELETE_DOC_TYPE_SUCCESS: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.DELETE_DOC_TYPE_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.POST_NOTIFICATION_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_NOTIFICATION_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.POST_NOTIFICATION_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_NOTIFICATION_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_NOTIFICATION_SUCCESS: {
      return state.set("isLoading", false).set("notification", fromJS(action.data));
    }
    case actions.GET_NOTIFICATION_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.UPDATE_NOTIFICATION_REQUEST: {
      return state.set("loading", true);
    }
    case actions.UPDATE_NOTIFICATION_SUCCESS: {
      return state.set("loading", false);
    }
    case actions.UPDATE_NOTIFICATION_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.GET_DOCUMENT_BY_ID_REQUEST: {
      return state.set("loading", true);
    }
    case actions.GET_DOCUMENT_BY_ID_SUCCESS: {
      return state.set("loading", false).set("separateDocument", fromJS(action.data));
    }
    case actions.GET_DOCUMENT_BY_ID_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_REQUEST: {
      return state.set("loading", true);
    }
    case actions.GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_SUCCESS: {
      return state.set("loading", false).set("documentList", fromJS(action.data));
    }
    case actions.GET_DOCUMENT_HISTORY_BY_DOCUMENT_ID_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    default:
      return state;
  }
}
