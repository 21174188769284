import React from "react";
import {
  Card,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch } from "react-redux";
import {
  putClientPriority,
  updateClientStatus,
} from "../../../../../Redux/ClientSupportManagement/Action";
import impulse from "../../../../../Assets/impulse.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { List } from "immutable";
import { duplicateNumberForNoAssignee } from "../../../../../Utils/DuplicateNumber";

/*----------------------------------Utils Start------------------------------------*/
const dropDownStyle = {
  width: "250px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px",
    display: "flex",
    justifyContent: "start",
  },
};
/*----------------------------------Utils End--------------------------------------*/

const ReportsCardList = ({
  filteredTableData,
  pageNo,
  rowsPerPage,
  handleChangeAssigneeName,
  newAgentData,
  callBack,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleView = (id) => {
    navigate(`/clientSupport/viewIssue?id=${id}`);
  };

  const handlePriorityChange = (event, id) => {
    event.stopPropagation();
    const payload = {
      supportId: id,
      newPriority: event.target.value,
    };
    dispatch(putClientPriority(payload, callBack));
  };

  const handleStatusChange = (event, id) => {
    event.stopPropagation();
    const payload = {
      supportId: id,
      currentStatus: event.target.value,
    };
    dispatch(updateClientStatus(payload, callBack));
  };

  const handleViewTicket = (id) => {
    navigate(`/clientSupport/viewTicket?id=${id}`);
  };

  const useStyles = makeStyles({
    ListCard: {
      height: "100px",
      background: "#F5F7F8 !important",
    },
    ClientName: {
      fontSize: "16px",
      fontWeight: "600",
      margin: "0px",
      cursor: "pointer",
      "&:hover": {
        color: "#1976D2",
      },
    },
    UserName: {
      fontSize: "14px",
      margin: "0px",
      color: "rgb(108, 115, 127)",
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    email: {
      fontSize: "12px",
      margin: "0px",
      color: "rgb(108, 115, 127)",
    },
    priority: {
      color: "#167BFF",
      border: "2px solid",
      background: "#d4eafc",
    },
  });

  const classes = useStyles();

  const priorityOptions = [
    { value: "Low", dotColor: "#A0D76A" },
    { value: "Medium", dotColor: "#4DA1FF" },
    { value: "High", dotColor: "#FFD012" },
    { value: "Urgent", dotColor: "#FF5959" },
  ];

  const statusOptions = [
    { value: "Open", icon: impulse },
    { value: "Close", icon: impulse },
    { value: "Inprogress", icon: impulse },
    { value: "Waiting on Customer", icon: impulse },
  ];

  return (
    <div className="container-fluid ">
      <div className="scrolable-card">
        {filteredTableData.get("result", List()).map((data, index) => (
          <div className="pt-2 ">
            <Card
              className={`row mb-1 ${classes.ListCard}`}
              onClick={() => handleViewTicket(data.get("_id", ""))}
            >
              <div className="col-1 d-flex justify-content-center align-items-center">
                <div className="d-flex">
                  {rowsPerPage * (pageNo - 1) + index + 1}
                </div>
              </div>
              <div className="col-6 gap-1 d-flex flex-column justify-content-center">
                <h6 className={classes.ClientName}>
                  {data.get("subject", "")}
                </h6>
                <p className={classes.UserName}>
                  <MailOutlineIcon
                    sx={{ color: "#647a8e", fontSize: "15px" }}
                  />
                  {data.get("name", "")}-
                  {
                    <span className="text-primary">
                      T{data.get("ticketNo", "")}
                    </span>
                  }
                </p>
              </div>

              <div className="col-4 d-flex align-items-center FormControl">
                <div>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Tooltip title="priority" placement="left" arrow>
                      <FormControl sx={{ m: 0.5, minWidth: 165 }}>
                        <Select
                          value={data.get("priority", "")}
                          onChange={(event) =>
                            handlePriorityChange(event, data.get("_id", ""))
                          }
                          IconComponent={KeyboardArrowDownIcon}
                          sx={dropDownStyle}
                        >
                          {priorityOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              component="div"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              <div
                                style={{
                                  width: 9,
                                  height: 9,
                                  margin: "6px",
                                  backgroundColor: option.dotColor,
                                }}
                              />
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Tooltip title="status" placement="left" arrow>
                      <FormControl sx={{ m: 0.5, minWidth: 165 }}>
                        <Select
                          value={data.get("status", "")}
                          onChange={(event) =>
                            handleStatusChange(event, data.get("_id", ""))
                          }
                          IconComponent={KeyboardArrowDownIcon}
                          sx={dropDownStyle}
                        >
                          {statusOptions.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              component="div"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              <img
                                style={{
                                  width: 20,
                                  height: 20,
                                }}
                                src={option.icon}
                                alt=""
                              />
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </div>

                  <div
                    className="d-flex justify-content-center align-items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Tooltip title="Assignee" placement="left" arrow>
                      <FormControl sx={{ m: 0.5, minWidth: 165 }}>
                        <Select
                          value={data.get(
                            "assignee",
                            duplicateNumberForNoAssignee
                          )}
                          onChange={(event) =>
                            handleChangeAssigneeName({
                              AssigneeId: event.target.value,
                              id: data.get("_id", ""),
                            })
                          }
                          IconComponent={KeyboardArrowDownIcon}
                          sx={dropDownStyle}
                        >
                          {newAgentData.map((option) => (
                            <MenuItem
                              key={option.userId}
                              value={option.userId}
                              component="div"
                              style={{ display: "flex", gap: "10px" }}
                            >
                              <PersonIcon
                                style={{ fontSize: "20px" }}
                                className="text-primary"
                              />
                              {option.username}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="col-1 d-flex justify-content-center align-items-center">
                <div className="d-flex">
                  <Tooltip title="View" arrow placement="top">
                    <IconButton
                      onClick={(e) =>
                        handleView(data.get("_id", ""), e.stopPropagation())
                      }
                    >
                      <VisibilityIcon sx={{ color: "#1976D2" }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportsCardList;

export const getAssigneeName = (key, value, AllAgent) => {
  const replyAgentName = AllAgent.find((item) => item[key] === value + "");
  if (!replyAgentName) return "No Assignee";
  return replyAgentName.username;
};
