import { List, Map } from "immutable";
const testCaseData = (state) => state.TestCaseReducer;

const selectIsLoading = (state) => testCaseData(state).get("isLoading", false);
const selectIsLoggedIn = (state) =>
  testCaseData(state).get("isLoggedIn", false);
const selectUser = (state) => testCaseData(state).get("user", null);
const selectExecuted = (state) => testCaseData(state).get("executed", false);
const selectMessage = (state) => testCaseData(state).get("message", "");
const selectAuthType = (state) => testCaseData(state).get("Auth_type", "");
const selectRoles = (state) => testCaseData(state).get("roles", List());
const selectDepartment = (state) =>
  testCaseData(state).get("department", List());
const selectUsers = (state) => testCaseData(state).get("users", List());
const selectFeatureList = (state) =>
  testCaseData(state).get("featureList", List());
const selectJiraMemberList = (state) =>
  testCaseData(state).get("jiraMemberList", List());
const selectTestCaseData = (state) =>
  testCaseData(state).get("testCaseData", Map());
const selectOriginalTestCaseData = (state) =>
  testCaseData(state).get("originalTestCaseData", Map());
const selectViewTestCaseData = (state) =>
  testCaseData(state).get("viewTestCaseData", Map());
const selectGoogleAuthDetails = (state) =>
  testCaseData(state).get("googleAuthDetails", Map());
const selectAccessToken = (state) => testCaseData(state).get("accessToken", "");

export {
  selectIsLoading,
  selectIsLoggedIn,
  selectUser,
  selectExecuted,
  selectMessage,
  selectAuthType,
  selectRoles,
  selectUsers,
  selectFeatureList,
  selectJiraMemberList,
  selectTestCaseData,
  selectOriginalTestCaseData,
  selectViewTestCaseData,
  selectDepartment,
  selectGoogleAuthDetails,
  selectAccessToken,
};
