import React, { useRef, useState } from "react";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getUserData } from "../../Redux/TestCase/Action";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import SendIcon from "@mui/icons-material/Send";
import "./updateBioImg.css";
import Webcam from "react-webcam";
import "./webImagesDisplay.css";

const backEndApi = process.env.REACT_APP_BackEndUrl;

// upload attachment
const AddAttachment = ({
  id,
  rowUserName,
  addAttachment,
  setAddAttachment,
  setUserData,
  setOpenUpload,
  setOpenVert,
  setBioOpen,
}) => {
  const dispatch = useDispatch();

  const [selectedFiles, setSelectedFiles] = useState([]);

  let updateUserName = rowUserName?.replaceAll(" ", "_");

  const handleFileChange = async (event) => {
    const files = event.target.files;

    const base64Array = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();

      let newUserName = `${updateUserName}____${id}_${i + 1}.jpg`;

      reader.onload = () => {
        const base64Data = reader.result;
        base64Array.push({ imgLink: base64Data, userName: newUserName });
        if (base64Array.length === files.length) {
          setSelectedFiles(base64Array);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadCancel = () => {
    setOpenUpload(false);
  };

  const handleUploadFile = () => {
    axios
      .put(`${backEndApi}oauth/addExUserAttachment?id=${id}`, {
        userAttachment: selectedFiles,
      })
      .then((res) => {
        dispatch(getUserData());
        Swal.fire({
          text: "Picture Updated Successfully",
          icon: "success",
          showCancelButton: false,
        });
        setOpenUpload(false);
        setBioOpen(false);
        setOpenVert("");
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          text: "Picture Update failed...!",
          icon: "warning",
          showCancelButton: false,
        });
      });
    setAddAttachment(false);
  };

  return (
    <Dialog open={addAttachment} PaperProps={{ style: { minWidth: "400px" } }}>
      <DialogTitle className="update-picture-heading">
        UPDATE PICTURE
      </DialogTitle>
      <div className="m-5">
        <input
          type="file"
          multiple
          onChange={(event) => handleFileChange(event)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <Button
        onClick={handleUploadFile}
        variant="contained"
        endIcon={<DownloadForOfflineIcon />}
        className="upload-user-picture">
        Upload Picture
      </Button>
      <Button
        onClick={handleUploadCancel}
        variant="contained"
        endIcon={<CancelIcon />}
        className="upload-user-picture-cancel">
        Cancel
      </Button>
    </Dialog>
  );
};

// show webcam imgaes
const ImageGallery = ({ img1, img2, img3 }) => {
  return (
    <div className="image-gallery">
      <img src={img1} alt="img1" className="gallery-image" />
      <img src={img2} alt="img2" className="gallery-image" />
      <img src={img3} alt="img3" className="gallery-image" />
    </div>
  );
};

//take webcam attachment
const WebcamImage = ({
  openCamera,
  setOpenCamera,
  id,
  rowUserName,
  setUserData,
  setBioOpen,
  editOpenImgId,
  setOpenVert,
}) => {
  const dispatch = useDispatch();
  const webcamRef = useRef(null);
  const [imageCount, setImageCount] = useState(0);
  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    // get picture url from server
    axios
      .post(`${backEndApi}oauth/postUploadFile`, {
        imageSrc,
        imageCount,
      })
      .then((res) => {
        let count = res.data.message.imgCount;
        let url = res.data.message.imgURL;

        let updateUserName = rowUserName?.replaceAll(" ", "_");

        let newUserName = `${updateUserName}____${id}_${count}.jpg`;

        setImageCount(count);
        if (count === 1) {
          setImg1(url);
          setSelectedFiles([
            ...selectedFiles,
            { imgLink: url, userName: newUserName },
          ]);
        } else if (count === 2) {
          setImg2(url);
          setSelectedFiles([
            ...selectedFiles,
            { imgLink: url, userName: newUserName },
          ]);
        } else {
          setImg3(url);
          setSelectedFiles([
            ...selectedFiles,
            { imgLink: url, userName: newUserName },
          ]);
        }
        setOpenVert("");
      })
      .catch((error) => {
        //     // Handle error
        console.error(error);
      });
  };

  const handleSubmitAttachment = () => {
    // get picture url from server
    axios
      .put(`${backEndApi}oauth/addExUserAttachment?id=${id}`, {
        userAttachment: selectedFiles,
      })
      .then((res) => {
        dispatch(getUserData());
        Swal.fire({
          text: "Picture Updated Successfully",
          icon: "success",
          showCancelButton: false,
          customClass: {
            image: "sweetalert-image",
          },
        });
        setBioOpen(false);
        setOpenVert("");
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          text: "Picture Update failed...!",
          icon: "warning",
          showCancelButton: false,
        });
      });
  };

  const removeCamera = () => {
    setOpenCamera(false);
  };

  return (
    <div>
      <Dialog open={openCamera} className="dialog-box">
        <DialogTitle className="heading">Webcam Capture</DialogTitle>
        <Webcam className="handleCameraImage" audio={false} ref={webcamRef} />
        <ImageGallery img1={img1} img2={img2} img3={img3} />
        {!img3 && (
          <Button
            onClick={capture}
            variant="contained"
            endIcon={<LocalSeeIcon />}
            className="take-picture-webcam-attachment">
            Take Picture
          </Button>
        )}
        {img3 && (
          <Button
            onClick={handleSubmitAttachment}
            variant="contained"
            endIcon={<SendIcon />}
            className="take-picture-webcam-attachment">
            Send
          </Button>
        )}
        <Button
          onClick={removeCamera}
          variant="contained"
          endIcon={<CancelIcon />}
          className="take-picture-webcam-cancel-attachment">
          Cancel
        </Button>
      </Dialog>
    </div>
  );
};

// main component update picture
const AttachmentType = ({
  id,
  rowUserName,
  addAttachment,
  setAddAttachment,
  setBioOpen,
  setOpenVert,
  setUserData,
}) => {
  const [openCamera, setOpenCamera] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const handleOpenCamera = () => {
    setOpenCamera(true);
  };

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleClose = () => {
    setAddAttachment(false);
  };

  return (
    <Dialog open={true}>
      <DialogTitle>UPDATE BIO METRIC</DialogTitle>
      <Button
        variant="contained"
        endIcon={<AddAPhotoIcon />}
        className="update-img"
        onClick={handleOpenCamera}>
        Take Picture
      </Button>
      {openCamera && (
        <WebcamImage
          openCamera={openCamera}
          setOpenCamera={setOpenCamera}
          id={id}
          rowUserName={rowUserName}
          setBioOpen={setBioOpen}
          setOpenVert={setOpenVert}
        />
      )}
      <Button
        variant="contained"
        endIcon={<DownloadForOfflineIcon />}
        className="update-upload-img"
        onClick={handleOpenUpload}>
        Upload Image
      </Button>
      {openUpload && (
        <AddAttachment
          addAttachment={addAttachment}
          setAddAttachment={setAddAttachment}
          id={id}
          rowUserName={rowUserName}
          setUserData={setUserData}
          setOpenUpload={setOpenUpload}
          setOpenVert={setOpenVert}
          setBioOpen={setBioOpen}
        />
      )}
      <Button
        variant="contained"
        endIcon={<HighlightOffIcon />}
        className="update-close-img"
        onClick={handleClose}>
        close
      </Button>
    </Dialog>
  );
};

export default AttachmentType;
