import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import PhonelinkIcon from "@mui/icons-material/Phonelink";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AssessmentIcon from "@mui/icons-material/Assessment";
import StorageIcon from "@mui/icons-material/Storage";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <HomeIcon />,
  },
  {
    title: "Attendance",
    path: "/Attendance",
    icon: <HowToRegIcon />,
  },

  {
    title: "Assets",
    icon: <PhonelinkIcon />,
    iconClosed: <ExpandLess />,
    iconOpened: <ExpandMore />,

    subNav: [
      {
        title: "Assets Management",
        path: "/assetManagement",
        icon: <DragIndicatorIcon sx={{ marginLeft: "13px" }} />,
      },
      {
        title: "Assets Map List",
        path: "/assetsMapList",
        icon: <DragIndicatorIcon sx={{ marginLeft: "13px" }} />,
      },
    ],
  },

  {
    title: "Billing",
    path: "/cloudBilling",
    icon: <ReceiptLongIcon />,
  },
  {
    title: "Client Support",
    icon: <PhonelinkIcon />,
    iconClosed: <ExpandLess />,
    iconOpened: <ExpandMore />,

    subNav: [
      {
        title: "Tickets",
        path: "/clientSupport",
        icon: <ManageSearchIcon sx={{ marginLeft: "13px" }} />,
      },
      {
        title: "Dashboard",
        path: "/clientSupport/dashboard",
        icon: <DashboardIcon sx={{ marginLeft: "13px" }} />,
      },
    ],
  },
  {
    title: "DB Dump",
    path: "/dumpDB",
    icon: <StorageIcon />,
  },
  {
    title: "Documentation",
    icon: <LibraryBooksIcon />,
    iconClosed: <ExpandLess />,
    iconOpened: <ExpandMore />,

    subNav: [
      {
        title: "My Documents",
        path: "/MyDocuments",
        icon: <DragIndicatorIcon sx={{ marginLeft: "13px" }} />,
      },
      {
        title: "All Documentation",
        path: "/Documentation",
        icon: <DragIndicatorIcon sx={{ marginLeft: "13px" }} />,
      },
    ],
  },

  {
    title: "Testing tool",
    path: "/testCase",
    icon: <AssessmentIcon />,
  },
];
