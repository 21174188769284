import { Button } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import ViewIndividualTicket from "./ViewIndividualTicket";
import { Map } from "immutable";

export default function DashBoardTicketReports({ tickets, clientName }) {
  const [viewTicket, setViewTicket] = useState(false);
  const [issueData, setIssueData] = useState(Map());

  const handleView = (item) => {
    setIssueData(item);
    setViewTicket(true);
  };

  return (
    <table className="custom-table mb-4">
      <thead>
        <tr>
          <th scope="col">No</th>
          <th scope="col">Issue Type</th>
          <th scope="col">Status</th>
          <th scope="col">Priority</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        {tickets.map((item, index) => (
          <tr>
            <th scope="row">{index + 1}</th>
            <td>{item.get("type", "")}</td>
            <td>{item.get("status", "")}</td>
            <td>{item.get("priority", "")}</td>
            <td>
              <Button
                variant="contained"
                sx={{ width: "45%", height: "30px" }}
                onClick={() => handleView(item)}
              >
                view
              </Button>
            </td>
          </tr>
        ))}
        {viewTicket && (
          <ViewIndividualTicket
            open={viewTicket}
            setOpen={setViewTicket}
            issueData={issueData}
            clientName={clientName}
          />
        )}
      </tbody>
    </table>
  );
}
