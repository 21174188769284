import React, { useState, useEffect, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { AgTable } from "./AgTable";
import { ExecuteColumnDefs } from "./constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as action from "../../Redux/TestCase/Action";
import { connect } from "react-redux";
import { getRow } from "../../Utils/FunctionUtils";
import Snackbar from "../../LoaderSnackbar/Snackbar";
import Loader from "../../LoaderSnackbar/Loader";
import { selectIsLoading, selectOriginalTestCaseData, selectExecuted, selectJiraMemberList, selectMessage } from "../../Redux/TestCase/Selector";
const JiraTicketModel = React.lazy(() => import("./Modal/JiraTicketModel"));

function Execute({ loadingStatus, getJiraMemberList, executedStatus, getExecutedData, setData, postOriginalCase, testCaseList, postTestCaseStatus, postCreateJiraTicket, jiraMemberList, messageStatus }) {
  const location = useLocation();
  const [modelShow, setModelShow] = useState(false);
  const paramsId = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
  const [searchParams] = useSearchParams();
  const cloneKey = searchParams.get("cloneKey");
  searchParams.get("__firebase_request_key");
  const navigate = useNavigate();
  const [rowId, setRowId] = useState(false);
  const callBack = (res) => {
    const cloneKey = res.cloneKey;
    localStorage.clear();
    navigate(`/testCase/execute/${paramsId}?cloneKey=${cloneKey}`);
  };
  const executedCallBack = () => {
    setData({ executed: false });
  };
  const handleClose = (save = false) => {
    setModelShow(false);
  };
  useEffect(() => {
    if (cloneKey !== null) getExecutedData(paramsId, cloneKey, executedCallBack);
  }, [cloneKey]); // eslint-disable-line
  useEffect(() => {
    getTableData();
    setData({ executed: false });
  }, []); // eslint-disable-line

  const getTableData = () => {
    if (cloneKey === null && executedStatus) {
      let payload = JSON.parse(localStorage.getItem("reason"));
      payload.id = paramsId;
      postOriginalCase(payload, callBack);
    }
    if (cloneKey !== null) getExecutedData(paramsId, cloneKey, executedCallBack);
  };

  const handleExecute = (id) => {
    navigate(`/testCase/viewTestCase?id=${id}${cloneKey === null ? "" : `&cloneKey=${cloneKey}`}`);
  };
  const handleSelect = (value, id, previousData) => {
    const payload = {
      status: value,
      id: id,
    };
    setRowId(id);
    if (previousData === "Not-verified" && value === "Failed") {
      getJiraMemberList(() => {
        setModelShow(true);
        postTestCaseStatus(payload, getTableData);
      });
    } else {
      postTestCaseStatus(payload, getTableData);
    }
  };
  return (
    <>
      {/* id */}
      <Snackbar show={true} message={messageStatus} reduxSetData={setData}/>
      <Loader isLoading={loadingStatus} />
      {modelShow && (
        <Suspense fallback={"Loading... "}>
          <JiraTicketModel close={handleClose} show={modelShow} id={rowId} setData={setData} postCreateJiraTicket={postCreateJiraTicket} options={jiraMemberList} />{" "}
        </Suspense>
      )}
      <div className="my-2"></div>

      <AgTable columnDefs={ExecuteColumnDefs(handleExecute, handleSelect)} rowData={getRow(testCaseList.data, false)} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingStatus: selectIsLoading(state),
    messageStatus: selectMessage(state),
    testCaseList: selectOriginalTestCaseData(state).toJS(),
    executedStatus: selectExecuted(state),
    jiraMemberList: selectJiraMemberList(state).toJS(),
  };
};

export default connect(mapStateToProps, action)(Execute);
