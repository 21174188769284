import React from "react";
import { Accordion, AccordionDetails, Tooltip } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  useStyles,
  IconLeftAccordionSummary,
} from "../../Styles/Document/DocumentStyle";
import { List } from "immutable";
import { useSelector } from "react-redux";
import { selectUsers, selectDepartment } from "../../Redux/TestCase/Selector";

const DocumentAccordion = ({ data, expanded, handleAccordion }) => {
  const classes = useStyles();

  const departmentList = useSelector(selectDepartment);
  const userList = useSelector(selectUsers);
  const FilteredDepartment = departmentList.filter((item) =>
    data?.get("shareWithDepartment", List()).includes(item.get("_id", ""))
  );
  const FilteredUser = userList.filter((item) =>
    data?.get("shareWithUser", List()).includes(item.get("_id", ""))
  );

  return (
    <Accordion
      expanded={expanded}
      onChange={handleAccordion}
      sx={{ marginTop: "10px", background: "#E8EEF0", minWidth: "100%" }}
      disableGutters
    >
      <IconLeftAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <div>ALL DETAILS</div>
      </IconLeftAccordionSummary>
      <AccordionDetails sx={{ background: "white" }}>
        <div className={classes.AccordionRow}>
          {data.get("sender", "") !== "" && (
            <>
              <div className={classes.AccordionCol}>
                <label className={classes.AccordionLabel}>SENDER : </label>
                <label className={classes.AccordionLabel2}>
                  {data.get("sender", "")}
                </label>
              </div>
              <div className={classes.AccordionCol}>
                <label className={classes.AccordionLabel}>DESCRIPTION : </label>
                <label className={classes.AccordionLabel2}>
                  {data.get("description", "")}
                </label>
              </div>
            </>
          )}
        </div>
        <div className={classes.AccordionRow}>
          <div className={classes.AccordionCol}>
            <label className={classes.AccordionLabel}>DEPARTMENT NAME : </label>
            <label className={classes.AccordionLabel2}>
              {data.getIn(["DepartmentName", "label"], "")}
            </label>
          </div>
          <div className={classes.AccordionCol}>
            <label className={classes.AccordionLabel}>PRODUCT NAME :</label>
            <label className={classes.AccordionLabel2}>
              {data.getIn(["ProductName", "label"], "")}
            </label>
          </div>
        </div>
        <div className={classes.AccordionRow}>
          <div className={classes.AccordionCol}>
            <label className={classes.AccordionLabel}>DOCUMENT NAME : </label>
            <label className={classes.AccordionLabel2}>
              {data.get("DocumentName", "")}
            </label>
          </div>
          <div className={classes.AccordionCol}>
            <label className={classes.AccordionLabel}>DOCUMENT TAGS : </label>
            <label className={classes.AccordionLabel2}>
              {data
                .get("DocumentTags", List())
                ?.map((item) => item.get("label", "") + ",")}
            </label>
          </div>
        </div>
        <div className={classes.AccordionRow}>
          {data.get("shareWithDepartment", List()).size !== 0 && (
            <div className={`d-flex  ${classes.AccordionCol}`}>
              <label className={classes.AccordionLabel}>
                SHARED DEPARTMENTS :{" "}
              </label>
              <label
                className={`d-flex flex-row w-50 ${classes.AccordionLabel2}`}
              >
                {FilteredDepartment.map((item) =>
                  item.get("departmentName", "")
                )}
                {FilteredDepartment.size > 2 && (
                  <Tooltip
                    title={
                      <h1 style={{ fontSize: "15px" }}>
                        {FilteredDepartment.map(
                          (innerItem, innerIndex) =>
                            innerItem.get("departmentName", "") + ","
                        )}
                      </h1>
                    }
                    arrow
                    placement="top"
                  >
                    <div className="text-primary ms-2">View All</div>
                  </Tooltip>
                )}
              </label>
            </div>
          )}
          {data.get("shareWithUser", List()).size !== 0 && (
            <div className={`d-flex  ${classes.AccordionCol}`}>
              <label className={classes.AccordionLabel}>SHARED USERS : </label>
              <label
                className={`d-flex flex-row w-50 ${classes.AccordionLabel2}`}
              >
                {FilteredUser.map((item) => item.get("username", "") + ",")}
                {FilteredUser.size > 1 && (
                  <Tooltip
                    title={
                      <h1 style={{ fontSize: "15px" }}>
                        {FilteredUser.map(
                          (innerItem, innerIndex) =>
                            innerItem.get("username", "") + ","
                        )}
                      </h1>
                    }
                    arrow
                    placement="top"
                  >
                    <div className="text-primary ms-2">View All</div>
                  </Tooltip>
                )}
              </label>
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
export default DocumentAccordion;
