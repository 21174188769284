import { List, Map } from "immutable";
const Documentation = (state) => state.Documentation;

const selectIsLoading = (state) => Documentation(state).get("isLoading", false);
const selectMessage = (state) => Documentation(state).get("message", "");
const selectAllOption = (state) => Documentation(state).get("allOption", List());
const selectNotification = (state) => Documentation(state).get("notification", List());
const selectDocumentList = (state) => Documentation(state).get("documentList", Map());
const selectMyDocumentList = (state) => Documentation(state).get("myDocumentList", Map());
const selectSeparateDocument = (state) => Documentation(state).get("separateDocument", Map());

export { selectIsLoading, selectMessage, selectAllOption, selectNotification, selectDocumentList, selectMyDocumentList, selectSeparateDocument };
