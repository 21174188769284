import React, { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { Map, fromJS } from "immutable";
// import GoogleLogin from "react-google-login";
// import { gapi } from "gapi-script";
import LoginForm from "./LoginForm";
import "./login.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getOauth2UserInfo,
  getGoogleAuthDetails,
  setData,
} from "../../Redux/TestCase/Action";
import {
  selectAuthType,
  selectGoogleAuthDetails,
} from "../../Redux/TestCase/Selector";
import logo from "../../Assets/logo.svg";
import { SendRequest } from "../Firebase/firebase";

const Login = () => {
  const location = useLocation();
  const mongoId = new URLSearchParams(location.search).get("id");
  const [showRegister, setShowRegister] = useState({ show: false, API: false });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const googleAuthDetails = useSelector(selectGoogleAuthDetails);
  const profileImmutable = useSelector(selectAuthType);
  const profile =
    profileImmutable === "" ? profileImmutable : profileImmutable?.toJS();

  const clearGoogleAuth = () =>
    dispatch(setData(fromJS({ googleAuthDetails: Map() })));

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (codeResponse) => {
      dispatch(getGoogleAuthDetails(codeResponse.code));
    },
    onError: (errorResponse) => console.log("Login Failed", errorResponse),
  });

  const loginCallBack = (data, type = "") => {
    if (type !== "") {
      setShowRegister((prev) => {
        return { ...prev, show: true };
      });
    } else if (data.role !== "") {
      if (data.role === "Admin") {
        navigate("/admin/");
      } else if (!data.isActive && data.attachment?.length === 0) {
        navigate(`/auth/login?id=${data._id}`);
      } else if (data.isActive) {
        navigate("/");
        if (data.role === "Clientsupport") {
          SendRequest(data.email, data.username, data._id);
        }
      } else {
        navigate("/auth/pendingApproval/");
      }
    } else {
      setShowRegister((prev) => {
        return { ...prev, show: true };
      });
    }
  };

  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem("access_token"));
    if (mongoId) {
      dispatch(
        getOauth2UserInfo(accessToken, (data) => loginCallBack(data, "mongoId"))
      );
      setShowRegister((prev) => {
        return { ...prev, API: true };
      });
      clearGoogleAuth();
    }
  }, [mongoId]); //eslint-disable-line

  useEffect(() => {
    const initialLoginAccessToken = googleAuthDetails.get("access_token", "");
    if (initialLoginAccessToken) {
      dispatch(getOauth2UserInfo(initialLoginAccessToken, loginCallBack));
      setShowRegister((prev) => {
        return { ...prev, API: true };
      });
      clearGoogleAuth();
    }
  }, [googleAuthDetails]); //eslint-disable-line

  return (
    <div className="digi-login-page">
      <div className="digi-info">
        <div className="header">Welcome to Digival Solutions</div>
        <div className="sub-header">
          {" "}
          We build products for educational institutions to stay relevant for
          the present and future digital world.
        </div>
        <div className="sub-header">
          {" "}
          Digival IT Solutions Private Limited is founded to transform
          educational institutions to stay relevant for the present and future
          digital world by providing them relevant, personalized, appropriate,
          and cost justified products, solutions, and training. “Our products,
          solutions, & training enable the educational institutions to achieve
          the learning outcomes by enhancing the quality of programs{" "}
        </div>
      </div>
      <div style={{ width: "50%" }}>
        <div>
          {Object.keys(profile).length > 0 && showRegister.API ? (
            <div>
              {showRegister.show && (
                <LoginForm user={profile} urlMongoId={mongoId} />
              )}
            </div>
          ) : (
            <div className="registration-page">
              <div className="login-container">
                <img src={logo} alt="logo" />
                <h1 className="login-title">Log in to your account</h1>
                <div
                  id="google-signin-button"
                  className="login-button"
                  onClick={login}>
                  <div className="google-logo" />
                  <div className="button-text">Sign in with Google</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
