import React ,{useState} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Card, IconButton, Tooltip } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  deleteAssetMappingApi,
  EditMapIDAction,
  EditMapIndexAction,
} from "../../../Redux/AssetsManagement/Action";
// import MapPopup from "./MapPopup";
import { styled } from "@mui/material/styles";
import { useSweetAlert } from "../../../Utils/Sweetalert";
import Pagination from "../../../Utils/Pagination";

// const useStyles = makeStyles({
//   thead: {
//     textAlign: "center!important",
//     fontSize: "18px!important",
//     fontWeight: "500!important",
//     textTransform: "uppercase!important",
//   },
//   tbody: {
//     textAlign: "center!important",
//   },
// });

const AssetsMapList = ({ setOpen }) => {
  const sweetAlert = useSweetAlert();

  const MapList = useSelector((state) => state.AssetsManagement).get(
    "getAssetsMapList",
    ""
  );

  // const classes = useStyles();

  const dispatch = useDispatch();
  const [paginationSplice, setPaginationSplice] = useState({ start: 0, end: 5 });

  const handelDelete = (id) => {
    // dispatch(deleteAssetMappingApi(id));
    sweetAlert({ id, ApiCall: deleteAssetMappingApi });
  };

  const handleEdit = (id, index) => {
    dispatch(EditMapIDAction(id));
    dispatch(EditMapIndexAction(index));
    setOpen(true);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      color: theme.palette.common.white,
      fontSize: "20px",
      fontWight: "600",
      backgroundColor: "#1976D2",
      textTransform: "capitalize",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "10px 16px",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  return (
    <>
    <Card>
      <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell>No</StyledTableCell>
              <StyledTableCell>Employee Name</StyledTableCell>
              <StyledTableCell>Asset Type</StyledTableCell>
              <StyledTableCell>Recieved Date</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {/* StyledTableRow */}
            {MapList.slice(paginationSplice.start, paginationSplice.end).map((item, index) => {
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{index + 1}</StyledTableCell>
                  <StyledTableCell>
                    {item.get("employeeName", "")}
                  </StyledTableCell>
                  <StyledTableCell>{item.get("assetType", "")}</StyledTableCell>
                  <StyledTableCell>
                    {new Date(
                      item.get("receivedDate", "")
                    ).toLocaleDateString()}
                  </StyledTableCell>
                  {/* <TableCell className={classes.tbody}>
                    {item.get("returnDate", "") !== null  ?  item.get("returnDate", "") : '---'}
                  </TableCell> */}
                  <StyledTableCell>
                    <div className="d-flex">
                      <Tooltip title="Edit" arrow placement="top">
                        <IconButton
                          onClick={() => handleEdit(item.get("_id", ""), index)}
                          sx={{ marginRight: "18px" }}
                        >
                          <BorderColorIcon color="success" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete" arrow placement="top">
                        <IconButton
                          onClick={() => handelDelete(item.get("_id", ""))}
                        >
                          <DeleteForeverIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
    <Pagination handleChange={setPaginationSplice} count={MapList.size} />

    </>
  );
};

export default AssetsMapList;
