import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Box, Button } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import "./userPopup.css";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const userPopup = ({ userOpen, setUserOpen, userData, user }) => {
  const handleClose = () => {
    setUserOpen(false);
  };
  const logOut = () => {
    localStorage.clear();
    window.location.href = "/auth/login";
  };
  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={userOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "320px", // Set your width here
              height: "400px",
              position: "absolute",
              right: "0",
              top: "20px",
              borderRadius: "10px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          // onClose={handleClose}
          sx={{
            height: "100px",
            background:
              "linear-gradient(97deg, rgb(26, 123, 220), rgb(86, 184, 255)) 0px 0px no-repeat padding-box padding-box transparent",
            boxShadow: 2,
          }}
        >
          <Avatar
            alt="user"
            sx={{
              width: "90px",
              height: "90px",
              border: "3px solid white",
              position: "absolute",
              top: "100px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 2,
            }}
            src={userData.picture}
          />
        </BootstrapDialogTitle>
        <DialogContent>
          <Box style={{ height: "calc(100% - 100px)", padding: "50px 5px" }}>
            <h4 style={{ textAlign: "center", textTransform: "uppercase" }}>
              {user?.username}
            </h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                // gap: "5px",
              }}
            >
              <div style={{ height: "58px" }}>
                <label
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    color: "#CECECE",
                    textTransform: "uppercase",
                  }}
                >
                  Role
                </label>
                <p style={{ fontSize: "16px", fontWeight: "600" }}>
                  {user?.role}
                </p>
              </div>
              {user?.email && (
                <div>
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#CECECE",
                      textTransform: "uppercase",
                    }}
                  >
                    Email
                  </label>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>
                    {user?.email}
                  </p>
                </div>
              )}
              {user?.email && (
                <div>
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#CECECE",
                      textTransform: "uppercase",
                    }}
                  >
                    Department
                  </label>
                  <p style={{ fontSize: "16px", fontWeight: "600" }}>
                    {user?.email}
                  </p>
                </div>
              )}
            </div>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Button
                variant="contained"
                sx={{ background: "#1976D2" }}
                startIcon={<LogoutIcon />}
                onClick={logOut}
              >
                LogOut
              </Button>
            </div>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
};

export default userPopup;
