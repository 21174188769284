import React, { useState } from 'react'
import { BootstrapDialog, BootstrapDialogTitle } from "../../TestCase/Styles";
import DialogContent from "@mui/material/DialogContent";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import {
    Button
} from "@mui/material";

const DateRange = ({ open, handleClose, handleSubmit }) => {
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })
    const handleSelect = (date) => {
        setSelectionRange(date.selection)
    }

    const handleSearch = () => {
        handleSubmit(selectionRange.startDate.toISOString(), selectionRange.endDate.toISOString())
    }
    return (
        <div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            // width: "100%",
                            maxWidth: "100%", // Set your width here
                            height: "80%",
                        },
                    },
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleClose}
                    className='bold'
                >
                    Date Range Selection
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <DateRangePicker
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                    />
                    <div className='d_all_center'>
                        <Button className='primary m-2 popup_tr' onClick={handleSearch}>Search</Button>
                        <Button className='primary m-2 popup_tr' onClick={handleClose}>cancel</Button>
                    </div>
                </DialogContent>
            </BootstrapDialog>


        </div>
    )
}

export default DateRange
