import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, TextField } from "@mui/material";
import { useDispatch } from "react-redux";

import {
  getDocumentsType,
  postCreateDocumentsType,
  updateDocType,
} from "../../../Redux/DocumentManagement/Action";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Popup = ({
  open,
  setOpen,
  type,
  setType,
  Update,
  setUpdate,
  setIsShown,
  formData,
  setFormData,
}) => {
  const [value, setValue] = useState("");
  console.log(value,value);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
    setType("");
    setUpdate("");
    setIsShown(false);
  };

  const callBack = () => {
    setOpen(false);
    setType("");
    setUpdate("");
    setIsShown(false);
    dispatch(getDocumentsType());

    // setFormData((prev) => { return prev.set(type, type === "Content" ? value.getData() : fromJS(value))})
  };
  const UpdateCallBack = () => {
    setOpen(false);
    setType("");
    setUpdate("");
    setIsShown(false);
    dispatch(getDocumentsType());
    if (type === "Tags") {

      let index = formData
        .get("DocumentTags", "")
        .findIndex((item) => item.get("label") === Update.get("documentTags",""));
        formData.get("DocumentTags").size > 0 &&
      setFormData((prev) => {
        return prev.setIn(["DocumentTags",index,"label"] ,value);
      });
    } else {
      // formData.getIn(["ProductName", "label"], "") === value &&
      formData.get("ProductName").size > 0 &&
        setFormData((prev) => {
          return prev.setIn(["ProductName","label"] ,value);
        });
    }
  };

  const handleSubmit = () => {
    const payload = {
      value,
      type,
    };

    if (Update !== "") {
      const UpdateLoad = {
        type,
        id: Update.get("_id", ""),
        newData: value,
      };

      dispatch(updateDocType(UpdateLoad, UpdateCallBack));
    } else {
      dispatch(postCreateDocumentsType(payload, callBack));
    }
  };

  const handleChange = (onChangeValue) => {
    setValue(onChangeValue.target.value);
  };

  useEffect(() => {
    if (Update !== "") {
      if (type === "Tags") {
        setValue(Update.get("documentTags", ""));
      } else {
        setValue(Update.get("productName", ""));
      }
    }
  }, [Update]); //eslint-disable-line
  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "400px", // Set your width here
              height: "250px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Add New
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            style={{ height: "100%" }}
            className="d-flex justify-content-center align-items-center"
          >
            <TextField
              id="outlined-basic"
              label={`${type} Name`}
              variant="outlined"
              autoComplete="none"
              sx={{ width: "100%" }}
              value={value}
              onChange={handleChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => handleSubmit()}
            disabled={value === ""}
          >
            {Update !== "" ? "Update" : "Submit"}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
};

export default Popup;
