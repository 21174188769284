import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Row, Col } from "react-bootstrap";
import { validateReason } from "../../../Utils/Validation";
import { Select, MenuItem } from "@mui/material";

export const ExecutionReason = ({ close, show, id, callBack, setData }) => {
  const [reason, setReason] = useState({
    executed: "",
    reason: "",
    id: id,
  });
  const options = [
    {
      value: "",
      name: "select",
    },
    {
      value: "Tharani",
      name: "Tharani",
    },
    {
      value: "Vasudev",
      name: "Vasudev",
    },
    {
      value: "Noorul",
      name: "Noorul",
    },
    {
      value: "Manobala",
      name: "Manobala",
    },
    {
      value: "Adil",
      name: "Adil",
    },
  ];
  const handleChange = (value, key) => {
    setReason({ ...reason, [key]: value });
  };

  const handleSave = () => {
    const validation = validateReason(reason);
    if (validation !== "") {
      setData({ message: validation });
      return;
    }
    localStorage.setItem("reason", JSON.stringify(reason));
    callBack();
    close(true);
  };

  return (
    <Modal show={show} onHide={close} dialogClassName="modal-900" centered>
      <Modal.Header closeButton className="bg-gray">
        <Modal.Title>Reason for Execution</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 f-16 m-3">
        <Row className="m-1">
          <Col lg={6}>Executed by</Col>
          <Col lg={6}>
            <Select className="reason_select table_select" value={reason.executed} onChange={(e) => handleChange(e.target.value, "executed")}>
              {options.map((value) => (
                <MenuItem value={value.value}>{value.name}</MenuItem>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="m-1">
          <Col lg={6}>Execution Name</Col>
          <Col lg={6}>
            <input type="text" onChange={(e) => handleChange(e.target.value, "reason")} className="w-100"></input>
          </Col>
        </Row>
        <div className="w-100 d-flex align-items-center justify-content-center">
          <button className="btn-primary m-2 p-1" onClick={() => handleSave()}>
            save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ExecutionReason.prototype = {
  show: PropTypes.bool,
  close: PropTypes.func,
  id: PropTypes.bool,
  callBack: PropTypes.func,
};
