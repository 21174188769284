import { useSearchParams } from "react-router-dom"; //useNavigate, useLocation,
import React, { useEffect, useState, Suspense } from "react";
import * as action from "../../Redux/TestCase/Action";
import { connect } from "react-redux";
import Snackbar from "../../LoaderSnackbar/Snackbar";
import Loader from "../../LoaderSnackbar/Loader";
import { Row, Col } from "react-bootstrap";
import { Select, MenuItem } from "@mui/material";
import { RecordRTCPromisesHandler } from "recordrtc";
import { ShowMultiMedia } from "./Modal/ShowMultiMedia";
import ImageView from "../../Utils/ImageView";
import pin from "../../Assets/pin.png";
import { selectIsLoading, selectJiraMemberList, selectMessage } from "../../Redux/TestCase/Selector";
const JiraTicketModel = React.lazy(() => import("./Modal/JiraTicketModel"));

function ViewTestCase({ loadingStatus, getSingleCaseData, postExecutedVideo, getJiraMemberList, postUploadFile, patchActualResultUpdate, postTestCaseStatus, postCreateJiraTicket, setData, jiraMemberList, messageStatus }) {
  const [singleData, setSingleData] = useState({});
  const [recorder, setRecorder] = useState(null);
  const [stream, setStream] = useState(null);
  const [payload, setPayload] = useState({
    index: "",
    id: "",
    key: "",
    value: "",
  });
  //   const [videoBlob, setVideoUrlBlob] = useState(null);
  const [modelShow, setModelShow] = useState(false);
  const [viewMultiMedia, setViewMultiMedia] = useState({
    modelShow: false,
    videoURL: "",
  });
  const [searchParams] = useSearchParams();
  const caseId = searchParams.get("id");
  const cloneKey = searchParams.get("cloneKey");
  const options = [
    {
      value: "Not-verified",
      name: "Not-verified",
    },
    {
      value: "Open",
      name: "Open",
    },
    {
      value: "Re-open",
      name: "Re-open",
    },
    {
      value: "Hold",
      name: "Hold",
    },
    {
      value: "Passed",
      name: "Passed",
    },
    {
      value: "Failed",
      name: "Failed",
    },
  ];
  const callBack = (data) => {
    setSingleData(data);
  };

  useEffect(() => {
    getSingleCaseData(caseId, cloneKey, callBack);
  }, []); // eslint-disable-line

  const handleExecute = () => {
    stream === null ? startRecording() : stopRecording();
  };

  const postCallBack = () => {
    setTimeout(() => {
      getSingleCaseData(caseId, cloneKey, callBack);
    }, 1500);
  };
  const startRecording = async () => {
    const mediaDevices = navigator.mediaDevices;
    if (navigator.mediaDevices === undefined) {
      setData({
        message: "Your browser does NOT support the Screen record API.",
      });
    }
    const stream = await mediaDevices.getDisplayMedia({
      video: true,
      audio: false,
    });

    const recorder = new RecordRTCPromisesHandler(stream, {
      type: "video",
      frameInterval: 100,
    });
    await recorder.startRecording();
    setRecorder(recorder);
    setStream(stream);
  };

  const stopRecording = async () => {
    await recorder.stopRecording();
    const blob = await recorder.getBlob();
    stream.stop();
    // setVideoUrlBlob(blob);
    setStream(null);
    const file = new FormData();
    const mp4File = new File([blob], "demo.mp4", { type: "video/mp4" });
    file.append("file", mp4File);
    file.append("id", caseId);
    postExecutedVideo(file, postCallBack);
  };
  const handleChange = (value) => {
    const payload = {
      status: value,
      id: caseId,
    };
    if (singleData.status === "Not-verified" && value === "Failed") {
      getJiraMemberList(() => {
        setModelShow(true);
        postTestCaseStatus(payload, postCallBack);
      });
    } else {
      postTestCaseStatus(payload, postCallBack);
    }
  };
  const handleClose = (save = false) => {
    setModelShow(false);
  };
  const handleCloseMedia = (save = false) => {
    setViewMultiMedia((previousData) => {
      return { ...previousData, modelShow: false };
    });
  };

  const handleChangeActual = (value, key, index) => {
    if (key === "attachment") {
      singleData.ActualResult[index].attachment.length > 0 && (value = singleData.ActualResult[index].attachment + "," + value);
    }
    setPayload({
      index,
      id: caseId,
      key,
      value,
    });
    setSingleData((prev) => {
      prev.ActualResult[index][key] = value;
      return prev;
    });
  };

  const postFileCallBack = (value, index) => {
    handleChangeActual(value.replaceAll(" ", "_"), "attachment", index);
  };
  const handlePostUploadFile = (value, index) => {
    const file = new FormData();
    file.append("file", value);
    postUploadFile(file, (value) => postFileCallBack(value, index));
  };

  useEffect(() => {
    const timeout =
      payload.id &&
      setTimeout(() => {
        patchActualResultUpdate(payload);
      }, [1500]);
    return () => {
      clearTimeout(timeout);
    };
  }, [payload]); // eslint-disable-line

  const getDateDiff = () => {
    var createdTime = singleData.createdAt;
    createdTime = new Date(createdTime);
    return createdTime.getDate() + "/" + (createdTime.getMonth() + 1) + "/" + createdTime.getFullYear() + "  " + createdTime.getHours() + ":" + createdTime.getMinutes();
  };
  return (
    <>
      <Snackbar show={true} message={messageStatus} reduxSetData={setData}/>
      <Loader isLoading={loadingStatus} />
      {viewMultiMedia.modelShow && <ShowMultiMedia close={handleCloseMedia} show={viewMultiMedia.modelShow} url={viewMultiMedia.videoURL} title={singleData.TestCaseID}></ShowMultiMedia>}
      {modelShow && (
        <Suspense fallback={"Loading... "}>
          <JiraTicketModel close={handleClose} show={modelShow} id={caseId} setData={setData} postCreateJiraTicket={postCreateJiraTicket} options={jiraMemberList} />{" "}
        </Suspense>
      )}
      <div className="d-sm-flex flex-column align-items-center justify-content-center mb-4">
        <h1 className="h3 mb-0 text-dark-800 mt-5">Test Case overview</h1>
        <Row className="m-1 w-90 m-3">
          <Col xs={6} className="viewCaseColumn">
            <Row>
              <Col xs={6}>
                <label>Features Name:</label>
                <label className="bold ms-2">{singleData.featuresName}</label>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <label>Version:</label>
                <label className="bold ms-2">{singleData.version}</label>
              </Col>
            </Row>
            {singleData?.executionReason?.reason && (
              <Row>
                <Col xs={6}>
                  <label>Execution Reason:</label>
                  <label className="bold ms-2">{singleData?.executionReason?.reason}</label>
                </Col>
              </Row>
            )}
            {cloneKey !== null && (
              <Row>
                <Col xs={6}>
                  <label>Clone Key:</label>
                  <label className="bold ms-2">{singleData.cloneKey}</label>
                </Col>
              </Row>
            )}

            <Row>
              <Col xs={6}>
                <label>Test case Description:</label>
                <label className="bold ms-2">{singleData.TestCaseDescription}</label>
              </Col>
            </Row>
          </Col>
          <Col xs={6} className="viewCaseColumn">
            <Row>
              <Col xs={6}>
                <label>Module:</label>
                <label className="bold ms-2">{singleData.module}</label>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <label>Test CaseID:</label>
                <label className="bold ms-2">{singleData.TestCaseID}</label>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <label>Date:</label>
                <label className="bold ms-2">{getDateDiff()}</label>
              </Col>
            </Row>
            {cloneKey !== null && (
              <Row>
                <Col xs={6} className="d-flex flex-row">
                  <label>status:</label>
                  <Select className="reason_select table_select ms-2" value={singleData.status === undefined ? "" : singleData.status} onChange={(e) => handleChange(e.target.value)}>
                    {options?.map((value) => (
                      <MenuItem value={value.value}>{value.name}</MenuItem>
                    ))}
                  </Select>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={6}>
                <label>Videos:</label>
                <label className="bold ms-2">
                  {singleData.videoLink?.length > 0
                    ? singleData.videoLink.map((item, index) => {
                        return (
                          <span
                            onClick={() =>
                              setViewMultiMedia((previousData) => {
                                return {
                                  videoURL: item.RecordVideoLink,
                                  modelShow: true,
                                };
                              })
                            }
                            className=" color_blue"
                          >
                            Video {index + 1} ,
                          </span>
                        );
                      })
                    : "No video"}
                </label>
              </Col>
            </Row>
          </Col>
          <Col xs={6}></Col>
        </Row>
        <Row>
          <table className="stepView">
            <thead>
              <tr>
                <td className="stepView_gray bold">Steps</td>
                <td className="stepView_gray bold">Expected Result</td>
                {cloneKey !== null && <td className="stepView_gray bold">Actual Result</td>}
              </tr>
            </thead>
            <tbody>
              {singleData.Steps?.map((item, index) => {
                return (
                  <tr>
                    <td className="p-2">{item}</td>
                    <td className="p-2">{singleData.ExpectedResult[index]}</td>
                    {cloneKey !== null && (
                      <td className="p-2">
                        {/* {singleData.ActualResult[index].text} */}

                        <input className="form-control text_box width275" type="text" value={singleData.ActualResult[index]?.text} onChange={(e) => handleChangeActual(e.target.value, "text", index)} placeholder="Enter Actual Result.."></input>
                        <label for="file-input" className="pin_position">
                          <img src={pin} alt="Attachment" width={10} height={20} />
                        </label>
                        <input className="form-control text_box d-none" type="file" onChange={(e) => handlePostUploadFile(e.target.files[0], index)} id="file-input"></input>
                        {singleData.ActualResult[index]?.attachment && singleData.ActualResult[index]?.attachment !== "" && <ImageView imageList={singleData.ActualResult[index]?.attachment} />}
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Row>
        {cloneKey !== null && (
          <button className="btn-primary p-1 m-2" onClick={handleExecute}>
            {stream === null ? "Execute Now" : "Stop"}
          </button>
        )}
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loadingStatus: selectIsLoading(state),
    messageStatus: selectMessage(state),
    jiraMemberList: selectJiraMemberList(state).toJS(),
  };
};

export default connect(mapStateToProps, action)(ViewTestCase);
