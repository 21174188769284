import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setData,
  getAllUserWithDate,
  getSpecificDateDetail,
} from "../../../Redux/AttendanceManagement/Action";
import {
  selectIsLoading,
  selectMessage,
  selectDateWiseDate,
  selectAllUser,
} from "../../../Redux/AttendanceManagement/Selector";
// import { List } from "immutable";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownload from "@mui/icons-material/FileDownload";
import Snackbar from "../../../LoaderSnackbar/Snackbar";
import Loader from "../../../LoaderSnackbar/Loader";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../Styles/Billing/TableDesign";
import Pagination from "../../../Utils/Pagination";
// import DateWishTable from "../Model/dateWishTable.js";
import DateRange from '../Model/dateRange';
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import setTableData from '../../../Utils/TableDataChanges'

const Attendance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadingStatus = useSelector(selectIsLoading);
  const messageStatus = useSelector(selectMessage);
  const dateWiseData = useSelector(selectDateWiseDate);
  const allUsers = useSelector(selectAllUser);
  //const popupData = useSelector(selectSpecificDateDetail);

  const [popupView, setPopupView] = useState(false);
  const [filterDate, setFilterDate] = useState({ start: '', end: '', ISOstart: '', ISOend: '' });
  const [paginationSplice, setPaginationSplice] = useState({
    start: 0,
    end: 5,
  });
  const sortingDate = (data) => {
    return data.sort(function (a, b) {
      console.log(a.get("_id", ""));
      const dateA = a.get("_id", "").split("/").map(Number);
      const dateB = b.get("_id", "").split("/").map(Number);

      if (dateA[2] !== dateB[2]) {
        return dateB[2] - dateA[2]; // Compare years
      }

      if (dateA[1] !== dateB[1]) {
        return dateB[1] - dateA[1]; // Compare months
      }

      return dateB[0] - dateA[0]; // Compare days
    });
  };

  useEffect(() => {
    dispatch(getAllUserWithDate());
  }, []); //eslint-disable-line

  const downloadCallback = (date) => {
    const { presentData, absentData } = setTableData(date, dateWiseData, allUsers);
    downloadExcel(presentData.concat(absentData).toJS(), date);
  };

  const downloadExcel = (data, filename) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };
  const handleView = (date) => {
    navigate(`/Attendance/ViewByDate?date=${date}`)
  };

  const handleDownload = (date) => {
    dispatch(getSpecificDateDetail(date, () => downloadCallback(date)));
  };

  const handleClosePopup = () => {
    setPopupView(false);
  };

  const splitDate = (value) => {
    return value.split('T')[0].split('-').reverse().join('/')
  }
  const addOneDay = (value) => {
    const date = new Date(value);
    date.setDate(date.getDate() + 1);
    const updatedDateString = date.toISOString();
    return updatedDateString
  }

  const handleDayFilter = (start, end) => {
    setFilterDate({
      start: splitDate(addOneDay(start)),
      end: splitDate(addOneDay(end)),
      ISOstart: start,
      ISOend: end
    })
    setPopupView(false);
  }
  useEffect(() => {
    filterDate.ISOstart !== '' && filterDate.ISOend !== '' && dispatch(getAllUserWithDate(filterDate.ISOstart, addOneDay(filterDate.ISOend)));
  }, [filterDate]); //eslint-disable-line

  return (
    <div className="container-fluid">
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <Box sx={{ width: "100%", height: "50px" }} className="mt-4">
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          {filterDate.start !== '' && filterDate.end !== '' && <div className="d_all_center">
            <div className='mx-4'>
              <label>From:</label>
              <span>{filterDate.start}</span>
            </div>
            <div>
              <label>To:</label>
              <span>{filterDate.end}</span>
            </div>
            <Button className='primary popup_tr mx-4 ' onClick={() => setFilterDate({
              start: '',
              end: ''
            })}>Clear</Button>
          </div>
          }

          <Button className='primary popup_tr me-2' onClick={() => setPopupView(true)}>DateWise filter</Button>
        </Box>
      </Box>


      <Card>
        <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell>No</StyledTableCell>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Total count</StyledTableCell>
                <StyledTableCell>Present count</StyledTableCell>
                <StyledTableCell>Absent count</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortingDate(dateWiseData)
                .slice(paginationSplice.start, paginationSplice.end)
                .map((item, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>{item.get("_id", "")}</StyledTableCell>
                      <StyledTableCell className="d_all_center">
                        {allUsers?.size}
                      </StyledTableCell>
                      <StyledTableCell className="d_all_center">
                        {item.get("data", "")?.size}
                      </StyledTableCell>
                      <StyledTableCell className="d_all_center">
                        {allUsers?.size - item.get("data", "")?.size}
                      </StyledTableCell>
                      <StyledTableCell className="d_all_center">
                        <Tooltip title="View" arrow placement="top">
                          <IconButton
                            onClick={() => handleView(item.get("_id", ""))}
                            sx={{ marginRight: "18px" }}
                          >
                            <VisibilityIcon sx={{ color: "#1976D2" }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Download" arrow placement="top">
                          <IconButton
                            onClick={() => handleDownload(item.get("_id", ""))}
                            sx={{ marginRight: "18px" }}
                          >
                            <FileDownload sx={{ color: "#1976D2" }} />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Pagination
        handleChange={setPaginationSplice}
        // count={filteredTableData.size}
        count={dateWiseData?.size}
      />
      {
        popupView && (
          <DateRange
            open={popupView}
            handleClose={handleClosePopup}
            handleSubmit={handleDayFilter}
          />
        )
      }
      {/* {
        popupView && (
          <DateWishTable
            open={popupView}
            handleClose={handleClosePopup}
            data={presentData.concat(absentData)}
          />
        )
      } */}
    </div >
  );
};

export default Attendance;
