import { fromJS, Map } from "immutable";
import * as actions from "./Action";

const initialState = fromJS({
  isLoading: false,
  message: "",
  supportList: Map(),
  supportClientList: [],
  allAgentData: [],
  filterClientIssueData: Map({
    page: 1,
    limit: 10,
    clientName: "",
    clientType: "",
    type: "",
    priority: "",
    status: "",
    startDate: null,
    endDate: null,
  }),
});

export default function ClientSupportManagement(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DATA_SUCCESS: {
      return state.merge(action.data);
    }
    case actions.CREATE_ISSUE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.CREATE_ISSUE_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.CREATE_ISSUE_FAILURE: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.RETRIEVE_ALL_ISSUE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.RETRIEVE_ALL_ISSUE_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("supportList", fromJS(action.data));
    }
    case actions.RETRIEVE_ALL_ISSUE_FAILURE: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.GET_SUPPORT_CLIENT_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_SUPPORT_CLIENT_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("supportClientList", fromJS(action.data));
    }
    case actions.GET_SUPPORT_CLIENT_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    case actions.CREATE_SUPPORT_CLIENT_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.CREATE_SUPPORT_CLIENT_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.CREATE_SUPPORT_CLIENT_FAILURE: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.GET_INDIVIDUAL_ISSUE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_INDIVIDUAL_ISSUE_SUCCESS: {
      return state.set("isLoading", false);
    }
    case actions.GET_INDIVIDUAL_ISSUE_FAILURE: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.PUT_SUPPORT_CLIENT_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.PUT_SUPPORT_CLIENT_SUCCESS: {
      return state.set("isLoading", false);
    }
    case actions.PUT_SUPPORT_CLIENT_FAILURE: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.PUT_CLIENT_STATUS_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.PUT_CLIENT_STATUS_SUCCESS: {
      return state.set("isLoading", false);
    }
    case actions.PUT_CLIENT_STATUS_FAILURE: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.DELETE_SUPPORT_CLIENT_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.DELETE_SUPPORT_CLIENT_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("message", "Deleted Successfully...!");
    }
    case actions.DELETE_SUPPORT_CLIENT_FAILURE: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.UPDATE_CLIENT_ASSIGNED_PERSON_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.UPDATE_CLIENT_ASSIGNED_PERSON_SUCCESS: {
      return state.set("isLoading", false);
    }
    case actions.UPDATE_CLIENT_ASSIGNED_PERSON_FAILURE: {
      return state.set("isLoading", false).set("message", action.data);
    }

    case actions.PUT_CLIENT_PRIORITY_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.PUT_CLIENT_PRIORITY_SUCCESS: {
      return state.set("isLoading", false);
    }
    case actions.PUT_CLIENT_PRIORITY_FAILURE: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.CREATE_CLIENT_REPLY_MAIL_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.CREATE_CLIENT_REPLY_MAIL_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.CREATE_CLIENT_REPLY_MAIL_FAILURE: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.FILTER_DATA_IN_CLIENT_SUPPORT_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.FILTER_DATA_IN_CLIENT_SUPPORT__SUCCESS: {
      return state.set("isLoading", false);
    }
    case actions.FILTER_DATA_IN_CLIENT_SUPPORT__FAILURE: {
      return state.set("isLoading", false);
    }
    case actions.ALL_AGENT_LIST_DATA_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.ALL_AGENT_LIST_DATA_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("allAgentData", fromJS(action.data));
    }
    case actions.ALL_AGENT_LIST_DATA_FAILURE: {
      return state.set("isLoading", false);
    }

    case actions.CREATE_SUPPORT_CATEGORY_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.CREATE_SUPPORT_CATEGORY_SUCCESS: {
      return state.set("isLoading", false).set("message", action.message);
    }
    case actions.CREATE_SUPPORT_CATEGORY_FAILURE: {
      return state.set("isLoading", false);
    }

    case actions.ALL_CATEGORY_LIST_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.ALL_CATEGORY_LIST_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("allCategoryList", fromJS(action.data));
    }
    case actions.ALL_CATEGORY_LIST_FAILURE: {
      return state.set("isLoading", false);
    }

    case actions.CATEGORY_COUNT_LIST_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.CATEGORY_COUNT_LIST_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("CategoryAndCountList", fromJS(action.data));
    }
    case actions.CATEGORY_COUNT_LIST_FAILURE: {
      return state.set("isLoading", false);
    }

    case actions.COUNT_BASED_TICKETS_LIST_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.COUNT_BASED_TICKETS_LIST_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("categoryBasedTickets", fromJS(action.data));
    }
    case actions.COUNT_BASED_TICKETS_LIST_FAILURE: {
      return state.set("isLoading", false);
    }

    default:
      return state;
  }
}
