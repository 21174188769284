import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  getDocumentHistoryById,
  deleteNotification,
  getAllDocumentList,
  getMyDocumentList,
} from "../../../Redux/DocumentManagement/Action";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { Button } from "@mui/material";
import {
  useStyles,
  BootstrapDialog,
} from "../../../Styles/Document/DocumentStyle";
import DocumentAccordion from "../DocumentAccordion";
import { fromJS } from "immutable";
import { selectUser } from "../../../Redux/TestCase/Selector";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// const useStyles = makeStyles((theme) => ({
//   expanded: {
//     minHeight: 15,
//     maxHeight: 15,
//   },
//   DialogContent: {
//     width: "100%",
//   },
//   BtnRow: {
//     display: "flex",
//     gap: "10px",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   Btn: {
//     padding: "8px 10px",
//     margin: "10px 0px",
//   },
//   AccordionRow: {
//     width: "100%",
//     display: "flex",
//     gap: "10px",
//     fontSize: "20px",
//   },
//   AccordionCol: {
//     width: "50%",
//     marginBottom: "10px",
//   },
//   AccordionLabel: {
//     width: "35%",
//     fontSize: "14px",
//     fontWeight: "bold",
//   },
//   accordionRoot: {
//     maxHeight: "15px",
//     fontSize: "14px",
//   },
//   AccordionLabel2: {
//     width: "35%",
//     fontSize: "14px",
//   },
// }));

const ReadNotification = ({
  data,
  readNotification,
  setReadNotification,
  setNotificationShow,
}) => {
  const dispatch = useDispatch();
  const reduxUserData = useSelector(selectUser).toJS();

  const [formData, setFormData] = useState();

  const callBack = (value) => {
    value && Object.keys(value).length > 0 && setFormData(value);
  };

  useEffect(() => {
    dispatch(getDocumentHistoryById(data.get("documentId", ""), callBack));
  }, []); //eslint-disable-line
  const handleClose = () => {
    setReadNotification(false);
  };
  const handleAccordion = () => {
    setExpanded((prev) => !prev);
  };
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  console.log(formData, "formData", data);

  const disableAllPopup = () => {
    setNotificationShow(false);
    setReadNotification(false);
  };

  const deleteCallBack = () => {
    window.location.href.split("/").includes("Documentation")
      ? dispatch(
        getAllDocumentList(
          {
            department: reduxUserData?.department,
            id: reduxUserData?._id,
          },
          disableAllPopup
        )
      )
      : dispatch(getMyDocumentList(data.get("mentor", ""), disableAllPopup));
  };
  const handleClick = (key) => {
    const payload = {
      value: key,
      documentId: formData?._id,
      id: data.get("_id", ""),
      mentor: data.get("mentor", ""),
    };
    dispatch(deleteNotification(payload, deleteCallBack));
  };
  const ImmutableFormData = fromJS(formData);
  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={readNotification}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "95%", // Set your width here
              height: "95%",
              borderRadius: "10px",
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{ padding: "16px", textAlign: "center" }}
        >
          DOCUMENT CONTENT
        </BootstrapDialogTitle>
        <DialogContent dividers className={classes.DialogContent}>
          <Box sx={{ position: "sticky", top: 0 }}>
            <div style={{ background: "white", padding: "10px" }}>
              <DocumentAccordion
                data={data.merge(ImmutableFormData)}
                expanded={expanded}
                handleAccordion={handleAccordion}
              />
            </div>
          </Box>
          <p style={{ fontWeight: "600", padding: "3px 16px" }}>CONTENT</p>
          <div
            className='editor_m0'
            dangerouslySetInnerHTML={{ __html: formData?.Content }}
            style={{ minWidth: "100%", maxHeight: "88%", padding: "3px 16px" }}
          ></div>
        </DialogContent>
        <div className={classes.BtnRow}>
          <Button
            onClick={() => handleClick("Accept")}
            variant="contained"
            className={classes.Btn}
          >
            Accept
          </Button>
          <Button
            onClick={() => handleClick("Decline")}
            variant="contained"
            className={classes.Btn}
          >
            Decline
          </Button>
        </div>
      </BootstrapDialog>
    </Box>
  );
};

export default ReadNotification;
