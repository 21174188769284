import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../../Redux/AssetsManagement/Action";
import PopupCategory from "./PopupCategory";
import { Map } from "immutable";
import dayjs from "dayjs";


//---------------------------------
// import AdapterDateFns from '@mui/lab/AdapterDateFns'
// import { LocalizationProvider } from '@mui/lab'
// import { DatePicker } from '@mui/lab'
const useStyles = makeStyles({
  form: {
    width: "550px",
  },
  formGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    "&:nth-child(2)": {
      alignItems: "start",
    },
  },
  FormControl: {
    width: "48%",
  },
  Formlabel: {
    background: "black",
    color: "white",
    padding: "10px",
  },
  FormFileErr: {
    fontSize: "12px",
    position: "relative",
    // top: "15px",
    left: "15px",
    color: "#D53A3A",
  },
  formDes: {
    width: "100%",
  },
  FileUpdate: {
    marginTop: "10px",
  },
});
const PopupForm = ({ handleSubmit }) => {
  const classes = useStyles();
  // function filterWeekends(date) {
  //   // Return false if Saturday or Sunday
  //   return date.getDay() === 0 || date.getDay() === 6;
  // }

  //props--------------------------------------------------------->

  const errorShow = useSelector((state) => state.AssetsManagement).getIn(
    ["popUpStatus", "Errorshow"],
    false
  );

  const CategoryGetApi = useSelector((state) => state.AssetsManagement).get(
    "geCategoryApi",
    ""
  );

  let assets = useSelector((state) => state.AssetsManagement).get(
    "AssetsformData",
    ""
  );

  // Requiring module
  const dispatch = useDispatch();
  //-------------------------------------------------------------->

  const onchangeAssets = (onChangeValue, key) => {
    if (key === "purchaseOn") {
      assets = assets.set(key, new Date(onChangeValue).toLocaleDateString());
    } else if (key === "attachment") {
      assets = assets.set(key, onChangeValue.target.files[0]);
    } else {
      assets = assets.set(key, onChangeValue.target.value);
    }
    //---------onchange-------------->
    dispatch(setData(Map({ AssetsformData: assets })));
  };

  //----------------------------------------------------------------->

  return (
    <Box component={"form"} className={classes.form} onSubmit={handleSubmit}>
      <Box className={classes.formGroup}>
        <TextField
          error={errorShow && assets.get("assetType") === "" ? true : false}
          select
          label="Assets Type"
          variant="outlined"
          sx={{ width: "90%" }}
          value={assets.get("assetType", "")}
          helperText={
            errorShow && assets.get("assetType") === ""
              ? "Assets Type field invalidvalue"
              : ""
          }
          onChange={(e) => onchangeAssets(e, "assetType")}
          autoComplete="none"
        >
          {CategoryGetApi.map((data, index) => {
            return (
              <MenuItem value={data.get("assetsType", "")} key={index}>
                {data.get("assetsType", "")}
              </MenuItem>
            );
          })}
        </TextField>
        <div>
          <PopupCategory />
        </div>
      </Box>
      <Box className={classes.formGroup}>
        <TextField
          error={errorShow && assets.get("price") === "" ? true : false}
          label="Price"
          variant="outlined"
          value={assets.get("price")}
          helperText={
            errorShow && assets.get("price") === "" ? "Price field invalid" : ""
          }
          className={classes.FormControl}
          onChange={(e) => onchangeAssets(e, "price")}
          autoComplete="none"
        />

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="purchase on"
            value={dayjs(assets.get("purchaseOn", ""))}
            onChange={(e) => onchangeAssets(e, "purchaseOn")}
            renderInput={(params) => (
              <TextField
                {...params}
                error={
                  errorShow && assets.get("purchaseOn") === null ? true : false
                }
                className={classes.FormControl}
                helperText={
                  errorShow && assets.get("purchaseOn") === null
                    ? "purchaseOn field invalid"
                    : ""
                }
                autoComplete="none"
              />
            )}
          />
        </LocalizationProvider>
      </Box>

      <Box className={classes.formGroup}>
        <TextField
          error={errorShow && assets.get("model") === "" ? true : false}
          label="Model"
          variant="outlined"
          autoComplete="none"
          value={assets.get("model")}
          helperText={
            errorShow && assets.get("model") === "" ? "model field invalid" : ""
          }
          className={classes.FormControl}
          onChange={(e) => onchangeAssets(e, "model")}
        />

        <div style={{ marginLeft: "15px", width: "48%" }}>
          <label htmlFor={"icon-button-file"} className={classes.Formlabel}>
            Choose File
          </label>

          <TextField
            id={"icon-button-file"}
            type="file"
            style={{ display: "none" }}
            onChange={(e) => onchangeAssets(e, "attachment")}
            multiple
          />
          <div className={classes.FileUpdate}>
            {assets.get("attachment") ? (
              <>
                {assets.get("attachment") !== assets.get("previousAttach")
                  ? assets.get("attachment").name
                  : assets.get("attachment")}
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={classes.FormFileErr}>
            {errorShow && assets.get("attachment") === ""
              ? "file field invalid"
              : ""}
          </div>
        </div>
      </Box>

      <Box className={classes.formGroup}>
        <TextField
          error={errorShow && assets.get("description") === "" ? true : false}
          label="Description"
          variant="outlined"
          value={assets.get("description")}
          className={classes.formDes}
          helperText={
            errorShow && assets.get("description") === ""
              ? "Description field invalid"
              : ""
          }
          InputProps={{ sx: { height: 100 } }}
          onChange={(e) => onchangeAssets(e, "description")}
          autoComplete="none"
        />
      </Box>
      <DialogActions dividers>
        <Button autoFocus type="submit">
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
};

export default PopupForm;
