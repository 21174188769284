import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";

export default function ViewIndividualTicket({
  open,
  setOpen,
  issueData,
  clientName,
}) {
  const tdStyle = {
    padding: "15px",
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle id="alert-dialog-title">
        <div className="d-flex justify-content-between">
          <div className="fw-bold">Ticket Details</div>
          <div className="cursor-pointer custom-hover" onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <table>
          <tr>
            <td style={tdStyle} className="fw-bold">
              NAME
            </td>
            <td style={tdStyle}>{issueData.get("name", "")}</td>
          </tr>
          <tr>
            <td style={tdStyle} className="fw-bold">
              EMAIL
            </td>
            <td style={tdStyle}>{issueData.get("email", "")}</td>
          </tr>
          <tr>
            <td style={tdStyle} className="fw-bold">
              CLIENT NAME
            </td>
            <td style={tdStyle}>{clientName}</td>
          </tr>
          <tr>
            <td style={tdStyle} className="fw-bold">
              ASSIGNED PERSON
            </td>
            <td style={tdStyle}>
              {issueData.get("assigney", "") || "No Assignee"}
            </td>
          </tr>
          <tr>
            <td style={tdStyle} className="fw-bold">
              PRIORITY
            </td>
            <td style={tdStyle}>{issueData.get("priority", "")}</td>
          </tr>
          <tr>
            <td style={tdStyle} className="fw-bold align-top">
              DESCRIPTION
            </td>
            <td style={tdStyle}>{issueData.get("description", "")}</td>
          </tr>
          <tr>
            <td style={tdStyle} className="fw-bold">
              STATUS
            </td>
            <td style={tdStyle}>{issueData.get("status", "")}</td>
          </tr>
        </table>
      </DialogContent>
    </Dialog>
  );
}
