import { createAction } from "../../Utils/FunctionUtils";
import axios from "axios";

const backEndApi = process.env.REACT_APP_BackEndUrl;

export const SET_DATA_SUCCESS = "SET_DATA_SUCCESS";
const setDataSuccess = createAction(SET_DATA_SUCCESS, "data");

export const setData = (data) => {
  return (dispatch) => {
    dispatch(setDataSuccess(data));
  };
};

export const EditBillIDAction = (data) => {
  return {
    type: "EDIT_BILL_ID",
    payLoad: data,
  };
};
export const SnackbarAction = (data) => {
  return {
    type: "SNACKBAR_ACTION",
    payLoad: data,
  };
};
export const GET_ALL_TYPE_REQUEST = "GET_ALL_TYPE_REQUEST";
export const GET_ALL_TYPE_SUCCESS = "GET_ALL_TYPE_SUCCESS";
export const GET_ALL_TYPE_FAILURE = "GET_ALL_TYPE_FAILURE";
const getAllTypeRequest = createAction(GET_ALL_TYPE_REQUEST);
const getAllTypeSuccess = createAction(GET_ALL_TYPE_SUCCESS, "data");
const getAllTypeFailure = createAction(GET_ALL_TYPE_FAILURE, "error");

export const getAllType = () => {
  return (dispatch) => {
    dispatch(getAllTypeRequest());
    axios
      .get(`${backEndApi}billing/getAllType`)
      .then((res) => {
        dispatch(getAllTypeSuccess(res.data.message));
      })
      .catch((error) => dispatch(getAllTypeFailure(error)));
  };
};

export const POST_ADD_CLOUD_TYPE_REQUEST = "POST_ADD_CLOUD_TYPE_REQUEST";
export const POST_ADD_CLOUD_TYPE_SUCCESS = "POST_ADD_CLOUD_TYPE_SUCCESS";
export const POST_ADD_CLOUD_TYPE_FAILURE = "POST_ADD_CLOUD_TYPE_FAILURE";

const postAddCloudTypeRequest = createAction(POST_ADD_CLOUD_TYPE_REQUEST);
const postAddCloudTypeSuccess = createAction(
  POST_ADD_CLOUD_TYPE_SUCCESS,
  "data"
);
const postAddCloudTypeFailure = createAction(
  POST_ADD_CLOUD_TYPE_FAILURE,
  "error"
);
export const postAddCloudType = (payload, callBack) => {
  return (dispatch) => {
    dispatch(postAddCloudTypeRequest());
    axios
      .post(`${backEndApi}billing/addCloudType`, payload)
      .then((res) => {
        dispatch(postAddCloudTypeSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(postAddCloudTypeFailure(error)));
  };
};

export const UPDATE_CLOUD_TYPE_REQUEST = "UPDATE_CLOUD_TYPE_REQUEST";
export const UPDATE_CLOUD_TYPE_SUCCESS = "UPDATE_CLOUD_TYPE_SUCCESS";
export const UPDATE_CLOUD_TYPE_FAILURE = "UPDATE_CLOUD_TYPE_FAILURE";

const updateCloudTypeRequest = createAction(UPDATE_CLOUD_TYPE_REQUEST);
const updateCloudTypeSuccess = createAction(UPDATE_CLOUD_TYPE_SUCCESS, "data");
const updateCloudTypeFailure = createAction(UPDATE_CLOUD_TYPE_FAILURE, "error");
export const updateCloudType = (payload, callBack) => {
  return (dispatch) => {
    dispatch(updateCloudTypeRequest());
    axios
      .put(`${backEndApi}billing/updateCloudType`, payload)
      .then((res) => {
        dispatch(updateCloudTypeSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(updateCloudTypeFailure(error)));
  };
};

export const DELETE_CLOUD_TYPE_REQUEST = "DELETE_CLOUD_TYPE_REQUEST";
export const DELETE_CLOUD_TYPE_SUCCESS = "DELETE_CLOUD_TYPE_SUCCESS";
export const DELETE_CLOUD_TYPE_FAILURE = "DELETE_CLOUD_TYPE_FAILURE";
const deleteCloudTypeRequest = createAction(DELETE_CLOUD_TYPE_REQUEST);
const deleteCloudTypeSuccess = createAction(DELETE_CLOUD_TYPE_SUCCESS, "data");
const deleteCloudTypeFailure = createAction(DELETE_CLOUD_TYPE_FAILURE, "error");

export const deleteCloudType = (id, callBack) => {
  return (dispatch) => {
    dispatch(deleteCloudTypeRequest());
    axios
      .delete(`${backEndApi}billing/deleteCloudType?id=${id}`)
      .then((res) => {
        dispatch(deleteCloudTypeSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(deleteCloudTypeFailure(error)));
  };
};

export const POST_ADD_SERVICE_TYPE_REQUEST = "POST_ADD_SERVICE_TYPE_REQUEST";
export const POST_ADD_SERVICE_TYPE_SUCCESS = "POST_ADD_SERVICE_TYPE_SUCCESS";
export const POST_ADD_SERVICE_TYPE_FAILURE = "POST_ADD_SERVICE_TYPE_FAILURE";
const postAddServiceTypeRequest = createAction(POST_ADD_SERVICE_TYPE_REQUEST);
const postAddServiceTypeSuccess = createAction(
  POST_ADD_SERVICE_TYPE_SUCCESS,
  "data"
);
const postAddServiceTypeFailure = createAction(
  POST_ADD_SERVICE_TYPE_FAILURE,
  "error"
);
export const postAddServiceType = (payload, callBack) => {
  return (dispatch) => {
    dispatch(postAddServiceTypeRequest());
    axios
      .post(`${backEndApi}billing/addServiceType`, payload)
      .then((res) => {
        dispatch(postAddServiceTypeSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(postAddServiceTypeFailure(error)));
  };
};

export const UPDATE_SERVICE_TYPE_REQUEST = "UPDATE_SERVICE_TYPE_REQUEST";
export const UPDATE_SERVICE_TYPE_SUCCESS = "UPDATE_SERVICE_TYPE_SUCCESS";
export const UPDATE_SERVICE_TYPE_FAILURE = "UPDATE_SERVICE_TYPE_FAILURE";
const updateServiceTypeRequest = createAction(UPDATE_SERVICE_TYPE_REQUEST);
const updateServiceTypeSuccess = createAction(
  UPDATE_SERVICE_TYPE_SUCCESS,
  "data"
);
const updateServiceTypeFailure = createAction(
  UPDATE_SERVICE_TYPE_FAILURE,
  "error"
);
export const updateServiceType = (payload, callBack) => {
  return (dispatch) => {
    dispatch(updateServiceTypeRequest());
    axios
      .put(`${backEndApi}billing/updateServiceType`, payload)
      .then((res) => {
        dispatch(updateServiceTypeSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(updateServiceTypeFailure(error)));
  };
};

export const DELETE_SERVICE_TYPE_REQUEST = "DELETE_SERVICE_TYPE_REQUEST";
export const DELETE_SERVICE_TYPE_SUCCESS = "DELETE_SERVICE_TYPE_SUCCESS";
export const DELETE_SERVICE_TYPE_FAILURE = "DELETE_SERVICE_TYPE_FAILURE";
const deleteServiceTypeRequest = createAction(DELETE_SERVICE_TYPE_REQUEST);
const deleteServiceTypeSuccess = createAction(
  DELETE_SERVICE_TYPE_SUCCESS,
  "data"
);
const deleteServiceTypeFailure = createAction(
  DELETE_SERVICE_TYPE_FAILURE,
  "error"
);

export const deleteServiceType = (id, callBack) => {
  return (dispatch) => {
    dispatch(deleteServiceTypeRequest());
    axios
      .delete(`${backEndApi}billing/deleteServiceType?id=${id}`)
      .then((res) => {
        dispatch(deleteServiceTypeSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(deleteServiceTypeFailure(error)));
  };
};

export const GET_BILLING_REQUEST = "GET_BILLING_REQUEST";
export const GET_BILLING_SUCCESS = "GET_BILLING_SUCCESS";
export const GET_BILLING_FAILURE = "GET_BILLING_FAILURE";
const getBillingRequest = createAction(GET_BILLING_REQUEST);
const getBillingSuccess = createAction(GET_BILLING_SUCCESS, "data");
const getBillingFailure = createAction(GET_BILLING_FAILURE, "error");

export const getBilling = (payload) => {
  return (dispatch) => {
    dispatch(getBillingRequest());
    axios
      .post(`${backEndApi}billing/getService`, payload)
      .then((res) => {
        dispatch(getBillingSuccess(res.data.message));
      })
      .catch((error) => dispatch(getBillingFailure(error)));
  };
};

export const POST_BILLING_REQUEST = "POST_BILLING_REQUEST";
export const POST_BILLING_SUCCESS = "POST_BILLING_SUCCESS";
export const POST_BILLING_FAILURE = "POST_BILLING_FAILURE";
const postBillingRequest = createAction(POST_BILLING_REQUEST);
const postBillingSuccess = createAction(POST_BILLING_SUCCESS, "data");
const postBillingFailure = createAction(POST_BILLING_FAILURE, "error");

export const postBilling = (payload, callBack) => {
  return (dispatch) => {
    dispatch(postBillingRequest());
    axios
      .post(`${backEndApi}billing/createService`, payload)
      .then((res) => {
        dispatch(postBillingSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(postBillingFailure(error)));
  };
};

export const PUT_BILLING_REQUEST = "PUT_BILLING_REQUEST";
export const PUT_BILLING_SUCCESS = "PUT_BILLING_SUCCESS";
export const PUT_BILLING_FAILURE = "PUT_BILLING_FAILURE";
const putBillingRequest = createAction(PUT_BILLING_REQUEST);
const putBillingSuccess = createAction(PUT_BILLING_SUCCESS, "data");
const putBillingFailure = createAction(PUT_BILLING_FAILURE, "error");
export const putBilling = (payload, callBack) => {
  return (dispatch) => {
    dispatch(putBillingRequest());
    axios
      .put(`${backEndApi}billing/updateService`, payload)
      .then((res) => {
        dispatch(putBillingSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(putBillingFailure(error)));
  };
};

export const DELETE_BILLING_REQUEST = "DELETE_BILLING_REQUEST";
export const DELETE_BILLING_SUCCESS = "DELETE_BILLING_SUCCESS";
export const DELETE_BILLING_FAILURE = "DELETE_BILLING_FAILURE";
const deleteBillingRequest = createAction(DELETE_BILLING_REQUEST);
const deleteBillingSuccess = createAction(DELETE_BILLING_SUCCESS, "data");
const deleteBillingFailure = createAction(DELETE_BILLING_FAILURE, "error");
export const deleteBilling = (payload, callBack) => {
  return (dispatch) => {
    dispatch(deleteBillingRequest());
    axios
      .delete(`${backEndApi}billing/dropService?serviceId=${payload}`)
      .then((res) => {
        dispatch(deleteBillingSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(deleteBillingFailure(error)));
  };
};

export const SNAP_POST_BILLING_REQUEST = "SNAP_POST_BILLING_REQUEST";
export const SNAP_POST_BILLING_SUCCESS = "SNAP_POST_BILLING_SUCCESS";
export const SNAP_POST_BILLING_FAILURE = "SNAP_POST_BILLING_FAILURE";
const snapPostBillingRequest = createAction(SNAP_POST_BILLING_REQUEST);
const snapPostBillingSuccess = createAction(SNAP_POST_BILLING_SUCCESS, "data");
const snapPostBillingFailure = createAction(SNAP_POST_BILLING_FAILURE, "error");

export const snapPostBilling = (payload, callBack) => {
  return (dispatch) => {
    dispatch(snapPostBillingRequest());
    axios
      .post(`${backEndApi}billing/snapService?id=${payload}`)
      .then((res) => {
        dispatch(snapPostBillingSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(snapPostBillingFailure(error)));
  };
};
export const SNAP_GET_BILLING_REQUEST = "SNAP_GET_BILLING_REQUEST";
export const SNAP_GET_BILLING_SUCCESS = "SNAP_GET_BILLING_SUCCESS";
export const SNAP_GET_BILLING_FAILURE = "SNAP_GET_BILLING_FAILURE";
const snapGetBillingRequest = createAction(SNAP_GET_BILLING_REQUEST);
const snapGetBillingSuccess = createAction(SNAP_GET_BILLING_SUCCESS, "data");
const snapGetBillingFailure = createAction(SNAP_GET_BILLING_FAILURE, "error");

export const snapGetBilling = (payload, callBack) => {
  return (dispatch) => {
    dispatch(snapGetBillingRequest());
    axios
      .get(`${backEndApi}billing/retrieveSnapData?snapId=${payload}`)
      .then((res) => {
        dispatch(snapGetBillingSuccess(res.data.message));
      })
      .catch((error) => dispatch(snapGetBillingFailure(error)));
  };
};
export const GET_INDIVIDUAL_SERVICE_DATA_REQUEST =
  "GET_INDIVIDUAL_SERVICE_DATA_REQUEST";
export const GET_INDIVIDUAL_SERVICE_DATA_SUCCESS =
  "GET_INDIVIDUAL_SERVICE_DATA_SUCCESS";
export const GET_INDIVIDUAL_SERVICE_DATA_FAILURE =
  "GET_INDIVIDUAL_SERVICE_DATA_FAILURE";
const getIndividualServiceDataRequest = createAction(
  GET_INDIVIDUAL_SERVICE_DATA_REQUEST
);
const getIndividualServiceDataSuccess = createAction(
  GET_INDIVIDUAL_SERVICE_DATA_SUCCESS,
  "data"
);
const getIndividualServiceDataFailure = createAction(
  GET_INDIVIDUAL_SERVICE_DATA_FAILURE,
  "error"
);

export const getIndividualServiceData = (id, callBack) => {
  return (dispatch) => {
    dispatch(getIndividualServiceDataRequest());
    axios
      .get(`${backEndApi}billing/individualServiceData?id=${id}`)
      .then((res) => {
        dispatch(getIndividualServiceDataSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(getIndividualServiceDataFailure(error)));
  };
};

// reminder post data
export const create_reminder_data_REQUEST = "create_reminder_data_REQUEST";
export const create_reminder_data_SUCCESS = "create_reminder_data_SUCCESS";
export const create_reminder_data_FAILURE = "create_reminder_data_FAILURE";
const createRminderRequest = createAction(create_reminder_data_REQUEST);
const createReminderSuccess = createAction(
  create_reminder_data_SUCCESS,
  "data"
);
const createReminderFailure = createAction(
  create_reminder_data_FAILURE,
  "error"
);

export const reminderDataHandle = (payload, callBack) => {
  return (dispatch) => {
    dispatch(createRminderRequest());
    axios
      .post(`${backEndApi}billing/reminderData`, payload)
      .then((res) => {
        dispatch(createReminderSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(createReminderFailure(error)));
  };
};

// get reminder service data
export const GET_REMINDER_DETAILS_REQUEST = "GET_REMINDER_DETAILS_REQUEST";
export const GET_REMINDER_DETAILS_SUCCESS = "GET_REMINDER_DETAILS_SUCCESS";
export const GET_REMINDER_DETAILS_FAILURE = "GET_REMINDER_DETAILS_FAILURE";
const getReminderRequest = createAction(GET_REMINDER_DETAILS_REQUEST);
const getReminderSuccess = createAction(GET_REMINDER_DETAILS_SUCCESS, "data");
const getReminderFailure = createAction(GET_REMINDER_DETAILS_FAILURE, "error");

export const getReminderDetails = (id, callBack) => {
  return (dispatch) => {
    dispatch(getReminderRequest());
    axios
      .get(`${backEndApi}billing/getReminderDetails?id=${id}`)
      .then((res) => {
        dispatch(getReminderSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(getReminderFailure(error)));
  };
};
