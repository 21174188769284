import { fromJS } from "immutable";
import * as actions from "./Action";

const initialState = fromJS({
  message: "",
  isLoading: false,
  dateWiseDate: [],
  SpecificDateDetail: [],
  allUser: [],
});
//----------------------------------------------------------------->
export default function AttendanceManagement(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DATA_SUCCESS: {
      return state.merge(action.data);
    }
    case actions.GET_ALL_USER_WITH_DATE_REQUEST: {
      return state.set("isLoading", true)
    }
    case actions.GET_ALL_USER_WITH_DATE_SUCCESS: {
      return state.set("isLoading", false).set("dateWiseDate", fromJS(action.data?.DateWisePresent)).set("allUser", fromJS(action.data?.allUsername));
    }
    case actions.GET_ALL_USER_WITH_DATE_FAILURE: {
      return state.set("isLoading", false)
    }
    case actions.GET_SPECIFIC_DATE_DETAIL_REQUEST: {
      return state.set("isLoading", true)
    }
    case actions.GET_SPECIFIC_DATE_DETAIL_SUCCESS: {
      return state.set("isLoading", false).set("SpecificDateDetail", fromJS(action.data))
    }
    case actions.GET_SPECIFIC_DATE_DETAIL_FAILURE: {
      return state.set("isLoading", false)
    }
    default:
      return state;
  }
}
