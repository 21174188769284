import React from "react";
import { useLocation } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  // const history = useHistory();
  const navigate = useNavigate();

  // console.log("location", location.pathname.slice(1).toUpperCase());
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div className="mt-2 d-flex gap-2">
      {[
        "/cloudBilling/billing",
        "/CreateDocument",
        "/viewDocument",
        "/EditDocument",
        "/documentHistory",
        "/clientSupport/viewIssue",
        "/clientSupport/viewTicket",
      ].includes(location.pathname) && (
        <ArrowBackIcon onClick={handleClick} style={{ cursor: "pointer" }} />
      )}
      <h5>
        {location.pathname === "/"
          ? "Home"
          : location.pathname.slice(1).charAt(0).toUpperCase() +
            location.pathname.slice(2)}
      </h5>
    </div>
  );
};

export default Breadcrumbs;
