import React from "react";
import { Button } from "@mui/material";
import MainAssetsTable from "../MainAssets/MainAssetsTable";
import UiPopUpForm from "../../02.Ui/PopUpForm";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import {
  EditAssetsAction,
  ErrorAction,
  PopupAction,
  postAssetServiceApi,
  putAssetServiceApi,
  TotalResetAction,
} from "../../../../Redux/AssetsManagement/Action";

const MainAssets = () => {
  const popOpen = useSelector((state) => state.AssetsManagement).getIn(
    ["popUpStatus", "PopupOpen"],
    false
  );

  const assets = useSelector((state) => state.AssetsManagement).get(
    "AssetsformData",
    {}
  );
  const EditAsset = useSelector((state) => state.AssetsManagement).getIn(
    ["popUpStatus", "EditAsset"],
    false
  );

  const dispatch = useDispatch();

  //---------------------------------------------------------->

  const handleSubmit = (e) => {
    e.preventDefault();
    //submit
    const formData = new FormData();
    formData.append("assetType", assets.get("assetType"));
    formData.append("price", assets.get("price"));
    formData.append("purchaseOn", assets.get("purchaseOn"));
    formData.append("model", assets.get("model"));
    formData.append("attachment", assets.get("attachment"));
    formData.append("description", assets.get("description"));
    console.log("formData", { assets, formData });

    //update
    const formDatas = new FormData();
    formDatas.append("assetId", assets.get("_id"));
    formDatas.append("assetType", assets.get("assetType"));
    formDatas.append("price", assets.get("price"));
    formDatas.append("purchaseOn", assets.get("purchaseOn"));
    formDatas.append("model", assets.get("model"));
    formDatas.append("description", assets.get("description"));

    if (assets.get("attachment") !== assets.get("previousAttach")) {
      formDatas.append("attachment", assets.get("attachment"));
    }
    if (
      !assets.get("assetType") ||
      !assets.get("price") ||
      !assets.get("purchaseOn") ||
      !assets.get("model") ||
      !assets.get("attachment") ||
      !assets.get("description")
    ) {
      dispatch(ErrorAction(true));
    } else if (EditAsset !== false) {
      dispatch(putAssetServiceApi(formDatas));

      dispatch(PopupAction(false));
      dispatch(ErrorAction(false));
      dispatch(EditAssetsAction(false));
      //-------------------------------------------->
      dispatch(TotalResetAction());
    } else {
      dispatch(postAssetServiceApi(formData));

      dispatch(PopupAction(false));
      dispatch(ErrorAction(false));
      dispatch(EditAssetsAction(false));
      //-------------------------------------------->
      dispatch(TotalResetAction());
    }
  };
  // className="container-fluid mt-3"
  return (
    <div className="container-fluid mt-4 ">
      <div className="d-flex justify-content-end">
        <Button
          variant="contained"
          onClick={() => dispatch(PopupAction(true))}
          className="mb-2 "
        >
          <AddIcon sx={{fontSize:"18px", fontWeight:"700"}}/> Create Assets
        </Button>
      </div>
      {/* --------------------------------------------------------------------- */}
      <MainAssetsTable />

      {popOpen && <UiPopUpForm handleSubmit={handleSubmit} />}

      {/* ---------------------------------------------------------------------  */}
      {/* ---------------------------------------------------------------------  */}
    </div>
  );
};

export default MainAssets;
