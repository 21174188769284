import React from "react";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import "./EmailEditor.css";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import * as ClassicEditor from "ckeditor5-custom-build/build/ckeditor";

// const editorConfig = {
//   toolbar: {
//     items: [
//       "bold",
//       "italic",
//       "underline",
//       "|",
//       "fontSize",
//       "fontColor",
//       "|",
//       "numberedList",
//       "bulletedList",
//       "|",
//       "link",
//       "imageInsert",
//       "table",
//       "code",
//       "|",
//       "undo",
//       "redo",
//       "removeFormat",
//       "defaultFontStyle",
//     ],
//   },s
// };

function EmailEditor({
  handleDelete,
  ticketData,
  handleSendReply,
  handleEditorChange,
  sendData,
}) {
  // const [editorState, setEditorState] = useState(`Hi ${ticketData?.name}`);
  // console.log("editorState", editorState);
  // const handleEditorChange = (value) => {
  //   setEditorState(value.getData());
  // };
  // const handleSave = () => {
  //   const contentState = editorState.getCurrentContent();
  //   const rawContent = convertToRaw(contentState);
  //   const htmlContent = draftToHtml(rawContent);

  //   // Perform your desired actions with the HTML content (e.g., save to a database)
  //   console.log(htmlContent);
  // };

  // const toolbarOptions = {
  //   options: ["inline", "list", "textAlign", "image"],
  //   inline: {
  //     options: ["bold", "italic", "underline"],
  //   },
  //   list: {
  //     options: ["unordered", "ordered"],
  //   },
  //   textAlign: {
  //     options: ["left", "center", "right", "justify"],
  //   },
  //   image: {
  //     uploadEnabled: true,
  //     previewImage: true,
  //     inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
  //     dropdownClassName: "custom-image-dropdown",
  //     className: "custom-image-option",
  //   },
  // };

  // function createMarkup(html) {
  //   return {
  //     __html: DOMPurify.sanitize(html),
  //   };
  // }
  return (
    <Card className="mail_card">
      <CardHeader
        className="mail_card_head_1"
        avatar={
          <Avatar sx={{ width: "32px", height: "32px" }} aria-label="recipe">
            R
          </Avatar>
        }
        title="From: digivalsolutions (support@digivalsolutions-support.freshdesk.com) "
        action={<i class="fa fa-expand" aria-hidden="true"></i>}></CardHeader>
      <Divider />
      <CardHeader
        className="mail_card_head_2"
        title={<span className="email_to"> To : {ticketData?.email} </span>}
        action={"Cc Bcc"}></CardHeader>
      <CardContent className="mail_card_Content">
        {/* <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          toolbarClassName="email-editor-toolbar"
          wrapperClassName="email-editor-content"
          editorClassName="email-editor"
          toolbar={toolbarOptions}
        /> */}

        <GrammarlyEditorPlugin>
          <CKEditor
            editor={ClassicEditor}
            // config={editorConfig}
            config={{
              toolbar: [
                "bold",
                "italic",
                "underline",
                "numberedList",
                "bulletedList",
                "link",
                "imageUpload",
                "imageInsert",
                "insertTable",
                "code",
                "mediaEmbed",
                "undo",
                "redo",
              ],
            }}
            data={sendData?.Subject}
            onChange={(event, editor) => {
              handleEditorChange(editor);
            }}
          />
        </GrammarlyEditorPlugin>
      </CardContent>
      <CardActions className="mail_card_action">
        <div className="w-100 row p-0 m-0 d-flex justify-content-between">
          <div className="col-4 d-flex align-items-center gap-3">
            <label className="email_label" htmlFor={"icon-button-file"}>
              <i class="fa fa-paperclip" aria-hidden="true"></i>
            </label>
            <input
              id={"icon-button-file"}
              type="file"
              style={{ display: "none" }}
              multiple
            />
          </div>
          <div className="col-4 d-flex justify-content-end align-items-center gap-2">
            <div className="email_saved_text">saved</div>
            <button className="email_delete_btn" onClick={handleDelete}>
              <DeleteOutlineIcon sx={{ color: "#183247", fontSize: "22px" }} />
            </button>
            <button className="email_send_btn" onClick={handleSendReply}>
              send
            </button>
          </div>
        </div>
      </CardActions>
    </Card>
  );
}

export default EmailEditor;
