import React from "react";
import { AgGridReact } from "ag-grid-react";
import PropTypes from "prop-types";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export const AgTable = ({ rowData, columnDefs }) => {
  const getRowStyle = (params) => {
    const rowIndex = rowData.findIndex((item) => item?.className);
    if (rowIndex === params.node.rowIndex) {
      return { color: rowData[rowIndex].className };
    }
    if (params.node.rowIndex % 2 === 0) {
      return { backgroundColor: "#cff4fc" };
    }
  };

  // const onGridReady = (params) => {
  //   params.api.sizeColumnsToFit();
  // };
  // const autoSizeColumns = (params) => {
  //   const colIds = params.columnApi.getAllDisplayedColumns().map((col) => col.getColId());
  //   params.columnApi.autoSizeColumns(colIds);
  // }; for column resizing
  return (
    <div className="grid-container">
      <div id="myGrid" className="ag-grid ag-theme-alpine">
        <AgGridReact
          // onGridReady={onGridReady}
          // onFirstDataRendered={autoSizeColumns} for column resizing
          columnDefs={columnDefs}
          rowData={rowData}
          getRowStyle={getRowStyle}
          suppressRowClickSelection
          width={"100%"}
          defaultColDef={{
            headerClass: function (params) {
              return "bg-dark text-light";
            },
            sortable: true,
            flex: 1,
            minWidth: 160,
            width: "auto",
            resizable: true,
            cellClass: "cell-wrap-text ag-grid-cell",
            autoHeight: true,
            filter: "agTextColumnFilter",
            filterParams: {
              buttons: ["reset", "apply"],
              closeOnApply: true,
              suppressAndOrCondition: true,
            },
          }}
          rowHeight={60}
          animateRows
          rowModelType="clientSide"
        />
      </div>
    </div>
  );
};

AgTable.propTypes = {
  rowData: PropTypes.array,
  columnDefs: PropTypes.array,
};
