import { Map } from 'immutable'
const assignmentData = (state) => state.BillingState;

const selectIsLoading = (state) => assignmentData(state).get("isLoading");

const selectAllTypeDetails = (state) => assignmentData(state).get("allTypeDetails");

const selectBillingApi = (state) => assignmentData(state).get("getBillingApi");

const selectUpdateId = (state) => assignmentData(state).get("billUpdateId");

const selectSnapBillApi = (state) => assignmentData(state).get("getSnapBillingApi");

const selectGetٍSeparateBillingData = (state) => assignmentData(state).get("getٍSeparateBillingData");
const selectLoadingStauts = (state) => assignmentData(state).get('loading', '');
const selectMessageStatus = (state) => assignmentData(state).get('message', "");
const selectPagination = (state) => assignmentData(state).get('pagination', Map())

export { selectIsLoading, selectAllTypeDetails, selectBillingApi, selectUpdateId, selectSnapBillApi, selectGetٍSeparateBillingData, selectLoadingStauts, selectMessageStatus, selectPagination };
