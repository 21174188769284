import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { List } from "immutable";
import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { useStyles } from "../../../Styles/Billing/BillingStyle";
import "./billView.css";

const BillingView = ({ Billing }) => {
  const classes = useStyles();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          // height: "550px",
          display: "flex",
          padding: "5px",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box className="d-flex flex-column w-100 gap-3">
          <div className="d-flex flex-column align-items-center ">
            <div className="d-flex flex-column align-items-start ">
              <Box>
                <label className={classes.label}>
                  Client Name :{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {Billing.get("clientName", "")}{" "}
                  </span>
                </label>
              </Box>
              <Box>
                <label className={classes.label}>
                  Cloud Type : <span>{Billing.get("cloudType", "")}</span>{" "}
                </label>
              </Box>
            </div>
          </div>
          <Box className="d-flex justify-content-center">
            <TableContainer component={Paper} sx={{ maxHeight: "250px" }}>
              <Table aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.th}>Service Type</TableCell>
                    <TableCell className={classes.th}>Reason</TableCell>
                    <TableCell className={classes.th}>Per Hour</TableCell>
                    <TableCell className={classes.th}>Per Month</TableCell>
                    <TableCell className={classes.th}>Quantity</TableCell>
                    <TableCell className={classes.th}>No RAM</TableCell>
                    <TableCell className={classes.th}>No CPU</TableCell>
                    <TableCell className={classes.th}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Billing.get("service", List()).map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.get("serviceType", "")}</TableCell>
                      <TableCell>{item.get("reason", "")}</TableCell>
                      <TableCell>{item.get("perHour", "")}</TableCell>
                      <TableCell>{item.get("perMonth", "")}</TableCell>
                      <TableCell>{item.get("quantity", "")}</TableCell>
                      <TableCell>
                        {item.get("ramNo", "") === ""
                          ? "--"
                          : item.get("ramNo", "")}
                      </TableCell>
                      <TableCell>
                        {item.get("cpuNo", "") === ""
                          ? "--"
                          : item.get("cpuNo", "")}
                      </TableCell>
                      <TableCell>
                        {item.get("perMonth", "") * item.get("quantity", "")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
         
          <Box className={classes.content}>
            <Box className={classes.totalAmount}>
              <label className={classes.label}>
                Total Cost : {Billing.get("totalCost", 0)?.toFixed(2)}
              </label>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BillingView;
