import React, { useEffect } from "react";
import MainAssets from "../03.Pages/MainAssets/MainAssets";
import { useDispatch, useSelector } from "react-redux";
import { getAssetServiceApi, getCategoryTypeApi, setData } from "../../../Redux/AssetsManagement/Action";
import Snackbar from "../../../LoaderSnackbar/Snackbar";
import Loader from "../../../LoaderSnackbar/Loader";

const UiMainAssets = () => {
  const dispatch = useDispatch();

  //props-state------------------------------------------->
  const loadingStatus = useSelector((state) => state.AssetsManagement).get("isLoading", "");
  const messageStatus = useSelector((state) => state.AssetsManagement).get("message", "");

  useEffect(() => {
    dispatch(getAssetServiceApi());
    dispatch(getCategoryTypeApi());
  }, []); // eslint-disable-line

  return (
    <div>
      <MainAssets />
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
    </div>
  );
};

export default UiMainAssets;
