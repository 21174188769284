import React from "react";

function ImageView({ imageList }) {
  return (
    <div>
      {imageList.split(",").map((item, index) => {
        return item !== "" && <img height={200} src={item} alt="images" className="p-2"></img>;
      })}
    </div>
  );
}

export default ImageView;
