import { createAction, setLocalStorageValue } from "../../Utils/FunctionUtils";

import axios from "axios";

const backEndApi = process.env.REACT_APP_BackEndUrl;
const modifySysTime = new Date().getTime() + 55 * 60 * 1000;

// export function bindComments(postId) {
//   return function (dispatch) {
//     return API.fetchComments(postId).then((comments) => {
//       // dispatch

//       dispatch({
//         type: BIND_COMMENTS,
//         comments,
//         postId,
//       });
//     });
//   };
// }
export const reduxMessage = (message) => {
  return { type: "message", payload: message };
};
export const reduxLoading = (status) => {
  return { type: "loading", payload: status };
};
export const reduxExecuted = (bool) => {
  return { type: "executed", payload: bool };
};

export const SET_DATA_SUCCESS = "SET_DATA_SUCCESS";
// setData({message:'some thing'})
const setDataSuccess = createAction(SET_DATA_SUCCESS, "data");
export const setData = (data) => {
  return (dispatch) => {
    dispatch(setDataSuccess(data));
  };
};

export const GET_ALL_FEATURE_LIST_REQUEST = "GET_ALL_FEATURE_LIST_REQUEST";
export const GET_ALL_FEATURE_LIST_SUCCESS = "GET_ALL_FEATURE_LIST_SUCCESS";
export const GET_ALL_FEATURE_LIST_FAILURE = "GET_ALL_FEATURE_LIST_FAILURE";

const getAllFeatureListRequest = createAction(GET_ALL_FEATURE_LIST_REQUEST);
const getAllFeatureListSuccess = createAction(
  GET_ALL_FEATURE_LIST_SUCCESS,
  "data"
);
const getAllFeatureListFailure = createAction(
  GET_ALL_FEATURE_LIST_FAILURE,
  "error"
);

export const getAllFeatureList = () => {
  return (dispatch) => {
    dispatch(getAllFeatureListRequest());
    axios
      .get(`${backEndApi}testCase/retrieveTestCase`)
      .then((res) => {
        dispatch(getAllFeatureListSuccess(res.data.message));
      })
      .catch((error) => dispatch(getAllFeatureListFailure(error)));
  };
};

export const GET_EXECUTED_DATA_REQUEST = "GET_EXECUTED_DATA_REQUEST";
export const GET_EXECUTED_DATA_SUCCESS = "GET_EXECUTED_DATA_SUCCESS";
export const GET_EXECUTED_DATA_FAILURE = "GET_EXECUTED_DATA_FAILURE";

const getExecutedDataRequest = createAction(GET_EXECUTED_DATA_REQUEST);
const getExecutedDataSuccess = createAction(GET_EXECUTED_DATA_SUCCESS, "data");
const getExecutedDataFailure = createAction(GET_EXECUTED_DATA_FAILURE, "error");

export const getExecutedData = (id, key, callback) => {
  return (dispatch) => {
    dispatch(getExecutedDataRequest());
    axios
      .get(`${backEndApi}testCase/getExecutedClone?id=${id}&cloneKey=${key}`)
      .then((res) => {
        dispatch(getExecutedDataSuccess(res.data.message));
        callback && callback();
      })
      .catch((error) => dispatch(getExecutedDataFailure(error)));
  };
};

export const POST_ORIGINAL_CASE_REQUEST = "POST_ORIGINAL_CASE_REQUEST";
export const POST_ORIGINAL_CASE_SUCCESS = "POST_ORIGINAL_CASE_SUCCESS";
export const POST_ORIGINAL_CASE_FAILURE = "POST_ORIGINAL_CASE_FAILURE";

const postOriginalCaseRequest = createAction(POST_ORIGINAL_CASE_REQUEST);
const postOriginalCaseSuccess = createAction(
  POST_ORIGINAL_CASE_SUCCESS,
  "data"
);
const postOriginalCaseFailure = createAction(
  POST_ORIGINAL_CASE_FAILURE,
  "error"
);

export const postOriginalCase = (payload, callback) => {
  return (dispatch) => {
    dispatch(postOriginalCaseRequest());
    axios
      .post(`${backEndApi}testCase/testCaseClone`, payload)
      .then((res) => {
        dispatch(postOriginalCaseSuccess(res.data.message));
        callback && callback(res.data.message);
      })
      .catch((error) => dispatch(postOriginalCaseFailure(error)));
  };
};
export const GET_UPLOADED_CASE_REQUEST = "GET_UPLOADED_CASE_REQUEST";
export const GET_UPLOADED_CASE_SUCCESS = "GET_UPLOADED_CASE_SUCCESS";
export const GET_UPLOADED_CASE_FAILURE = "GET_UPLOADED_CASE_FAILURE";

const getUploadedCaseRequest = createAction(GET_UPLOADED_CASE_REQUEST);
const getUploadedCaseSuccess = createAction(GET_UPLOADED_CASE_SUCCESS, "data");
const getUploadedCaseFailure = createAction(GET_UPLOADED_CASE_FAILURE, "error");

export const getUploadedCase = (id) => {
  return (dispatch) => {
    dispatch(getUploadedCaseRequest());
    axios
      .get(`${backEndApi}testCase/getUploadedFile?id=${id}`)
      .then((res) => {
        dispatch(getUploadedCaseSuccess(res.data.message));
      })
      .catch((error) => dispatch(getUploadedCaseFailure(error)));
  };
};
export const GET_UPLOADED_CLONE_CASE_REQUEST =
  "GET_UPLOADED_CLONE_CASE_REQUEST";
export const GET_UPLOADED_CLONE_CASE_SUCCESS =
  "GET_UPLOADED_CLONE_CASE_SUCCESS";
export const GET_UPLOADED_CLONE_CASE_FAILURE =
  "GET_UPLOADED_CLONE_CASE_FAILURE";

const getUploadedCaseCloneRequest = createAction(
  GET_UPLOADED_CLONE_CASE_REQUEST
);
const getUploadedCaseCloneSuccess = createAction(
  GET_UPLOADED_CLONE_CASE_SUCCESS,
  "data"
);
const getUploadedCaseCloneFailure = createAction(
  GET_UPLOADED_CLONE_CASE_FAILURE,
  "error"
);

export const getUploadedCloneCase = (id, cloneKey) => {
  return (dispatch) => {
    dispatch(getUploadedCaseCloneRequest());
    axios
      .get(
        `${backEndApi}testCase/getuploadedClonefile?id=${id}&cloneKey=${cloneKey}`
      )
      .then((res) => {
        dispatch(getUploadedCaseCloneSuccess(res.data.message));
      })
      .catch((error) => dispatch(getUploadedCaseCloneFailure(error)));
  };
};

export const POST_TEST_CASE_DATA_REQUEST = "POST_TEST_CASE_DATA_REQUEST";
export const POST_TEST_CASE_DATA_SUCCESS = "POST_TEST_CASE_DATA_SUCCESS";
export const POST_TEST_CASE_DATA_FAILURE = "POST_TEST_CASE_DATA_FAILURE";

const postTestCaseDataRequest = createAction(POST_TEST_CASE_DATA_REQUEST);
const postTestCaseDataSuccess = createAction(
  POST_TEST_CASE_DATA_SUCCESS,
  "data"
);
const postTestCaseDataFailure = createAction(
  POST_TEST_CASE_DATA_FAILURE,
  "error"
);

export const postTestCaseData = (
  caseCreated = "",
  payload,
  getCallBack,
  callBack
) => {
  const URL =
    caseCreated === "" ? "testCase/testCaseInfo" : "testCase/uploadFile";

  return (dispatch) => {
    dispatch(postTestCaseDataRequest());
    axios
      .post(`${backEndApi}${URL}`, payload)
      .then((res) => {
        dispatch(postTestCaseDataSuccess(res.data.message));
        if (caseCreated === "") {
          callBack(res.data.message);
        } else getCallBack(caseCreated);
      })
      .catch((error) => dispatch(postTestCaseDataFailure(error)));
  };
};

export const POST_EXECUTED_VIDEO_REQUEST = "POST_EXECUTED_VIDEO_REQUEST";
export const POST_EXECUTED_VIDEO_SUCCESS = "POST_EXECUTED_VIDEO_SUCCESS";
export const POST_EXECUTED_VIDEO_FAILURE = "POST_EXECUTED_VIDEO_FAILURE";

const postExecutedVideoRequest = createAction(POST_EXECUTED_VIDEO_REQUEST);
const postExecutedVideoSuccess = createAction(
  POST_EXECUTED_VIDEO_SUCCESS,
  "data"
);
const postExecutedVideoFailure = createAction(
  POST_EXECUTED_VIDEO_FAILURE,
  "error"
);

export const postExecutedVideo = (file, callBack) => {
  return (dispatch) => {
    dispatch(postExecutedVideoRequest());
    axios
      .post(`${backEndApi}testCase/uploadVideo`, file)
      .then((res) => {
        dispatch(postExecutedVideoSuccess(res.data.message));
        callBack && callBack(res.data.message);
      })
      .catch((error) => dispatch(postExecutedVideoFailure(error)));
  };
};

export const POST_TEST_CASE_STATUS_REQUEST = "POST_TEST_CASE_STATUS_REQUEST";
export const POST_TEST_CASE_STATUS_SUCCESS = "POST_TEST_CASE_STATUS_SUCCESS";
export const POST_TEST_CASE_STATUS_FAILURE = "POST_TEST_CASE_STATUS_FAILURE";

const postTestCaseStatusRequest = createAction(POST_TEST_CASE_STATUS_REQUEST);
const postTestCaseStatusSuccess = createAction(
  POST_TEST_CASE_STATUS_SUCCESS,
  "data"
);
const postTestCaseStatusFailure = createAction(
  POST_TEST_CASE_STATUS_FAILURE,
  "error"
);

export const postTestCaseStatus = (payload, callBack = () => {}) => {
  return (dispatch) => {
    dispatch(postTestCaseStatusRequest());
    axios
      .post(`${backEndApi}testCase/testCaseStatus`, payload)
      .then((res) => {
        dispatch(postTestCaseStatusSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(postTestCaseStatusFailure(error)));
  };
};

export const POST_TEST_CASE_INSERT_ROW_REQUEST =
  "POST_TEST_CASE_INSERT_ROW_REQUEST";
export const POST_TEST_CASE_INSERT_ROW_SUCCESS =
  "POST_TEST_CASE_INSERT_ROW_SUCCESS";
export const POST_TEST_CASE_INSERT_ROW_FAILURE =
  "POST_TEST_CASE_INSERT_ROW_FAILURE";

const postTestCaseInsertRowRequest = createAction(
  POST_TEST_CASE_INSERT_ROW_REQUEST
);
const postTestCaseInsertRowSuccess = createAction(
  POST_TEST_CASE_INSERT_ROW_SUCCESS,
  "data"
);
const postTestCaseInsertRowFailure = createAction(
  POST_TEST_CASE_INSERT_ROW_FAILURE,
  "error"
);

export const postTestCaseInsertRow = (data, callBack) => {
  return (dispatch) => {
    dispatch(postTestCaseInsertRowRequest());
    axios
      .post(`${backEndApi}testCase/uploadRowData`, data)
      .then((res) => {
        dispatch(postTestCaseInsertRowSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(postTestCaseInsertRowFailure(error)));
  };
};

export const POST_CLONE_CASE_INSERT_ROW_REQUEST =
  "POST_CLONE_CASE_INSERT_ROW_REQUEST";
export const POST_CLONE_CASE_INSERT_ROW_SUCCESS =
  "POST_CLONE_CASE_INSERT_ROW_SUCCESS";
export const POST_CLONE_CASE_INSERT_ROW_FAILURE =
  "POST_CLONE_CASE_INSERT_ROW_FAILURE";

const postCloneCaseInsertRowRequest = createAction(
  POST_CLONE_CASE_INSERT_ROW_REQUEST
);
const postCloneCaseInsertRowSuccess = createAction(
  POST_CLONE_CASE_INSERT_ROW_SUCCESS,
  "data"
);
const postCloneCaseInsertRowFailure = createAction(
  POST_CLONE_CASE_INSERT_ROW_FAILURE,
  "error"
);

export const postCloneCaseInsertRow = (data, callBack) => {
  return (dispatch) => {
    dispatch(postCloneCaseInsertRowRequest());
    axios
      .post(`${backEndApi}testCase/executedinsertrowapi`, data)
      .then((res) => {
        dispatch(postCloneCaseInsertRowSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(postCloneCaseInsertRowFailure(error)));
  };
};

export const GET_JIRA_MEMBER_LIST_REQUEST = "GET_JIRA_MEMBER_LIST_REQUEST";
export const GET_JIRA_MEMBER_LIST_SUCCESS = "GET_JIRA_MEMBER_LIST_SUCCESS";
export const GET_JIRA_MEMBER_LIST_FAILURE = "GET_JIRA_MEMBER_LIST_FAILURE";

const getJiraMemberListRequest = createAction(GET_JIRA_MEMBER_LIST_REQUEST);
const getJiraMemberListSuccess = createAction(
  GET_JIRA_MEMBER_LIST_SUCCESS,
  "data"
);
const getJiraMemberListFailure = createAction(
  GET_JIRA_MEMBER_LIST_FAILURE,
  "error"
);

export const getJiraMemberList = (callBack) => {
  return (dispatch) => {
    dispatch(getJiraMemberListRequest());
    axios
      .get(`${backEndApi}testCase/getJiraMember `)
      .then((res) => {
        dispatch(getJiraMemberListSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(getJiraMemberListFailure(error)));
  };
};

export const POST_CREATE_JIRA_TICKET_REQUEST =
  "POST_CREATE_JIRA_TICKET_REQUEST";
export const POST_CREATE_JIRA_TICKET_SUCCESS =
  "POST_CREATE_JIRA_TICKET_SUCCESS";
export const POST_CREATE_JIRA_TICKET_FAILURE =
  "POST_CREATE_JIRA_TICKET_FAILURE";

const postCreateJiraTicketRequest = createAction(
  POST_CREATE_JIRA_TICKET_REQUEST
);
const postCreateJiraTicketSuccess = createAction(
  POST_CREATE_JIRA_TICKET_SUCCESS,
  "data"
);
const postCreateJiraTicketFailure = createAction(
  POST_CREATE_JIRA_TICKET_FAILURE,
  "error"
);

export const postCreateJiraTicket = (data, callBack) => {
  return (dispatch) => {
    dispatch(postCreateJiraTicketRequest());
    axios
      .post(`${backEndApi}testCase/createIssue`, data)
      .then((res) => {
        dispatch(postCreateJiraTicketSuccess(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(postCreateJiraTicketFailure(error)));
  };
};

export const GET_SINGLE_CASE_DATA_REQUEST = "GET_SINGLE_CASE_DATA_REQUEST";
export const GET_SINGLE_CASE_DATA_SUCCESS = "GET_SINGLE_CASE_DATA_SUCCESS";
export const GET_SINGLE_CASE_DATA_FAILURE = "GET_SINGLE_CASE_DATA_FAILURE";

const getSingleCaseDataRequest = createAction(GET_SINGLE_CASE_DATA_REQUEST);
const getSingleCaseDataSuccess = createAction(
  GET_SINGLE_CASE_DATA_SUCCESS,
  "data"
);
const getSingleCaseDataFailure = createAction(
  GET_SINGLE_CASE_DATA_FAILURE,
  "error"
);

export const getSingleCaseData = (id, cloneKey, callback) => {
  return (dispatch) => {
    dispatch(getSingleCaseDataRequest());
    axios
      .get(`${backEndApi}testCase/singleTestCase?id=${id}&cloneKey=${cloneKey}`)
      .then((res) => {
        dispatch(getSingleCaseDataSuccess(res.data.message));
        callback && callback(res.data.message);
      })
      .catch((error) => dispatch(getSingleCaseDataFailure(error)));
  };
};

export const DELETE_FEATURE_DATA_REQUEST = "DELETE_FEATURE_DATA_REQUEST";
export const DELETE_FEATURE_DATA_SUCCESS = "DELETE_FEATURE_DATA__SUCCESS";
export const DELETE_FEATURE_DATA_FAILURE = "DELETE_FEATURE_DATA__FAILURE";

const deleteFeatureDataRequest = createAction(DELETE_FEATURE_DATA_REQUEST);
const deleteFeatureDataSuccess = createAction(
  DELETE_FEATURE_DATA_SUCCESS,
  "data"
);
const deleteFeatureDataFailure = createAction(
  DELETE_FEATURE_DATA_FAILURE,
  "error"
);

export const deleteFeatureData = (id, cloneKey, callback) => {
  return (dispatch) => {
    dispatch(deleteFeatureDataRequest());
    axios
      .delete(
        `${backEndApi}testCase/deleteFeatureData?id=${id}&cloneKey=${cloneKey}`
      )
      .then((res) => {
        dispatch(deleteFeatureDataSuccess(res.data.message));
        callback && callback(res.data.message);
      })
      .catch((error) => dispatch(deleteFeatureDataFailure(error)));
  };
};

export const PATCH_ACTUAL_RESULT_UPDATE_REQUEST =
  "PATCH_ACTUAL_RESULT_UPDATE_REQUEST";
export const PATCH_ACTUAL_RESULT_UPDATE_SUCCESS =
  "PATCH_ACTUAL_RESULT_UPDATE_SUCCESS";
export const PATCH_ACTUAL_RESULT_UPDATE_FAILURE =
  "PATCH_ACTUAL_RESULT_UPDATE_FAILURE";

const patchActualResultUpdateRequest = createAction(
  PATCH_ACTUAL_RESULT_UPDATE_REQUEST
);
const patchActualResultUpdateSuccess = createAction(
  PATCH_ACTUAL_RESULT_UPDATE_SUCCESS,
  "data"
);
const patchActualResultUpdateFailure = createAction(
  PATCH_ACTUAL_RESULT_UPDATE_FAILURE,
  "error"
);

export const patchActualResultUpdate = (payload, callback = () => {}) => {
  return (dispatch) => {
    dispatch(patchActualResultUpdateRequest());
    axios
      .patch(`${backEndApi}testCase/actualResultUpdate`, payload)
      .then((res) => {
        dispatch(patchActualResultUpdateSuccess(res.data.message));
        callback && callback(res.data.message);
      })
      .catch((error) => dispatch(patchActualResultUpdateFailure(error)));
  };
};
export const POST_UPLOAD_FILE_REQUEST = "POST_UPLOAD_FILE_REQUEST";
export const POST_UPLOAD_FILE_SUCCESS = "POST_UPLOAD_FILE_SUCCESS";
export const POST_UPLOAD_FILE_FAILURE = "POST_UPLOAD_FILE_FAILURE";

const postUploadFileRequest = createAction(POST_UPLOAD_FILE_REQUEST);
const postUploadFileSuccess = createAction(POST_UPLOAD_FILE_SUCCESS, "data");
const postUploadFileFailure = createAction(POST_UPLOAD_FILE_FAILURE, "error");

export const postUploadFile = (payload, callback = () => {}) => {
  return (dispatch) => {
    dispatch(postUploadFileRequest());
    axios
      .post(`${backEndApi}testCase/postUploadFile`, payload)
      .then((res) => {
        dispatch(postUploadFileSuccess(res.data.message));
        callback && callback(res.data.message);
      })
      .catch((error) => dispatch(postUploadFileFailure(error)));
  };
};

export const GET_OAUTH2_USER_INFO_REQUEST = "GET_OAUTH2_USER_INFO_REQUEST";
export const GET_OAUTH2_USER_INFO_SUCCESS = "GET_OAUTH2_USER_INFO_SUCCESS";
export const GET_OAUTH2_USER_INFO_FAILURE = "GET_OAUTH2_USER_INFO_FAILURE";

const getOauth2UserInfoRequest = createAction(GET_OAUTH2_USER_INFO_REQUEST);
const getOauth2UserInfoSuccess = createAction(
  GET_OAUTH2_USER_INFO_SUCCESS,
  "data"
);
const getOauth2UserInfoFailure = createAction(
  GET_OAUTH2_USER_INFO_FAILURE,
  "error"
);

export const getOauth2UserInfo = (accessToken, callback) => {
  return (dispatch) => {
    dispatch(getOauth2UserInfoRequest());
    axios
      .get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        dispatch(getOauth2UserInfoSuccess(res.data));
        dispatch(postLogin(res.data.email, callback));
      })
      .catch((error) => {
        dispatch(getOauth2UserInfoFailure(error));
      });
  };
};

export const POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAILURE = "POST_REGISTER_FAILURE";

const postRegisterRequest = createAction(POST_REGISTER_REQUEST);
const postRegisterSuccess = createAction(POST_REGISTER_SUCCESS, "data");
const postRegisterFailure = createAction(POST_REGISTER_FAILURE, "error");

export const postRegister = (payload, callback = () => {}) => {
  return (dispatch) => {
    dispatch(postRegisterRequest());
    axios
      .post(`${backEndApi}oauth/register`, payload)
      .then((res) => {
        dispatch(postRegisterSuccess(res.data.message));
        callback && callback(res.data.message);
      })
      .catch((error) => dispatch(postRegisterFailure(error)));
  };
};

export const GET_USER_ROLE_DATA_REQUEST = "GET_USER_ROLE_DATA_REQUEST";
export const GET_USER_ROLE_DATA_SUCCESS = "GET_USER_ROLE_DATA_SUCCESS";
export const GET_USER_ROLE_DATA_FAILURE = "GET_USER_ROLE_DATA_FAILURE";

const getUserRoleDataRequest = createAction(GET_USER_ROLE_DATA_REQUEST);
const getUserRoleDataSuccess = createAction(GET_USER_ROLE_DATA_SUCCESS, "data");
const getUserRoleDataFailure = createAction(
  GET_USER_ROLE_DATA_FAILURE,
  "error"
);

export const getUserRoleData = () => {
  return (dispatch) => {
    dispatch(getUserRoleDataRequest());
    axios
      .get(`${backEndApi}oauth/getRoles`)
      .then((res) => {
        dispatch(getUserRoleDataSuccess(res.data.message));
      })
      .catch((error) => dispatch(getUserRoleDataFailure(error)));
  };
};
export const GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE";

const getUserDataRequest = createAction(GET_USER_DATA_REQUEST);
const getUserDataSuccess = createAction(GET_USER_DATA_SUCCESS, "data");
const getUserDataFailure = createAction(GET_USER_DATA_FAILURE, "error");

export const getUserData = () => {
  return (dispatch) => {
    dispatch(getUserDataRequest());
    axios
      .get(`${backEndApi}oauth/getUserData`)
      .then((res) => {
        dispatch(getUserDataSuccess(res.data.message));
      })
      .catch((error) => dispatch(getUserDataFailure(error)));
  };
};

export const POST_USER_ROLE_REQUEST = "POST_USER_ROLE_REQUEST";
export const POST_USER_ROLE_SUCCESS = "POST_USER_ROLE_SUCCESS";
export const POST_USER_ROLE_FAILURE = "POST_USER_ROLE_FAILURE";

const postUserRoleRequest = createAction(POST_USER_ROLE_REQUEST);
const postUserRoleSuccess = createAction(POST_USER_ROLE_SUCCESS, "data");
const postUserRoleFailure = createAction(POST_USER_ROLE_FAILURE, "error");

export const postUserRole = (payload) => {
  return (dispatch) => {
    dispatch(postUserRoleRequest());
    axios
      .post(`${backEndApi}oauth/userRole`, payload)
      .then((res) => {
        dispatch(postUserRoleSuccess(res.data.message));
        dispatch(getUserRoleData());
      })
      .catch((error) => dispatch(postUserRoleFailure(error)));
  };
};

export const DELETE_USER_ROLE_REQUEST = "DELETE_USER_ROLE_REQUEST";
export const DELETE_USER_ROLE_SUCCESS = "DELETE_USER_ROLE_SUCCESS";
export const DELETE_USER_ROLE_FAILURE = "DELETE_USER_ROLE_FAILURE";

const deleteUserRoleRequest = createAction(DELETE_USER_ROLE_REQUEST);
const deleteUserRoleSuccess = createAction(DELETE_USER_ROLE_SUCCESS, "data");
const deleteUserRoleFailure = createAction(DELETE_USER_ROLE_FAILURE, "error");

export const deleteUserRole = (payload, callback) => {
  return (dispatch) => {
    dispatch(deleteUserRoleRequest());
    axios
      .delete(`${backEndApi}oauth/DeleteUserData?userId=${payload.userId}`)
      .then((res) => {
        dispatch(deleteUserRoleSuccess(res.data.message));
        dispatch(getUserData());
        callback && callback();
      })
      .catch((error) => dispatch(deleteUserRoleFailure(error)));
  };
};

export const PUT_ROLE_CHANGE_REQUEST = "PUT_ROLE_CHANGE_REQUEST";
export const PUT_ROLE_CHANGE_SUCCESS = "PUT_ROLE_CHANGE_SUCCESS";
export const PUT_ROLE_CHANGE_FAILURE = "PUT_ROLE_CHANGE_FAILURE";

const putRoleChangeRequest = createAction(PUT_ROLE_CHANGE_REQUEST);
const putRoleChangeSuccess = createAction(PUT_ROLE_CHANGE_SUCCESS, "data");
const putRoleChangeFailure = createAction(PUT_ROLE_CHANGE_FAILURE, "error");

export const putRoleChange = (payload, callback = () => {}) => {
  return (dispatch) => {
    dispatch(putRoleChangeRequest());
    axios
      .put(`${backEndApi}oauth/roleChange`, payload)
      .then((res) => {
        dispatch(putRoleChangeSuccess(res.data.message));
        dispatch(getUserData());
        callback && callback();
      })
      .catch((error) => dispatch(putRoleChangeFailure(error)));
  };
};

export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE";

const postLoginRequest = createAction(POST_LOGIN_REQUEST);
const postLoginSuccess = createAction(POST_LOGIN_SUCCESS, "data");
const postLoginFailure = createAction(POST_LOGIN_FAILURE, "error");

export const postLogin = (email, callback) => {
  return (dispatch) => {
    dispatch(postLoginRequest());
    axios
      .post(`${backEndApi}oauth/login?email=${email}`)
      .then((res) => {
        dispatch(postLoginSuccess(res.data.message));
        localStorage.setItem(
          "AuthName",
          JSON.stringify(res.data.message.username)
        );
        localStorage.setItem("authRes", JSON.stringify(res.data.message));
        callback && callback(res.data.message);
      })
      .catch((error) => dispatch(postLoginFailure(error)));
  };
};

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_FAILURE = "DELETE_ROLE_FAILURE";

const deleteRoleRequest = createAction(DELETE_ROLE_REQUEST);
const deleteRoleSuccess = createAction(DELETE_ROLE_SUCCESS, "data");
const deleteRoleFailure = createAction(DELETE_ROLE_FAILURE, "error");

export const deleteRole = (payload) => {
  return (dispatch) => {
    dispatch(deleteRoleRequest());
    axios
      .delete(`${backEndApi}oauth/deleteRole?roleId=${payload}`)
      .then((res) => {
        dispatch(deleteRoleSuccess(res.data.message));
        dispatch(getUserRoleData());
      })
      .catch((error) => dispatch(deleteRoleFailure(error)));
  };
};

export const POST_PAGE_ACCESS_REQUEST = "POST_PAGE_ACCESS_REQUEST";
export const POST_PAGE_ACCESS_SUCCESS = "POST_PAGE_ACCESS_SUCCESS";
export const POST_PAGE_ACCESS_FAILURE = "POST_PAGE_ACCESS_FAILURE";

const postPageAccessRequest = createAction(POST_PAGE_ACCESS_REQUEST);
const postPageAccessSuccess = createAction(POST_PAGE_ACCESS_SUCCESS, "data");
const postPageAccessFailure = createAction(POST_PAGE_ACCESS_FAILURE, "error");

export const postPageAccess = (payload) => {
  return (dispatch) => {
    dispatch(postPageAccessRequest());
    axios
      .post(`${backEndApi}oauth/pageAccess`, payload)
      .then((res) => {
        dispatch(postPageAccessSuccess(res.data.message));
        dispatch(getUserRoleData());
      })
      .catch((error) => dispatch(postPageAccessFailure(error)));
  };
};

export const POST_CREATE_DEPARTMENT_REQUEST = "POST_CREATE_DEPARTMENT_REQUEST";
export const POST_CREATE_DEPARTMENT_SUCCESS = "POST_CREATE_DEPARTMENT_SUCCESS";
export const POST_CREATE_DEPARTMENT_FAILURE = "POST_CREATE_DEPARTMENT_FAILURE";

const postCreateDepartmentRequest = createAction(
  POST_CREATE_DEPARTMENT_REQUEST
);
const postCreateDepartmentSuccess = createAction(
  POST_CREATE_DEPARTMENT_SUCCESS,
  "data"
);
const postCreateDepartmentFailure = createAction(
  POST_CREATE_DEPARTMENT_FAILURE,
  "error"
);

export const postCreateDepartment = (payload) => {
  return (dispatch) => {
    dispatch(postCreateDepartmentRequest());
    axios
      .post(`${backEndApi}documentation/createDepartment`, payload)
      .then((res) => {
        dispatch(postCreateDepartmentSuccess(res.data.message));
        dispatch(getUserRoleData());
      })
      .catch((error) => dispatch(postCreateDepartmentFailure(error)));
  };
};

export const DELETE_CREATE_DEPARTMENT_REQUEST =
  "DELETE_CREATE_DEPARTMENT_REQUEST";
export const DELETE_CREATE_DEPARTMENT_SUCCESS =
  "DELETE_CREATE_DEPARTMENT_SUCCESS";
export const DELETE_CREATE_DEPARTMENT_FAILURE =
  "DELETE_CREATE_DEPARTMENT_FAILURE";

const deleteCreateDepartmentRequest = createAction(
  DELETE_CREATE_DEPARTMENT_REQUEST
);
const deleteCreateDepartmentSuccess = createAction(
  DELETE_CREATE_DEPARTMENT_SUCCESS,
  "data"
);
const deleteCreateDepartmentFailure = createAction(
  DELETE_CREATE_DEPARTMENT_FAILURE,
  "error"
);

export const deleteCreateDepartment = (id) => {
  return (dispatch) => {
    dispatch(deleteCreateDepartmentRequest());
    axios
      .delete(`${backEndApi}documentation/deleteIndividualDepartment?id=${id}`)
      .then((res) => {
        dispatch(deleteCreateDepartmentSuccess(res.data.message));
        dispatch(getUserRoleData());
      })
      .catch((error) => dispatch(deleteCreateDepartmentFailure(error)));
  };
};

export const POST_ADD_USER_DEPARTMENT_REQUEST =
  "POST_ADD_USER_DEPARTMENT_REQUEST";
export const POST_ADD_USER_DEPARTMENT_SUCCESS =
  "POST_ADD_USER_DEPARTMENT_SUCCESS";
export const POST_ADD_USER_DEPARTMENT_FAILURE =
  "POST_ADD_USER_DEPARTMENT_FAILURE";

const postAddUserDepartmentRequest = createAction(
  POST_ADD_USER_DEPARTMENT_REQUEST
);
const postAddUserDepartmentSuccess = createAction(
  POST_ADD_USER_DEPARTMENT_SUCCESS,
  "data"
);
const postAddUserDepartmentFailure = createAction(
  POST_ADD_USER_DEPARTMENT_FAILURE,
  "error"
);

export const postAddUserDepartment = (payload, callBack) => {
  return (dispatch) => {
    dispatch(postAddUserDepartmentRequest());
    axios
      .post(`${backEndApi}oauth/addUserDepartment`, payload)
      .then((res) => {
        dispatch(postAddUserDepartmentSuccess(res.data.message));
        dispatch(getUserData(res.data.message));
        callBack && callBack();
      })
      .catch((error) => dispatch(postAddUserDepartmentFailure(error)));
  };
};

export const GENERATE_ACCESS_TOKEN_REQUEST = "GENERATE_ACCESS_TOKEN_REQUEST";
export const GENERATE_ACCESS_TOKEN_SUCCESS = "GENERATE_ACCESS_TOKEN_SUCCESS";
export const GENERATE_ACCESS_TOKEN_FAILURE = "GENERATE_ACCESS_TOKEN_FAILURE";

const generateAccessTokenRequest = createAction(GENERATE_ACCESS_TOKEN_REQUEST);
const generateAccessTokenSuccess = createAction(
  GENERATE_ACCESS_TOKEN_SUCCESS,
  "data"
);
const generateAccessTokenFailure = createAction(
  GENERATE_ACCESS_TOKEN_FAILURE,
  "error"
);

export const reGenerateAccessToken = (payload) => {
  return (dispatch) => {
    dispatch(generateAccessTokenRequest());
    axios
      .post("https://oauth2.googleapis.com/token", null, payload)
      .then((res) => {
        dispatch(generateAccessTokenSuccess(res.data.access_token));
        setLocalStorageValue(res.data.access_token, modifySysTime);
      })
      .catch((error) => generateAccessTokenFailure(error));
  };
};

export const POST_GOOGLE_AUTH_REQUEST = "POST_GOOGLE_AUTH_REQUEST";
export const POST_GOOGLE_AUTH_SUCCESS = "POST_GOOGLE_AUTH_SUCCESS";
export const POST_GOOGLE_AUTH_FAILURE = "POST_GOOGLE_AUTH_FAILURE";

const postGoogleAuthRequest = createAction(POST_GOOGLE_AUTH_REQUEST);
const postGoogleAuthSuccess = createAction(POST_GOOGLE_AUTH_SUCCESS, "data");
const postGoogleAuthFailure = createAction(POST_GOOGLE_AUTH_FAILURE, "error");

export const getGoogleAuthDetails = (codeId) => {
  return (dispatch) => {
    dispatch(postGoogleAuthRequest());
    axios
      .post(`${backEndApi}oauth/googleAuthTokens`, { code: codeId })
      .then((res) => {
        dispatch(postGoogleAuthSuccess(res.data.message));
        setLocalStorageValue(
          res.data.message.access_token,
          modifySysTime,
          res.data.message.refresh_token
        );
      })
      .catch((error) => dispatch(postGoogleAuthFailure(error)));
  };
};
