import React from "react";
import Tooltip from "@mui/material/Tooltip";

export default class AgTableReason extends React.Component {
  constructor() {
    super();
    this.state = {
      modelShow: false,
      videoURL: "",
    };
  }
  handleClose = () => {
    this.setState({ modelShow: false });
  };
  handleVideo = (value) => {
    this.setState({ modelShow: true });
    this.setState({ videoURL: value });
  };
  getDateDiff = () => {
    // const getTime = new Date();
    var createdTime = this.props.data.createdAt;
    createdTime = new Date(createdTime);
    // const diff = getTime - createdTime;
    // if (diff > 864e5) return Math.floor(diff / 864e5) + " Days ago"; // 36e5
    // else return "Today";
    return createdTime.getDate() + "/" + (createdTime.getMonth() + 1) + "/" + createdTime.getFullYear() + "  " + createdTime.getHours() + ":" + createdTime.getMinutes();
  };

  render() {
    return (
      <div className="p-2">
        <Tooltip title={`Done by: ${this.props.data?.executionReason?.executed}. Date: ${this.getDateDiff()}`}>
          <span className="height_20">
            Reason<span className="bold"> {this.props.data?.executionReason?.reason}</span>
          </span>
        </Tooltip>
        {/* <span className="height_20">
          Done by:<span className="bold"> {this.props.data?.executionReason?.executed}</span>
        </span>
        <span className="">
          Reason: <span className="bold"> {this.props.data?.executionReason?.reason}</span>
        </span> */}
        {/* <span className="bold"> {this.getDateDiff()}</span> */}
      </div>
    );
  }
}
