import React, { useState } from "react";
import { MoreVert } from "@material-ui/icons";
import { Menu, MenuItem } from "@material-ui/core";
import "./AdminTable.css";
import { Chip } from "@mui/material";
import CreatePopup from "./Modal/createPopup";
import EditPageAccess from "./Modal/EditPageAccess";
import { fromJS } from "immutable";
import EditAndDelete from "./Modal/EditAndDelete";
import {
  postCreateDepartment,
  postUserRole,
  deleteRole,
  deleteCreateDepartment,
  postPageAccess,
} from "../../Redux/TestCase/Action";
import { useDispatch, useSelector } from "react-redux";
import { selectRoles } from "../../Redux/TestCase/Selector";

/*---------------------------------------UtilsStart------------------------------------------*/
const defaultBool = { role: false, department: false };
const anchorOrigin = {
  vertical: "top",
  horizontal: "left",
};
const transformOrigin = {
  vertical: "top",
  horizontal: "left",
};

const initialTableData = {
  openVert: false,
  selectedRow: null,
  selectedRole: "",
  anchorEl: null,
  openIndex: null,
  openAddDialog: defaultBool,
  openDeleteRoleDialog: defaultBool,
  openDialogType: "",
};
/*---------------------------------------UtilsEnd--------------------------------------------*/

/*---------------------------------------CustomHooksStart-------------------------------------*/
const useTableHook = ({ setRole }) => {
  const [tableData, setTableData] = useState(fromJS(initialTableData));
  const dispatch = useDispatch();
  const roles = useSelector(selectRoles);

  // const handleChangeTableData = (value) => {
  //   if (value === "MoreVertClose") {
  //     setTableData((pre) => pre.set("selectedRow", null).set("openVert", ""));
  //   } else if (value === "ChangeDialogType") {
  //     setTableData((pre) =>
  //       pre.set("openDialogType", "page").set("openVert", "")
  //     );
  //   } else if (value === "AddDialogClose") {
  //     setTableData((pre) =>
  //       pre.set("openAddDialog", fromJS({ department: false, role: false }))
  //     );
  //     setRole("");
  //   } else if (value === "DialogTypeClose") {
  //     setTableData((pre) => pre.set("openDialogType", ""));
  //   } else if (value === "DeleteDialogClose") {
  //     setTableData((pre) =>
  //       pre.set("openDeleteRoleDialog", fromJS(defaultBool))
  //     );
  //   }
  // };

  // const handleChangeDialog = (key) => {
  //   if (key === "role") {
  //     setTableData((pre) => pre.setIn(["openAddDialog", key], true));
  //   } else {
  //     setTableData((pre) =>
  //       pre.setIn(["openDeleteRoleDialog", key], true).set("openVert", "")
  //     );
  //   }
  // };

  const handleMoreVertClose = () => {
    setTableData((pre) => pre.set("selectedRow", null).set("openVert", ""));
  };
  const handleAddRole = (key) => {
    setTableData((pre) => pre.setIn(["openAddDialog", key], true));
  };
  const handleChangeDialogType = () => {
    setTableData((pre) =>
      pre.set("openDialogType", "page").set("openVert", "")
    );
  };
  const handleDeleteRole = (key) => {
    setTableData((pre) =>
      pre.setIn(["openDeleteRoleDialog", key], true).set("openVert", "")
    );
  };
  const handleAddDialogClose = () => {
    setTableData((pre) =>
      pre.set("openAddDialog", fromJS({ department: false, role: false }))
    );
    setRole("");
  };

  const openDialogTypeClose = () => {
    setTableData((pre) => pre.set("openDialogType", ""));
  };

  const handleAddFormSubmit = (event, data) => {
    event.preventDefault();
    tableData.getIn(["openAddDialog", "department"], false) &&
      dispatch(postCreateDepartment({ name: data }));
    tableData.getIn(["openAddDialog", "role"], false) &&
      dispatch(postUserRole({ role: data }));
    setTableData((pre) =>
      pre.set("openAddDialog", fromJS({ department: false, role: false }))
    );
    setRole("");
  };

  const handleDeleteDialogClose = () => {
    setTableData((pre) => pre.set("openDeleteRoleDialog", fromJS(defaultBool)));
  };

  const handleDeleteRoleSubmit = (event, role, type) => {
    event.preventDefault();
    setTableData((pre) => pre.set("openDeleteRoleDialog", fromJS(defaultBool)));
    type === "Role"
      ? dispatch(deleteRole(role._id))
      : dispatch(deleteCreateDepartment(role._id));
  };

  const handleMoreVertClick = (event, row, data, index) => {
    setTableData((pre) =>
      pre
        .set("openVert", data)
        .set("selectedRow", row)
        .set("selectedRole", row)
        .set("anchorEl", event.currentTarget)
        .set("openIndex", index)
    );
  };

  return [
    tableData,
    handleMoreVertClose,
    handleAddRole,
    handleChangeDialogType,
    handleDeleteRole,
    handleAddDialogClose,
    openDialogTypeClose,
    handleAddFormSubmit,
    handleDeleteDialogClose,
    handleDeleteRoleSubmit,
    roles,
    handleMoreVertClick,
  ];
};

/*---------------------------------------CustomHooksEnd---------------------------------------*/

const UserRolesTable = ({ setRole }) => {
  const [
    tableData,
    handleMoreVertClose,
    handleAddRole,
    handleChangeDialogType,
    handleDeleteRole,
    handleAddDialogClose,
    openDialogTypeClose,
    handleAddFormSubmit,
    handleDeleteDialogClose,
    handleDeleteRoleSubmit,
    roles,
    handleMoreVertClick,
  ] = useTableHook({
    setRole,
  });

  const handleCheckIndex = (index) => {
    return Boolean(
      tableData.get("openVert", "") === "role" &&
        tableData.get("openIndex", "") === index
    );
  };

  return (
    <div className="role-list-container">
      <h2 className="role-list-title">Roles</h2>
      <div className="d-flex align-items-center justify-content-center">
        <button
          class="icon-btn add-btn ml-5 mb-2"
          onClick={() => handleAddRole("role")}>
          <div class="add-icon"></div>
          <div class="btn-txt">Add Role</div>
        </button>
      </div>
      <table className="portal_table">
        <thead>
          <tr>
            <th>S.no</th>
            <th>Role</th>
            <th>Page Access</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {roles.toJS().length > 0 &&
            roles.toJS().map((role, index) => (
              <tr key={index} id={index}>
                <td>{index + 1}</td>
                <td>{role.role}</td>
                <td>
                  {role?.pageAccess?.map((item) => (
                    <Chip className="mx-1" label={item} color="primary" />
                  ))}
                </td>
                <td key={index}>
                  <MoreVert
                    className="icon"
                    onClick={(event) =>
                      handleMoreVertClick(event, role, "role", index)
                    }
                  />
                  <Menu
                    key={index}
                    id={index}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    open={handleCheckIndex(index)}
                    onClose={handleMoreVertClose}
                    anchorEl={tableData.get("anchorEl", null)}>
                    <MenuItem onClick={() => handleChangeDialogType()}>
                      <span className="bold">Add Access Pages</span>
                    </MenuItem>
                    <MenuItem
                      key={role.role}
                      onClick={() => handleDeleteRole("role")}>
                      <span className="bold">Delete Role</span>
                    </MenuItem>
                  </Menu>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {tableData.getIn(["openAddDialog", "role"], false) && (
        <CreatePopup
          show={tableData.getIn(["openAddDialog", "role"], false)}
          handleClose={handleAddDialogClose}
          handleClick={handleAddFormSubmit}
          title={"Role"}
          validate={roles}
        />
      )}

      {tableData.getIn(["openDeleteRoleDialog", "role"], false) && (
        <EditAndDelete
          show={tableData.get("openDeleteRoleDialog", "")}
          handleClose={handleDeleteDialogClose}
          handleClick={handleDeleteRoleSubmit}
          title={"Role"}
          data={tableData.get("selectedRole", "")}
          displayData={tableData.getIn(["selectedRole", "role"], "")}
          role={"delete"}
        />
      )}

      {tableData.get("openDialogType", "") === "page" && (
        <EditPageAccess
          show={true}
          handleClose={openDialogTypeClose}
          handleClick={postPageAccess}
          role={tableData.get("selectedRole", "")}
        />
      )}
    </div>
  );
};
export default UserRolesTable;
