import axios from "axios";
import { createAction } from "../../Utils/FunctionUtils";

const backEndApi = process.env.REACT_APP_BackEndUrl;

export const SET_DATA_SUCCESS = "SET_DATA_SUCCESS";
const setDataSuccess = createAction(SET_DATA_SUCCESS, "data");

export const setData = (data) => {
  return (dispatch) => {
    dispatch(setDataSuccess(data));
  };
};
export const DrawerAction = (data) => {
  return {
    type: "Drawer-Open_Close",
    payLoad: data,
  };
};
export const PopupAction = (data) => {
  return {
    type: "Popup_Open_Close",
    payLoad: data,
  };
};
export const ErrorAction = (data) => {
  return {
    type: "Error_show",
    payLoad: data,
  };
};
export const TotalAssetsAction = (data) => {
  return {
    type: "Total_Assets",
    payLoad: data,
  };
};
export const TotalResetAction = () => {
  return {
    type: "Total_Assets_Reset",
  };
};
export const EditAssetsAction = (data) => {
  return {
    type: "Edit_Assets",
    payLoad: data,
  };
};
export const EditCategoryAction = (data) => {
  return {
    type: "Edit_Category",
    payLoad: data,
  };
};
export const CategoryIDAction = (data) => {
  return {
    type: "Category_ID",
    payLoad: data,
  };
};
export const CategoryNameAction = (data) => {
  return {
    type: "Category_Name",
    payLoad: data,
  };
};
export const CategoryErrorAction = (data) => {
  return {
    type: "Error_Category",
    payLoad: data,
  };
};
export const categoryPopupAction = (data) => {
  return {
    type: "Category_open_close",
    payLoad: data,
  };
};
export const EditMapIDAction = (data) => {
  return {
    type: "EditMap_ID",
    payLoad: data,
  };
};
export const EditMapIndexAction = (data) => {
  return {
    type: "EditMapIndex",
    payLoad: data,
  };
};

//------------------------------AssetsApi----------------------------------------------->
export const GET_ASSET_SERVICE_API_REQUEST = "GET_ASSET_SERVICE_API_REQUEST";
export const GET_ASSET_SERVICE_API_SUCCESS = "GET_ASSET_SERVICE_API_SUCCESS";
export const GET_ASSET_SERVICE_API_FAILURE = "GET_ASSET_SERVICE_API_FAILURE";
const getAssetServiceApiRequest = createAction(GET_ASSET_SERVICE_API_REQUEST);
const getAssetServiceApiSuccess = createAction(
  GET_ASSET_SERVICE_API_SUCCESS,
  "data"
);
const getAssetServiceApiFailure = createAction(
  GET_ASSET_SERVICE_API_FAILURE,
  "error"
);

export const getAssetServiceApi = () => (dispatch) => {
  dispatch(getAssetServiceApiRequest());
  axios
    .get(`${backEndApi}asset/retrieveAsset`)
    .then((res) => {
      dispatch(getAssetServiceApiSuccess(res.data.message));
    })
    .catch((err) => {
      dispatch(getAssetServiceApiFailure(err));
    });
};

export const POST_ASSET_SERVICE_API_REQUEST = "POST_ASSET_SERVICE_API_REQUEST";
export const POST_ASSET_SERVICE_API_SUCCESS = "POST_ASSET_SERVICE_API_SUCCESS";
export const POST_ASSET_SERVICE_API_FAILURE = "POST_ASSET_SERVICE_API_FAILURE";
const postAssetServiceApiRequest = createAction(POST_ASSET_SERVICE_API_REQUEST);
const postAssetServiceApiSuccess = createAction(
  POST_ASSET_SERVICE_API_SUCCESS,
  "data"
);
const postAssetServiceApiFailure = createAction(
  POST_ASSET_SERVICE_API_FAILURE,
  "error"
);

export const postAssetServiceApi = (formData) => (dispatch) => {
  dispatch(postAssetServiceApiRequest());
  axios
    .post(`${backEndApi}asset/createAsset`, formData)
    .then((res) => {
      dispatch(postAssetServiceApiSuccess(res.data.message));
      dispatch(getAssetServiceApi());
    })
    .catch((err) => {
      dispatch(postAssetServiceApiFailure(err));
    });
};

export const PUT_ASSET_SERVICE_API_REQUEST = "PUT_ASSET_SERVICE_API_REQUEST";
export const PUT_ASSET_SERVICE_API_SUCCESS = "PUT_ASSET_SERVICE_API_SUCCESS";
export const PUT_ASSET_SERVICE_API_FAILURE = "PUT_ASSET_SERVICE_API_FAILURE";
const putAssetServiceApiRequest = createAction(PUT_ASSET_SERVICE_API_REQUEST);
const putAssetServiceApiSuccess = createAction(
  PUT_ASSET_SERVICE_API_SUCCESS,
  "data"
);
const putAssetServiceApiFailure = createAction(
  PUT_ASSET_SERVICE_API_FAILURE,
  "error"
);

export const putAssetServiceApi = (formData) => (dispatch) => {
  dispatch(putAssetServiceApiRequest());
  axios
    .put(`${backEndApi}asset/updateAsset`, formData)
    .then((res) => {
      dispatch(putAssetServiceApiSuccess(res.data.message));
      dispatch(getAssetServiceApi());
    })
    .catch((err) => {
      dispatch(putAssetServiceApiFailure(err));
    });
};

export const DELETE_ASSET_SERVICE_API_REQUEST =
  "DELETE_ASSET_SERVICE_API_REQUEST";
export const DELETE_ASSET_SERVICE_API_SUCCESS =
  "DELETE_ASSET_SERVICE_API_SUCCESS";
export const DELETE_ASSET_SERVICE_API_FAILURE =
  "DELETE_ASSET_SERVICE_API_FAILURE";
const deleteAssetServiceApiRequest = createAction(
  DELETE_ASSET_SERVICE_API_REQUEST
);
const deleteAssetServiceApiSuccess = createAction(
  DELETE_ASSET_SERVICE_API_SUCCESS,
  "data"
);
const deleteAssetServiceApiFailure = createAction(
  DELETE_ASSET_SERVICE_API_FAILURE,
  "error"
);

export const deleteAssetServiceApi = (id) => (dispatch) => {
  dispatch(deleteAssetServiceApiRequest());
  axios
    .delete(`${backEndApi}asset/deleteAsset?assetId=${id}`)
    .then((res) => {
      dispatch(deleteAssetServiceApiSuccess(res.data.message));
      dispatch(getAssetServiceApi());
    })
    .catch((err) => {
      dispatch(deleteAssetServiceApiFailure(err));
    });
};
//------------------------------categoryApi----------------------------------------------->
export const GET_CATEGORY_SERVICE_API_REQUEST =
  "GET_CATEGORY_SERVICE_API_REQUEST";
export const GET_CATEGORY_SERVICE_API_SUCCESS =
  "GET_CATEGORY_SERVICE_API_SUCCESS";
export const GET_CATEGORY_SERVICE_API_FAILURE =
  "GET_CATEGORY_SERVICE_API_FAILURE";
const getCategoryServiceApiRequest = createAction(
  GET_CATEGORY_SERVICE_API_REQUEST
);
const getCategoryServiceApiSuccess = createAction(
  GET_CATEGORY_SERVICE_API_SUCCESS,
  "data"
);
const getCategoryServiceApiFailure = createAction(
  GET_CATEGORY_SERVICE_API_FAILURE,
  "error"
);

export const getCategoryTypeApi = () => (dispatch) => {
  dispatch(getCategoryServiceApiRequest());
  axios
    .get(`${backEndApi}asset/categoriesData`)
    .then((res) => {
      dispatch(getCategoryServiceApiSuccess(res.data.message));
    })
    .catch((err) => {
      dispatch(getCategoryServiceApiFailure(err));
    });
};

export const POST_CATEGORY_SERVICE_API_REQUEST =
  "POST_CATEGORY_SERVICE_API_REQUEST";
export const POST_CATEGORY_SERVICE_API_SUCCESS =
  "POST_CATEGORY_SERVICE_API_SUCCESS";
export const POST_CATEGORY_SERVICE_API_FAILURE =
  "POST_CATEGORY_SERVICE_API_FAILURE";
const postCategoryServiceApiRequest = createAction(
  POST_CATEGORY_SERVICE_API_REQUEST
);
const postCategoryServiceApiSuccess = createAction(
  POST_CATEGORY_SERVICE_API_SUCCESS,
  "data"
);
const postCategoryServiceApiFailure = createAction(
  POST_CATEGORY_SERVICE_API_FAILURE,
  "error"
);

export const postCategoryTypeApi =
  (categoryType, setError, setcategoryType, assetsType) => (dispatch) => {
    dispatch(postCategoryServiceApiRequest());
    axios
      .post(`${backEndApi}asset/createCategories`, categoryType)
      .then((res) => {
        setError(false);
        setcategoryType(assetsType);
        dispatch(getCategoryTypeApi());
        dispatch(postCategoryServiceApiSuccess(res.data.message));
      })
      .catch((err) => {
        dispatch(postCategoryServiceApiFailure(err));
      });
  };
export const PUT_CATEGORY_SERVICE_API_REQUEST =
  "PUT_CATEGORY_SERVICE_API_REQUEST";
export const PUT_CATEGORY_SERVICE_API_SUCCESS =
  "PUT_CATEGORY_SERVICE_API_SUCCESS";
export const PUT_CATEGORY_SERVICE_API_FAILURE =
  "PUT_CATEGORY_SERVICE_API_FAILURE";
const putCategoryServiceApiRequest = createAction(
  PUT_CATEGORY_SERVICE_API_REQUEST
);
const putCategoryServiceApiSuccess = createAction(
  PUT_CATEGORY_SERVICE_API_SUCCESS,
  "data"
);
const putCategoryServiceApiFailure = createAction(
  PUT_CATEGORY_SERVICE_API_FAILURE,
  "error"
);

export const putCategoryTypeApi =
  (categoryID, categoryType, setError, setCategoryType, assetsType) =>
  (dispatch) => {
    dispatch(putCategoryServiceApiRequest());
    axios
      .put(
        `${backEndApi}asset/updateCategories?assetsTypeId=${categoryID}&newAssetsType=${categoryType}`
      )
      .then((res) => {
        setError(false);
        setCategoryType(assetsType);
        dispatch(EditCategoryAction(false));
        dispatch(getCategoryTypeApi());
        dispatch(putCategoryServiceApiSuccess(res.data.message));
      })
      .catch((err) => {
        dispatch(putCategoryServiceApiFailure(err));
      });
  };

export const DELETE_CATEGORY_SERVICE_API_REQUEST =
  "DELETE_CATEGORY_SERVICE_API_REQUEST";
export const DELETE_CATEGORY_SERVICE_API_SUCCESS =
  "DELETE_CATEGORY_SERVICE_API_SUCCESS";
export const DELETE_CATEGORY_SERVICE_API_FAILURE =
  "DELETE_CATEGORY_SERVICE_API_FAILURE";
const deleteCategoryServiceApiRequest = createAction(
  DELETE_CATEGORY_SERVICE_API_REQUEST
);
const deleteCategoryServiceApiSuccess = createAction(
  DELETE_CATEGORY_SERVICE_API_SUCCESS,
  "data"
);
const deleteCategoryServiceApiFailure = createAction(
  DELETE_CATEGORY_SERVICE_API_FAILURE,
  "error"
);

export const deleteCategoryTypeApi = (id) => (dispatch) => {
  dispatch(deleteCategoryServiceApiRequest());
  axios
    .delete(`${backEndApi}asset/dropCategories?assetsTypeId=${id}`)
    .then((res) => {
      dispatch(deleteCategoryServiceApiSuccess(res.data.message));
      dispatch(getCategoryTypeApi());
    })
    .catch((err) => {
      dispatch(deleteCategoryServiceApiFailure(err));
    });
};

//-------------------------------------------------------------------------------------------->
export const GET_ASSET_MAPPING_API_REQUEST = "GET_ASSET_MAPPING_API_REQUEST";
export const GET_ASSET_MAPPING_API_SUCCESS = "GET_ASSET_MAPPING_API_SUCCESS";
export const GET_ASSET_MAPPING_API_FAILURE = "GET_ASSET_MAPPING_API_FAILURE";
const getAssetMappingRequest = createAction(GET_ASSET_MAPPING_API_REQUEST);
const getAssetMappingSuccess = createAction(
  GET_ASSET_MAPPING_API_SUCCESS,
  "data"
);
const getAssetMappingFailure = createAction(
  GET_ASSET_MAPPING_API_FAILURE,
  "error"
);

export const getAssetMappingApi = () => (dispatch) => {
  dispatch(getAssetMappingRequest());
  axios
    .get(`${backEndApi}asset/retrieveEmployee`)
    .then((res) => {
      dispatch(getAssetMappingSuccess(res.data.message));
    })
    .catch((err) => {
      dispatch(getAssetMappingFailure(err));
    });
};

export const POST_ASSET_MAPPING_API_REQUEST = "POST_ASSET_MAPPING_API_REQUEST";
export const POST_ASSET_MAPPING_API_SUCCESS = "POST_ASSET_MAPPING_API_SUCCESS";
export const POST_ASSET_MAPPING_API_FAILURE = "POST_ASSET_MAPPING_API_FAILURE";
const postAssetMappingRequest = createAction(POST_ASSET_MAPPING_API_REQUEST);
const postAssetMappingSuccess = createAction(
  POST_ASSET_MAPPING_API_SUCCESS,
  "data"
);
const postAssetMappingFailure = createAction(
  POST_ASSET_MAPPING_API_FAILURE,
  "error"
);

export const postAssetMappingApi =
  (empData, setOpen, setEmpData, EmpDataForm) => (dispatch) => {
    dispatch(postAssetMappingRequest());
    axios
      .post(`${backEndApi}asset/employeeCreate`, empData)
      .then((res) => {
        setOpen(false);
        setEmpData(EmpDataForm);
        dispatch(getAssetMappingApi());
        dispatch(postAssetMappingSuccess(res.data.message));
      })
      .catch((err) => {
        dispatch(postAssetMappingFailure(err));
      });
  };

export const DELETE_ASSET_MAPPING_API_REQUEST =
  "DELETE_ASSET_MAPPING_API_REQUEST";
export const DELETE_ASSET_MAPPING_API_SUCCESS =
  "DELETE_ASSET_MAPPING_API_SUCCESS";
export const DELETE_ASSET_MAPPING_API_FAILURE =
  "DELETE_ASSET_MAPPING_API_FAILURE";
const deleteAssetMappingRequest = createAction(
  DELETE_ASSET_MAPPING_API_REQUEST
);
const deleteAssetMappingSuccess = createAction(
  DELETE_ASSET_MAPPING_API_SUCCESS,
  "data"
);
const deleteAssetMappingFailure = createAction(
  DELETE_ASSET_MAPPING_API_FAILURE,
  "error"
);

export const deleteAssetMappingApi = (id) => (dispatch) => {
  dispatch(deleteAssetMappingRequest());
  axios
    .delete(`${backEndApi}asset/dropEmployeeData?employeeId=${id}`)
    .then((res) => {
      dispatch(deleteAssetMappingSuccess(res.data.message));
      dispatch(getAssetMappingApi());
    })
    .catch((err) => {
      dispatch(deleteAssetMappingFailure(err));
    });
};

export const PUT_ASSET_MAPPING_API_REQUEST = "PUT_ASSET_MAPPING_API_REQUEST";
export const PUT_ASSET_MAPPING_API_SUCCESS = "PUT_ASSET_MAPPING_API_SUCCESS";
export const PUT_ASSET_MAPPING_API_FAILURE = "PUT_ASSET_MAPPING_API_FAILURE";
const putAssetEmpListRequest = createAction(PUT_ASSET_MAPPING_API_REQUEST);
const putAssetEmpListSuccess = createAction(
  PUT_ASSET_MAPPING_API_SUCCESS,
  "data"
);
const putAssetEmpListFailure = createAction(
  PUT_ASSET_MAPPING_API_FAILURE,
  "error"
);

export const putAssetMappingApi =
  (empData, setOpen, setEmpData, EmpDataForm) => (dispatch) => {
    dispatch(putAssetEmpListRequest());
    axios
      .put(`${backEndApi}asset/updateEmployeeData`, empData)
      .then((res) => {
        setOpen(false);
        setEmpData(EmpDataForm);
        dispatch(getAssetMappingApi());
        dispatch(putAssetEmpListSuccess(res.data.message));
      })
      .catch((err) => {
        dispatch(putAssetEmpListFailure(err));
      });
  };

//---------------------------------------------------------------------------->
export const GET_ASSET_EMPLOYEE_LIST_REQUEST =
  "GET_ASSET_EMPLOYEE_LIST_REQUEST";
export const GET_ASSET_EMPLOYEE_LIST_SUCCESS =
  "GET_ASSET_EMPLOYEE_LIST_SUCCESS";
export const GET_ASSET_EMPLOYEE_LIST_FAILURE =
  "GET_ASSET_EMPLOYEE_LIST_FAILURE";
const getAssetEmpListRequest = createAction(GET_ASSET_EMPLOYEE_LIST_REQUEST);
const getAssetEmpListSuccess = createAction(
  GET_ASSET_EMPLOYEE_LIST_SUCCESS,
  "data"
);
const getAssetEmpListFailure = createAction(
  GET_ASSET_EMPLOYEE_LIST_FAILURE,
  "error"
);

export const getAssetEmpListApi = () => (dispatch) => {
  dispatch(getAssetEmpListRequest());
  axios
    .get(`${backEndApi}asset/employeeList`)
    .then((res) => {
      dispatch(getAssetEmpListSuccess(res.data.message));
    })
    .catch((err) => {
      dispatch(getAssetEmpListFailure(err));
    });
};

//------------------------------------------------------------------------->
