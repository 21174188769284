import * as actions from "./Action";
import { fromJS } from "immutable";

const initialState = fromJS({
  isLoading: false,
  message: "",
  dbList: [],
});

export default function ReduxStore(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DATA_SUCCESS: {
      return state.merge(action.data);
    }
    case actions.POST_RESTORE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_RESTORE_SUCCESS: {
      return state.set("isLoading", false).set("message", fromJS(action.data));
    }
    case actions.POST_RESTORE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_DUMP_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_DUMP_SUCCESS: {
      return state.set("isLoading", false).set("message", fromJS(action.data));
    }
    case actions.POST_DUMP_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_DATABASE_NAME_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_DATABASE_NAME_SUCCESS: {
      return state.set("isLoading", false).set("dbList", fromJS(action.data));
    }
    case actions.GET_DATABASE_NAME_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage = message && typeof message === "string" ? message : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }

    default:
      return state;
  }
}
