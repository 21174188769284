import { fromJS, List } from "immutable";
import * as actions from "./Action";

const initialFormData = fromJS({
  assetType: "",
  price: "",
  purchaseOn: new Date(),
  model: "",
  attachment: "",
  description: "",
});

const initialCategory = fromJS({
  CategoryIndex: false,
  categoryID: "",
  CategoryName: "",
});

const initialMapEdit = fromJS({
  EditMapID: false,
  EditMapIndex: "",
});

const initialState = fromJS({
  drawerOpen: false,
  popUpStatus: {
    PopupOpen: false,
    Errorshow: false,
    EditAsset: false,
  },
  AssetsformData: initialFormData,
  getAssetsApi: List(),
  isLoading: false,
  //--------------------------->
  isopenCategory: false,
  geCategoryApi: List(),
  editCategorys: initialCategory,
  message: "",
  getEmpList: List(),
  getAssetsMapList: List(),
  EditMapPopup: initialMapEdit,
});
//----------------------------------------------------------------->
export default function AssetsManagement(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DATA_SUCCESS: {
      return state.merge(action.data);
    }
    case actions.GET_ASSET_SERVICE_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_ASSET_SERVICE_API_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("getAssetsApi", fromJS(action.data));
    }
    case actions.GET_ASSET_SERVICE_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    case actions.DELETE_ASSET_SERVICE_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.DELETE_ASSET_SERVICE_API_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.DELETE_ASSET_SERVICE_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    case actions.PUT_ASSET_SERVICE_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.PUT_ASSET_SERVICE_API_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.PUT_ASSET_SERVICE_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    case actions.POST_ASSET_SERVICE_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_ASSET_SERVICE_API_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.POST_ASSET_SERVICE_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    case actions.GET_CATEGORY_SERVICE_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_CATEGORY_SERVICE_API_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("geCategoryApi", fromJS(action.data));
    }
    case actions.GET_CATEGORY_SERVICE_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    case actions.DELETE_CATEGORY_SERVICE_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.DELETE_CATEGORY_SERVICE_API_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.DELETE_CATEGORY_SERVICE_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    case actions.POST_CATEGORY_SERVICE_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_CATEGORY_SERVICE_API_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.POST_CATEGORY_SERVICE_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }

    //---------------------------------------------------->
    case actions.GET_ASSET_MAPPING_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_ASSET_MAPPING_API_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("getAssetsMapList", fromJS(action.data));
    }
    case actions.GET_ASSET_MAPPING_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    case actions.POST_ASSET_MAPPING_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_ASSET_MAPPING_API_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.POST_ASSET_MAPPING_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    case actions.DELETE_ASSET_MAPPING_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.DELETE_ASSET_MAPPING_API_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.DELETE_ASSET_MAPPING_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    case actions.PUT_ASSET_MAPPING_API_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.PUT_ASSET_MAPPING_API_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.PUT_ASSET_MAPPING_API_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }

    //--------------------------------------------------->
    case actions.GET_ASSET_EMPLOYEE_LIST_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_ASSET_EMPLOYEE_LIST_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("getEmpList", fromJS(action.data));
    }
    case actions.GET_ASSET_EMPLOYEE_LIST_FAILURE: {
      return state.set("isLoading", false).set("message", action.error);
    }
    //----------------------------------------------------->
    case "Drawer-Open_Close":
      return state.set("drawerOpen", action.payLoad);
    case "Popup_Open_Close":
      return state.setIn(["popUpStatus", "PopupOpen"], action.payLoad);
    case "Error_show":
      return state.setIn(["popUpStatus", "Errorshow"], action.payLoad);
    case "Edit_Assets":
      return state.setIn(["popUpStatus", "EditAsset"], action.payLoad);
    case "Total_Assets":
      return state.set("AssetsformData", action.payLoad);
    case "Total_Assets_Reset":
      return state.set("AssetsformData", initialFormData);
    case "Category_open_close":
      return state.set("isopenCategory", action.payLoad);
    case "Edit_Category":
      return state.setIn(["editCategorys", "CategoryIndex"], action.payLoad);
    case "Category_ID":
      return state.setIn(["editCategorys", "categoryID"], action.payLoad);
    case "Category_Name":
      return state.setIn(["editCategorys", "CategoryName"], action.payLoad);
    case "EditMap_ID":
      return state.setIn(["EditMapPopup", "EditMapID"], action.payLoad);
    case "EditMapIndex":
      return state.setIn(["EditMapPopup", "EditMapIndex"], action.payLoad);
    default:
      return state;
  }
}
