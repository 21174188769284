export const daysBeforeCalculation = (currentDate,givenDate)=>{
    if(currentDate.diff(givenDate, "year")!== 0){
        return {
            key: 'year',
            diffDate:`${currentDate.diff(givenDate, "year")}`
        }
    }
    else
    if(currentDate.diff(givenDate, "month")!== 0){
        return {
            key: 'month',
            diffDate:`${currentDate.diff(givenDate, "month")}`
        }
    }else
    if(currentDate.diff(givenDate, "days")!== 0){
        return {
            key: 'days',
            diffDate:`${currentDate.diff(givenDate, "days")}`
        }
    }else
    if(currentDate.diff(givenDate, "hour")!== 0){
        return {
            key: 'hour',
            diffDate:`${currentDate.diff(givenDate, "hour")}`
        }
    }else
    if(currentDate.diff(givenDate, "minute")!== 0){
        return {
            key: 'minute',
            diffDate:`${currentDate.diff(givenDate, "minute")}`
        }
    }
}
