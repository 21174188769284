import { fromJS, List } from 'immutable'

const convertWorkingTime = (inTime, outTime) => {
    const [inHour, inMinute] = inTime.split(":").map(Number);
    const [outHour, outMinute] = outTime.split(":").map(Number);
    const diffMinutes = (outHour - inHour) * 60 + (outMinute - inMinute);
    const workingHours = Math.floor(diffMinutes / 60);
    const workingMinutes = diffMinutes % 60;
    return `${workingHours} Hours : ${workingMinutes} Minutes`;
};

const setTableData = (date, dateWiseData, allUsers) => {
    let tempPresentArray = [];
    let tempAbsentArray = [];
    const separateDate = dateWiseData
        .filter((item) => item.get("_id", "") === date)
        .getIn([0, "data"], List());
    allUsers.forEach((item) => {
        const filterData = separateDate.filter(
            (item2) => item2.get("mongoId", "") === item.get("_id", "")
        );
        if (filterData.size > 0) {
            tempPresentArray.push({
                date: date,
                member: filterData.getIn([0, "username"], ""),
                inTime: filterData.getIn([0, "inTime"], ""),
                outTime:
                    filterData.getIn([0, "outTime"], "") === ""
                        ? "None"
                        : filterData.getIn([0, "outTime"], ""),
                workingTime:
                    filterData.getIn([0, "outTime"], "") === ""
                        ? "----"
                        : convertWorkingTime(
                            filterData.getIn([0, "inTime"], ""),
                            filterData.getIn([0, "outTime"], "")
                        ),
            });
        } else {
            tempAbsentArray.push({
                date: date,
                member: item.get("username", ""),
                inTime: "Absent",
                outTime: "Absent",
                workingTime: "----",
            });
        }
    });
    return {
        presentData: fromJS(tempPresentArray),
        absentData: fromJS(tempAbsentArray),
    };
};
export default setTableData