import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  sidebar_custom_accordion: {
    "&:hover": {
      outline: "1px solid #475867",
      boxShadow: "none",
    },
  },
  custom_accordion_summary: {
    backgroundColor: "#f5f7f9",
    maxHeight: "52px !important",
    minHeight: "30px !important",
    borderRadius: "6px",
  },
  info_details_content: {
    paddingTop: "4px",
    fontWeight: "600",
  },
  info_details_Label: {
    fontSize: "14px",
    color: "#6f7c87",
  },
  contacts__view_more_info: {
    padding: "15px 0 20px",
    color: "#2c5cc5",
  },
  view_more_info: {
    textDecoration: "none",
  },
  timeline_activity_ticket: {
    display: "flex ",
  },
  time_entries_content: {
    padding: "8px 0px",
  },
  time_tracked: {
    backgroundColor: "#ebeff3",
    borderRadius: "4px",
    padding: "12px",
  },
  Log_btn: {
    borderRadius: "3px",
    color: "white",
    padding: "2px 10px",
    backgroundColor: "#264966",
    border: "1px solid #12344d",
    backgroundImage: "linear-gradient(to bottom,#264966,#12344d)",
  },
});
