import * as actions from "./Action";
import { fromJS } from "immutable";

const defaultTestCase = fromJS({
  data: [],
  featureData: [
    {
      featuresName: "",
      module: "",
      version: "",
    },
  ],
});
const defaultOriginalTestCase = fromJS({
  data: [],
  cloneKey: 0,
});

const initialState = fromJS({
  isLoading: false,
  executed: false,
  message: "",
  Auth_type: {},
  roles: [],
  department: [],
  users: [],
  featureList: [],
  jiraMemberList: [],
  testCaseData: defaultTestCase,
  originalTestCaseData: defaultOriginalTestCase,
  isLoggedIn: false,
  user: {},
  googleAuthDetails: {},
  accessToken: "",
});

export default function ReduxStore(state = initialState, action) {
  switch (action.type) {
    case actions.SET_DATA_SUCCESS: {
      return state.merge(action.data);
    }
    case actions.GET_ALL_FEATURE_LIST_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_ALL_FEATURE_LIST_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("featureList", fromJS(action.data))
        .set("testCaseData", defaultTestCase)
        .set("originalTestCaseData", defaultOriginalTestCase);
    }
    case actions.GET_ALL_FEATURE_LIST_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_ORIGINAL_CASE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_ORIGINAL_CASE_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("originalTestCaseData", fromJS(action.data))
        .set("message", "Clone created successfully...");
    }
    case actions.POST_ORIGINAL_CASE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_EXECUTED_DATA_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_EXECUTED_DATA_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("originalTestCaseData", fromJS(action.data));
    }
    case actions.GET_EXECUTED_DATA_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_UPLOADED_CASE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_UPLOADED_CASE_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("testCaseData", fromJS(action.data));
    }
    case actions.GET_UPLOADED_CASE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_UPLOADED_CLONE_CASE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_UPLOADED_CLONE_CASE_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("testCaseData", fromJS(action.data));
    }
    case actions.GET_UPLOADED_CLONE_CASE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_TEST_CASE_DATA_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_TEST_CASE_DATA_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("message", "Uploaded successfully...");
    }
    case actions.POST_TEST_CASE_DATA_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_EXECUTED_VIDEO_REQUEST: {
      return state.set("isLoading", true).set("message", "Uploading video...");
    }
    case actions.POST_EXECUTED_VIDEO_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("message", fromJS(action.data))
        .set("message", action.data);
    }
    case actions.POST_EXECUTED_VIDEO_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_TEST_CASE_STATUS_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_TEST_CASE_STATUS_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.POST_TEST_CASE_STATUS_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_TEST_CASE_INSERT_ROW_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_TEST_CASE_INSERT_ROW_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("message", "Row inserted successfully.");
    }
    case actions.POST_TEST_CASE_INSERT_ROW_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_JIRA_MEMBER_LIST_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_JIRA_MEMBER_LIST_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("jiraMemberList", fromJS(action.data));
    }
    case actions.GET_JIRA_MEMBER_LIST_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_CREATE_JIRA_TICKET_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_CREATE_JIRA_TICKET_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("message", "issue created successfully");
    }
    case actions.POST_CREATE_JIRA_TICKET_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.PATCH_ACTUAL_RESULT_UPDATE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.PATCH_ACTUAL_RESULT_UPDATE_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("message", "Updated successfully...");
    }
    case actions.PATCH_ACTUAL_RESULT_UPDATE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_UPLOAD_FILE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_UPLOAD_FILE_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("message", "Updated successfully...");
    }
    case actions.POST_UPLOAD_FILE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_OAUTH2_USER_INFO_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_OAUTH2_USER_INFO_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("Auth_type", fromJS(action.data));
    }
    case actions.GET_OAUTH2_USER_INFO_FAILURE: {
      return state.set("isLoading", false);
    }
    case actions.POST_GOOGLE_AUTH_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_GOOGLE_AUTH_SUCCESS: {
      return state
        .set("googleAuthDetails", fromJS(action.data))
        .set("isLoading", false);
    }
    case actions.POST_GOOGLE_AUTH_FAILURE: {
      return state
        .set("isLoading", false)
        .set("message", "An error occurred. Please try again.");
    }
    case actions.GENERATE_ACCESS_TOKEN_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GENERATE_ACCESS_TOKEN_SUCCESS: {
      return state.set("accessToken", action.data).set("isLoading", false);
    }
    case actions.GENERATE_ACCESS_TOKEN_FAILURE: {
      return state
        .set("isLoading", false)
        .set("message", "An error occurred. Please try again.");
    }
    case actions.POST_REGISTER_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_REGISTER_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.POST_REGISTER_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_USER_ROLE_DATA_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_USER_ROLE_DATA_SUCCESS: {
      return state
        .set("isLoading", false)
        .set("roles", fromJS(action.data.userData))
        .set("department", fromJS(action.data.department));
    }
    case actions.GET_USER_ROLE_DATA_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.GET_USER_DATA_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.GET_USER_DATA_SUCCESS: {
      return state.set("isLoading", false).set("users", fromJS(action.data));
    }
    case actions.GET_USER_DATA_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_USER_ROLE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_USER_ROLE_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.POST_USER_ROLE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.PUT_ROLE_CHANGE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.PUT_ROLE_CHANGE_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.PUT_ROLE_CHANGE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_LOGIN_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_LOGIN_SUCCESS: {
      return state.set("isLoading", false).set("user", fromJS(action.data));
    }
    case actions.POST_LOGIN_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.DELETE_ROLE_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.DELETE_ROLE_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.DELETE_ROLE_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    case actions.POST_PAGE_ACCESS_REQUEST: {
      return state.set("isLoading", true);
    }
    case actions.POST_PAGE_ACCESS_SUCCESS: {
      return state.set("isLoading", false).set("message", action.data);
    }
    case actions.POST_PAGE_ACCESS_FAILURE: {
      const { response: { data: { message = "" } = {} } = {} } = action.error;
      const errorMessage =
        message && typeof message === "string"
          ? message
          : "An error occurred. Please try again.";
      return state.set("isLoading", false).set("message", errorMessage);
    }
    default:
      return state;
  }
}
