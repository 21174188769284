import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import "./UpdateDetails.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getUserData } from "../../Redux/TestCase/Action";

const backEndApi = process.env.REACT_APP_BackEndUrl;

export default function FormDialog({
  updateDetailsOpen,
  userData,
  setUserData,
  id,
}) {
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  // close popup
  const closeUpdateUserDetails = () => {
    setUserData((pre) => pre.set("updateDetailsOpen", false));
    // setTableValue((pre) => ({ ...pre, updateDetailsOpen: false }));
  };

  // get user name
  const handleUserName = (event) => {
    setUserData((pre) => pre.set("rowUserName", event.target.value));
    // setTableValue((pre) => ({ ...pre, rowUserName: event.target.value }));
  };

  //get user phone number
  const handleUserNumber = (event) => {
    setUserData((pre) => pre.set("rowPhoneNumber", event.target.value));
    // setTableValue((pre) => ({ ...pre, rowPhoneNumber: event.target.value }));
  };

  // post api call user details
  const editUserDetailsData = (event) => {
    const data = {
      id,
      userName: userData.get("rowUserName", ""),
      userNumber: userData.get("rowPhoneNumber", ""),
    };
    if (validateData(data)) {
      axios
        .put(`${backEndApi}oauth/updateUserDetails`, data)
        .then(() => {
          console.log("UPdated data");
          dispatch(getUserData());
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setError("Invalid Data...!");
    }
  };

  // validation data
  const validateData = (details) => {
    if (details.id === "") {
      return false;
    } else if (details.userName === "") {
      return false;
    } else if (details.userNumber === "") {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Dialog open={true} PaperProps={{ style: { minWidth: "500px" } }}>
        <Paper>
          <DialogTitle className="user-details-heading">
            User Details Update
          </DialogTitle>

          <IconButton
            edge="start"
            color="inherit"
            onClick={closeUpdateUserDetails}
            aria-label="close"
            className="Cancel">
            <CloseIcon />
          </IconButton>

          <div className="content-div">
            <InputLabel htmlFor="my-input">User Name :</InputLabel>
            <TextField
              id="margin-normal"
              margin="normal"
              value={userData.get("rowUserName", "")}
              variant="outlined"
              className="user_name"
              onChange={(event) => handleUserName(event)}
            />
            <br /> <br />
            <InputLabel htmlFor="my-input">User Number :</InputLabel>
            <TextField
              id="margin-normal"
              margin="normal"
              value={userData.get("rowPhoneNumber", "")}
              variant="outlined"
              className="user_number"
              onChange={(event) => handleUserNumber(event)}
            />
            <br />
            <br />
            <p className="error">{error}</p>
            <br />
            <Button
              variant="outlined"
              endIcon={<SendIcon />}
              onClick={(event) => editUserDetailsData(event)}
              className="send-user-details">
              Send
            </Button>
          </div>
        </Paper>
      </Dialog>
    </div>
  );
}
