import { fromJS, Map } from "immutable";
import * as actions from "./Action";

const initial = fromJS({
  loading: false,
  message: "",
  allTypeDetails: {
    cloudeTypesData: [],
    serviceTypesData: [],
  },
  getBillingApi: [],
  getٍSeparateBillingData: {},
  billUpdateId: false,
  snackbarOpen: false,
  getSnapBillingApi: [],
  pagination: Map({ page: 1, limit: 5, filterName: '' })
});

export default function BillingState(state = initial, action) {
  switch (action.type) {
    case actions.SET_DATA_SUCCESS: {
      return state.merge(action.data);
    }
    case "EDIT_BILL_ID":
      return state.set("billUpdateId", action.payLoad);
    case "SNACKBAR_ACTION":
      return state.set("snackbarOpen", action.payLoad);
    case actions.GET_ALL_TYPE_REQUEST: {
      return state.set("loading", true);
    }
    case actions.GET_ALL_TYPE_SUCCESS: {
      return state.set("loading", false).set("allTypeDetails", fromJS(action.data));
    }
    case actions.GET_ALL_TYPE_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.POST_ADD_CLOUD_TYPE_REQUEST: {
      return state.set("loading", true);
    }
    case actions.POST_ADD_CLOUD_TYPE_SUCCESS: {
      return state.set("loading", false);
    }
    case actions.POST_ADD_CLOUD_TYPE_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.POST_ADD_SERVICE_TYPE_REQUEST: {
      return state.set("loading", true);
    }
    case actions.POST_ADD_SERVICE_TYPE_SUCCESS: {
      return state.set("loading", false);
    }
    case actions.POST_ADD_SERVICE_TYPE_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.GET_BILLING_REQUEST: {
      return state.set("loading", true);
    }
    case actions.GET_BILLING_SUCCESS: {
      return state.set("loading", false).set("getBillingApi", fromJS(action.data));
    }
    case actions.GET_BILLING_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.POST_BILLING_REQUEST: {
      return state.set("loading", true);
    }
    case actions.POST_BILLING_SUCCESS: {
      return state.set("loading", false).set("message", "Added Successfully...!");
    }
    case actions.POST_BILLING_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.PUT_BILLING_REQUEST: {
      return state.set("loading", true);
    }
    case actions.PUT_BILLING_SUCCESS: {
      return state.set("loading", false).set("message", "Updated Successfully...!");
    }
    case actions.PUT_BILLING_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.DELETE_BILLING_REQUEST: {
      return state.set("loading", true);
    }
    case actions.DELETE_BILLING_SUCCESS: {
      return state.set("loading", false).set("message", "Deleted Successfully...!");
    }
    case actions.DELETE_BILLING_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.SNAP_POST_BILLING_REQUEST: {
      return state.set("loading", true);
    }
    case actions.SNAP_POST_BILLING_SUCCESS: {
      return state.set("loading", false).set("message", "snapshot is Updated Successfully...!");
    }
    case actions.SNAP_POST_BILLING_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.SNAP_GET_BILLING_REQUEST: {
      return state.set("loading", true);
    }
    case actions.SNAP_GET_BILLING_SUCCESS: {
      return state.set("loading", false).set("getSnapBillingApi", fromJS(action.data));
    }
    case actions.SNAP_GET_BILLING_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.GET_INDIVIDUAL_SERVICE_DATA_REQUEST: {
      return state.set("loading", true);
    }
    case actions.GET_INDIVIDUAL_SERVICE_DATA_SUCCESS: {
      return state.set("loading", false).set("getٍSeparateBillingData", fromJS(action.data));
    }
    case actions.GET_INDIVIDUAL_SERVICE_DATA_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.UPDATE_SERVICE_TYPE_REQUEST: {
      return state.set("loading", true);
    }
    case actions.UPDATE_SERVICE_TYPE_SUCCESS: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.UPDATE_SERVICE_TYPE_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.DELETE_SERVICE_TYPE_REQUEST: {
      return state.set("loading", true);
    }
    case actions.DELETE_SERVICE_TYPE_SUCCESS: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.DELETE_SERVICE_TYPE_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.UPDATE_CLOUD_TYPE_REQUEST: {
      return state.set("loading", true);
    }
    case actions.UPDATE_CLOUD_TYPE_SUCCESS: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.UPDATE_CLOUD_TYPE_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.DELETE_CLOUD_TYPE_REQUEST: {
      return state.set("loading", true);
    }
    case actions.DELETE_CLOUD_TYPE_SUCCESS: {
      return state.set("loading", false).set("message", action.data);
    }
    case actions.DELETE_CLOUD_TYPE_FAILURE: {
      return state.set("loading", false).set("message", action.data);
    }
    default:
      return state;
  }
}
