import { Button, Dialog, DialogTitle, TextField } from "@material-ui/core";
import React, { useRef, useState } from "react";
import "./updateBioImg.css";
import Webcam from "react-webcam";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getUserData } from "../../Redux/TestCase/Action";
import Swal from "sweetalert2";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import CancelIcon from "@mui/icons-material/Cancel";

const backEndApi = process.env.REACT_APP_BackEndUrl;

// upload img
export const UploadImage = ({
  openUpload,
  setOpenUpload,
  id,
  setUserData,
  editOpenImgId,
  imageCount,
  existing,
  rowUserName,
  setOpenVert,
}) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState("");

  const handleUploadCancel = () => {
    setOpenUpload(false);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result;
      setSelectedFile(base64Data);
    };

    reader.readAsDataURL(file);
  };

  const handleUploadFile = () => {
    let updateUserName = rowUserName?.replaceAll(" ", "_");

    let newUserName = `${updateUserName}____${id}_${imageCount + 1}.jpg`;

    axios
      .put(
        `${backEndApi}oauth/updateAttachment?id=${id}&imgId=${editOpenImgId}`,
        { imgLink: selectedFile, userName: newUserName }
      )
      .then((res) => {
        dispatch(getUserData());
        setOpenVert("");
        Swal.fire({
          text: "Picture Updated Successfully",
          html: `<h2>Bio Metric Image Updated</h2><br><img src=${res.data.message} alt="Image" class="sweat-alert-img"/>`,
          icon: "success",
          showCancelButton: false,
          customClass: {
            image: "sweetalert-image",
          },
        });
        setUserData((pre) => pre.set("bioOpen", false));
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          text: "Picture Update failed...!",
          icon: "warning",
          showCancelButton: false,
        });
      });
    setOpenUpload(false);
  };

  return (
    <Dialog open={openUpload}>
      <DialogTitle className="update-picture-heading">
        UPDATE PICTURE
      </DialogTitle>
      <div className="m-5">
        <TextField
          type="file"
          onChange={(e) => handleFileChange(e)}
          variant="outlined"
          error={!selectedFile}
          helperText={!selectedFile && "Please select a file"}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
      <Button
        onClick={handleUploadFile}
        variant="contained"
        endIcon={<DownloadForOfflineIcon />}
        className="upload-user-picture">
        Upload Picture
      </Button>
      <Button
        onClick={handleUploadCancel}
        variant="contained"
        endIcon={<CancelIcon />}
        className="upload-user-picture-cancel">
        Cancel
      </Button>
    </Dialog>
  );
};

// webcam take img
export const WebcamImage = ({
  openCamera,
  setOpenCamera,
  id,
  rowUserName,
  setUserData,
  editOpenImgId,
  imageCount,
  existing,
  setOpenVert,
}) => {
  const dispatch = useDispatch();
  const webcamRef = useRef(null);

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();

    let updateUserName = rowUserName?.replaceAll(" ", "_");

    let newUserName = `${updateUserName}____${id}_${imageCount + 1}.jpg`;

    setOpenCamera(false);
    // get picture url from server
    axios
      .put(
        `${backEndApi}oauth/updateAttachment?id=${id}&imgId=${editOpenImgId}`,
        { imgLink: imageSrc, userName: newUserName }
      )
      .then((res) => {
        dispatch(getUserData());
        setOpenVert("");
        Swal.fire({
          text: "Picture Updated Successfully",
          html: `<h2>Bio Metric Image Updated</h2><br><img src=${res.data.message} alt="Image" class="sweat-alert-img"/>`,
          icon: "success",
          showCancelButton: false,
          customClass: {
            image: "sweetalert-image",
          },
        });
        setUserData((pre) => pre.set("bioOpen", false));
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          text: "Picture Update failed...!",
          icon: "warning",
          showCancelButton: false,
        });
      });
  };

  const removeCamera = () => {
    setOpenCamera(false);
  };

  return (
    <div>
      <Dialog open={openCamera} className="dialog-box">
        <DialogTitle className="heading">Webcam Capture</DialogTitle>
        <Webcam className="handleCameraImage" audio={false} ref={webcamRef} />
        <Button
          onClick={capture}
          variant="contained"
          endIcon={<LocalSeeIcon />}
          className="take-picture-webcam">
          Take Picture
        </Button>
        <Button
          onClick={removeCamera}
          variant="contained"
          endIcon={<CancelIcon />}
          className="take-picture-webcam-cancel">
          Cancel
        </Button>
      </Dialog>
    </div>
  );
};

// main component update picture
const UpdateBioMetric = ({
  id,
  setEditOpen,
  rowUserName,
  editOpenImgId,
  setUserData,
  imageCount,
  existing,
  setOpenVert,
}) => {
  const [openCamera, setOpenCamera] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const handleOpenCamera = () => {
    setOpenCamera(true);
  };

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleClose = () => {
    setEditOpen(false);
  };
  return (
    <Dialog open={true}>
      <DialogTitle>UPDATE BIO METRIC</DialogTitle>
      <Button
        variant="contained"
        endIcon={<AddAPhotoIcon />}
        className="update-img"
        onClick={handleOpenCamera}>
        Take Picture
      </Button>
      {openCamera && (
        <WebcamImage
          openCamera={openCamera}
          setOpenCamera={setOpenCamera}
          id={id}
          rowUserName={rowUserName}
          setUserData={setUserData}
          editOpenImgId={editOpenImgId}
          imageCount={imageCount}
          existing={existing}
          setOpenVert={setOpenVert}
        />
      )}
      <Button
        variant="contained"
        endIcon={<DownloadForOfflineIcon />}
        className="update-upload-img"
        onClick={handleOpenUpload}>
        Upload Image
      </Button>
      {openUpload && (
        <UploadImage
          openUpload={openUpload}
          setOpenUpload={setOpenUpload}
          id={id}
          setUserData={setUserData}
          editOpenImgId={editOpenImgId}
          imageCount={imageCount}
          existing={existing}
          rowUserName={rowUserName}
          setOpenVert={setOpenVert}
        />
      )}
      <Button
        variant="contained"
        endIcon={<HighlightOffIcon />}
        className="update-close-img"
        onClick={handleClose}>
        close
      </Button>
    </Dialog>
  );
};

export default UpdateBioMetric;
