import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import Routing from "./Route";
import { BrowserRouter } from "react-router-dom";
import { onMessageListener } from "./Modules/Firebase/firebase";
import Dlogo from "./Assets/Dlogo.jpg";

function App() {
  // const [imageUrl, setImageUrl] = useState(null);
  // const [resize, setResize] = useState(true);
  // const iframeRef = useRef(null);

  // const takeScreenShot = async () => {
  //   try {
  //     iframeRef.current.contentWindow.postMessage(
  //       "readyToTakeScreenShot",
  //       HELP_WIDGET_URL
  //     );
  //     const stream = await navigator.mediaDevices.getDisplayMedia({
  //       preferCurrentTab: true,
  //     });
  //     const imageCapture = new ImageCapture(stream.getVideoTracks()[0]);
  //     const bitmap = await imageCapture.grabFrame();
  //     const canvas = document.createElement("canvas");
  //     canvas.width = bitmap.width;
  //     canvas.height = bitmap.height;
  //     const context = canvas.getContext("2d");
  //     context.drawImage(bitmap, 0, 0);
  //     const dataURL = canvas.toDataURL("image/png");
  //     setImageUrl(dataURL);
  //     await stream.getTracks().forEach((track) => track.stop());
  //     iframeRef.current.contentWindow.postMessage(
  //       "readyImage",
  //       HELP_WIDGET_URL
  //     );
  //   } catch (err) {
  //     iframeRef.current.contentWindow.postMessage(
  //       "failureToTakeImageOperation",
  //       HELP_WIDGET_URL
  //     );
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("message", (e) => {
  //     if (e.origin !== HELP_WIDGET_URL) {
  //       return;
  //     }
  //     if (e.data === "captureScreenShot") {
  //       takeScreenShot();
  //     }
  //     if (e.data.iframe === "true" || "false") {
  //       setResize(e.data.iframe);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   if (imageUrl) {
  //     if (!iframeRef.current) return;
  //     iframeRef.current.contentWindow.postMessage(imageUrl, HELP_WIDGET_URL);
  //   }
  // }, [imageUrl]);

  onMessageListener()
    .then((payload) => {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          new Notification(payload.notification?.title, {
            body: payload.notification?.body,
            icon: Dlogo,
            vibrate: true,
          });
        }
      });
    })
    .catch((err) => {
      console.log(err.message, "error");
    });

  return (
    <div>
      <Provider store={store}>
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
        {/* <HelpWidget iframeRef={iframeRef} resize={resize} /> */}
      </Provider>
    </div>
  );
}

export default App;
