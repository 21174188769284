import React, { useState, useEffect } from "react";
import {
    Box
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    getSpecificDateDetail, getAllUserWithDate
} from "../../../Redux/AttendanceManagement/Action";
import {
    selectDateWiseDate,
    selectAllUser,
    // selectSpecificDateDetail,
} from "../../../Redux/AttendanceManagement/Selector";
import AttendanceTable from '../Model/attendanceTable'
import { useLocation } from "react-router-dom";
// import { fromJS, List } from 'immutable'
import makeTableData from '../../../Utils/TableDataChanges'

const DateWishTable = () => {
    const dateWiseData = useSelector(selectDateWiseDate);
    const allUsers = useSelector(selectAllUser);
    // const SpecificDateDetail = useSelector(selectSpecificDateDetail);
    const dispatch = useDispatch();
    const location = useLocation();
    const queryDate = new URLSearchParams(location.search).get("date");
    const { presentData, absentData } = makeTableData(queryDate, dateWiseData, allUsers)
    const [tableData, setTableData] = useState([]) //

    useEffect(() => {
        setTableData(presentData.concat(absentData))
    }, [allUsers]) //eslint-disable-line

    useEffect(() => {
        dispatch(getAllUserWithDate());
        dispatch(getSpecificDateDetail(queryDate))
    }, []) //eslint-disable-line
    return (
        <div className="d-flex justify-content-center flex-column">
            <h3 id="customized-dialog-title"
                className='bold d_all_center my-3'>
                {queryDate} Date details
            </h3>
            <div>
                <Box className="container-fluid">
                    <AttendanceTable data={tableData} />
                </Box>
            </div>
        </div>
    )
}

export default DateWishTable
