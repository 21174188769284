import TurnRightIcon from "@mui/icons-material/TurnRight";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TurnLeftIcon from "@mui/icons-material/TurnLeft";
import ArticleIcon from '@mui/icons-material/Article';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export const ButtonData = {
  Reply_Name: "Reply",
  Reply_icon: <TurnLeftIcon />,
  Reply_tooltip: "Reply (r)",

  Note_Name: "Add Note",
  Note_icon: <ArticleIcon />,
  Note_tooltip: "Add Note (n)",

  Forward_Name: "Forward",
  Forward_icon: <TurnRightIcon />,
  Forward_tooltip: "Forward (f)",

  Close_Name: "Close",
  Close_icon: <TaskAltIcon />,
  Close_tooltip: "Shift + Close to skip notification email",

  Delete_Name: "Delete",
  Delete_icon: <DeleteOutlineIcon />,
  Delete_tooltip: "Delete (d)",
};
