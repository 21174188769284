import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  Popover,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import LoupeIcon from "@mui/icons-material/Loupe";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Editors } from "./Editor";
import Popup from "./Model/Popup";
import { useSelector, useDispatch } from "react-redux";
import {
  selectAllOption,
  selectMessage,
  selectIsLoading,
} from "../../Redux/DocumentManagement/Selector";
import { selectAuthType, selectUsers } from "../../Redux/TestCase/Selector";
import {
  getDocumentsType,
  postCreateDocument,
  getDocumentById,
  setData,
  putDocumentUpdate,
  deleteDocType,
  postNotification,
  getDocumentHistoryById,
} from "../../Redux/DocumentManagement/Action";
import { fromJS, List, Map } from "immutable";
import { useNavigate } from "react-router";
import { validateDocuments } from "../../Utils/Validation";
import Dropdown from "react-bootstrap/Dropdown";
import Snackbar from "../../LoaderSnackbar/Snackbar";
import Loader from "../../LoaderSnackbar/Loader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import "./Editor.css";
import { useSweetAlert } from "../../Utils/Sweetalert";
import ApprovalPopup from "./Model/ApprovalPopup";
import { useLocation } from "react-router-dom";
import { getUserData } from "../../Redux/TestCase/Action";
import ReplyIcon from "@mui/icons-material/Reply";
import DocumentAccordion from "./DocumentAccordion";
import SharePopup from "./Model/SharePopup";
import shareImg from "../../Assets/share.png";

const useStyles = makeStyles({
  Forms: {
    borderRadius: "10px",
    padding: "5px",
  },
  FormRow: {
    width: "100%",
    height: "60px",
    display: "flex",
  },
  FormCol: {
    width: "50%",
    display: "flex",
  },

  FormControl: {
    width: "93%",
  },
});

const DocumentForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sweetAlert = useSweetAlert();
  const loadingStatus = useSelector(selectIsLoading);
  const messageStatus = useSelector(selectMessage);
  const allOptions = useSelector(selectAllOption);
  const Users = useSelector(selectUsers);
  const Auth_data = useSelector(selectAuthType).toJS();

  const [formData, setFormData] = useState(
    fromJS({
      DepartmentName: {},
      author: Auth_data?.email,
      ProductName: {},
      DocumentName: "",
      DocumentTags: [],
      Content: "",
      shareWithDepartment: [],
      shareWithUser: [],
    })
  );
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [isShown, setIsShown] = useState(false);
  const [Update, setUpdate] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [approval, setApproval] = useState(false);
  const [shareType, setShareType] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const location = useLocation();
  const documentId = new URLSearchParams(location.search).get("id");
  const historyView =
    new URLSearchParams(location.search).get("history") === "true";

  const viewOnly = window.location.href.includes("viewDocument");

  const editCallBack = (value) => {
    Object.keys(value).length > 0 && setFormData(fromJS(value));
  };

  const handleChangeShow = (index) => {
    const showData = {
      [index]: true,
    };

    setIsShown(showData);
  };
  const handleEditType = (e, item, type) => {
    e.stopPropagation();
    setOpen(true);
    setType(type);
    setUpdate(item);
  };

  const handleDeleteType = (e, item, type) => {
    e.stopPropagation();

    const deleteCallBack = () => {
      dispatch(getDocumentsType());

      if (type === "Tags") {
        let a = formData
          .get("DocumentTags", "")
          .filter((d) => d.get("label") !== item.get("documentTags"));
        setFormData((prev) => {
          return prev.set("DocumentTags", a);
        });
      } else {
        item.get("productName", "") ===
          formData.getIn(["ProductName", "label"], "") &&
          setFormData((prev) => {
            return prev.set("ProductName", Map());
          });
      }
    };
    sweetAlert({
      id: item.get("_id", ""),
      ApiCall: deleteDocType,
      callBack: deleteCallBack,
      type: type,
    });
  };

  useEffect(() => {
    if (
      window.location.href.includes("EditDocument") ||
      window.location.href.includes("viewDocument")
    ) {
      historyView
        ? dispatch(getDocumentHistoryById(documentId, editCallBack))
        : dispatch(getDocumentById(documentId, editCallBack));
    }
    dispatch(getDocumentsType());
    viewOnly && dispatch(getUserData());
  }, []); //eslint-disable-line

  const handleChange = (value, key) => {
    setFormData((prev) =>
      prev.set(key, key === "Content" ? value.getData() : fromJS(value))
    );
  };

  const callBack = (value) => {
    navigate("/documentation");
  };
  console.log(formData, "formData");

  const handleSubmit = (status, callBack) => {
    const validation = validateDocuments(formData);
    if (validation !== "") {
      dispatch(setData(fromJS({ message: validation })));
      return;
    }
    formData.get("_id", "") === ""
      ? dispatch(postCreateDocument(formData.set("status", status), callBack))
      : dispatch(
          putDocumentUpdate(
            formData.set("status", status).set("editedBy", Auth_data?.email),
            callBack
          )
        );
  };

  const handleApproval = (data) => {
    const payload = {
      sender: Auth_data?.email,
      mentor: data.getIn(["Name", "email"], ""),
      documentId,
      // documentId: documentHistoryId,
      description: data.get("Description", ""),
      status: "New",
    };

    dispatch(postNotification(payload, callBack));
  };

  const handleDraft = (id) => {
    navigate(`/EditDocument?id=${id}`);
    setApproval(true);
  };

  const handleAdd = (types) => {
    setOpen(true);
    setType(types);
  };
  const handleShare = (data) => {
    setShareType(data);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleAccordion = () => {
    setExpanded((prev) => !prev);
  };

  const handleShareWith = (key, value) => {
    setFormData((prev) =>
      prev.set(
        key === "User" ? "shareWithUser" : "shareWithDepartment",
        fromJS(value)
      )
    );
    setShareType("");
  };

  return (
    <>
      <Snackbar show={true} message={messageStatus} reduxSetData={setData} />
      <Loader isLoading={loadingStatus} />
      <Box className={`bg-light ${classes.Forms}`}>
        <Box className="d-flex gap-3 flex-wrap">
          <div style={{ width: viewOnly ? "100%" : "94%" }}>
            {viewOnly ? (
              <DocumentAccordion
                expanded={expanded}
                handleAccordion={handleAccordion}
                data={formData}
                departmentList={allOptions.get("Department", List())}
                userList={Users}
              />
            ) : (
              <Accordion
                expanded={expanded}
                onChange={handleAccordion}
                sx={{
                  marginBottom: "3px",
                  background: "#A5ADBD",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div>
                    <h5>ADD DETAILS </h5>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ marginBottom: "3px", background: "white" }}
                >
                  <Box className={classes.FormRow}>
                    <Box className={classes.FormCol}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        label=" Document Name"
                        variant="outlined"
                        className={classes.FormControl}
                        autoComplete="none"
                        value={formData.get("DocumentName", "")}
                        onChange={(e) =>
                          handleChange(e.target.value, "DocumentName")
                        }
                      />
                    </Box>
                    <Box className={classes.FormCol}>
                      <Autocomplete
                        disablePortal
                        id="Department_Name"
                        value={
                          formData.getIn(["DepartmentName", "label"], "") !== ""
                            ? formData.get("DepartmentName", Map()).toJS()
                            : ""
                        }
                        size="small"
                        options={allOptions
                          .get("Department", List())
                          .map((item) => {
                            return { label: item.get("departmentName", "") };
                          })
                          .toJS()}
                        sx={{ width: "93%" }}
                        renderInput={(params) => (
                          <TextField {...params} label="Department Name" />
                        )}
                        onChange={(e, option) =>
                          handleChange(option, "DepartmentName")
                        }
                      />
                    </Box>
                  </Box>
                  <Box className={classes.FormRow}>
                    <Box className={classes.FormCol}>
                      <Autocomplete
                        value={formData.get("DocumentTags", List()).toJS()}
                        multiple
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          if (!event) return;
                          setInputValue(newInputValue);
                        }}
                        disablePortal
                        id="checkboxes-tags-demo"
                        options={allOptions
                          .get("Tags", List())
                          .map((item) => {
                            return { label: item.get("documentTags", "") };
                          })
                          .toJS()}
                        size="small"
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option, TagIndex) => (
                          <MenuItem
                            {...props}
                            className="d-flex justify-content-between p-2"
                            onMouseEnter={() =>
                              handleChangeShow(TagIndex.index)
                            }
                            onMouseLeave={() => setIsShown(false)}
                          >
                            <div>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={formData
                                  .get("DocumentTags", List())
                                  .some(
                                    (item) =>
                                      item.get("label", "") === option.label
                                  )}
                              />
                              {option.label}
                            </div>
                            {isShown[TagIndex.index] && (
                              <div className="d-flex gap-2">
                                <EditIcon
                                  sx={{ color: "#9CA3AF" }}
                                  onClick={(e) =>
                                    handleEditType(
                                      e,
                                      allOptions.getIn(
                                        ["Tags", TagIndex.index],
                                        ""
                                      ),
                                      "Tags"
                                    )
                                  }
                                />
                                <DeleteIcon
                                  sx={{ color: "#9CA3AF" }}
                                  onClick={(e) =>
                                    handleDeleteType(
                                      e,
                                      allOptions.getIn(
                                        ["Tags", TagIndex.index],
                                        ""
                                      ),
                                      "Tags"
                                    )
                                  }
                                />
                              </div>
                            )}
                          </MenuItem>
                        )}
                        sx={{ width: "93%" }}
                        renderInput={(params) => {
                          return <TextField {...params} label="Tag chip" />;
                        }}
                        onChange={(e, option) =>
                          handleChange(option, "DocumentTags")
                        }
                      />
                      <div>
                        <Tooltip title="ADD NEW" placement="top" arrow>
                          <IconButton onClick={() => handleAdd("Tags")}>
                            <LoupeIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Box>

                    <Box className={classes.FormCol}>
                      <Autocomplete
                        disablePortal
                        id="Product Name"
                        value={
                          formData.getIn(["ProductName", "label"], "") !== ""
                            ? formData.get("ProductName", Map()).toJS()
                            : ""
                        }
                        size="small"
                        options={allOptions
                          .get("Product", List())
                          .map((item) => {
                            return { label: item.get("productName", "") };
                          })
                          .toJS()}
                        sx={{ width: "93%" }}
                        renderInput={(params) => (
                          <TextField {...params} label=" Product Name" />
                        )}
                        renderOption={(props, option, TagIndex) => (
                          <MenuItem
                            {...props}
                            onMouseEnter={() =>
                              handleChangeShow(TagIndex.index)
                            }
                            onMouseLeave={() => setIsShown(false)}
                            className="d-flex justify-content-between"
                          >
                            {option.label}
                            {isShown[TagIndex.index] && (
                              <div className="d-flex gap-2">
                                <EditIcon
                                  sx={{ color: "#9CA3AF" }}
                                  onClick={(e) =>
                                    handleEditType(
                                      e,
                                      allOptions.getIn(
                                        ["Product", TagIndex.index],
                                        ""
                                      ),
                                      "Product"
                                    )
                                  }
                                />
                                <DeleteIcon
                                  sx={{ color: "#9CA3AF" }}
                                  onClick={(e) =>
                                    handleDeleteType(
                                      e,
                                      allOptions.getIn(
                                        ["Product", TagIndex.index],
                                        ""
                                      ),
                                      "Product"
                                    )
                                  }
                                  sh
                                />
                              </div>
                            )}
                          </MenuItem>
                        )}
                        onChange={(e, option) =>
                          handleChange(option, "ProductName")
                        }
                      />

                      <div>
                        <Tooltip title="ADD NEW" placement="top" arrow>
                          <IconButton onClick={() => handleAdd("Product")}>
                            <LoupeIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
          {!viewOnly && (
            <div className="mt-3">
              <Tooltip
                aria-describedby={id}
                title="share"
                placement="top"
                arrow
                onClick={handleClick}
              >
                <img
                  src={shareImg}
                  alt={"share"}
                  style={{ height: "30px", width: "30px" }}
                />
              </Tooltip>
              <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
              >
                <MenuItem sx={{ p: 1.5 }} onClick={() => handleShare("User")}>
                  <ReplyIcon />
                  share with user
                </MenuItem>
                <MenuItem
                  sx={{ p: 1.5 }}
                  onClick={() => handleShare("Department")}
                >
                  <ReplyIcon />
                  share with Department
                </MenuItem>
              </Popover>
            </div>
          )}
        </Box>

        <Box sx={{ height: "76vh", width: "100%" }}>
          {viewOnly ? (
            <div className="d-flex flex-column">
              <h3 className="my-3 text-decoration-underline">
                {formData.get("DocumentName", "")}
              </h3>
              <div
                className="bg-light editor_m0"
                dangerouslySetInnerHTML={{
                  __html: formData.get("Content", ""),
                }}
              ></div>
            </div>
          ) : (
            <Editors
              htmlContent={formData.get("Content", "")}
              handleChange={(editor) => {
                handleChange(editor, "Content");
              }}
            />
          )}
        </Box>
        {!viewOnly && (
          <Box
            sx={{
              textAlign: "start",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              gap: "10px",
            }}
          >
            <Dropdown className="">
              <Dropdown.Toggle variant="primary" id="dropdown-variants-Primary">
                {formData.get("_id", "") === "" ? "Publish" : "Update"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <span onClick={() => handleSubmit("publish", callBack)}>
                    without Approval{" "}
                  </span>
                </Dropdown.Item>
                <Dropdown.Item>
                  <span onClick={() => handleSubmit("draft", handleDraft)}>
                    with Approval
                  </span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Box>
        )}
      </Box>
      {open && (
        <Popup
          open={open}
          setOpen={setOpen}
          type={type}
          setType={setType}
          Update={Update}
          setUpdate={setUpdate}
          setIsShown={setIsShown}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      {approval && (
        <ApprovalPopup
          approval={approval}
          setApproval={setApproval}
          Auth_data={Auth_data}
          handleApproval={handleApproval}
        />
      )}
      {shareType !== "" && (
        <SharePopup
          open={shareType !== ""}
          shareType={shareType}
          setShareType={setShareType}
          handleSubmit={handleShareWith}
          options={
            shareType === "User"
              ? Users.filter(
                  (item) => item.get("email", "") !== Auth_data?.email
                )
                  .toJS()
                  .map((item) => {
                    return { ...item, label: item.username };
                  })
              : allOptions
                  .get("Department", List())
                  .toJS()
                  .map((item) => {
                    return { ...item, label: item.departmentName };
                  })
          }
          selected={formData.get(
            shareType === "User" ? "shareWithUser" : "shareWithDepartment",
            List()
          )}
        />
      )}
    </>
  );
};

export default DocumentForm;
