import {
  Box,
  Button,
  Divider,
  FormHelperText,
  MenuItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch, useSelector } from "react-redux";
import { selectAllTypeDetails, selectPagination } from "../../../Redux/BillingManagement/Selector";
import { fromJS, List, Map } from "immutable";
import {
  EditBillIDAction,
  getBilling,
  postBilling,
  putBilling,
  SnackbarAction,
  setData,
  deleteCloudType,
  getAllType,
  deleteServiceType,
} from "../../../Redux/BillingManagement/Action";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudPopup from "../ModelPopup/Cloud";
import ServicePopup from "../ModelPopup/Service";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import BillingView from "./BillView";
import { IconButton } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSweetAlert } from "../../../Utils/Sweetalert";
import { validateBilling } from "../../../Utils/Validation";

const useStyles = makeStyles({
  Forms: {
    width: "650px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    boxShadow: "0 3px 5px 2px rgba(167, 168, 170, 0.8)",
  },

  FormContainer: {
    width: "80%",
    height: "95%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  FormGroups: {
    width: "100%",
    maxHeight: "80%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "25px",
    padding: "8px",
  },
  DynamicForm: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
  },
  Quantity: {
    width: "30%",
    height: "40px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "2px solid #C4C4C4",
    borderRadius: "30px",
  },
  FormControl: {
    width: "100%",
  },

  FormCtrl: {
    width: "55%",
  },

  FormRow: {
    marginBottom: "20px",
  },

  header: {
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
    zIndex: 2,
  },

  dropDownBtn: {
    display: "flex",
    justifyContent: "space-between",
  },
});

//-------------------------------------------------------/
const initialService = fromJS({
  serviceType: "",
  perHour: 0,
  perMonth: 0,
  quantity: 1,
  ramNo: 0,
  cpuNo: 0,
  reason: "",
});
const initialFormData = fromJS({
  clientName: "",
  cloudType: "",
  service: [initialService],
  index: 0,
  totalCost: 0,
});

const Forms = ({ setOpen, type, value }) => {
  const classes = useStyles();
  const navCate = useNavigate();
  const dispatch = useDispatch();
  const sweetAlert = useSweetAlert();
  const [cloudPopup, setCloudPopup] = useState(false);
  const [servicePopup, setServicePopup] = useState(false);
  const allTypeDetails = useSelector(selectAllTypeDetails);
  const [Billing, setBilling] = useState(initialFormData);
  const [errorShow, setErrorShow] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [updateCloud, setUpdateCloud] = useState("");
  const [updateService, setUpdateService] = useState("");
  const pageNation = useSelector(selectPagination);
  const page = pageNation.get("page", 0);
  const limit = pageNation.get("limit", 0);
  const filterName = pageNation.get("filterName", "");

  const handleClickOpen = () => {
    setCloudPopup(true);
  };
  const handleService = () => {
    setServicePopup(true);
  };

  const handleChange = (onChangeValue, key, index = 0) => {
    var keyValue;
    if (key === "service") {
      keyValue = allTypeDetails
        .get("serviceTypesData", List())
        .filter((item) => item.get("serviceType", "") === onChangeValue)
        .get(0, Map())
        .set("quantity", Billing.getIn(["service", index, "quantity"], 1));
      setBilling(Billing.setIn([key, index], keyValue));
    } else if (key === "reason") {
      setBilling(Billing.setIn(["service", index, key], onChangeValue));
    } else {

      keyValue = onChangeValue;
      setBilling(Billing.set(key, keyValue));
      setIsShown(false);
    }
  };

  const handleDeleteService = (index) => {
    setBilling((prev) =>
      prev.set("service", prev.get("service", List()).delete(index))
    );
  };

  const SubmitCallBack = () => {
    dispatch(getBilling({
      page: page,
      limit: limit,
      filterName: filterName,
    }));
    setBilling(initialFormData);
    dispatch(EditBillIDAction(false));
    dispatch(SnackbarAction(true));
    navCate("/cloudBilling");
    setOpen(pre => pre.set("open", false));
  };

  const UpdateCallBack = () => {
    dispatch(getBilling({
      page: page,
      limit: limit,
      filterName: filterName,
    }));
    setBilling(initialFormData);
    dispatch(EditBillIDAction(false));
    navCate("/cloudBilling");
    setOpen(pre => pre.set("open", false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validation = validateBilling(Billing);
    if (validation !== "") {
      dispatch(setData(fromJS({ message: validation })));
      setErrorShow(true);
      return;
    }

    if (
      !Billing.get("clientName", "") ||
      !Billing.get("cloudType", "") ||
      !Billing.getIn(["service", 0, "serviceType"], "")
    ) {
    } else if (value !== undefined) {
      dispatch(putBilling(Billing, UpdateCallBack));
    } else {
      dispatch(postBilling(Billing, SubmitCallBack));
    }
  };

  const handleQuantity = (value, index) => {
    setBilling((prev) => {
      var preValue = prev.getIn(["service", index, "quantity"], "");
      var quantityUpdate =
        value === "add"
          ? ++preValue
          : prev.getIn(["service", index, "quantity"], "") > 1
            ? --preValue
            : 1;
      return prev.setIn(["service", index, "quantity"], quantityUpdate);
    });
  };

  useEffect(() => {
    value !== undefined && setBilling(value);
  }, [value]); // eslint-disable-line

  useEffect(() => {
    setBilling((prev) =>
      prev.set(
        "totalCost",
        prev
          .get("service", List())
          .reduce(
            (initial, sum, currentIndex, prev) =>
              prev.getIn([currentIndex, "perMonth"], 0) *
              prev.getIn([currentIndex, "quantity"], 0) +
              initial,
            0
          )
      )
    );
  }, [Billing.get("service", List())]); // eslint-disable-line

  const handleAddService = () => {
    setBilling((prev) =>
      prev.set("service", prev.get("service", List()).push(initialService))
    );
  };
  const handleEditType = (e, item, type) => {
    e.stopPropagation();

    if (type === "Cloud") {
      setCloudPopup(true);
      setUpdateCloud(item);
    } else {
      setServicePopup(true);
      setUpdateService(item);
    }
  };

  const callBack = () => {
    dispatch(getAllType());
    setBilling(Billing.set("cloudType", ""));
  };

  const handleDeleteType = (e, item, type) => {
    e.stopPropagation();
    console.log(item);
    sweetAlert({
      id: item.get("_id", ""),
      ApiCall: type === "Cloud" ? deleteCloudType : deleteServiceType,
      callBack,
    });
    // setCloudMenuOpen(false);
  };
  const handleChangeShow = (index) => {
    const showData = {
      [index]: !isShown[index],
    };
    setIsShown(showData);
  };

  return (
    <>
      {type !== "View" && (
        <Box
          className={`${type === "Edit" ? "height_500px" : "height_100"} ${classes.Forms
            }`}
          component={"form"}
          onSubmit={handleSubmit}
        >
          <Box className={`${classes.FormContainer} `}>
            <Box className={classes.FormGroups}>
              <Box>
                <TextField
                  error={
                    errorShow && Billing.get("clientName", "") === ""
                      ? true
                      : false
                  }
                  className={classes.FormControl}
                  id="outlined-basic"
                  label="Client Name"
                  variant="outlined"
                  autoComplete="none"
                  value={Billing.get("clientName", "")}
                  onChange={(e) => handleChange(e.target.value, "clientName")}
                  helperText={
                    errorShow && Billing.get("clientName", "") === ""
                      ? "clientName is required!"
                      : ""
                  }
                />
              </Box>
              <FormControl>
                <InputLabel
                  id="demo-controlled-open-select-label"
                  error={
                    errorShow && Billing.get("cloudType", "") === ""
                      ? true
                      : false
                  }
                >
                  Cloud Type
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"

                  error={
                    errorShow && Billing.get("cloudType", "") === ""
                      ? true
                      : false
                  }
                  className={`${classes.FormControl}  `}
                  label="Cloud Type"
                  variant="outlined"
                  autoComplete="none"
                  value={Billing.get("cloudType", "")}
                  onChange={(e) => handleChange(e.target.value, "cloudType")}
                  renderValue={(selected) => selected}
                  MenuProps={{
                    style: {
                      maxHeight: "500px",
                    },
                  }}
                >
                  {allTypeDetails
                    .get("cloudTypesData", List())
                    .map((item, index) => {
                      return (
                        <MenuItem
                          value={item.get("name", "")}
                          onMouseEnter={(e) => {
                            handleChangeShow(index);
                          }}
                          onMouseLeave={() => setIsShown(false)}
                          className="d-flex justify-content-between scroll-design "
                          sx={{ paddingBottom: "0" }}
                        >
                          <div>{item.get("name", "")}</div>
                          <div className="d-flex gap-3 ">
                            <div>
                              {isShown[index] && (
                                <EditIcon
                                  sx={{ color: "#9CA3AF" }}
                                  onClick={(e) =>
                                    handleEditType(e, item, "Cloud")
                                  }
                                />
                              )}
                            </div>
                            <div>
                              {isShown[index] && (
                                <DeleteIcon
                                  sx={{ color: "#9CA3AF" }}
                                  onClick={(e) =>
                                    handleDeleteType(e, item, "Cloud")
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </MenuItem>
                      );
                    })}

                  <Box
                    variant="div"
                    className={`d-flex justify-content-end mx-3 ${classes.header}`}
                  >
                    <Divider />
                    <Box className={classes.dropDownBtn}>
                      {/* <Button onClick={handleCloudClose}>Cancel</Button> */}
                      <Button
                        className="m-1 "
                        variant="outlined"
                        onClick={handleClickOpen}
                      >
                        + Add
                      </Button>
                    </Box>
                  </Box>
                </Select>

                {errorShow && Billing.get("cloudType", "") === "" ? (
                  <FormHelperText error>
                    "cloudType is required!"
                  </FormHelperText>
                ) : null}
              </FormControl>

              <Box>
                {Billing.get("service", List()).map((service, i) => {
                  return (
                    <Box className={classes.FormRow}>
                      <Box className={classes.DynamicForm}>
                        <FormControl style={{ width: "55%" }}>
                          <InputLabel
                            id="demo-controlled-open-select-label"
                            error={
                              errorShow && service.get("serviceType", "") === ""
                                ? true
                                : false
                            }
                          >
                            Services
                          </InputLabel>
                          <Select
                            labelId="demo-controlled-open-select-label"
                            id="demo-controlled-open-select"
                            // endAdornment={"text"}
                            error={
                              errorShow && service.get("serviceType", "") === ""
                                ? true
                                : false
                            }
                            label="Service"
                            variant="outlined"
                            autoComplete="none"
                            value={service.get("serviceType", "")}
                            onChange={(e) =>
                              handleChange(e.target.value, "service", i)
                            }

                            renderValue={(selected) => selected}
                            MenuProps={{
                              style: {
                                maxHeight: "300px",
                              },
                            }}
                          >
                            {allTypeDetails
                              .get("serviceTypesData", List())
                              .map((item, index) => {
                                return (
                                  <MenuItem
                                    value={item.get("serviceType", "")}
                                    onMouseEnter={(e) => {
                                      handleChangeShow(index);
                                    }}
                                    onMouseLeave={() => setIsShown(false)}
                                    className="d-flex justify-content-between scroll-design"
                                    sx={{ paddingBottom: "0" }}
                                  >
                                    {/* {item.get("serviceType", "")} */}
                                    <div>{item.get("serviceType", "")}</div>
                                    <div className="d-flex ">
                                      <div>
                                        {isShown[index] && (
                                          <EditIcon
                                            sx={{ color: "#9CA3AF" }}
                                            onClick={(e) =>
                                              handleEditType(e, item, "Service")
                                            }
                                          />
                                        )}
                                      </div>
                                      <div>
                                        {isShown[index] && (
                                          <DeleteIcon
                                            sx={{ color: "#9CA3AF" }}
                                            onClick={(e) =>
                                              handleDeleteType(
                                                e,
                                                item,
                                                "Service"
                                              )
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </MenuItem>
                                );
                              })}

                            <Box
                              variant="div"
                              className={`d-flex justify-content-end mx-2  ${classes.header}`}
                            >
                              <Divider />
                              <Box className={classes.dropDownBtn}>
                                <Box variant="div">
                                  {/* <Button onClick={handleServiceClose}>Cancel</Button> */}
                                </Box>
                                <Button
                                  variant="outlined"
                                  onClick={handleService}
                                >
                                  + Add
                                </Button>
                              </Box>
                            </Box>
                          </Select>
                        </FormControl>
                        <Box>
                          <TextField
                            value={service.get("reason", "")}
                            onChange={(e) =>
                              handleChange(e.target.value, "reason", i)
                            }
                            id="outlined-basic"
                            label="Reason"
                            variant="outlined"
                          />
                        </Box>
                        <Box className={classes.Quantity}>
                          <Box>
                            <RemoveIcon
                              sx={{ fontSize: "18px", cursor: "pointer" }}
                              onClick={() => handleQuantity("sub", i)}
                            />
                          </Box>

                          <Box>
                            <label>{service.get("quantity", 1)}</label>
                          </Box>
                          <Box>
                            <AddIcon
                              sx={{ fontSize: "20px", cursor: "pointer" }}
                              onClick={() => handleQuantity("add", i)}
                            />
                          </Box>
                        </Box>
                        <Box>
                          {Billing.get("service", List()).size > 1 && (
                            <IconButton onClick={() => handleDeleteService(i)}>
                              <DeleteForeverIcon color="error" />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box>
              <Button onClick={() => handleAddService()}>+ Add Service</Button>
            </Box>
            <Box sx={{ margin: "10px 0" }}>
              <label>
                Total cost: {Billing.get("totalCost", 0)?.toFixed(2)}
              </label>
            </Box>

            <Box sx={{ textAlign: "center" }}>
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Box>

            {cloudPopup && (
              <CloudPopup
                options={allTypeDetails.get("cloudTypesData", List())}
                cloudPopup={cloudPopup}
                setCloudPopup={setCloudPopup}
                Billing={Billing}
                setBilling={setBilling}
                updateCloud={updateCloud}
                setUpdateCloud={setUpdateCloud}
              />
            )}

            {servicePopup && (
              <ServicePopup
                options={allTypeDetails.get("serviceTypesData", List())}
                servicePopup={servicePopup}
                setServicePopup={setServicePopup}
                updateService={updateService}
                setUpdateService={setUpdateService}
              />
            )}
          </Box>
        </Box>
      )}

      {type === "View" && <BillingView Billing={value} />}
    </>
  );
};

export default Forms;
