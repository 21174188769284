import React from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { CategoryIDAction, CategoryNameAction, deleteCategoryTypeApi, EditCategoryAction } from "../../../../Redux/AssetsManagement/Action";

const MainCategoryTabel = () => {
  const CategoryGetApi = useSelector((state) => state.AssetsManagement).get("geCategoryApi","");

  const dispatch = useDispatch();

  const handelDelete = (id) => {
    dispatch(deleteCategoryTypeApi(id));
  };
  
  const handelEdit = (index, id, type) => {
    dispatch(EditCategoryAction(index))
    dispatch(CategoryIDAction(id))
    dispatch(CategoryNameAction(type))
  };

  return (
    <div>
      <Paper>
        <TableContainer sx={{ maxHeight: "250px" }}>
          <Table aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>NO</TableCell>
                <TableCell>Category Name</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {CategoryGetApi.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.get("assetsType", "")}</TableCell>
                    <TableCell>
                    <div className="d-flex">
                      <Tooltip title="Edit" arrow placement="top">
                        <IconButton
                          sx={{ marginRight: "20px" }}
                          onClick={() =>
                            handelEdit(
                              index,
                              item.get("_id", ""),
                              item.get("assetsType", "")
                            )
                          }
                        >
                          <BorderColorIcon color="success" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete" arrow placement="top">
                        <IconButton
                          onClick={() => handelDelete(item.get("_id", ""))}
                        >
                          <DeleteForeverIcon color="error" />
                        </IconButton>
                      </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default MainCategoryTabel;
