import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";

const EditAndDelete = ({ show, title, handleClose, handleClick, data,displayData }) => {
  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle> {`Delete ${title}`}</DialogTitle>
      <DialogContent>
        <div>
          <p>
            Are you sure you want to delete <span class="bold">{displayData}</span> {title}
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={(e) => handleClick(e, data,title)} color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAndDelete;
