import React, { useEffect } from "react";
import ReplySidePage from "./ReplySidePage/ReplySidePage";
import ReplySideIcon from "./ReplySideIcon/ReplySideIcon";
import ReplySideBar from "./ReplySideBar/ReplySideBar";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getIndividualIssue } from "../../../../../Redux/ClientSupportManagement/Action";

/*-------------------------------------UtilsStart----------------------------------------*/

/*-------------------------------------UtilsStart----------------------------------------*/

const ReplyMainPage = ({
  open,
  setOpen,
  handleForwardOpen,
  handleReplyAction,
  ticketData,
  setTicketData,
}) => {
  const [expanded, setExpanded] = useState("panel1");
  const location = useLocation();
  const viewTicketID = new URLSearchParams(location.search).get("id");
  const [mailReply, setMailReply] = useState([]);
  const dispatch = useDispatch();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const styles = [
    {
      minHeight: "calc(100vh - 130px)",
      width: "100%",
      display: "grid",
      gridTemplateColumns: `1fr ${!open.forward ? "minmax(100px, 100px)" : ""}`,
    },
    {
      height: "calc(100vh - 130px)",
      position: "relative",
      width: "100%",
      display: "grid",
      textAlign: "justify",
      gridTemplateColumns: `2.6fr ${open.sidebar ? "1fr" : ""}`,
    },
  ];

  const handleData = (data) => {
    setTicketData(data.issueIndividualData);
    setMailReply(data.issueIndividualReply);
  };

  useEffect(() => {
    dispatch(
      getIndividualIssue(viewTicketID, (data) => {
        handleData(data);
      })
    );
  }, []); //eslint-disable-line

  return (
    <div style={styles[0]}>
      <div style={styles[1]}>
        <ReplySidePage
          open={open}
          setOpen={setOpen}
          handleForwardOpen={handleForwardOpen}
          handleReplyAction={handleReplyAction}
          ticketData={ticketData}
          viewTicketID={viewTicketID}
          mailReply={mailReply}
          handleData={handleData}
        />
        {open.sidebar && (
          <ReplySideBar
            expanded={expanded}
            handleChange={handleChange}
            ticketData={ticketData}
          />
        )}
      </div>
      {!open.forward && <ReplySideIcon handleChange={handleChange} />}
    </div>
  );
};

export default ReplyMainPage;
