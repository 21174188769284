import React, { useState } from "react";
import { TablePagination } from "@mui/material";

const Pagination = ({ count, handleChange }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleChange({
      start: newPage * rowsPerPage,
      end: newPage * rowsPerPage + rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setRowsPerPage(value);
    handleChange({ start: 0 * value, end: 0 * value + value });
    setPage(0);
  };

  return (
    <TablePagination
      className="admin-table-pagination"
      component="div"
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPageOptions={[5, 10, 25]}
      showLastButton={true}
      showFirstButton={true}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default Pagination;
