import React from "react";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import "./ReplySidePage.css";
import { Avatar } from "@mui/material";
import { ButtonDesign } from "../../../ButtonDesign/ButtonDesign";
import { ButtonData } from "../../../ButtonDesign/ButtonData";
import EmailEditor from "../../../EmailEditor/EmailEditor";
import { useRef } from "react";
import { useEffect } from "react";
import moment from "moment";
import { makeStyles, Modal, Backdrop, Fade } from "@material-ui/core";

//utils
import { daysBeforeCalculation } from "../../../../../../Utils/DaysBeforeCalculation";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthType } from "../../../../../../Redux/TestCase/Selector";
import {
  createReplyMail,
  updateClientStatus,
} from "../../../../../../Redux/ClientSupportManagement/Action";
import { getUserData } from "../../../../../../Redux/TestCase/Action";
import {
  getSupportClient,
  updateAssigneeName,
} from "../../../../../../Redux/ClientSupportManagement/Action";
import {
  selectSupportClientList,
  selectAllAgentData,
} from "../../../../../../Redux/ClientSupportManagement/Selector";
import { duplicateNumberForNoAssignee } from "../../../../../../Utils/DuplicateNumber";

const useStyles = makeStyles((theme) => ({
  modal: {
    margin: "0px auto",
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundcolor: "red",
    },
  },
  img: {
    outline: "none",
  },
  customColor: {
    color1: "#FF0000",
    color2: "green",
    color3: "yellow",
  },
}));

const isNotValid = [null, undefined, "", duplicateNumberForNoAssignee];
export function getLocalStorageItem(key) {
  const value = JSON.parse(localStorage.getItem(key));
  return value;
}
function base64ToFile(
  base64String,
  fileName = "image.png",
  contentType = "image/png"
) {
  const base64StringWithoutPrefix = base64String.replace(
    /^data:[^;]+;base64,/,
    ""
  );
  const addPadding = (str) => {
    while (str.length % 4) {
      str += "=";
    }
    return str;
  };
  const base64StringWithPadding = addPadding(base64StringWithoutPrefix);
  const byteCharacters = atob(base64StringWithPadding);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });

  // Create a File object
  const file = new File([blob], fileName, { type: contentType });
  return file;
}

function extractImageTags(htmlString) {
  // Create a new DOMParser
  const parser = new DOMParser();

  // Parse the HTML string into a DOM document
  const doc = parser.parseFromString(htmlString, "text/html");

  // Use querySelectorAll to get all image tags
  const imageTags = doc.querySelectorAll("img");

  // Convert the NodeList to an array and extract the src attribute
  const imageSrcArray = Array.from(imageTags).map((img) =>
    base64ToFile(img.getAttribute("src"))
  );

  return imageSrcArray;
}
function removeImageTags(htmlString) {
  // Use a regular expression to match and remove image tags
  const withoutImages = htmlString.replace(/<img\b[^>]*>/gi, "");

  return withoutImages;
}
const getConstructedData = (sendData) => {
  const subject = sendData.Subject;
  const formData = new FormData();
  if (subject.includes("<img")) {
    sendData.file = extractImageTags(subject);
    sendData.file.forEach((file) => {
      formData.append("file", file);
    });
    sendData.Subject = removeImageTags(subject);
  }
  const jsonString = JSON.stringify(sendData);
  formData.append("request", jsonString);
  return formData;
};

const ReplySidePage = ({
  open,
  setOpen,
  handleForwardOpen,
  handleReplyAction,
  ticketData,
  viewTicketID,
  mailReply,
  handleData,
}) => {
  const scrollRef = useRef();
  const currentDate = moment();
  const dispatch = useDispatch();
  const givenDate = moment(ticketData?.createdAt);
  const rangeBetween = daysBeforeCalculation(currentDate, givenDate);
  const Auth_data = useSelector(selectAuthType).toJS();
  const classes = useStyles();
  const supportClientList = useSelector(selectSupportClientList);
  const allAgentData = useSelector(selectAllAgentData);

  // const SupportClientList = useSelector(selectSupportClientList).toJS();
  // const Users = useSelector(selectUsers).toJS();

  // const getFilterData = SupportClientList.filter(
  //   (data) => data.clientName === ticketData?.clientName
  // ).map((item) => item.Assigned);

  // const finalFilter = Users.filter((item) =>
  //   getFilterData[0]?.includes(item._id)
  // ).map((item) => {
  //   return { userName: item.username, email: item.email };
  // });

  const [sendData, setSendData] = useState({
    ticketId: viewTicketID,
    toMail: "",
    from: Auth_data?.name,
    status: "",
    ticketNo: "",
    userName: "",
    id: "",
    issueData: "",
  });

  const [openAttachmentPhoto, setOpenAttachmentPhoto] = useState(false);
  const [attachmentImage, setAttachmentImage] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const handleEditorChange = (value) => {
    setSendData((prev) => {
      return { ...prev, Subject: value.getData() };
    });
  };
  const handleDelete = () => {
    setOpen((prev) => {
      return { ...prev, isReplay: false };
    });
  };
  const callBack = (data) => {
    handleData(data);
    setSendData((prev) => {
      return { ...prev, Subject: "" };
    });
  };

  const handleSendReply = () => {
    if (sendData.Subject) {
      const constructSendData = getConstructedData(sendData);
      dispatch(createReplyMail(constructSendData, callBack, viewTicketID));
      setOpen((prev) => {
        return { ...prev, isReplay: false, scrollView: false };
      });
      dispatch(
        updateClientStatus({ supportId: id, currentStatus: "Inprogress" })
      );
    } else {
      alert("Please Enter Subject");
    }
  };

  const handleGetLastSentTime = (createTime) => {
    return daysBeforeCalculation(currentDate, moment(createTime));
  };

  const handleOpenAttachment = (image) => {
    setAttachmentImage(image);
    setOpenAttachmentPhoto(true);
  };

  useEffect(() => {
    let resizeObserver;
    const handleResize = () => {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    };

    if (open.scrollView) {
      resizeObserver = new ResizeObserver(handleResize);
      if (scrollRef.current) {
        resizeObserver.observe(scrollRef.current);
      }
    }
    const timer = setTimeout(() => {
      setOpen((prev) => {
        return { ...prev, scrollView: false };
      });
    }, 1000);

    return () => {
      clearTimeout(timer);
      if (resizeObserver && scrollRef.current) {
        resizeObserver.unobserve(scrollRef.current); // eslint-disable-line
      }
    };
  }, [open.scrollView]); // eslint-disable-line

  useEffect(() => {
    if (ticketData) {
      setSendData((prev) => {
        return {
          ...prev,
          toMail: ticketData?.email,
          userName: ticketData?.name,
          ticketNo: ticketData?.ticketNo,
          status: ticketData?.status,
          id: id,
          // agent: finalFilter,
          issueData: ticketData?.subject,
        };
      });
    }
  }, [ticketData]); // eslint-disable-line

  useEffect(() => {
    dispatch(getUserData());
    if (supportClientList.size === 0) dispatch(getSupportClient());
  }, []); //eslint-disable-line

  useEffect(() => {
    const checkAssigneeName = isNotValid.includes(ticketData?.assignee + "");
    const checkAssigneeField = isNotValid.includes(ticketData?.assignee);
    const from = mailReply[0]?.from;
    const role = getLocalStorageItem("authRes").role !== "Admin";
    const check =
      mailReply.length > 0 && role && (checkAssigneeName || checkAssigneeField);
    const checkClientSupportUser = allAgentData
      .map((agentDetails) => agentDetails.get("username", ""))
      .includes(from);
    const replyAgentName = allAgentData.find(
      (item) => item.get("username", "") === from
    );
    if (check && checkClientSupportUser) {
      dispatch(
        updateAssigneeName({
          id: id,
          AssigneeId: replyAgentName.get("userId", ""),
        })
      );
    }
  }, [mailReply]); //eslint-disable-line

  return (
    <div className="overflow-auto ReplySidePage">
      <div className="ticket_details_header">
        <div className="ticket_header_container">
          <div className="d-flex justify-content-between w-100">
            <div>
              <span>
                <EmailOutlinedIcon className="ticket_header_Icon" />
              </span>
              <span className="ticket_header_title mx-3">
                {ticketData?.subject}
              </span>
            </div>
            <div className="ticket_header_title  fw-light mx-2">
              <span>
                <h6 className="fw-bold">
                  CurrentStatus :{" "}
                  <span
                    className={
                      ticketData?.status === "Inprogress"
                        ? "badge  bg-warning"
                        : ticketData?.status === "Open"
                        ? "badge bg-success"
                        : "badge bg-danger"
                    }
                  >
                    {ticketData?.status}
                  </span>
                </h6>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="ticket-created-info ">
        Created by
        <span className="ticket-created-title">
          {supportClientList
            .find((data) => data.get("_id", "") === ticketData?.clientId)
            ?.get("clientName", "")}
        </span>
      </div>
      <div className="ticket_details_wrapper" ref={scrollRef}>
        <div className="ticket_Report_description">
          <div className="ticket_Report_item__header">
            <Avatar className="ticket_Report_header_avatar" alt="Sharp">
              {ticketData?.name.split("")[0].toUpperCase()}
            </Avatar>

            <div className="ticket_Report_header_desc-info">
              <div className="ticket_Report_header_sender-info">
                <p className="text-primary text-capitalize mb-0">
                  {ticketData?.name}{" "}
                </p>
              </div>
              <div className="ticket_Report_header_created-time">
                <span>
                  {rangeBetween?.diffDate > 0
                    ? `${rangeBetween?.diffDate} ${
                        rangeBetween?.key
                      } ago ${moment(ticketData?.createdAt).format(
                        "ddd, D MMM YYYY [at] h:mm A"
                      )}`
                    : ""}
                </span>
              </div>
            </div>
          </div>
          <div className="ticket_Report_item__content">
            <div>
              <span>
                <EmailOutlinedIcon className="ticket_Report_content_icon" />
              </span>
            </div>
            <div>
              <div style={{ width: "100%" }}>{ticketData?.description}</div>
              <br />
              {ticketData?.attachment.map((item, index) => {
                return (
                  <>
                    <div
                      class="accordion mt-3"
                      id={`accordionExample${index}`}
                      key={index}
                      style={{ width: "300px" }}
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id={`headingOne${index}`}>
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne${index}`}
                            aria-expanded="true"
                            aria-controls={`collapseOne${index}`}
                          >
                            document 0{index + 1}
                          </button>
                        </h2>
                        <div
                          id={`collapseOne${index}`}
                          class="accordion-collapse collapse "
                          aria-labelledby={`headingOne${index}`}
                          data-bs-parent={`#accordionExample${index}`}
                        >
                          <div class="accordion-body">
                            <img
                              style={{ cursor: "pointer" }}
                              alt="img"
                              width="100%"
                              src={item}
                              onClick={() => handleOpenAttachment(item)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              {openAttachmentPhoto && (
                <Modal
                  className={classes.modal}
                  open={openAttachmentPhoto}
                  onClose={() => setOpenAttachmentPhoto(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade
                    in={openAttachmentPhoto}
                    timeout={500}
                    className={classes.img}
                  >
                    <img
                      src={attachmentImage}
                      alt="asd"
                      style={{ maxHeight: "90%", maxWidth: "90%" }}
                    />
                  </Fade>
                </Modal>
              )}
              <br />
              Thanks, <br />
              {ticketData?.name}
            </div>
          </div>
        </div>

        {mailReply.length > 0 &&
          mailReply.map((item, index) => {
            return (
              <div className="ticket_Reply_description ">
                <div className="ticket_Reply_item__header">
                  <Avatar
                    className="ticket_Report_header_avatar"
                    alt="Sharp"
                    src="/static/images/avatar/1.jpg"
                  />

                  <div className="ticket_Report_header_desc-info">
                    <div className="ticket_Report_header_sender-info">
                      <p className="text-primary">
                        {item.from}{" "}
                        <span className="text-secondary">replied</span>
                      </p>
                    </div>
                    <div className="ticket_Report_header_created-time">
                      <span>
                        {handleGetLastSentTime(item?.createdAt)?.diffDate
                          ? handleGetLastSentTime(item?.createdAt)?.diffDate
                          : "0 minute"}{" "}
                        {handleGetLastSentTime(item?.createdAt)?.key} ago (
                        {moment(item?.createdAt).format(
                          "ddd, D MMM YYYY [at] h:mm A"
                        )}
                        )
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ticket_Reply_item__content">
                  <div>
                    <span>
                      <EmailOutlinedIcon className="ticket_Report_content_icon" />
                    </span>
                  </div>
                  <div>
                    <div
                      className="mail-reply-images"
                      dangerouslySetInnerHTML={{ __html: item.Subject }}
                    ></div>
                  </div>
                  {item?.subjectAttachment?.map((item) => {
                    return (
                      <div
                        class="accordion mt-3"
                        id={`accordionExample${index}`}
                        key={index}
                        style={{ width: "300px" }}
                      >
                        <div class="accordion-item">
                          <h2
                            class="accordion-header"
                            id={`headingOne${index}`}
                          >
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${index}`}
                              aria-expanded="true"
                              aria-controls={`collapseOne${index}`}
                            >
                              document 0{index + 1}
                            </button>
                          </h2>
                          <div
                            id={`collapseOne${index}`}
                            class="accordion-collapse collapse "
                            aria-labelledby={`headingOne${index}`}
                            data-bs-parent={`#accordionExample${index}`}
                          >
                            <div class="accordion-body">
                              <img
                                style={{ cursor: "pointer" }}
                                alt="img"
                                width="100%"
                                src={item}
                                onClick={() => handleOpenAttachment(item)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}

        {!open.isReplay && (
          <div className="ticket_reply_bar">
            <Avatar className="ticket_Report_header_avatar" alt="Sharp">
              {ticketData?.name.split("")[0].toUpperCase()}
            </Avatar>
            <div className="d-flex gap-3">
              <ButtonDesign
                name={ButtonData.Reply_Name}
                icon={ButtonData.Reply_icon}
                tooltip={ButtonData.Reply_tooltip}
                handleAction={handleReplyAction}
              />

              <ButtonDesign
                name={ButtonData.Forward_Name}
                icon={ButtonData.Forward_icon}
                tooltip={ButtonData.Forward_tooltip}
                handleAction={handleForwardOpen}
              />
            </div>
          </div>
        )}
        <div>
          {open.isReplay && (
            <EmailEditor
              handleDelete={handleDelete}
              ticketData={ticketData}
              handleSendReply={handleSendReply}
              handleEditorChange={handleEditorChange}
              sendData={sendData}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ReplySidePage;
